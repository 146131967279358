import {emptySwFaqFilter, SwFaqFilters } from "./swFaq.models";
import {createContext, Dispatch, useMemo, useReducer} from "react";
import {useRouteMatch} from "react-router-dom";
import {withArrayActionLogger} from "Components/reduxLogger";
import {FaqAbstract} from "../Faqs/faq.models";
import {Software} from "Entities/Software/software.services";

interface State {
    urlBasePath: string,
    filters: SwFaqFilters,
    activeSoftware?: Software,
    faqModel?: FaqAbstract,
}

export const makeInitialContext = (urlBasePath: string, activeSoftware?: Software): State => ({
    urlBasePath: urlBasePath,
    filters: emptySwFaqFilter,
    activeSoftware: activeSoftware,
    faqModel: undefined,
});
export type SwFaqManagerAction =
    //| ['INIT', { urlBasePath: string, activeSoftware?: Software }]
    ['SET_FILTERS', SwFaqFilters]
    | ['SET_FAQ_MODEL', FaqAbstract | undefined]
    | ['SET_ACTIVE_SOFTWARE', Software | undefined];
const _reducer = (state: State, action: SwFaqManagerAction): State => {
    switch (action[0]) {
        // case 'INIT':
        //     return makeInitialContext(action[1].urlBasePath, action[1].activeSoftware);
        case 'SET_FILTERS':
            return {
                ...state,
                filters: action[1]
            };
        case 'SET_FAQ_MODEL':
            return {
                ...state,
                faqModel: action[1]
            };
        case 'SET_ACTIVE_SOFTWARE':
            return {
                ...state,
                activeSoftware: action[1]
            };
        default:
            return state;
    }
}
const reducer = withArrayActionLogger(_reducer, 'SwFaqsManagerContext');

export const SwFaqsManagerContext = createContext<[State, Dispatch<SwFaqManagerAction>]>([
    makeInitialContext(''),
    (action: SwFaqManagerAction) => { /* */
    }  // dummy dispatcher
]);
export const useSwFaqsManagerReducer = () => {

    const routeMatch = useRouteMatch();
    const urlBasePath = routeMatch.url;
    
    const initialContext = useMemo(() => {
        console.log("Making SwFaqManager initial context");
        return makeInitialContext(urlBasePath);
    }, [urlBasePath]);

    return useReducer(reducer, initialContext);
}
