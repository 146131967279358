import React, {useContext} from "react";
import {useSoftwaresWithFaqs} from "./swFaq.services";
import {useTranslation} from "react-i18next";
import {CollectionLayout} from "Components/Collection/CollectionLayout";
import {Status} from "Components/reduxLogger";
import {RemoteCollectionPagination} from "Components/RemoteEntities/Collection/Pagination";
import {FaqSoftwaresList} from "./FaqSoftwaresList";
import {SwFaqFiltersToolbar} from "./SwFaqFiltersToolbar";
import {SwFaqsManagerContext} from "./SwFaqsManagerContext";

export function SelectFaqSoftware() {

    const [context] = useContext(SwFaqsManagerContext);

    const [softwares, softwaresApi] = useSoftwaresWithFaqs(context.filters);

    const [t] = useTranslation();

    return (
        <CollectionLayout id='SwFaqsExplorer'
                          title={t('faqs')}
                          hasNewButton={false}
                          isLoading={softwares.status === Status.Pending}
        >
            {{
                controls: <SwFaqFiltersToolbar/>,
                pagination: softwares.isPaginated
                    ? <RemoteCollectionPagination paginated={softwares} methods={softwaresApi}/>
                    : null,

                content: <FaqSoftwaresList softwares={softwares.entities} withPdf filters={context.filters}/>
            }}
        </CollectionLayout>

    )
}