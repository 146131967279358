import React, { useCallback, useEffect, useState } from "react";
import { Snackbar, Box, Button, Alert } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useForm, UseFormProps, UseFormReturn, DefaultValues, UnpackNestedValue, SubmitErrorHandler, SubmitHandler, FieldValues } from "react-hook-form";
import { useGuard, useGuardedExec } from 'Components/GuardService';


export type EntityFormCustomRenderProps<TModel extends FieldValues, TContext = Record<string, never>> = UseFormReturn<TModel> & {
  rootName: string,
  disabled?: boolean,
  context: TContext,
  defaultValues: DefaultValues<TModel> | null,
  onPendingChanges: (isDirty: boolean) => void,
  onSave?: () => void, // handles Save button click
  onCancel?: () => void, // handles Back button click 
  onDelete?: () => void, // handles Delete button click 
  onReset?: () => void   // callback when form is reset to initial values
}

/**
 * Container that holds a react-form-hook instance for an entity, 
 * provides callbacks to Save/Delete the entity instance, GoBack to exit the form. 
 * Child component is expected to render form inputs and Save/Delete/GoBack buttons.
 * 
 * @param props see below
 * @returns 
 */
export function EntityFormCustom<TModel extends FieldValues, TContext = Record<string, never>>(props: {

  name: string,
  defaultValues?: DefaultValues<TModel>,

  onSubmitValid?: SubmitHandler<TModel>,
  // what to do when user clicks Save and the form values are valid
  // By default, EntityForm will convert empty strings to undefined, coalesce form values 
  // with defaultModel values, and pass the resulting entity instance to onSave() (see below)
  // In dev mode will also console.log entity instance.

  onSubmitInvalid?: SubmitErrorHandler<TModel>,
  // what to do when user clicks Save and the form values are NOT valid
  // By default, in dev mode will console.log errors. 

  onSave?: (entity: UnpackNestedValue<TModel>) => void, // handle Save button click when the form values are valid.
  onCancel?: () => void, // handle Back button click
  onDelete?: () => void, // handle Delete button click
  onReset?: () => void   // callback when form is reset to initial values

  onBeforeSave?: () => void, // optional action that will be executed before getting the form values when the Save button is clicked. 
  // Useful to get value of inputs such as rich text editors, that typically do not push their value to the form upon change or blur events (because it would inefficient),
  // but, instead, have their own Save button to convert their content to the form field type.

  canDelete?: boolean, // default: true
  formProps?: Omit<UseFormProps<TModel>, 'defaultValues'>,
  snackbarInvalidValues?: false,
  disabled?: boolean,
  context: TContext,
  children: React.FunctionComponent<EntityFormCustomRenderProps<TModel, TContext>>  // renders controls for entity fields and save/cancel/delete/reset buttons
}) {

  const {name: formName, disabled} = props;

  //console.log("EntityFormCustom", props);
  
  //* FORM API
  const form = useForm<TModel>({
    defaultValues: props.defaultValues,
    mode: 'onBlur',
    ...props.formProps
  });

  //* HANDLE DEFAULT VALUES CHANGED

  const { reset } = form;
  const { defaultValues } = props;

  React.useEffect(() => {
    if (defaultValues) {
      process.env.NODE_ENV === 'development' && console.log("EntityForm reset", defaultValues);
      reset(defaultValues);
    }
  }, [defaultValues, reset]);


  //* HANDLE DIRTY STATE

  const { formState: { isDirty: isFormDirty } } = form;
  const [forceDirty, setForceDirty] = useState(false);
  const isDirty = isFormDirty || forceDirty;

  const [raiseGuard, removeGuard] = useGuard();
  const {name} = props;
  // Make sure navigation guard will be removed on unmounting. 
  React.useEffect(() => { return (() => removeGuard(name)) }, [name, removeGuard]);
  React.useEffect(() => {
    if (!disabled) {
      if (isDirty) {
        raiseGuard(name);
      } else {
        removeGuard(name);
      }
    }
    //return removeGuard; 
    // don't do cleanup here, because useEffect's cleanup runs at every render, not only when component unmounts (see https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/) 
    // instead, do the cleanup in a separate useEffect(), see above
  }, [isDirty, raiseGuard, removeGuard, formName, disabled, name]);

  //* HANDLE CANCEL CLICKED

  const { onCancel } = props;
  const guardedExec = useGuardedExec();
  const handleCancelClicked = useCallback(() => {
    if (onCancel) {
      guardedExec(onCancel);
    } 
  }, [guardedExec, onCancel]);
  
  //* HANDLE SAVE CLICKED: if errors notify, if correct save

  const [openInvalidNotification, setOpenInvalidNotification] = React.useState(false);

  const { onSubmitValid, onSubmitInvalid, onSave, onBeforeSave, onReset } = props;

  const handleSaveClicked = useCallback(() => {
    if (onBeforeSave) {
      onBeforeSave();
    }
    form.handleSubmit(
      onSubmitValid ?? ((data) => {
        if (onSave)
          onSave(data);
      }),
      onSubmitInvalid ?? ((errors) => {
        setOpenInvalidNotification(true);
        process.env.NODE_ENV === 'development' && console.log('FORM ERRORS', errors);
      })
    )();
  }, [form, onSubmitInvalid, onSubmitValid, onSave, onBeforeSave]);


  //* HANDLE RESET CLICKED: reset form to default values

  const handleResetClicked = useCallback(() => {
    reset(defaultValues);
    if (onReset) onReset();
  }, [defaultValues, reset, onReset]);

  const { t } = useTranslation();

  //* RENDER
  return <>
    {props.children({ /* Component that renders form controls for the specific entity */
      ...form,
      rootName: props.name,
      defaultValues: props.defaultValues ?? null,
      context: props.context,
      disabled: disabled,
      onPendingChanges: setForceDirty,
      onCancel: props.onCancel ? handleCancelClicked : undefined,
      onSave: (!disabled && props.onSave) ? handleSaveClicked : undefined,
      onReset: (!disabled && props.onReset) ? handleResetClicked : undefined,
      onDelete: !disabled ? props.onDelete : undefined
    })}
    {props.snackbarInvalidValues != false && (
      <Snackbar id={`entity-form-{props.id}-notify`}
      autoHideDuration={2800}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={openInvalidNotification}
      onClose={() => setOpenInvalidNotification(false)}
    >
      <Alert onClose={() => setOpenInvalidNotification(false)} severity='error'>
        {t('invalidValues')}
      </Alert>
    </Snackbar>
    )}
  </>;
}







