import { tabA11yProps, TabPanel } from 'Components/Containers/TabPanel';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Paper, Stack, Tab, Tabs, useTheme } from '@mui/material';

import TranslationForm, { FaqTranslationFormProps } from './FaqTranslationForm';


export default function FaqTranslationsContainer(props: FaqTranslationFormProps) {

   const { control } = props;

   const fieldArray = useFieldArray({ name: 'translations', control, keyName: '_fk' });
   const { fields, remove, append } = fieldArray;

   const [t, { language: currentLang }] = useTranslation();

   //* Ensure there are all languages de, it, en
   useEffect(() => {
      if (!fields.find(x => x.language === 'de')) { append({ language: 'de' }) }
      if (!fields.find(x => x.language === 'it')) { append({ language: 'it' }) }
      if (!fields.find(x => x.language === 'en')) { append({ language: 'en' }) }
   }, [fields, append]);


   //* Active tab
   const [activeTab, setActiveTab] = useState(0);
   const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
   };

   //* Sort translations with the current language first
   useEffect(() => {
      let translationIndexToShow = fields.findIndex(x => x.language === currentLang);
      if (translationIndexToShow === -1) {
         translationIndexToShow = fields.findIndex(x => x.title?.length > 0)
         if (translationIndexToShow === -1) {
            translationIndexToShow = 0;
         }
      }
      setActiveTab(translationIndexToShow);
   }, [fields, currentLang, setActiveTab]);

   
   const theme = useTheme();

   return (
      <Paper variant='outlined' sx={{ m: 1 }}>

         <Stack direction='row' sx={{ borderBottom: 1, borderColor: theme.palette.divider, justifyContent: 'space-between' }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="text tabs">
               {fields.map((field, index) => <Tab key={index} label={t(field.language)} {...tabA11yProps(0)} />)}
            </Tabs>
         </Stack>

         {fields.map((field, index) => (
            <TabPanel activeIndex={activeTab} index={index} key={index}>
               <TranslationForm
                  {...props}
                  index={index}
                  fieldArray={fieldArray}
                  field={field}
                  path={`translations.${index}`}
                  key={field['_fk']}
                  onRemove={() => remove(index)}
               />
            </TabPanel>
         ))}
      </Paper>
   )
}


