import { Status, stoppableWait, wait } from 'Components/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarNotify, NotificationParams } from 'Components/Feedback/SnackbarNotify';
import { useCancelable } from 'Components/cancelable';
import { SnackbarOrigin } from '@mui/material';
import { RemoteEntityState } from './entity.state';

export function RemoteEntityResultHandler<TModel>(props: RemoteEntityState<TModel> & {
  onSaved?: (entity: TModel) => void,
  onDeleted?: () => void,
  onError?: (err: any) => void,
  translationBase?: string,
  notifySuccess?: boolean, // default: true
  notifyError?: boolean, // default: true
  bold?: boolean, // defaults to true,
  notificationPosition?: SnackbarOrigin | undefined
}) {

  const { t } = useTranslation();

  const [notification, setNotification] = React.useState<NotificationParams | null>(null);
  const { upsertStatus, upsertError, deleteStatus, deleteError, data, onSaved, onDeleted, onError } = props;
  const { translationBase } = props;
  const bold = props.bold != false;

  const notifyError = props.notifyError !== undefined ? props.notifyError : true;
  const notifySuccess = props.notifySuccess !== undefined ? props.notifySuccess : true;

  const cancelable = useCancelable<void>();

  //console.log(props);

  React.useEffect(() => {
    
    if (upsertStatus === Status.Success && data) {

      if (notifySuccess) {

        const [waitDone, stopWaiting] = stoppableWait(1500)
        waitDone.then(() => {
          setNotification(null);
          onSaved && onSaved(data);
        }).catch((err) => console.log(err));

        setNotification({ severity: 'success',  message: t('commitOk'), duration: 1500 });
        return stopWaiting; // cleanup effect

      } else {

        onSaved && onSaved(data);
      }
    } 
    else if (upsertStatus === Status.Failed) {

      if (notifyError) {

        const [waitDone, stopWaiting] = stoppableWait(4000);
        waitDone.then(() => {
          onError && onError(upsertError);
          setNotification(null);
        }).catch((err) => console.log(err));
        
        setNotification({
          severity: 'error',
          message: t(getErrorMsgKey(upsertError, 'commitError', translationBase)),
          duration: 4000
        });
        return stopWaiting; // cleanup effect

      } else {
        onError && onError(upsertError);
      }
    }
  }, [upsertStatus, upsertError, onSaved, onError, t, translationBase, notifyError, notifySuccess, data])


  React.useEffect(() => {
    if (deleteStatus === Status.Success) {

      if (notifySuccess) {

        const [waitDone, stopWaiting] = stoppableWait(1500)
        waitDone.then(() => {
          setNotification(null);
          onDeleted && onDeleted();
        }).catch((err) => console.log(err));

        setNotification({ severity: 'success', message: t('commitOk'), duration: 1500 });
        
        return stopWaiting; // cleanup effect

      } else {

        onDeleted && onDeleted();
      }

    } else if (deleteStatus === Status.Failed) {

      if (notifyError) {

        const [waitDone, stopWaiting] = stoppableWait(4000);
        waitDone.then(() => {
          onError && onError(deleteError);
          setNotification(null);
        }).catch((err) => console.log(err));

        setNotification({
          severity: 'error',
          message: t(getErrorMsgKey(deleteError, 'commitError', translationBase)),
          duration: 4000
        });
        return stopWaiting; // cleanup effect
        
      } else {
        onError && onError(deleteError);
      }
    }
  }, [deleteStatus, deleteError, onDeleted, onError, t, translationBase, cancelable, notifyError, notifySuccess]);

  return notification ? (
    <SnackbarNotify {...notification}
      onClose={() => setNotification(null)}
      bold={bold}
      position={props.notificationPosition}
    />) : null
}

export const getErrorMsgKey = (error: any, defaultKey: string, translationBase?: string) => {
  if (!!error?.details) {
    const key = `${translationBase ? translationBase + '.' : ''}${error.details.Message}`;
    return key;
  } else {
    return defaultKey;
  }
}