import {useHistory} from "react-router-dom";
import {useRemote} from "./RemoteProvider";
import {useEffect} from "react";

export default function ActiveDirectoryLogout() {
    const history = useHistory()
    const [_auth, remote] = useRemote();
    
    useEffect(()=>{
        remote.logout()
        history.replace("/login")
    },[])

    return <></>
}