
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface Macroarea {
   id: number,
   sortIndex: number,
   //code?: string;
   descriptionDe: string;
   descriptionEn: string;
   descriptionIt: string;
}

export const emptyMacroarea = (): Macroarea => ({
   id: 0,
   sortIndex: 0,
   descriptionDe: '',
   descriptionEn: '',
   descriptionIt: ''
})


export function macroareaDescriptionGetter(lang: string) {
   return (obj?: Macroarea) => {
      if (obj) {
         const key = `description${lang[0].toUpperCase()}${lang[1]}`;
         const result = obj[key as any as keyof (Macroarea)]?.toString();
         if (result && result.length > 0)
            return result;
         else
            return (obj.sortIndex > 0 ? obj.sortIndex.toString() : '') + ' id:' + obj.id.toString();
      }
      else return '';
   }
}

export function useMacroareaDescriptionGetter() {

   const { i18n } = useTranslation();
   const { language } = i18n;
   //  console.log("language", language);
   return useMemo(() => macroareaDescriptionGetter(language), [language]);
}


export const isSameMacroarea = (a: Macroarea, b: Macroarea) => a?.id === b?.id;

export const dummyMacroareaFromId = (id: number): Macroarea => {
   const desc = 'id:' + id.toString();
   return {
      id,
      sortIndex: 0,
      descriptionDe: desc,
      descriptionIt: desc,
      descriptionEn: desc
   };
}

export const useOptionLabelGetter = () => {
   const getDescription = useMacroareaDescriptionGetter();
   return (entity?: Macroarea) => (entity ? getDescription(entity) : '');
}
