import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Snackbar, Box, Button, Alert, IconButton, Portal, Stack } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useForm, UseFormProps, UseFormReturn, DefaultValues, UnpackNestedValue, SubmitErrorHandler, SubmitHandler, FieldValues } from "react-hook-form";
import { useGuard, useGuardedExec } from 'Components/GuardService';
// import CloseIcon from '@mui/icons-material/Close';
// import SaveIcon from '@mui/icons-material/Save';
// import ReplayIcon from '@mui/icons-material/Replay';
// import ClearIcon from '@mui/icons-material/Clear';
// import UndoIcon from '@mui/icons-material/Undo';
import DoneIcon from '@mui/icons-material/Done';
import BackspaceIcon from '@mui/icons-material/Backspace';


export type EntityFormRenderProps<TModel extends FieldValues, TContext = Record<string, never>> = UseFormReturn<TModel> & {
  disabled?: boolean,
  defaultValues: DefaultValues<TModel> | null,
  context: TContext,
  onPendingChanges: (isDirty: boolean) => void
}

/**
 * Holds a react-form-hook instance for an entity and renders 
 * (1) custom inputs 
 * (2) buttons to Save/Delete the entity instance, GoBack to exit the form.
 * Shows notification popup when user clicks Save but form status is invalid.
 * 
 * @param props see below
 * @returns 
 */
export function EntityForm<TModel extends FieldValues, TContext = Record<string, never>>(props: {

  rootId: string,

  defaultValues?: DefaultValues<TModel> | (() => DefaultValues<TModel>),

  onSubmitValid?: SubmitHandler<TModel>,
  // what to do when user clicks Save and the form values are valid
  // By default, EntityForm will convert empty strings to undefined, coalesce form values 
  // with defaultModel values, and pass the resulting entity instance to onSave() (see below)
  // In dev mode will also console.log entity instance.

  onSubmitInvalid?: SubmitErrorHandler<TModel>,
  // what to do when user clicks Save and the form values are NOT valid
  // By default, in dev mode will console.log errors. 

  onSave?: (data: UnpackNestedValue<TModel>) => void, // handle Save button click when the form values are valid.
  onCancel?: () => void, // handle Back button click
  onDelete?: () => void, // handle Delete button click

  onBeforeSave?: () => void, // optional action that will be executed before getting the form values when the Save button is clicked. 
  // Useful to get value of inputs such as rich text editors, that typically do not push their value to the form upon change or blur events (because it would inefficient),
  // but, instead, have their own Save button to convert their content to the form field type.

  canDelete?: boolean, // default: true

  formProps?: Omit<UseFormProps<TModel>, 'defaultValues'>,

  disabled?: boolean,

  context: TContext,

  children: React.FunctionComponent<EntityFormRenderProps<TModel, TContext>>,  // component that renders controls for entity fields

  buttonsContainer: React.MutableRefObject<HTMLElement | undefined>
}) {

  const { rootId, disabled, defaultValues } = props;

  const _defaultValues: DefaultValues<TModel> | undefined = useMemo(() => (defaultValues && (typeof defaultValues === 'function' ? defaultValues() : defaultValues)) ?? undefined, [defaultValues]);

  //* FORM API

  const form = useForm<TModel>({
    defaultValues: _defaultValues,
    mode: 'onBlur',
    ...props.formProps
  });


  //* HANDLE DEFAULT VALUES CHANGED

  const { reset } = form;

  React.useEffect(() => {
    if (_defaultValues) {
      //process.env.NODE_ENV === 'development' && console.log("EntityForm reset", _defaultValues);
      reset(_defaultValues);
    }
  }, [_defaultValues, reset]);


  //* HANDLE DIRTY STATE
  
  const { formState: { isDirty: isFormDirty } } = form;
  const [forceDirty, setForceDirty] = useState(false);
  const isDirty = isFormDirty || forceDirty;

  const [raiseGuard, removeGuard] = useGuard();

  // Make sure navigation guard will be removed on unmounting. 
  React.useEffect(() => { return (() => removeGuard(rootId)) }, [rootId, removeGuard]);
  React.useEffect(() => {
    if (!disabled) {
      console.log(rootId, isDirty ? "RAISE GUARD" : "REMOVE GUARD");
      if (isDirty) {
        raiseGuard(rootId);
      } else {
        removeGuard(rootId);
      }
    }
    //return removeGuard; 
    // don't do cleanup here, because useEffect's cleanup runs at every render, not only when component unmounts (see https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/) 
    // instead, do the cleanup in a separate useEffect(), see above   
  }, [isDirty, disabled, raiseGuard, removeGuard, rootId]);

  
  //* HANDLE CANCEL CLICKED

  const { onCancel } = props;
  const guardedExec = useGuardedExec();
  const handleCancelClicked = useCallback(() => {
    if (onCancel) {
      guardedExec(onCancel);
    }
  }, [guardedExec, onCancel]);
  
  //* HANDLE SAVE CLICKED     if errors notify, if correct save

  const [openInvalidNotification, setOpenInvalidNotification] = React.useState(false);

  const { onSubmitValid, onSubmitInvalid, onSave, onBeforeSave } = props;

  const handleSaveClicked = useCallback(() => {
    if (onBeforeSave) {
      onBeforeSave();
    }
    form.handleSubmit(
      onSubmitValid ?? ((data) => {
        if (onSave)
          onSave(data);
      }),
      onSubmitInvalid ?? ((errors) => {
        setOpenInvalidNotification(true);
        process.env.NODE_ENV === 'development' && console.log('FORM ERRORS', errors);
      })
    )();
  }, [form, onSubmitInvalid, onSubmitValid, onSave, onBeforeSave]);


  const { t } = useTranslation();

  //* RENDER

  const renderedButtons = disabled ? null : <>

    {isDirty && props.onSave && (<IconButton onClick={handleSaveClicked}><DoneIcon /></IconButton>)}
    <IconButton onClick={handleCancelClicked}><BackspaceIcon /></IconButton>
  </>;


  return <>
    <form id={`EntityForm-${props.rootId}`}>

      {/* CUSTOM ENTITY INPUTS */}
      {props.children({
        ...form,
        defaultValues: _defaultValues ?? null,
        context: props.context,
        onPendingChanges: setForceDirty,
        disabled: disabled
      })}

      {/* FORM BUTTONS */}
      {props.buttonsContainer ? (
        <Portal container={props.buttonsContainer.current}>{renderedButtons}</Portal>
      ) : (
        { renderedButtons }
      )}

    </form>

    <Snackbar id={`EntityForm-${props.rootId}-notify`}
      autoHideDuration={2800}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={openInvalidNotification}
      onClose={() => setOpenInvalidNotification(false)}
    >
      <Alert onClose={() => setOpenInvalidNotification(false)} severity='error'>
        {t('invalidValues')}
      </Alert>
    </Snackbar>
  </>;
}







