import { useGuard } from "Components/GuardService";
import { Status } from "Components/utils";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, UnpackNestedValue } from "react-hook-form";
import { RemoteEntityApi, RemoteEntityReadOptions } from "./RemoteEntity";



export type EntityEditorApiProps<
   TModelVerbose extends FieldValues,
   TModel = TModelVerbose,
   TContext = Record<string, never>
> = RemoteEntityReadOptions & {
   editorId: string,
   useRemoteEntity: (context: TContext) => RemoteEntityApi<TModelVerbose, any, TModel>, // hook to access remote entity instance
   idKey: keyof TModel, // name of id property (needed to fetch remote entity)
   model: TModelVerbose | string | number | false,
   // if false, will create new entity instance. 
   // If string or number, must be the unique id of a remote entity instance to fetch
   isEditable?: boolean,
   isDeletable?: boolean, // default: true
   proposeDelete?: boolean,
   context: TContext
}


/**
 * REMOTE ENTITY EDITOR API
 * Fetches or create entity instance and provides state and methods for an entity editor 
 * to edit/un-edit/save/delete/close the entity.
 * 
 * @param props 
 * @returns 
 */
export function useEntityEditorApi<
   TModel extends FieldValues,
   TModelVerbose extends FieldValues = TModel,
   TContext = Record<string, never>
>(props: EntityEditorApiProps<TModelVerbose, TModel, TContext>) {

   const [entityState, entityMethods] = props.useRemoteEntity(props.context);

   const { model, idKey, isEditable, isDeletable, asNew, newId, remoteClone } = props;

   //* ENTITY to edit

   useEffect(() => {
      if (model === false) {
         entityMethods.create();
      } else if (typeof model === 'object') {
         entityMethods.create(model);
      } else {
         const id = model;
         let options: RemoteEntityReadOptions = {};
         if (asNew) {
            options = {...options, asNew: true, newId: newId };
         }
         if (remoteClone) {
            options = {...options, remoteClone: true }
         }
         entityMethods.read(id, {}, options);
      }
   }, [model, entityMethods, idKey, newId, asNew, remoteClone]);


   //* DELETE ENTITY

   const [isDeleteConfirmPending, setDeleteConfirmPending] = useState(!!props.proposeDelete);
   const { delete: remoteDelete } = entityMethods;
   const deleteEntity = useCallback(() => setDeleteConfirmPending(true), []);
   const handleDeleteConfirmed = useCallback(() => {
      setDeleteConfirmPending(false);
      remoteDelete();
   }, [remoteDelete])
   const handleDeleteAborted = useCallback(() => setDeleteConfirmPending(false), []);

   //* SAVE ENTITY
   
   const { save: remoteSave } = entityMethods;
   
   const saveEntity = useCallback((data: UnpackNestedValue<TModelVerbose>) => {
      const _entity = data as TModelVerbose;
      remoteSave(_entity, { asNew });
   }, [remoteSave, asNew]);

   
   const { writeStatus } = entityState;
   const removeGuard = useGuard()[1];
   const { editorId } = props;
   useEffect(() => { return (() => removeGuard(editorId)) }, [editorId, removeGuard]);
   useEffect(() => {
      if (writeStatus === Status.Success) {
         removeGuard(editorId);
      }
   }, [removeGuard, writeStatus, editorId]);


   return {
      editorId: props.editorId,
      entityState,
      disabled: !isEditable,
      doSave: saveEntity,
      doDelete: isDeletable !== false ? deleteEntity : undefined,
      isDeleteConfirmPending,
      onDeleteConfirmed: handleDeleteConfirmed,
      onDeleteAborted: handleDeleteAborted,
   }
}


