import { CollectionState, PagingInfo as PaginationInfo } from './collection.state';
import { RemoteCollectionMethods } from 'Components/RemoteEntities/Collection/RemoteCollection';
import { makePageSizeOptions } from "Components/utils";
import { TablePagination } from '@mui/material';
import { PaginatedList, PaginationControls, usePaginated } from 'Components/Collection/Pagination';
import { useEffect } from 'react';

export function RemoteCollectionPagination(props: { 
  paginated: PaginationInfo, 
  methods: RemoteCollectionMethods,
  pageSizeOptions?: number[]
}) {

  const {methods, paginated} = props;

  // @ts-ignore: noUnusedParameters
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    methods.getPage(newPage+1);
  };

  const handleChangeRowsPerPage  = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {    
    methods.getPage(1, parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      component="div"
      count={paginated.totalItems ?? 0}
      page={(paginated.page ?? 1) - 1}  
        // above needs -1 because MUI pagination assumes first page = 0, 
        // whereas our RemoteCollection interface follows the backend convention firstPage=1
      onPageChange={handleChangePage}
      rowsPerPage={paginated.pageSize ?? 0}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={makePageSizeOptions(paginated.pageSize, props.pageSizeOptions)}
      showFirstButton showLastButton
    />
  );
}

export function usePaginationHandling<TModel>(props: { 
  entities: TModel[],
  doPaginate?: boolean
}) {

  const {entities, doPaginate} = props;
  
  /* Optional client-side pagination */
  const localPagination = usePaginated<TModel>();
  const { setCollection: setPaginatedCollection } = localPagination;
  
  useEffect(() => {
    if (doPaginate) {
      setPaginatedCollection(entities);
    }
  }, [entities, doPaginate, setPaginatedCollection]);

  return {
    localPagination: doPaginate ? localPagination : null,
    result: doPaginate ? localPagination.pageContent : entities
  }
}

export function PaginationController<TModel>(props: { 
  collection: CollectionState<TModel>,
  collectionApi: RemoteCollectionMethods, 
  localPagination: PaginatedList<TModel> | null
}) {

  const {collection, collectionApi, localPagination} = props;
  const { entities } = collection;

  return collection.isPaginated
    ? <RemoteCollectionPagination paginated={collection} methods={collectionApi} />
    : localPagination
      ? <PaginationControls paginatedList={localPagination} />
      : null;
}