import * as React from 'react';
import DraggableListItem, { ElementWithId } from './DraggableListItem';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import { SxProps, Theme } from '@mui/material';




export type DraggableListProps = {
    items: ElementWithId[];
    onDragEnd: OnDragEndResponder;
    dragdropId: string;
    itemSx?: SxProps<Theme>
};

const DraggableList = React.memo(({ items, onDragEnd, dragdropId, itemSx }: DraggableListProps) => {

    //console.log("draggableList", items);
    
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={dragdropId + '-droppable'}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => (
                            <DraggableListItem item={item} index={index} key={item.id} sx={itemSx}/>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});
DraggableList.displayName = 'DraggableList';

export default DraggableList;