import {emptyReleaseFilter, ReleaseFilters, ReleaseHeadVerbose} from "./release.models";
import {NoteAbstract} from "../Notes/note.models";
import {createContext, Dispatch, useMemo, useReducer} from "react";
import {useRouteMatch} from "react-router-dom";
import {withArrayActionLogger} from "Components/reduxLogger";

interface State {
    urlBasePath: string,
    filters: ReleaseFilters,
    expandedSoftwares: string[],
    activeRelease?: ReleaseHeadVerbose,
    noteModel?: NoteAbstract,
}

export const makeInitialContext = (urlBasePath: string): State => ({
    urlBasePath: urlBasePath,
    filters: emptyReleaseFilter,
    expandedSoftwares: [],
    activeRelease: undefined,
    noteModel: undefined,
});
export type ReleaseManagerAction =
    | ['INIT', { urlBasePath: string }]
    | ['SET_FILTERS', ReleaseFilters]
    | ['EXPAND_COLLAPSE_SOFTWARE', string]
    | ['SET_NOTE_MODEL', NoteAbstract | undefined]
    | ['SET_ACTIVE_RELEASE', ReleaseHeadVerbose | undefined];
const _reducer = (state: State, action: ReleaseManagerAction): State => {
    switch (action[0]) {
        case 'INIT':
            return makeInitialContext(action[1].urlBasePath);
        case 'SET_FILTERS':
            return {
                ...state,
                filters: action[1]
            };
        case 'EXPAND_COLLAPSE_SOFTWARE':
            const code = action[1];
            const list = [...state.expandedSoftwares];
            const foundIndex = list.indexOf(code);
            if (foundIndex == -1)
                list.push(code);
            else list.splice(foundIndex, 1);
            return {
                ...state,
                expandedSoftwares: list
            };
        case 'SET_NOTE_MODEL':
            return {
                ...state,
                noteModel: action[1]
            };
        case 'SET_ACTIVE_RELEASE':
            return {
                ...state,
                activeRelease: action[1]
            };
        default:
            return state;
    }
}
const reducer = withArrayActionLogger(_reducer, 'ReleasesManagerContext');

export const ReleasesManagerContext = createContext<[State, Dispatch<ReleaseManagerAction>]>([
    makeInitialContext(''),
    (action: ReleaseManagerAction) => { /* */
    }  // dummy dispatcher
]);
export const useReleasesManagerReducer = () => {

    const routeMatch = useRouteMatch();
    const urlBasePath = routeMatch.url;
    
    const initialContext = useMemo(() => {
        console.log("Making ReleaseManager initial context");
        return makeInitialContext(urlBasePath);
    }, [urlBasePath]);

    return useReducer(reducer, initialContext);
}
