import { FaqEditor } from './FaqEditor';
import { FaqViewer } from './FaqViewer';
import {FaqsCollectionAction, FaqState} from "./faqsManagerState";

export const FaqContainer = (props: {
   softwareCode: string,
   state: FaqState,
   dispatch: React.Dispatch<FaqsCollectionAction>
}) => {

   const { state } = props;
   
   console.log("FaqState", state);
   
   if (state.isEditing) {

      return <FaqEditor
         key={state.id}
         item={state}
         dispatch={props.dispatch}
         softwareCode={props.softwareCode} />

   } else {

      return state.show ? (
         <FaqViewer  key={state.id} {...state} dispatch={props.dispatch}/>
      ) : null
   }
}
