import { EntityFormCustomRenderProps } from 'Components/HookedForm/EntityFormCustom';
import { EntityEditorCustom } from 'Components/RemoteEntities/Entity/EntityEditorCustom';
import {
   makeEmptyNote, Note, NoteAbstract, NoteVerbose, noteVerboseToAbstract, ReleaseContext,
} from './note.models';
import { NoteCRUDContext, useNoteCRUD } from './note.services';
import {Dispatch, FC, useMemo, useRef} from 'react';
import { useTranslation } from 'react-i18next';

import BackspaceIcon from '@mui/icons-material/Backspace';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { NoteForm } from './NoteForm';
import { Typography, Button, IconButton, Paper, Stack, styled } from '@mui/material';
import { useConst } from 'Components/useConst';
import {isNew, NotesCollectionAction, NoteState, getCloneSetting} from "./notesManagerState";

export interface NotesEditorProps extends ReleaseContext {
   dispatch: Dispatch<NotesCollectionAction>,
   item: NoteState,
}

export function NoteEditor(props: NotesEditorProps) {

   const { item, dispatch, releaseId, softwareCode } = props;
   const { id, abstract } = item;

   const handleNoteSaved = useConst(() => (fullNote: NoteVerbose) => {
      const { id } = item;
      const abstract: NoteAbstract = noteVerboseToAbstract(fullNote);
      
      if (isNew(item)) {
         dispatch(['SAVE_NEW', { abstract, details: fullNote, expanded: true, show: true }]);
         dispatch(['CLOSE', fullNote.id]);
      } else {
         dispatch(['UPDATE', { id, patch: { abstract, details: fullNote } }]);
         dispatch(['CLOSE', id]);
      }
   });

   const handleNoteDeleted = useConst(() => () => dispatch(['DELETE', id]));

   const handleNoteClose = useConst(() => () => dispatch(['CLOSE', id]));

   const {isClone, isAcrossReleases, originReleaseId } = getCloneSetting(item);

   const clearData = isNew(item) ? item.clearEntity : undefined;

   const emptyNote = useMemo(() => ({
      ...clearData, // hack to clear the new-note form when a new note has been saved 
      ...makeEmptyNote({ releaseId, softwareCode })
   }), [releaseId, softwareCode, clearData]);

   return (
      <EntityEditorCustom<Note, NoteVerbose, NoteCRUDContext>
         editorId={'NoteEditor-' + (abstract?.id ?? 'new')}
         useRemoteEntity={useNoteCRUD}
         context={{
             softwareCode: softwareCode,
             isSoftwareChanged: softwareCode !== abstract?.softwareCode, 
             isClone, 
             releaseId: originReleaseId ?? releaseId,
             newReleaseId: isAcrossReleases ? releaseId : undefined,
         }}
         idKey='id'
         model={abstract?.id ?? false}
         remoteClone={isClone}
         defaultValues={abstract ?? emptyNote}
         onSaved={handleNoteSaved}
         onDeleted={handleNoteDeleted}
         onExit={handleNoteClose}
      >{CustomNoteForm}</EntityEditorCustom>);
}


type NoteFormProps = EntityFormCustomRenderProps<NoteVerbose, NoteCRUDContext>;

const CustomNoteForm: FC<NoteFormProps> = (props: NoteFormProps) => {

   const [t] = useTranslation();

   const { disabled } = props;

   const { formState: { isDirty }, getValues } = props;

   const titleContainerRef = useRef<HTMLElement | null>(null);

   console.log("NOTE FORM", getValues());

   return (
      <Paper variant='outlined' sx={{ my: 3, width: 1 }}>
         <Stack direction='row' justifyItems={'space-between'} alignItems='center'>
            <Typography variant='h5' flex={'1 1 auto'} id='note-translation-title' sx={{mx: 2}} ref={titleContainerRef}></Typography>
            <Stack flex='0 1 auto' direction='row' sx={{ my: 2 }} justifyContent='flex-end'>
               {!disabled && <>
                  {<IconButton onClick={props.onCancel}><BackspaceIcon /></IconButton>}
                  {<IconButton onClick={props.onDelete}><DeleteIcon /></IconButton>}
                  {<IconButton disabled={!isDirty} onClick={props.onSave}><SaveIcon /></IconButton>}
               </>}
            </Stack>
         </Stack>

         <NoteForm {...props} titleContainer={titleContainerRef.current} />

         <Stack direction='row-reverse' sx={{ m: 2 }}>
            <StyledButton variant='contained' onClick={props.onCancel}>{t('exit')}</StyledButton>
            <StyledButton variant='contained' onClick={props.onSave}>{t('saveAndClose')}</StyledButton>
            <StyledButton variant='contained' onClick={props.onDelete}>{t('delete')}</StyledButton>
         </Stack>
      </Paper>);
}


const StyledButton = styled(Button)(() => ({
   margin: '4px'
}));

