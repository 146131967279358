import styled from "@emotion/styled"
import { TableCell } from "@mui/material"


export const StyledCell = styled(TableCell)(
    ({ theme }) => ({
        '&.MuiTableCell-root': {
            paddingLeft: '4px',
            paddingRight: '4px'
        },
        '&.MuiTableCell-root:first-of-type': {
            paddingLeft: '16px',
        }
    })
)

