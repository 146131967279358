import { SxProps, Theme } from "@mui/material";
import { useCallback, useMemo } from "react";

import { DescribedCode } from "./describedCode.models";
import { useDescriptionGetter } from "./describedCode.models";
import { Control, FieldValues, Path } from "react-hook-form";
import { HookedAutocompleteOnBlurRemote, Rules } from "Components/HookedForm/inputControls";



const _empty = {};
const _valueMatcher = (option?: DescribedCode, value?: DescribedCode) => {
    if (option) return option.code === value?.code;
    else return value === undefined;
};

export function DescribedCodeAutocompleteHookedOnBlur<TFieldValues extends FieldValues, TPath extends Path<TFieldValues>>(props: {
    control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
    name: Path<TFieldValues>,
    endpoint: string,
    rules?: Rules<TFieldValues, Path<TFieldValues>>,
    label?: string,
    disabled?: boolean,
    sx?: SxProps<Theme>
}) {

    const { sx, ...otherProps } = props;
    const _sx = useMemo(() => ({maxWidth: 600, minWidth: 300, ...sx}), [sx]);

    const getDescription = useDescriptionGetter();

    const getOptionLabel = useCallback((entity?: DescribedCode) => entity ? (entity.code + ' ' + getDescription(entity)) : '', [getDescription]);

    const isOptionEqualToInput = useCallback((option: DescribedCode | undefined, textInput: string) => {
        if (!option) return textInput === '';
        const input = textInput.toLowerCase();
        if (option.code.toLowerCase().includes(input)) {
            return true;
        }
        const desc = getDescription(option);
        return desc.toLowerCase().includes(input);
    }, [getDescription]);

    return <HookedAutocompleteOnBlurRemote<TFieldValues, TPath> 
        sx={_sx}
        query={_empty}
        isOptionEqualToValue={_valueMatcher}
        getOptionLabel={getOptionLabel}
        isOptionEqualToInput={isOptionEqualToInput}
        disabled={props.disabled}
        {...otherProps} 
    />
}
