
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { DescribedCode } from 'Entities/DescribedCode/describedCode.models';
import {
    makeDescribedCodesSearchCollection
} from 'Entities/DescribedCode/describedCode.services';
import {
    makeDescribedCodeEditor
} from 'Entities/DescribedCode/DescribedCodesManager/DescribedCodeEditor';
import {
    DescribedCodesManager
} from 'Entities/DescribedCode/DescribedCodesManager/DescribedCodesManager';

const useCollection = makeDescribedCodesSearchCollection({endpoint: 'baseData/releaseStatuses'});

const EntityEditor = makeDescribedCodeEditor('baseData/releaseStatuses', 'ReleaseStatusEditor');

export function ReleaseStatusesManager() {
  return <DescribedCodesManager 
    titleKey='Menu.baseData.releaseStatuses'
    useCollection={useCollection}> 
    {EntityEditor}
   </DescribedCodesManager>
}


export const useReleaseStatusesService = () => useRemoteResourceQueryService<DescribedCode[], DescribedCode[]>({endpoint: 'baseData/releaseStatuses', defaultValue: []});




