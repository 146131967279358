import React from "react";
import { NavNode } from "./navTree";

type OpenMenu = [string, (menuFullName: string | null) => void] // getter, setter

const OpenMenuContext = React.createContext<OpenMenu>(['', () => { /* */ }]);

export function OpenMenuProvider(props: {
  children: React.ReactNode,
  resetOpenMenu: (oldOpenMenu: string) => string,
  rootName: string,
  enabledNodes: NavNode[]
}) {

  const { resetOpenMenu, rootName, enabledNodes } = props;
  
  const [openMenu, setOpenMenu] = React.useState<string>(rootName);

  const ensureEnabled = React.useCallback((menuFullName: string) => {
    const found = enabledNodes.find(node => node.fullName === menuFullName);
    return found ? menuFullName : resetOpenMenu(rootName);
  }, [enabledNodes, rootName, resetOpenMenu]);

  React.useEffect(() => {
    setOpenMenu(old => ensureEnabled(resetOpenMenu(old)));
  }, [resetOpenMenu, ensureEnabled])

  const doSetOpenMenu = React.useCallback((menuToOpen: string | null) => {
    setOpenMenu(old => menuToOpen || resetOpenMenu(old) || rootName);
  }, [resetOpenMenu, rootName]);

  const contextVal: OpenMenu = React.useMemo(() => ([openMenu, doSetOpenMenu]), [openMenu, doSetOpenMenu]);

  return <OpenMenuContext.Provider value={contextVal}>{props.children}</OpenMenuContext.Provider>
}

export const useOpenMenu = () => React.useContext(OpenMenuContext);