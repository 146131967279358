import { styled } from '@mui/material/styles';
import {Toolbar, IconButton, Typography, Box, Button, Drawer, Stack, Divider, PaperProps} from '@mui/material';
import Iconify from 'Components/iconify';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';


const StyledRoot = styled(Toolbar)(({ theme }) => ({
   height: 96,
   display: 'flex',
   justifyContent: 'space-between',
   padding: theme.spacing(0, 1, 0, 3),
}));


export default function ToolbarWithDrawer(props: {
   id?: string,
   onClose?: () => void,
   onClear?: () => void,
   drawerButtonText?: string,
   keepDrawerMounted?: boolean,
   drawerTitle?: string,
   drawerPaperProps?: Partial<PaperProps>, 
   children: {
      main: ReactNode,
      drawer: ReactNode
   }
}) {

   const [open, setOpen] = useState(false);

   const handleOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
      if (props.onClose) props.onClose();
   };

   const [t] = useTranslation();

   return (
      <StyledRoot id={props.id}>
         {props.children.main}

         {props.children.drawer && <>
            <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={handleOpen}>
               {props.drawerButtonText ?? 'Filters'}
            </Button>

            <Drawer
               anchor="right"
               open={open}
               onClose={handleClose}
               keepMounted={props.keepDrawerMounted}
               PaperProps={{
                  ...props.drawerPaperProps,
                  sx: { width: 'auto', maxWidth: '50%', border: 'none', overflow: 'hidden', ...props.drawerPaperProps?.sx },
               }}
            >
               <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                     {props.drawerTitle ?? 'Filters'}
                  </Typography>
                  <IconButton onClick={handleClose}>
                     <Iconify icon="eva:close-fill" />
                  </IconButton>
               </Stack>

               <Divider />

               {props.children.drawer}

               <Box sx={{ p: 3 }}>
                  <Button
                     fullWidth
                     size="large"
                     type="submit"
                     color="inherit"
                     variant="outlined"
                     startIcon={<Iconify icon="ic:round-clear-all" />}
                     onClick={props.onClear}
                  >
                     {t('clearAll')}
                  </Button>
               </Box>
            </Drawer>
         </>}
      </StyledRoot>
   );
}