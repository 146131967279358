import ToolbarWithDrawer from 'Components/Containers/ToolbarWithDrawer';
import { HookedCheckbox, HookedSelect, HookedTextField } from 'Components/HookedForm/inputControls';
import { StyledTextFieldAC } from 'Components/Input/StyledTextField';
import { deduplicate, hasContent } from 'Components/utils';
import {
    DescribedCode, isEqual, useDescriptionGetter
} from 'Entities/DescribedCode/describedCode.models';
import { DescribedCodeAutocomplete } from 'Entities/DescribedCode/DescribedCodeAutocomplete';
import { SimpleEntityAutocomplete } from 'Entities/SimpleEntity/SimpleEntityAutocomplete';
import { FormAutocomplete } from 'Entities/SoftwareParts/FormsAutocompletes';
import { Module } from 'Entities/SoftwareParts/module.services';
import { ModuleAutocomplete } from 'Entities/SoftwareParts/ModuleAutocompletes';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, Button, Stack, Typography } from '@mui/material';

import {NoteAbstract, NotesFilter} from './note.models';
import { isSameMacroarea, Macroarea, useMacroareaDescriptionGetter } from 'Entities/Macroarea/macroarea.service';


const useFilterOptionsFromNotes = (props: {notes: NoteAbstract[]}) => {

    const { notes } = props;
    
    return {
        todos: deduplicate((notes?.map(note => note.todo).filter(x => !!x) ?? []) as string[]),
        macroareas: deduplicate((notes?.map(note => note.macroarea).filter(x => !!x) ?? []) as Macroarea[], isSameMacroarea)
                    .sort((a,b) => a.sortIndex - b.sortIndex),
        departments: deduplicate((notes?.flatMap(note => note.departments).filter(x => !!x) ?? []) as DescribedCode[], isEqual),
        modules: deduplicate((notes?.map(note => note.module).filter(x => !!x) ?? []) as Module[], isEqual),
        forms: deduplicate((notes?.flatMap(note => note.forms).filter(x => !!x) ?? []) as Module[], isEqual),
    };
}

export interface NotesFilterToolbarProps {
    allNotes: NoteAbstract[], // needed to get filter options
    filter?: NotesFilter,
    onFilterChange: (filters?: NotesFilter) => void,
    showErgoOne: boolean,
    releaseId: number
}

export const NotesFilterToolbar = forwardRef<HTMLElement, NotesFilterToolbarProps>((props: NotesFilterToolbarProps, ref: ForwardedRef<HTMLElement>) => {

    const { allNotes, releaseId } = props;

    const options = useFilterOptionsFromNotes({notes: allNotes});

    console.log("INITIAL FILTER", props.filter);

    const form = useForm<NotesFilter>({ defaultValues: props.filter });
    const { control, getValues, reset, watch } = form;

    const { onFilterChange } = props;

    const handleClose = useCallback(() => {
        // propagate filter if it has values, otherwise propagate filter = undefined
        const values = getValues();
        if (hasContent(values)) {
            onFilterChange(values);
        }
        else {
            onFilterChange(undefined);
        }
    }, [onFilterChange, getValues]);

    const [t] = useTranslation();

    return (
        <ToolbarWithDrawer onClose={handleClose} onClear={() => reset({})} drawerPaperProps={{sx: {maxWidth: '100%'}}} /*keepDrawerMounted*/>
            {{
                main: <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: 1 }}>
                    <Box ref={ref} />
                    <Box marginRight={3}>
                        <Button onClick={() => {
                            reset({});
                            onFilterChange(undefined);
                        }}>{t('removeFilters')}</Button>
                    </Box>
                </Stack>,
                drawer: (
                    <Stack>
                        <Controller control={control} name="macroarea"
                            render={({ field }) => (
                                <SimpleEntityAutocomplete name='macroarea'
                                    label={t('macroarea')}
                                    multiple
                                    options={options.macroareas}
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />)}
                        />
                        <Controller control={control} name="department"
                            render={({ field }) => (
                                <DescribedCodeAutocomplete name='department'
                                    multiple
                                    label={t('department')}
                                    options={options.departments}
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />)}
                        />

                        <Controller control={control} name='module'
                            render={({ field }) => (
                                <ModuleAutocomplete multiple  name={'module'}
                                    options={options.modules}  
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />
                            )}
                        />
                        <Controller control={control} name='form'
                            render={({ field }) => (
                                <FormAutocomplete multiple name={'form'}
                                    options={options.forms} 
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />
                            )}
                        />

                        <Controller control={control} name="todo"
                            render={({ field }) => (
                                <Autocomplete<string, true>
                                    multiple
                                    options={options.todos}
                                    value={field.value ?? []}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur}
                                    sx={{ mx: 1 }}
                                    renderInput={(params) => <StyledTextFieldAC {...params} label={t('todo')} />}
                                />)}
                        />

                        {props.showErgoOne && <Typography><HookedCheckbox control={control} name='ergoOne' />ErgoONE</Typography>}

                        <HookedSelect control={control} name={'contentType'} label={t('noteContentType')}
                            options={[{ value: 'NonTechnical', label: 'NonTechnical' }, { value: 'Technical', label: 'Technical' }]}
                        />

                        <HookedTextField control={control} name='content' label={t('content')} />

                    </Stack>
                )
            }}
        </ToolbarWithDrawer>
    )
});