import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Macroarea } from "Entities/Macroarea/macroarea.service";
import {isNew, FaqsCollectionAction, FaqState} from "./faqsManagerState";

export interface FaqsGroup {
    key: Macroarea,
    faqs: FaqState[]
}

const groupFaqsByMacroarea = (faqs: FaqState[]): FaqsGroup[] => {

    const result = faqs
        .reduce((_groups, _faq) => {
            const macroarea: Macroarea = (isNew(_faq) ? undefined  : (_faq.abstract.macroarea)) 
                ?? { id: 0, sortIndex: 0, descriptionDe: '', descriptionIt: '', descriptionEn: ''};
            const myGroup = _groups.find(g => g.key.id === macroarea.id);
            if (myGroup === undefined) {
                _groups.push({
                    key: macroarea,
                    faqs: [_faq]
                })
            } else {
                myGroup.faqs.push(_faq)
            }
            return _groups;
        }, [] as FaqsGroup[])
        .sort((groupA, groupB) => groupA.key.sortIndex - groupB.key.sortIndex);

    return result;
}

export type UseGroupedFaqsReturn = [
    FaqsGroup[],
    (faqIds: number[], groupId: number) => void // handle order changed within group
]

export function useGroupedFaqs(props: {
    faqs: FaqState[],
    dispatch: React.Dispatch<FaqsCollectionAction>     
}): UseGroupedFaqsReturn {

    const { faqs, dispatch } = props;

    const groups = useMemo(() => groupFaqsByMacroarea(faqs), [faqs]);

    const handleOrderChangedWithinGroup = useCallback((faqIds: number[], groupId: number) => {

        let allFaqIds = [] as number[];
        groups.forEach(g => {
            if (g.key.id === groupId) {
                allFaqIds = allFaqIds.concat(faqIds);
            } else {
                allFaqIds = allFaqIds.concat(g.faqs.map(n => n.id));
            }
        });
        dispatch(['REORDER', allFaqIds])
        
    }, [dispatch, groups]);

    return [groups, handleOrderChangedWithinGroup];
}