import {ensureHtmlTags} from 'Components/HtmlContent/htmlAdapter';
import {CollectionState, useRemoteCollection} from 'Components/RemoteEntities/Collection/RemoteCollection';
import {RemoteEntityApi, useRemoteEntity} from 'Components/RemoteEntities/Entity/RemoteEntity';
import {
  useRemoteResourceQueryService,
  useRemoteResourceSubmissionService
} from 'Components/RemoteEntities/resource.services';
import {EmptyObject, Status} from 'Components/utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Faq, FaqAbstract, FaqFilter, FaqVerbose, minifyFaq} from './faq.models';
import {useReleaseStatusesService} from "../../BaseData/ReleaseStatus/ReleaseStatusesManager";


//************** FAQS COLLECTION of a given software */

export function useFaqAbstracts(props: {
  softwareCode?: string,
}) {

  const { softwareCode } = props;

  const { i18n: { language } } = useTranslation();

  const requiredQueryParams = React.useMemo(() => {
    if (softwareCode)
      return { language, excerptLength: 0 };
    else return undefined; // if the release exists but it's just been created, skip fetching because there are no faqs yet
  }, [softwareCode, language]);

  const [state, api] = useRemoteCollection<FaqAbstract>({
    endpoint: `faqs/${softwareCode}`,
    requiresQueryParams: true,
    queryParams: requiredQueryParams,
    isPaginated: false
  });

  return state;
}


//************** FAQS FILTERED COLLECTION */
export const faqsFilterToAPIQueryParams = (filter: FaqFilter) => ({

  softwareCode: filter.softwareCode,
  versionFrom: filter.versionFrom,
  versionTo: filter.versionTo,
  status: filter.status ? filter.status.map(x => x.code) : undefined,
  module: filter.module ? filter.module.map(x => x.code) : undefined,
  form: filter.form ? filter.form.map(x => ((x.moduleCode ?? '') + '__' + (x.code ?? ''))) : undefined,
  macroarea: filter.macroarea ? filter.macroarea.map(x => x.id) : undefined,
  department: filter.department ? filter.department.map(x => x.code) : undefined,
  customerId: filter.customerId,
  content: filter.content,
  ergoOne: filter.ergoOne,
  contentType: filter.contentType
})

export function useFilteredFaqAbstractsFromFullCollection(props: {
  softwareCode: string,
  filter?: FaqFilter,
  fullCollection: CollectionState<FaqAbstract>
}) {

  const { softwareCode, filter } = props;
  const { i18n: { language } } = useTranslation();

  const queryParams = React.useMemo(() => {
    //console.log("filter", filter);
    if (softwareCode && filter && Object.keys(filter).length > 0) {
      return { 
        language, 
        excerptLength: 0,
        ...faqsFilterToAPIQueryParams(filter)
      };
    } else return undefined; // if the release exists but it's just been created, skip fetching because there are no faqs yet
  }, [language, filter, softwareCode]);

  //console.log("FILTER", filter);
  

  const [filteredState, api] = useRemoteCollection<FaqAbstract>({
    endpoint: `faqs/${softwareCode}`,
    requiresQueryParams: true,
    queryParams,
    isPaginated: false,
    autoFetch: false,
  });

  // Fetch filtered collection only after full collection has been fetched ()
  const fullStatus = props.fullCollection.status;
  useEffect(() => {
    if (fullStatus === Status.Success) {
      api.getPage();
    }
  }, [api, fullStatus]);

  return queryParams ? filteredState : props.fullCollection;
}



//************** SORT COLLECTION  */

export function useFaqsSwappingService(props: { softwareCode: string }) {
  return useRemoteResourceSubmissionService<{idA: number, idB: number, macroareaId: number}, number[]>({ endpoint: `faqs/${props.softwareCode}/swap`, method: 'POST' });
}



//************** READONLY ENTITY */

export const useFaqReadonly = (props: { softwareCode: string, faqId: number }) => {
  const { softwareCode, faqId } = props;
  const endpoint = useMemo(() => `Faqs/${softwareCode}/${faqId}`, [softwareCode, faqId]);
  return useRemoteResourceQueryService<FaqVerbose, null>({ endpoint, defaultValue: null });
}



//************** CRUD ENTITY */

export interface FaqCRUDContext {
  isClone: boolean;
  softwareCode: string;
  newSoftwareCode: string;
}
export function useFaqCRUD(props: FaqCRUDContext): RemoteEntityApi<FaqVerbose, EmptyObject, Faq> {

  const { newSoftwareCode, softwareCode } = props;

  const onBeforeSaving = useCallback((entity: FaqVerbose) => {
    
    entity.translations.forEach((t) => {
      t.html = ensureHtmlTags(t.html);
      t.htmlTech = ensureHtmlTags(t.htmlTech);
    });
    
    entity.softwareCode = newSoftwareCode; 
    
    return entity;
    
  }, [newSoftwareCode]);
  
  //console.log("useFaqCRUD from software", softwareCode, "to", newSoftwareCode);

  const defaultQueryParams = useMemo(() => ({ 
    newSoftwareCode: newSoftwareCode !== softwareCode ? newSoftwareCode : undefined 
  }), [softwareCode, newSoftwareCode]);
  
  return useRemoteEntity<FaqVerbose, {newSoftwareCode?: string}, Faq>({
    idKey: 'id',
    remoteCreation: false,
    minify: minifyFaq,
    endpoint: `Faqs/${softwareCode}`,
    copyToEndpoint: newSoftwareCode != softwareCode ? `Faqs/${newSoftwareCode}` : undefined,
    defaultQueryParams,
    beforeWriting: onBeforeSaving,
  });
}


export const useErgoOneSetterService = (props: {softwareCode: string}) => {
  return useRemoteResourceSubmissionService<{id: number, ergoOne: boolean}, boolean>({endpoint: `Faqs/${props.softwareCode}/Faqs/ergoOne`, method: 'POST'});
}








