import { StyledTextFieldAC } from 'Components/Input/StyledTextField';
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { Status } from 'Components/utils';
import { useCallback, useEffect, useMemo } from 'react';

import {
    Autocomplete, AutocompleteChangeReason, 
    SxProps, Theme
} from '@mui/material';

import { SimpleEntity, dummySimpleEntityFromId, useOptionLabelGetter } from './simpleEntity.models';

const debug = false;


export function SimpleEntityAutocompleteIdsMulti(props: {
    name: string,
    value: number[],
    onChange?: (newValue: number[]) => void,
    label?: string,
    error?: boolean,
    sx?: SxProps<Theme>,
    endpoint: string
}) {

    const { value: idValues, onChange, label, error, name, sx, ...autocompleteProps } = props;

    const getOptionLabel = useOptionLabelGetter();

    if (debug) console.log("AutocompleteRemote", props);

    //* FETCH OPTIONS

    const [collection, fetch] = useRemoteResourceQueryService<SimpleEntity[], SimpleEntity[]>({ endpoint: props.endpoint, defaultValue: [] });
    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status != Status.Idle;

    // fetch immediately otherwise description of initial value cannot be shown
    useEffect(() => { if (!isInitialized) fetch({}); })

    const remoteList = collection.data;

    const options: SimpleEntity[] = useMemo(
        () => (idValues && !isLoaded) ? idValues.map(id => dummySimpleEntityFromId(id)) : remoteList,
        [remoteList, idValues, isLoaded]
    );

    const objValues: SimpleEntity[] = useMemo(() => idValues
        ? idValues.map(id => options.find(opt => opt.id === id)).filter(x => x !== undefined).map(x => x as SimpleEntity)
        : [],
        [idValues, options])

    if (debug) {
        console.log(name, "VALUE", idValues);
        console.log(name, "OPTIONS", options);
    }

    //* HANDLE SELECTION CHANGE

    const handleChange = useCallback((
        evt: React.SyntheticEvent<Element, Event>,
        value: SimpleEntity[] | null,
        reason: AutocompleteChangeReason
    ) => {
        if (!onChange) return;
        if (value) {
            onChange(value.map(x => x.id));
        } else {
            onChange([]);
        }
    }, [onChange]);

    return <Autocomplete<SimpleEntity, true, false, false> multiple
        value={objValues}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option: SimpleEntity, value?: SimpleEntity) => (option.id === value?.id)}
        onChange={handleChange}
        loading={collection.status === Status.Pending}
        autoSelect
        autoComplete
        clearOnBlur={true}
        sx={{ mx: 1, ...sx }}
        {...autocompleteProps}
        renderInput={(params) => (
            <StyledTextFieldAC {...params} name={name} label={label} variant="outlined" error={error} />
        )}
    />

}


