
import { MacroareaEditor } from './MacroareaEditor';
import { MacroareaView } from './MacroareaView';
import {ItemState, MacroareasCollectionAction} from "./macroareasManagerState";


export const MacroareaContainer = (props: { softwareCode: string } & {
    state: ItemState,
    dispatch: React.Dispatch<MacroareasCollectionAction>
}) => {

    const { state } = props;
    console.log(state);

    if (state.isEditing) {

        return <MacroareaEditor
            key={state.id}
            item={state}
            dispatch={props.dispatch}
            softwareCode={props.softwareCode} />

    } else {
        return <MacroareaView key={state.id} {...state} dispatch={props.dispatch} />
    }
}