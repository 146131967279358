
import {useState} from "react";
import { useTranslation } from 'react-i18next';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionProps,
    AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, styled, Typography, Box
} from '@mui/material';

import { DraggableFaqs } from './DraggableFaqs';
import { FaqContainer } from './FaqContainer';
import { macroareaDescriptionGetter, Macroarea } from 'Entities/Macroarea/macroarea.service';
import {FaqsCollectionAction, FaqState} from "./faqsManagerState";


export const FaqsGroupContainer = (props: {
    softwareCode: string,
    faqs: FaqState[],
    groupKey: Macroarea,
    isEditing: boolean,
    onOrderChanged: (idSequence: number[]) => void,
    expanded: boolean,
    dispatch: React.Dispatch<FaqsCollectionAction>
}) => {

    const { faqs, groupKey, isEditing, onOrderChanged, dispatch } = props;
    const [, { language }] = useTranslation();
    const getDescription = macroareaDescriptionGetter(language);
    
    console.log("Group", groupKey.descriptionIt, faqs);
    
    return (
        <FaqsGroupView accordion={groupKey.id !== 0} description={getDescription(groupKey)} expanded={props.expanded}>
            <DraggableFaqs
                softwareCode={props.softwareCode}
                macroareaId={groupKey?.id}
                disableDrag={isEditing}
                onOrderChanged={(ids) => onOrderChanged(ids)}
            >{
                    faqs
                        .filter(x => x.show)
                        .map((item) => ({
                            element: <FaqContainer key={item.id} state={item} dispatch={dispatch} softwareCode={props.softwareCode} />,
                            id: item.id
                        }))
                }</DraggableFaqs>
        </FaqsGroupView>)
}

const FaqsGroupView = (props: { accordion: boolean, expanded?: boolean, description: string, children: React.ReactNode }) => {
    
    //const accordionProps = props.expanded === true ? {expanded: true} : undefined;
    const [expanded, setExpanded] = useState(!!props.expanded);
    return props.accordion ? (
        <Accordion TransitionProps={{ unmountOnExit: true, mountOnEnter: true }} expanded={expanded} >
            <AccordionSummary onClick={() => setExpanded(val => !val)}>
                <Typography>{props.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    ) : <Box>{props.children}</Box>
}


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));