import {DescribedCode, useOptionLabelGetter} from "../DescribedCode/describedCode.models";
import {useRemoteResourceQueryService} from "Components/RemoteEntities/resource.services";
import {endpoint} from "./department.services";
import {Status} from "Components/reduxLogger";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@mui/material";
import {isOptionEqualToValue} from "../DescribedCode/DescribedCodeAutocomplete";
import {StyledTextFieldAC} from "Components/Input/StyledTextField";

const debug = false;
const name = "departments";
export const DepartmentsAutocomplete = (props: {
    value: DescribedCode[],
    onBlur: () => void,
    onChange: (value: DescribedCode[]) => void,
    selectAll?: boolean
}) => {

    const {value, onBlur, onChange, selectAll} = props;

    const getOptionLabel = useOptionLabelGetter();

    //* FETCH OPTIONS

    const [collection, fetch] = useRemoteResourceQueryService<DescribedCode[], DescribedCode[]>({
        endpoint,
        defaultValue: []
    });
    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status !== Status.Idle;

    const handleOpen = useCallback(
        () => {
            if (!isInitialized) fetch({});
        },
        [isInitialized, fetch]);

    const remoteList = collection.data;

    const options: DescribedCode[] = useMemo(
        () => (value && !isLoaded) ? (Array.isArray(value) ? value : [value]) : remoteList,
        [remoteList, value, isLoaded]
    );

    const optionsLoaded = collection.status === Status.Success ? options : false;

    if (debug) {
        console.log(name, "VALUE", value);
        console.log(name, "OPTIONS", options);
    }

    const [clearTrigger, setClearTrigger] = useState(0);
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form-clear.
    // This is needed because MUIAutocomplete in multiple-values mode with object options has a bug: 
    // it does not re-render when the value passed to it is reset to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => {
        if (!value) setClearTrigger((val) => val + 1);
    }, [value]);


    //* (Optional) Select all options programmatically

    if (debug) console.log(name, 'loaded?', isLoaded, "selectAll?", selectAll);

    useEffect(() => {
        if (selectAll) {
            handleOpen();
        }
    }, [selectAll, handleOpen]);

    useEffect(() => {
        if (selectAll && optionsLoaded) {
            onChange(optionsLoaded);
        }
    }, [selectAll, optionsLoaded, onChange]);

    const [t] = useTranslation();

    return (
        <Autocomplete<DescribedCode, boolean | undefined, boolean | undefined, false>
            options={options ?? []}
            value={value}
            multiple
            onOpen={handleOpen}
            onInputChange={handleOpen}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            loading={collection.status === Status.Pending}
            onBlur={onBlur}
            onChange={(evt, val) => {
                onChange(Array.isArray(val) ? val : (val ? [val] : []));
            }}
            autoSelect
            autoComplete
            clearOnBlur={true}
            key={clearTrigger}
            sx={{mx: 1, minWidth: 100}}
            renderInput={(params) => (
                <StyledTextFieldAC {...params} name={name} label={t('departments')} variant="outlined"/>
            )}
        />
    )
}