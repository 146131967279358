import AddOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import { Stack, IconButton } from '@mui/material';

export const AddBar = (props: { onAddButtonClick: () => void }) => (
    <Stack direction='row-reverse' sx={{ my: 1 }}>
        <IconButton onClick={props.onAddButtonClick} sx={{ mr: 1 }}>
            <AddOutlinedIcon />
        </IconButton>
    </Stack>
)

export const TitledAddBar = (props: { onAddButtonClick: () => void }) => (
    <Stack direction='row-reverse' sx={{ my: 1 }}>
        <IconButton onClick={props.onAddButtonClick} sx={{ mr: 1 }}>
            <AddOutlinedIcon />
        </IconButton>
    </Stack>
)