import {ReleasedSoftware, ReleaseFilters} from "./release.models";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import {SoftwareReleaseList} from "./SoftwareReleaseList";

export function ReleasesBySoftware(props: { softwares: ReleasedSoftware[], withPdf?: boolean, filters: ReleaseFilters }) {

    const {softwares} = props;

    const [t] = useTranslation();

    return (
        <TableContainer
            sx={{maxHeight: 1, maxWidth: 1, overflowY: 'auto', overflowX: 'auto'}}>
            <Table stickyHeader aria-label="table-header">
                <TableHead>
                    <TableRow>
                        <TableCell>{''}</TableCell>
                        <TableCell>{t('releaseVersion')}</TableCell>
                        <TableCell>{t('distributionDate')}</TableCell>
                        <TableCell>{t('status')}</TableCell>
                        <TableCell>{t('customerId')}</TableCell>
                        <TableCell align='right'>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {softwares.map((software) => (
                        <SoftwareReleaseList key={software.code} software={software} withPdf={props.withPdf} filters={props.filters}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}