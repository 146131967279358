import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'Components/utils';

import { Autocomplete, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTextFieldAC } from 'Components/Input/StyledTextField';


export interface VersionRange { versionFrom?: string, versionTo?: string }

export const VersionRangeSelect = (props: {
    software: string,
    value: VersionRange,
    onChange: (value: VersionRange | undefined) => void
}) => {

    const [{ data: options, status: optionsStatus }, fetchOptions] = useRemoteResourceQueryService<string[]>({ endpoint: `releases/${props.software}/versions`, defaultValue: [] });

    //const [_value, _setValue] = useState<VersionRange>(props.value);
    
    const { value } = props;

    const _options = (options && options.length > 0) ? options : [value.versionFrom, value.versionTo];
    
    const [t] = useTranslation();

    useEffect(() => { fetchOptions({}); }, [fetchOptions]);

    const {onChange} = props;
    const handleChange = (newRange: VersionRange) => {
        //_setValue(newRange);
        onChange(newRange);
    }
    
    return <>
        <Typography sx={{ ml: 2 }}>{t('release')}:</Typography>
        
        <Stack id="VersionRange" direction={'row'} justifyContent='flex-end' alignItems={'center'} sx={{flexGrow: 1}}>

            <Typography sx={{ mx: 2 }}>{t('from')}</Typography>

            <Autocomplete 
                value={value.versionFrom ?? null} 
                options={_options} 
                onChange={(evt, val, reason) => handleChange({ ...value, versionFrom: val ?? undefined })}
                sx={{display: 'block', flex: "0 0 150px", mx: 1}}
                renderInput={(params) => (
                    <StyledTextFieldAC {...params} variant="outlined"/>)}
            />
        </Stack>
        <Stack id="VersionRange" direction={'row'} justifyContent='flex-end' alignItems={'center'} sx={{flexGrow: 1}}>

            <Typography sx={{ mx: 2 }}>{t('to')}</Typography>

            <Autocomplete
                value={value.versionTo ?? null}
                options={_options}
                onChange={(evt, val, reason) => handleChange({ ...value, versionTo: val ?? undefined })}
                sx={{display: 'block', flex: "0 0 150px", mx: 1}}
                renderInput={(params) => (
                    <StyledTextFieldAC {...params} variant="outlined"/>)}
            />
        </Stack>
        <ProgressBackdrop open={optionsStatus === Status.Pending} />
    </>
}

