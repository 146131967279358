import { Snackbar, Alert, AlertColor, SnackbarOrigin, AlertTitle, Typography, useTheme, AlertProps, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export interface NotificationParams {
  severity: AlertColor,
  message: string | ((t: TFunction) => string),
  duration?: number,
  position?: SnackbarOrigin,
}

export type NotificationProps = AlertProps & NotificationParams & {
  onClose: (event: Event | React.SyntheticEvent<any, Event>) => void,
  bold?: boolean
}
export function SnackbarNotify(props: NotificationProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { severity, message, duration, position, onClose, bold, ...otherProps } = props;
  const _message = typeof message === 'string' ? message : message(t);
  const [open, setOpen] = useState(true);
  return (
    <Snackbar
      autoHideDuration={duration || (severity === 'error' ? 4000 : 2800)}
      anchorOrigin={position || { vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={(evt) => {setOpen(false); props.onClose(evt); }}
      message={_message}
      //TransitionComponent={(props: TransitionProps) => <Slide {...props} direction="up" />}
      TransitionComponent={(params: TransitionProps) => {
          const { children, ...otherParams } = params;
          return <Slide {...otherParams} direction="up">{children as React.ReactElement<any, any>}</Slide>
        }}
      >
      {(bold && severity === 'error')
        ? <Alert severity="error" onClose={onClose} {...otherProps}
          sx={{ color: theme.palette.error.main, fontSize: '130%', fontWeight: 'bold', ...otherProps.sx }} >
          {_message}
        </Alert>
        :
        <Alert severity={severity} onClose={onClose} {...otherProps}
          sx={{ fontSize: '120%', fontWeight: 'normal', ...otherProps.sx }} >
          {_message}
        </Alert>}
    </Snackbar >
  )
}