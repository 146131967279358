import { includesIgnoreCase } from "Components/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface DescribedCode {
  code: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionIt: string;
}

export const emptyDescribedCode = {
  code: undefined,
  descriptionDe: undefined,
  descriptionEn: undefined,
  descriptionIt: undefined,
};


export function getCodeAndDescription(lang: string) {
  return (obj?: DescribedCode) => {
    if (obj) return `${obj.code} - ${obj[('description' + lang[0].toUpperCase() + lang[1]) as any as keyof(DescribedCode)]}`;
    else return '';
  }
}

export function descriptionGetter(lang: string) {
  return (obj?: DescribedCode) => {
    if (obj) {
      const key = `description${lang[0].toUpperCase()}${lang[1]}`;
      const result = obj[key as any as keyof(DescribedCode)];
      if (result?.length > 0) 
        return result;
      else
        return obj.code;
    }
    else return '';
  }
}

export function useDescriptionGetter() {
  
  const { i18n } = useTranslation();
  const { language } = i18n;
//  console.log("language", language);
  return useMemo(() => descriptionGetter(language), [language]);
}

export const useOptionLabelGetter = () => {
  const getDescription = useDescriptionGetter();
  return (entity?: DescribedCode) => (entity ? getDescription(entity) : '');
}


export interface DescribedCodeFilter {
  code?: string;
  description?: string;
}

export const makeFilterPredicate = (filter?: DescribedCodeFilter & { language: string }) => {
  if (!filter) {
    return undefined;
  } else {
    const f = filter as DescribedCodeFilter;
    const _getDescription = descriptionGetter(filter.language);
    return (entity: DescribedCode): boolean => {
      return (!f.code || includesIgnoreCase(entity.code, f.code)) && (!f.description || includesIgnoreCase(_getDescription(entity), f.description));
    };
  }
};

export const isEqual = (a: DescribedCode, b: DescribedCode) => a?.code === b?.code;

export const describedCodeFromCode = (code: string): DescribedCode => {
  return {
    code,
    descriptionDe: code,
    descriptionIt: code,
    descriptionEn: code
  };
}
