import {useUrlQuery} from 'Components/useUrlQuery';
import {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {makeAppTheme} from 'Themes/makeAppThemes';

import {ThemeProvider} from '@mui/material';
import i18next from 'i18next';
import {useRemoteResourceQueryService} from "../Components/RemoteEntities/resource.services";
import {HashCredentialsProvider, useCurrentUrlCredentials} from './HashCredentialsProvider';
import {deep1Equal} from 'Components/utils';
import React from 'react';


export {getBrandImage} from './brandImage';

export type CustomerPageURLQueryParams = Record<string, string> & {
    language?: string,
    theme?: string,
    showHeader?: string,
    asAdmin?: string
}

export interface CustomerPageParams {
    language?: string,
    theme?: string,
    showHeader?: boolean,
    asAdmin?: boolean
}

export const useCustomerPageURLQueryParams = (): CustomerPageURLQueryParams => {

    const query = useUrlQuery();

    const newValue: CustomerPageURLQueryParams = {}

    const params = query.entries();
    let param = params.next();
    while (!param.done) {
        const keyValue = param.value;
        switch (keyValue[0].toLowerCase()) {
            case 'language':
                newValue.language = keyValue[1];
                break;
            case 'theme':
                newValue.theme = keyValue[1];
                break;
            case 'showheader':
                newValue.showHeader = keyValue[1];
                break;
            case 'asadmin':
                newValue.asAdmin = keyValue[1];
        }
        param = params.next();
    }

    const [value, setValue] = useState<CustomerPageURLQueryParams>(newValue);

    if (value != newValue && !deep1Equal(value, newValue)) {
        setValue(newValue);
        return newValue;
    }
    console.log('useCustomerPageParams', value);
    return value;
}

export const useCustomerPageParams = (): CustomerPageParams => {
    const values = useCustomerPageURLQueryParams();
    return {
        language: values.language,
        theme: values.theme,
        showHeader: values.showHeader !== undefined && values.showHeader.toLowerCase() === 'true',
        asAdmin: values.asAdmin !== undefined && values.asAdmin.toLowerCase() === 'true',
    }
}

export const CustomerPage = (props: { children: React.FC<{ showHeader: boolean }> }) => {

    const route = useRouteMatch();
    const {software} = route.params as any;

    const params = useCustomerPageURLQueryParams();

    const {language, showHeader, theme: themeName} = params;

    const theme = makeAppTheme(software, themeName ?? undefined);

    useEffect(() => {
        if (language) i18next.changeLanguage(language);
    }, [language]);

    return (
        <HashCredentialsProvider>
            <ThemeProvider theme={theme}>

                <props.children showHeader={showHeader !== 'false'}/>

            </ThemeProvider>
        </HashCredentialsProvider>
    )
}


