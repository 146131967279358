import {Box, Stack, styled, Typography, useTheme} from '@mui/material';
import {useCurrentUrlCredentials} from 'Pages/HashCredentialsProvider';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect} from "react-router";
import {useRouteMatch} from "react-router-dom";
import ProgressBackdrop from "../../Components/Feedback/ProgressBackdrop";
import {Status} from "../../Components/utils";
import {IliasAuthProvider} from '../IliasAuthProvider';

import {CustomerPage, getBrandImage, useCustomerPageURLQueryParams} from '../CustomerPage';

import FaqExplorer from './FaqExplorer';
import {useFaqFull} from "./faqFeed.services";
import FaqSimpleView from "./FaqSimpleView";
import {makeBackgroundColor} from './style';


const StyledContainer = styled('div')(({ theme }) => ({
    height: '100%',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: makeBackgroundColor(theme),
}));

const Wrapper = (props: { showHeader: boolean, children: React.ReactNode }) => {

    const theme = useTheme();
    const brand = useMemo(() => getBrandImage(theme), [theme]);
    const [t] = useTranslation();

    return (
        <StyledContainer id={'FaqFeed-root'}>

            {props.showHeader && (
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'flex-start'}>
                    <img src={brand} style={{ width: 200, height: 100 }} />
                    <Typography variant='h1' color={theme.palette.grey[500]} flex="1 0 auto" align={'right'} sx={{pb: 1, pr:8}}>FAQ</Typography>
                </Stack>
            )}

            <Box sx={{ flexShrink: 1, flexGrow: 1, overflow: 'visible' }}>
                {props.children}
            </Box>

        </StyledContainer>
    )
}

export const FaqFeedPage = () => (
    <CustomerPage>{
        (props: { showHeader: boolean }) => <Wrapper showHeader={props.showHeader}><FaqExplorer /></Wrapper>
    }</CustomerPage>
);

export const SingleFaqPage = () => {
    return  <CustomerPage>{
        (props: { showHeader: boolean }) => <SingleFaqPageContent showHeader={props.showHeader} />
    }</CustomerPage>;
}


const SingleFaqPageContent = (props: {showHeader: boolean}) => {
    
    const credentials = useCurrentUrlCredentials();
    
    const language = useCustomerPageURLQueryParams().language;
    
    const route = useRouteMatch<{software: string, faqId: string}>();
    const software = route.params.software;
    const faqId = Number.parseInt(route.params.faqId);

    const [state, fetch] = useFaqFull({softwareCode: software, faqId, language});
    const isInitialized = state.status !== Status.Idle
    useEffect(() => {
        console.log("LANG WHEN FETCH", language);
        if (!isInitialized && !!faqId)
            fetch({});
    }, [isInitialized]);

    console.log('route', route);
    console.log("FAQ item state", state);

    return <Wrapper showHeader={props.showHeader}>
        {state.status === Status.Success && (
            <IliasAuthProvider>
                <Box mt={props.showHeader ? 4 : 1}>
                    <FaqSimpleView {...state.data}/>
                </Box>    
            </IliasAuthProvider>
        )}
        {state.status === Status.Pending && <ProgressBackdrop open/>}
        {state.status === Status.Failed && <Redirect to={`/customer/${credentials.customer}/sw/${credentials.software}/user/${credentials.user}/faq/${credentials.hash}`} />}
    </Wrapper>
}







