import React, {useContext, useMemo, useState} from "react";
import {ReleasesManagerContext} from "./ReleasesManagerContext";
import {useForm} from "react-hook-form";
import {emptyReleaseFilter, ReleaseFilters} from "./release.models";
import {useReleasedSoftwares} from "./release.services";
import {useTranslation} from "react-i18next";
import {Box, Button, Divider, Drawer, IconButton, Stack, Typography} from "@mui/material";
import Iconify from "Components/iconify";
import {ReleaseFiltersForm} from "./ReleaseFiltersForm";
import {ReleasesBySoftware} from "./ReleasesBySoftware";


export function SelectOtherRelease(props: {container?: HTMLElement}) {

    const [context] = useContext(ReleasesManagerContext);
    
    //const { notesFilter, headFilter } = context.filters;
    const activeReleaseFamily = context.activeRelease?.software.family;
    
    const initialFilters = useMemo(() => ({
        notesFilter: {},
        headFilter: activeReleaseFamily ? { softwareCode: activeReleaseFamily } : {}
    }), [activeReleaseFamily]);
    
    console.log("initialFilters", initialFilters);

    const filtersForm = useForm<ReleaseFilters>({defaultValues: initialFilters});
    
    const { reset } = filtersForm;

    const [filters, setFilters] = useState<ReleaseFilters>(initialFilters);

    console.log("filters", filters);

    const [openFilters, setOpenFilters] = useState(false);
    const handleCloseFilters = () => {
        setFilters(filtersForm.watch());
        setOpenFilters(false);
    }
    const handleClearFilters = () => {
        reset(initialFilters);
        setFilters(initialFilters);
        setOpenFilters(false);
    }

    //console.log(filters);

    const [softwares, softwaresApi] = useReleasedSoftwares(filters);

    const [t] = useTranslation();

    return <>
        <Stack width={1} direction={'row-reverse'} alignItems={'baseline'} alignContent={'space-between'}>
            <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list"/>}
                    onClick={() => setOpenFilters(true)}>
                Filters
            </Button>
            <Typography sx={{flexGrow: 1}}>{t('noteClone.selectTarget')}</Typography>
        </Stack>

        <Drawer
            anchor="right"
            open={openFilters}
            onClose={handleCloseFilters}
            PaperProps={{
                sx: { width: 'auto', maxWidth: '50%', border: 'none', overflow: 'hidden'},
            }}
            ModalProps={{
                container: props.container
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>Filters</Typography>
                <IconButton onClick={handleCloseFilters}>
                    <Iconify icon="eva:close-fill" />
                </IconButton>
            </Stack>

            <Divider />

            <Box sx={{overflowY: 'scroll'}}>
                <ReleaseFiltersForm {...filtersForm} />
            </Box>

            <Box sx={{ p: 3 }}>
                <Button fullWidth  color="inherit"  variant="outlined"
                    startIcon={<Iconify icon="ic:round-clear-all" />}
                    onClick={handleClearFilters}
                >
                    {t('clearAll')}
                </Button>
            </Box>
        </Drawer>
                
        <ReleasesBySoftware softwares={softwares.entities} filters={filters}/>
    </>
}