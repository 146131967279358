import {EmptyObject} from "react-hook-form";
import {deepFreeze} from "Components/utils";
import {withArrayActionLogger} from "Components/reduxLogger";
import {Macroarea} from "../Entities/Macroarea/macroarea.service";

interface NewItemState {
    id: 0,
    isEditing: true,
    isDeleting: boolean,
    clearEntity: EmptyObject, // hack to clear new-entity form when a new macroarea has been saved
    entity: undefined,
}

const makeNewItemSlot = (): NewItemState => ({
    id: 0,
    isEditing: true,
    isDeleting: false,
    clearEntity: {},
    entity: undefined,
});

export interface ExistingItemState {
    id: number,
    isEditing: boolean,
    isDeleting: boolean,
    entity: Macroarea
}

export type ItemState = ExistingItemState | NewItemState;

export function isNew(item: ItemState): item is NewItemState {
    return ((item as NewItemState).id === 0);
}

export type MacroareasCollectionAction =
    | ['INIT', Macroarea[]]
    | ['OPEN', { id: number, forDelete: boolean }]
    | ['CLOSE', number]
    | ['REORDER', number[]]
    | ['SAVE', Macroarea]
    | ['SAVE_NEW', Macroarea]
    | ['DELETE', number]
const _itemsReducer = (state: ItemState[], action: MacroareasCollectionAction): ItemState[] => {

    switch (action[0]) {

        case 'INIT':
            return action[1].map(entity => {
                return deepFreeze({
                    id: entity.id,
                    entity: Object.freeze(entity),
                    isEditing: false,
                    isDeleting: false
                })
            });

        case 'REORDER': {  // re-arranges items according to the given IDs array            
            const reorderedIds = action[1];

            const newState: ItemState[] = [];

            reorderedIds.forEach(id => {
                const note = state.find(x => x.id === id);
                if (note) {
                    newState.push(note);
                }
            });

            if (newState.length !== state.length) {
                console.error("Missing IDs in collection re-sorting");
                return state;
            }
            return newState;
        }

        case 'OPEN': {  // sets editing mode on for given id
            const id = action[1].id;
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                if (id === 0) {

                    return [...state, makeNewItemSlot()];

                } else {
                    console.warn(`Opening Item with id ${id} for editing: item not found`);
                }

            } else { // item found 

                if (id !== 0) { // is existing item

                    state[pos] = {...state[pos], isEditing: true, isDeleting: action[1].forDelete};
                    return [...state];

                } // else no change in state because new item is always in editing mode
            }
            return state;
        }

        case 'CLOSE': {  // sets editing mode off for given id
            const id = action[1];
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.warn(`Closing Item with id ${id}: item not found`);
                return state;

            } else { // item found (existing or new, doesn't matter)

                const item = state[pos];
                const newState = [...state];
                if (!isNew(item)) {
                    newState[pos] = {...item, isEditing: false};
                } else {
                    newState.splice(pos, 1);
                }
                return newState;
            }
        }

        case 'DELETE': {  // discards item
            const id = action[1];

            const pos = state.findIndex(x => x.id === id);

            if (pos === -1) {

                console.warn(`Deleting Item with id ${id}: slot not found`);
                return state;

            } else {

                const newState = [...state];
                newState.splice(pos, 1);
                return newState;
            }
        }

        case 'SAVE': {
            const data = action[1];
            const id = data.id;

            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.error(`Saving Item with id ${id}: item not found`);
                return state;

            } else {

                const item = state[pos];
                const newState = [...state];
                newState[pos] = {id, entity: {...item.entity, ...data}, isEditing: false, isDeleting: false};
                return newState;
            }
        }

        case 'SAVE_NEW': {
            const data = action[1];
            const newId = data.id;

            const pos = state.findIndex(x => x.id === 0);
            if (pos === -1) {

                console.error("Creating Item: new-item slot not found");
                return state;

            } else {

                const item = state[pos];
                const newState = [...state];
                newState[pos] = {id: newId, entity: data, isEditing: false, isDeleting: false};
                return newState;
            }
        }

        default:
            return state;
    }
};
export const itemsReducer = withArrayActionLogger(_itemsReducer, 'MacroareasManagerState');