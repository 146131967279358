
import {Software} from "Entities/Software/software.services";
import {FaqFilter} from "../Faqs/faq.models";


export type SoftwareWithFaqs = Software & { faqsCount: number };


export interface SwFaqFilters {
  software?: Software,
  softwareFamily?: string,
  faqsFilter: FaqFilter
}

export const emptySwFaqFilter: SwFaqFilters = {
  faqsFilter: {}
}


