import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Macroarea } from "Entities/Macroarea/macroarea.service";
import {isNew, NotesCollectionAction, NoteState} from "./notesManagerState";

export interface NotesGroup {
    key: Macroarea,
    notes: NoteState[]
}

const groupNotesByMacroarea = (notes: NoteState[]): NotesGroup[] => {

    const result = notes
        .reduce((_groups, _note) => {
            const macroarea: Macroarea = (isNew(_note) ? undefined  : (_note.abstract.macroarea)) 
                ?? { id: 0, sortIndex: 0, descriptionDe: '', descriptionIt: '', descriptionEn: ''};
            const myGroup = _groups.find(g => g.key.id === macroarea.id);
            if (myGroup === undefined) {
                _groups.push({
                    key: macroarea,
                    notes: [_note]
                })
            } else {
                myGroup.notes.push(_note)
            }
            return _groups;
        }, [] as NotesGroup[])
        .sort((groupA, groupB) => groupA.key.sortIndex - groupB.key.sortIndex);

    return result;
}

export type UseGroupedNotesReturn = [
    NotesGroup[],
    (noteIds: number[], groupId: number) => void // handle order changed within group
]

export function useGroupedNotes(props: {
    notes: NoteState[],
    dispatch: React.Dispatch<NotesCollectionAction>     
}): UseGroupedNotesReturn {

    const { notes, dispatch } = props;

    const groups = useMemo(() => groupNotesByMacroarea(notes), [notes]);

    const handleOrderChangedWithinGroup = useCallback((noteIds: number[], groupId: number) => {

        let allNoteIds = [] as number[];
        groups.forEach(g => {
            if (g.key.id === groupId) {
                allNoteIds = allNoteIds.concat(noteIds);
            } else {
                allNoteIds = allNoteIds.concat(g.notes.map(n => n.id));
            }
        });
        dispatch(['REORDER', allNoteIds])
        
    }, [dispatch, groups]);

    return [groups, handleOrderChangedWithinGroup];
}