import {styled, Table} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import {Status} from 'Components/reduxLogger';
import {SimpleEntity} from 'Entities/SimpleEntity/simpleEntity.models';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IliasAuthProvider} from '../../IliasAuthProvider';

import {ReleaseNote} from '../newsFeed.models';
import {makeBackgroundColor} from '../style';
import {NewsTableHead} from './NewsTableHead';
import {NewsTableSection, Section} from './NewsTableSection';
import {Order, OrderByOptions, ViewContext} from './tableCommons';


const _makeSections = (notes: ReleaseNote[], language: string): Section[] => {
    return notes
        .reduce((sections, note) => {
            const areaId = note.macroarea?.id ?? 'no-area';
            let section = sections.find(s => s.id === areaId);
            if (!section) {
                const _area = note.macroarea ?? unspecifiedArea;
                section = { ..._area, notes: [], isHidden: false, wasHidden: false };
                sections.push(section);
            } 
            section.notes.push({ ...note, wasRead: note.isRead });
            if (note.isHiddenMacroarea) {
                section.isHidden = true;
                section.wasHidden = true;
            }
            return sections;
        }, [] as Section[])
        .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0));
}

const unspecifiedArea: SimpleEntity = { id: 0, sortIndex: 0, descriptionIt: 'Altro', descriptionEn: 'Other', descriptionDe: 'Other' }


export default function NewsTable(props: ViewContext & {
    notes: ReleaseNote[],
    onStatusChanged: () => void,
    expandSections: boolean,
}) {
    const { notes, onStatusChanged, expandSections, ...context } = props;

    const { language } = useTranslation()[1];

    //* DEFINE SECTIONS i.e. grouping by Macroarea

    const [sections, setSections] = useState<Section[]>(_makeSections([], language));

    useEffect(() => { setSections(_makeSections(notes, language)) }, [notes, language]);

    console.log("SECTIONS", sections);

    const handleSectionStatusChanged = (section: Section) => {
        //console.log("SECTION CHANGED", section);
        setSections(_sections => {
            const index = _sections.findIndex(x => x.id === section.id);
            if (index < 0)
                return _sections;
            else
                return [..._sections.slice(0, index), section, ..._sections.slice(index + 1)];
        });
    };


    //* DEFINE SORTING

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<OrderByOptions>('releaseVersion');

    const handleSortChange = (
        event: React.MouseEvent<unknown>,
        sortBy: OrderByOptions,
    ) => {
        const isAsc = orderBy === sortBy && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(sortBy);
    };

    const [remoteStatus, setRemoteStatus] = useState(Status.Idle);

    return <IliasAuthProvider>
        <TableContainer component={Paper} sx={{ width: '100%', maxHeight: '100%', mb: 2 }} id={'News-table-container'} >
            <StyledTable
                sx={{ minWidth: 750, height: '100%' }}
                aria-labelledby="tableTitle"
            >
                <NewsTableHead
                    order={order}
                    orderBy={orderBy}
                    onSortChange={handleSortChange}
                />
                <TableBody>
                    {sections.map((section) => (
                        <NewsTableSection
                            key={section.id}
                            section={section}
                            order={order}
                            orderBy={orderBy}
                            initiallyOpen={props.expandSections}
                            onChanged={handleSectionStatusChanged}
                            onRemoteStatus={setRemoteStatus}
                            {...context}
                        />)
                    )}
                </TableBody>
            </StyledTable>
        </TableContainer>
        <ProgressBackdrop open={remoteStatus === Status.Pending} />
    </IliasAuthProvider>;
}


const StyledTable = styled(Table)(({ theme }) => ({
    '.MuiTableBody-root': {
        background: makeBackgroundColor(theme)
    }
}))
