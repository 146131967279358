
export enum Status { Idle = 'Idle', Pending = 'Pending', Success = 'Success', Failed = 'Failed' }

export type Reducer<TState, TActions extends { type: string, payload?: any }> = (state: TState, action: TActions) => TState

export function withLogger<TState, TActions extends { type: string, payload?: any }>(
  reducer: Reducer<TState, TActions>
  ): Reducer<TState, TActions> {
  return function (state: TState, action: TActions): TState {
    const newState = reducer(state, action);
    console.log(`${reducer.name} ${action.type} \npayload`, action.payload, '\nold state', state, '\nnew state', newState);
    return newState;
  }
}

export type ArrayActionsReducer<TState, TActionTypes, TActions extends [TActionTypes, any?]> = (state: TState, action: TActions) => TState;

export function withArrayActionLogger<
  TState,
  TActionTypes,
  TActions extends [TActionTypes, any?]
>(reducer: ArrayActionsReducer<TState, TActionTypes, TActions>, name?: string): ArrayActionsReducer<TState, TActionTypes, TActions> {

  const _name = name ?? reducer.name;
  return function (state: TState, action: TActions): TState {
    // Print out deep copies. This helps to detect bugs where the state object is being mistakenly mutated (in the reducer or elsewhere)
    const printOldState = JSON.parse(JSON.stringify(state));
    const newState = reducer(state, action);
    const printNewState = JSON.parse(JSON.stringify(newState));
    console.log(`[${_name}] ${action[0]} \npayload`, (action.length > 0 && !!action[1]) ? JSON.parse(JSON.stringify(action[1])) : '[undefined payload]', '\nold state', printOldState, '\nnew state', printNewState);
    return newState;
  }
}



