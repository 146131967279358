import {useRemoteResourceQueryService} from "Components/RemoteEntities/resource.services";
import {useCallback, useEffect} from "react";
import {Status} from "Components/reduxLogger";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faWrench} from "@fortawesome/pro-regular-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {DownloadPdf} from "Components/DownloadPdf";

const pdfHttpParams = {
    headers: {'accept': 'application/csv'}
}
export const DownloadReleasePdf = (props: {
    releaseId: number,
    language: string,
    filename: string,
    techNotes?: boolean,
}) => {

    const {releaseId, language, filename, techNotes} = props;

    const [state, fetch] = useRemoteResourceQueryService<Blob, null, { releaseId: number, language: string }>({
        endpoint: props.techNotes ? 'releases/pdfTechnical' : 'releases/pdf',
        defaultValue: null,
        httpParams: pdfHttpParams
    });

    const {data: pdfBlob, status: pdfStatus} = state;

    const handleClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.stopPropagation();
        evt.preventDefault();
        fetch({releaseId, language});
    }, [fetch, language, releaseId]);


    return <DownloadPdf onClick={handleClick} blob={state.data} status={state.status} 
                        filename={props.filename + (techNotes ? '_technical' : '')} />
}