import {HookedCheckbox, HookedTextField} from 'Components/HookedForm/inputControls';
import {LabelSelect} from 'Entities/Label/LabelSelect';
import {NoteVerbose} from './note.models';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Box, Stack, Typography} from '@mui/material';

import {NoteTranslationFormProps} from './NoteTranslationForm';
import TranslationFormTabs from './NoteTranslationsContainer';
import {ModuleAutocompleteRemote} from 'Entities/SoftwareParts/ModuleAutocompletes';
import {FormAutocompleteRemote} from 'Entities/SoftwareParts/FormsAutocompletes';
import {SimpleEntityAutocompleteHookedOnBlur} from 'Entities/SimpleEntity/SimpleEntityAutocompleteHookedOnBlur';

import {DepartmentsAutocomplete} from "Entities/Department/DepartmentsAutocomplete";

export type NoteFormProps = NoteTranslationFormProps;

export const NoteForm: React.FC<NoteFormProps> = (props: NoteFormProps) => {

    const [t] = useTranslation();

    const {
        control,
        getValues,
        watch,
        context: {softwareCode}
    } = props;

    /* Hack for ERGO: in new note, when macroarea gets set, add all departments (they prefer to show  the note in unrelated depts rather than risk not showing it in related depts) */

    let doAddAllDepts = false;
    if (softwareCode === 'ERGOBUILD') {
        const isNewNote = getValues('id') == 0;
        const hasMacroarea = watch('macroarea') !== undefined;
        const departments = watch('departments');
        doAddAllDepts = isNewNote && hasMacroarea && (departments === undefined || (departments.length === 0));
    }
    
    //console.log("NOTE VALUES", getValues());

    return <Box>

        {/* ERGO ONE */}
        {props.context.softwareCode === 'ERGOBUILD' && (
            <Typography><HookedCheckbox control={control} name='ergoOne'/>Ergo ONE</Typography>
        )}
        <Stack flexDirection='row' alignItems={'stretch'} id='Notes-filters'>
            <Stack flex={'1 0 50%'}>

                {/* MACROAREA */}
                <SimpleEntityAutocompleteHookedOnBlur<NoteVerbose, 'macroarea'> control={control}
                                                                                name={"macroarea"}
                                                                                label={t('macroarea')}
                                                                                endpoint={`baseData/macroareas?softwareCode=${props.context.softwareCode}`}
                                                                                sx={{
                                                                                    maxWidth: 'auto',
                                                                                    minWidth: 'auto'
                                                                                }}

                />

                {/* DEPARTMENTS */}
                <Controller control={control} name={"departments"}
                            render={({field}) => {
                                return <DepartmentsAutocomplete
                                    value={field.value ?? []}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    selectAll={doAddAllDepts}
                                />
                            }}/>
            </Stack>

            <Stack flex={'1 0 50%'} sx={{pr: 1}}>

                {/* MODULE */}
                <Controller control={control} name={'module'}
                            render={({field}) => {
                                return (
                                    <ModuleAutocompleteRemote name={'module'}
                                                              softwareCode={softwareCode}
                                                              value={field.value}
                                                              onChange={(evt, value) => {
                                                                  field.onChange(value);
                                                              }}
                                                              onBlur={field.onBlur}/>
                                );
                            }}
                />

                {/* FORMS */}
                <Controller control={control} name='forms'
                            render={({field}) => (
                                <FormAutocompleteRemote name={"form"}
                                                        multiple
                                                        softwareCode={softwareCode}
                                                        value={field.value}
                                                        onChange={(evt, value) => {
                                                            field.onChange(value);
                                                        }}
                                                        onBlur={field.onBlur}/>
                            )}
                />
            </Stack>
        </Stack>

        <Stack direction='row' sx={{my: 2, mx: 0}}>

            {/* LABELS */}
            <LabelSelect control={control} path='labels' getValues={getValues}/>

            {/* TODO */}
            <HookedTextField control={control}
                             name={"todo"} label={t('todo')}
                             sx={{minWidth: 50}}
            />
        </Stack>

        <TranslationFormTabs  {...props} />
    </Box>
}
