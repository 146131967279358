import {
    EntityFormCustom, EntityFormCustomRenderProps as EntityFormCustomRenderProps
} from 'Components/HookedForm/EntityFormCustom';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import React, {useMemo} from 'react';
import {DefaultValues, FieldValues, UseFormProps} from 'react-hook-form';

import {EntityDeleteDialog} from './EntityDeleteDialog';
import {EntityEditorApiProps, useEntityEditorApi} from './useEntityEditorApi';
import {RemoteEntityResultHandler} from './RemoteEntityResultHandler';
import {Status} from 'Components/reduxLogger';
import {ContentLayout} from "../../Containers/ContentLayout";
import {Box, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {EntityForm} from "../../HookedForm/EntityForm";

/**
 * REMOTE ENTITY EDITOR
 * Fetches or creates entity instance,
 * Renders custom form to edit the entity,
 * Provides save/delete/cancel(go-back) callbacks for the form buttons.
 *
 * @param props
 * @returns
 */

export function EntityEditorCustom<
    TModel extends FieldValues,
    TModelVerbose extends FieldValues = TModel,
    TContext = Record<string, never>
>(
    props: EntityEditorApiProps<TModelVerbose, TModel, TContext> & {

        defaultValues?: DefaultValues<TModelVerbose>, // will be used to initialize the form when no initial entity is available (e.g. while fetching)
        context: TContext,
        onSaved?: (entity: TModelVerbose) => void,
        onDeleted?: () => void,
        onReadError?: (error: any) => void,
        onWriteError?: (error: any) => void,
        onExit: () => void,
        formProps?: Omit<UseFormProps<TModelVerbose>, 'defaultValues'>,
        children: React.FC<EntityFormCustomRenderProps<TModelVerbose, TContext>>,  // component that renders input fields for entity props

    }): React.ReactElement {

    const editorApi = useEntityEditorApi(props);

    const {entityState} = editorApi;
    const {data: entity} = entityState;

    const {defaultValues} = props;
    const _defaultValues = useMemo(() => ({...defaultValues, ...(entity as DefaultValues<TModelVerbose>)}), [entity, defaultValues]);

    console.log('EntityEditorCustom', props.editorId);
    
    return <>
        {((entityState.readStatus === Status.Idle || entityState.readStatus === Status.Failed) && true)

            || (entityState.readStatus === Status.Pending && <ProgressBackdrop open/>)

            || <>
                <ProgressBackdrop open={entityState.upsertStatus === Status.Pending}/>
                <EntityFormCustom<TModelVerbose, TContext>
                    name={props.editorId}
                    formProps={props.formProps}
                    defaultValues={_defaultValues}
                    context={props.context}
                    onSave={editorApi.doSave}
                    onDelete={editorApi.doDelete}
                    onCancel={props.onExit}
                    disabled={props.isEditable === false}
                >
                    {props.children}
                </EntityFormCustom>
                <EntityDeleteDialog
                    isConfirmationPending={editorApi.isDeleteConfirmPending}
                    onAborted={editorApi.onDeleteAborted}
                    onConfirmed={editorApi.onDeleteConfirmed}
                />
            </>
        }
        <RemoteEntityResultHandler {...entityState}
                                   onSaved={props.onSaved}
                                   onDeleted={props.onDeleted}
                                   onError={props.onWriteError}/>
    </>
}
