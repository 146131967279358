import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import {ColoredLabel} from "Components/Feedback/ColoredLabel";
import {getHtmlBodyAndStyle} from "Components/HtmlContent/htmlAdapter";
import HtmlContent from 'Components/HtmlContent/HtmlContent';
import {Status} from "Components/reduxLogger";

import {getContentTypeShortCode} from "Entities/commons";
import {useDescriptionGetter} from "Entities/DescribedCode/describedCode.models";
import {IliasLinkHandler} from "../../IliasAuthProvider";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {InfomindsColors} from "Themes/infomindsColors";
import {ReleaseNote} from "../newsFeed.models";
import {useReleaseNoteFull} from "../newsFeed.services";

export default function NoteView(props: ReleaseNote & { isTechUser: boolean }) {

    const [expanded, setExpanded] = useState(false);

    const [{ status: loadStatus, data: fullNote }, loadFullNote] = useReleaseNoteFull({ releaseId: props.releaseId, noteId: props.noteId, technical: props.isTechUser });

    const toggleExpand = () => {
        setExpanded(val => !val)
        if (loadStatus === Status.Idle) loadFullNote({});
    }

    const getDescription = useDescriptionGetter();

    const [t] = useTranslation();

    let html: string | undefined = props.html;
    if (expanded && fullNote) {
        const { body, style } = getHtmlBodyAndStyle(fullNote.html);
        html = body;
    } 

    return <Card>
        <CardContent sx={{ '&.MuiCardContent-root': { py: 1, position: 'relative' } }}>
            <Typography sx={{ mt: 2 }}>
                {props.labels.map((label) =>
                    <ColoredLabel key={label.code} color={label.color}>{getDescription(label)}</ColoredLabel>
                )}
                <span style={{ paddingLeft: 16 }}>{props.todo}</span>
                {props.isTechUser && <span>&nbsp;({getContentTypeShortCode(props)})</span>}
            </Typography>
            <Typography variant="h5">{props.title}</Typography>
            <Box sx={expanded ? {} : {
                maxHeight: 300,
                overflowY: 'hidden',
                textOverflow: 'ellipsis',
                WebkitMaskImage: 'linear-gradient(180deg, #000 50%, transparent)'
            }}>
                <IliasLinkHandler><HtmlContent html={html} /></IliasLinkHandler>
            </Box>
            <Box sx={{ width: 1, textAlign: 'center'}}>
                <Button variant='text'
                    sx={{ color: InfomindsColors.blue }}
                    onClick={toggleExpand}
                >
                    {t(expanded ? 'lessDetails' : 'moreDetails')}
                </Button>
            </Box>
        </CardContent>
    </Card>


}

