
import { NonEditableField } from 'Components/FormUtils';
import {
    ItemEditProps, ItemsWrapperProps, ItemViewProps
} from 'Components/RemoteEntities/Collection/CollectionEditController';
import {
    CollectionManagerWithSearch
} from 'Components/RemoteEntities/Collection/CollectionManagerWithSearch';
import { AddBar } from 'Components/RemoteEntities/Collection/CollectionUtils';
import { RemoteCollectionApi } from 'Components/RemoteEntities/Collection/RemoteCollection';
import React from 'react';
import { EmptyObject } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { DescribedCode, DescribedCodeFilter } from '../describedCode.models';
import { StyledCell } from './Styled';

export function DescribedCodesManager(props: {
  titleKey: string,
  useCollection: (props: { search?: string, filter?: DescribedCodeFilter }) => RemoteCollectionApi<DescribedCode>,
  alignCode?: 'right' | 'center',
  children: React.FC<ItemEditProps<DescribedCode>>
}) {
  return (
    <CollectionManagerWithSearch<DescribedCode>
      collectionId={props.titleKey}
      titleKey={props.titleKey}
      useCollection={props.useCollection}
      canAdd={true}
      context={{}}
    >{{
      //Filters: DescribedCodeFilterForm,
      ItemsWrapper: Wrapper,
      ItemView: DescribedCodeView,
      ItemEditor: props.children,
    }}</CollectionManagerWithSearch>
  )
}


export function DescribedCodeView<TContext = EmptyObject>(props: {
  entity: DescribedCode,
  context: TContext,
  onEditClick: () => void,
  onDeleteClick: () => void
}) {

  const { entity } = props;
  return (
    <TableRow>
      <StyledCell sx={{ pl: 4, align: 'right' }}>
        <NonEditableField value={entity.code} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionDe} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionIt} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionEn} plain />
      </StyledCell>
      <StyledCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={props.onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={props.onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </StyledCell>
    </TableRow>
  );
}


export function Wrapper(props: {
  children: React.ReactNode,
  editingIndex: number | null,
  onAddItemClick: () => void,
}) {
  const { t } = useTranslation();

  return (<>
    <TableContainer sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <Table aria-label="described-codes-table" size='medium'>
        <TableHead>
          <TableRow>
            <StyledCell>{t('code')}</StyledCell>
            <StyledCell>{t('descriptionDe')}</StyledCell>
            <StyledCell>{t('descriptionIt')}</StyledCell>
            <StyledCell>{t('descriptionEn')}</StyledCell>
            <StyledCell>{null}</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
    <AddBar onAddButtonClick={props.onAddItemClick}/>
  </>
  );
}






