import React from "react";
import { Box, Button, IconButton, Typography, Stack } from "@mui/material";
import { useTheme, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { SxProps } from "@mui/system";
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';

import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import BackIcon from '@mui/icons-material/ArrowBack';

export interface ContentLayoutProps {
    rootId?: string,
    title?: string | React.ReactNode,
    sx?: SxProps<Theme>,
    children: React.ReactNode,
    isLoading?: boolean,
    onBackClicked?: () => void,
    customHeader?: React.ReactNode
}

export function ContentLayout(props: ContentLayoutProps) {
    const theme = useTheme();
    const { t } = useTranslation();
    const rootId = props.rootId || 'ContentWrapper';
    return (
        <Box sx={{ mt: 3, ...props.sx }} id={rootId}>
            <Stack id={rootId + '-header'} direction='row' sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>

                {props.onBackClicked && <Box ml={-2}><IconButton onClick={props.onBackClicked}><BackIcon /></IconButton></Box>}
                
                <Typography variant='h6'
                    sx={{ color: theme.palette.text.secondary }}
                >{props.title}</Typography>

                <Stack direction='row-reverse' sx={{flex: 1}}>{props.customHeader || null}</Stack>
                
                {/* {props.onBackClicked && <Button type='button' variant="outlined" onClick={props.onBackClicked}>{t('back')}</Button>} */}
            </Stack>
            {props.children}
            <ProgressBackdrop open={!!props.isLoading} />
        </Box>
    )
}