
import { emptyMacroarea, Macroarea } from 'Entities/Macroarea/macroarea.service';
import { DefaultValues, UseFormReturn } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Stack } from '@mui/material';

import { SoftwareMacroarea, useMacroareaEditService } from './macroarea.service';
import { HookedTextField } from 'Components/HookedForm/inputControls';
import { EntityEditorCustom } from 'Components/RemoteEntities/Entity/EntityEditorCustom';
import { useConst } from 'Components/useConst';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {isNew, ItemState, MacroareasCollectionAction} from "./macroareasManagerState";


interface MacroareaEditorProps {
    softwareCode: string,
    dispatch: React.Dispatch<MacroareasCollectionAction>,
    item: ItemState,
    doDelete?: boolean
}

export const MacroareaEditor = (props: MacroareaEditorProps) => {

    const { item, softwareCode, dispatch } = props;
    const { id, entity } = item;
    const _entity: SoftwareMacroarea | undefined = useMemo(() => (entity ? {...entity, softwareCode } : undefined), [entity, softwareCode]);

    const defaultFormValues = useMemo(() => ({...emptyMacroarea(), softwareCode: softwareCode}), [softwareCode]);

    const handleItemSaved = useConst(() => (entity: Macroarea) => {
        const { id } = item;
        if (isNew(item))
            dispatch(['SAVE_NEW', entity]);
        else
            dispatch(['SAVE', entity]);
    });

    const handleItemDeleted = useConst(() => () => dispatch(['DELETE', id]));

    const handleItemClose = useConst(() => () => dispatch(['CLOSE', id]));

    return <EntityEditorCustom<SoftwareMacroarea, SoftwareMacroarea, { softwareCode: string }>
        editorId={'MacroareaEditor-' + (item?.id ?? 'new')}
        useRemoteEntity={useMacroareaEditService}
        context={{ softwareCode }}
        idKey='id'
        model={_entity ?? false}
        defaultValues={_entity ?? defaultFormValues}
        proposeDelete={item.isDeleting}
        onSaved={handleItemSaved}
        onDeleted={handleItemDeleted}
        onExit={handleItemClose}
    >{CustomMacroareaForm}</EntityEditorCustom>;
}


const StyledInput = (props: any) => <HookedTextField {...props} sx={{ mx: 0 }} />


function CustomMacroareaForm(props: UseFormReturn<SoftwareMacroarea> & {
    rootName: string,
    disabled?: boolean,
    defaultValues: DefaultValues<Macroarea> | null,
    onPendingChanges: (isDirty: boolean) => void,
    onSave?: () => void, // handles Save button click
    onCancel?: () => void, // handles Back button click 
    //onDelete?: () => void, // handles Delete button click 
    //onReset?: () => void   // callback when form is reset to initial values
}) {

    const { defaultValues } = props;

    const isNewEntity = !defaultValues?.id;

    const [t] = useTranslation();

    return <Stack direction='row'>
        <StyledInput name={'descriptionDe'} label={t('descriptionDe')} control={props.control} rules={{ required: true }} />
        <StyledInput name={'descriptionIt'} label={t('descriptionIt')} control={props.control} rules={{ required: true }} />
        <StyledInput name={'descriptionEn'} label={t('descriptionEn')} control={props.control} rules={{ required: false }} />
        <Stack direction='row'>
            <IconButton onClick={props.onSave} disabled={!props.formState.isDirty}>
                <SaveIcon />
            </IconButton>
            <IconButton onClick={props.onCancel}>
                <CloseIcon />
            </IconButton>
        </Stack>
    </Stack>
}