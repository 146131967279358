import { ReleaseContext } from './note.models';

import { NoteEditor } from './NoteEditor';
import { NoteViewer } from './NoteViewer';
import {NotesCollectionAction, NoteState} from "./notesManagerState";


export const NoteContainer = (props: ReleaseContext & {
   state: NoteState,
   dispatch: React.Dispatch<NotesCollectionAction>
}) => {

   const { state } = props;

   if (state.isEditing) {

      return <NoteEditor
         key={state.id}
         item={state}
         dispatch={props.dispatch}
         releaseId={props.releaseId}
         softwareCode={props.softwareCode} />

   } else {

      return state.show ? (
         <NoteViewer
            key={state.id}
            {...state}
            dispatch={props.dispatch}
            releaseId={props.releaseId} />
      ) : null
   }
}
