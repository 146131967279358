import React from 'react';
import {
    Control, Controller, ControllerFieldState, ControllerRenderProps, FieldValues, Path
} from 'react-hook-form';

import { TextFieldProps } from '@mui/material';

import { Rules } from './commons';

import {StyledTextField} from 'Components/Input/StyledTextField';

// const CustomTextField = styled(TextField)({
//   '& .MuiInputBase-root.Mui-disabled': {
//     backgroundColor: '#f0f0f0',
//   },
// });

export function HookedTextField<TFieldValues extends FieldValues>(props: Omit<TextFieldProps, "value" | "defaultValue" | "ref" | "onBlur"> & {
  name: Path<TFieldValues>,
  control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
  rules?: Rules<TFieldValues, Path<TFieldValues>>,
  onBlur?: () => void, //PropagateEventCallback,
  block?: boolean,
  maxLength?: number,
}): React.ReactElement {
  const { name, control, rules, block, maxLength, type, label, onChange, ...otherProps } = props;
  if (maxLength) {
    otherProps.inputProps = { ...otherProps.inputProps, maxLength }
  }
  if (block) {
    otherProps.sx = { ...otherProps.sx, display: 'flex' }  // normally FormControl (TextFields' root element) has display: inline-flex
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={(renderProps: {
        field: ControllerRenderProps<TFieldValues>,
        fieldState: ControllerFieldState
      }) => {
        const { field, fieldState } = renderProps;
        return (
          <StyledTextField
            error={fieldState.invalid}
            name={field.name}
            value={!field.value ? (type == 'password' && !fieldState.isDirty ? 'xxxxxx' : '') : field.value}
            //value={!field.value ? '' : field.value}
            type={type}
            label={(!field.value && type === 'password') ? undefined : label}
            ref={field.ref}
            //onBlur={handleBlur}
            onBlur={field.onBlur}
            onChange={(val) => {
              field.onChange(val);
              onChange && onChange(val);
            }}
            variant='outlined'
            {...otherProps}
          />
        )
      }}
    />
  )
}

