

import {
  SortingSpecs, useProcessedRemoteCollection, useRemoteCollection
} from 'Components/RemoteEntities/Collection/RemoteCollection';
import { useRemoteEntity } from 'Components/RemoteEntities/Entity/RemoteEntity';
import {
  useRemoteResourceQueryService, useRemoteResourceSubmissionService
} from 'Components/RemoteEntities/resource.services';
import { includesIgnoreCase } from 'Components/utils';
import { descriptionGetter } from './describedCode.models';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DescribedCode } from './describedCode.models';

//************** COLLECTION */

export function makeDescribedCodesCollectionService<TModel extends DescribedCode = DescribedCode>(_props: { endpoint: string }) {
  return () => {
    return useRemoteResourceQueryService<TModel[], TModel[]>({
      endpoint: _props.endpoint,
      defaultValue: []
    });
  }
}


//************** SEARCHABLE COLLECTION */

//const _sorting: SortingSpecs<DescribedCode> = [{ by: 'code' }];

export function makeSearchPredicate(filter?: {search?: string, language: string }) {
  if (!filter) {
    return undefined;
  } else {
    const { search, language } = filter;
    const _getDescription = descriptionGetter(language);
    return (entity: DescribedCode): boolean => {
      return includesIgnoreCase(entity.code, search) || includesIgnoreCase(_getDescription(entity), search);
    }
  }
}

export function makeDescribedCodesSearchCollection<TModel extends DescribedCode = DescribedCode>(_props: { endpoint: string }) {

  return (props: { search?: string }) => {

    const { i18n } = useTranslation();
    const { language } = i18n;
    const { search } = props;

    const filterWithLanguage = useMemo(() => ({ search, language }), [search, language]);
    
    return useProcessedRemoteCollection<TModel, {search?: string, language: string }>({
      endpoint: _props.endpoint,
      isPaginated: false,
      requiresQueryParams: false,
      filterParams: filterWithLanguage,
      makeFilterPredicate: makeSearchPredicate,
      //sort: _sorting
    });
  }
}


//************** FILTERED COLLECTION */

export interface DescribedCodeFilter {
  code?: string,
  description?: string,
}

function makeFilterPredicate(filter?: DescribedCodeFilter & { language: string }) {
  if (!filter) {
    return undefined;
  } else {
    const f = filter as DescribedCodeFilter;
    const _getDescription = descriptionGetter(filter.language);
    return (entity: DescribedCode): boolean => {
      return (!f.code || includesIgnoreCase(entity.code, f.code))
        && (!f.description || includesIgnoreCase(_getDescription(entity), f.description));
    }
  }
}



export function makeFilteredDescribedCodesCollection<TModel extends DescribedCode = DescribedCode>(_props: { endpoint: string }) {

  return (props: { filter?: DescribedCodeFilter }) => {

    const { i18n } = useTranslation();
    const { language } = i18n;
    const { filter } = props;

    const filterWithLanguage = useMemo(() => ({ ...filter, language }), [filter, language]);

    return useProcessedRemoteCollection<TModel, DescribedCodeFilter & { language: string }>({
      endpoint: _props.endpoint,
      isPaginated: false,
      requiresQueryParams: false,
      filterParams: filterWithLanguage,
      makeFilterPredicate,
      //sort: _sorting
    });
  }
}



//************** ENTITY READ and UPDATE */

export function makeDescribedCodeService<TModel extends DescribedCode = DescribedCode>(_props: { endpoint: string }) {

  return () => {
    return useRemoteEntity<TModel>({
      endpoint: _props.endpoint,
      idKey: 'code',
      remoteCreation: false
    });
  }
}


export function makeDescribedCodesUpdaterService<TModel extends DescribedCode = DescribedCode>(_props: { endpoint: string }) {
  return () => {
    return useRemoteResourceSubmissionService<{ upsert: TModel[], remove: string[] }, boolean>({
      endpoint: _props.endpoint,
      method: 'POST'
    });
  }
}




