import {Box} from '@mui/system';
import {ContentLayout} from 'Components/Containers/ContentLayout';
import {Software} from "Entities/Software/software.services";
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {FaqsManager} from "../Faqs/FaqsManager";
import {SoftwareLoader} from "./SoftwareLoader";


import {SwFaqsManagerContext} from "./SwFaqsManagerContext";

export function SoftwareFaqsEditor() {

    const [
        {urlBasePath, activeSoftware: contextSoftware, filters }, 
        dispatchContext
    ] = useContext(SwFaqsManagerContext);

    const routeMatch = useRouteMatch<{ softwareCode: string }>();
    const { softwareCode: softwareCodeFromUrl } = routeMatch.params;
    
    console.log("softwareCodeFromUrl", softwareCodeFromUrl);

    const {push: historyPush} = useHistory();

    const swInitializer = contextSoftware ?? (softwareCodeFromUrl === 'new' ? undefined : softwareCodeFromUrl);
        
    const [software, setSoftware] = useState(contextSoftware);
    
    // When both software and swInitializer are objects but different, set software = swInitializer. 
    // This allows to switch to an other software when cloning FAQ
    useEffect(() => {
        if (software && typeof swInitializer === 'object' && swInitializer.code !== software.code)
            setSoftware(swInitializer)
    }, [software, swInitializer]);

    console.log("swInitializer", swInitializer);
    console.log("software", software);

    const initializerCode = typeof swInitializer === 'object' ? swInitializer.code : swInitializer;

    const handleLoaded = useCallback((value: Software) => {
        setSoftware(value);
        if (value.code !== initializerCode)
            dispatchContext(['SET_ACTIVE_SOFTWARE', value]);
        historyPush(`${urlBasePath}/${value.code}`);
    }, [urlBasePath, historyPush, initializerCode, dispatchContext]);

    const handleExit = useCallback(() => {
        dispatchContext(['SET_ACTIVE_SOFTWARE', undefined]);
        historyPush(urlBasePath);
    }, [dispatchContext, historyPush, urlBasePath]);


    const title = software ? software.description + ' FAQ' : 'FAQ';
    
    const softwareToFetch = (typeof swInitializer === 'object') ? false : swInitializer;
    
    console.log('softwareToFetch', softwareToFetch);
        
    
    return <ContentLayout rootId={'SwFaq-root'} onBackClicked={handleExit} title={title}>
        
        {!software && <SoftwareLoader onLoaded={handleLoaded} code={softwareToFetch}/>}

        {software && (
            <Box ml={2} id={'SwFaqNotes-root'}>
                <FaqsManager softwareCode={software.code ?? softwareCodeFromUrl} initialFilter={filters.faqsFilter}/>
            </Box>
        )}
    </ContentLayout>
}

