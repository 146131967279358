import {Box, Typography} from "@mui/material";
import {useMemo} from "react";
import {IliasLinkHandler} from "../IliasAuthProvider";
import { Faq } from "./faqFeed.models";
import HtmlContent from 'Components/HtmlContent/HtmlContent';
import { useTranslation } from "react-i18next";
import { getHtmlBodyAndStyle } from "Components/HtmlContent/htmlAdapter";

export default function FaqSimpleView(props: Faq) {

    const [t] = useTranslation();
    const html = props.html;
    
    const htmlBody = useMemo(() => { 
        const { body, style } = getHtmlBodyAndStyle(html);
        return body;
    }, [html]);
    
    return <>
        <Typography variant={'h3'}>{props.title}</Typography>
        <IliasLinkHandler>
            <HtmlContent html={htmlBody}/>
        </IliasLinkHandler>
    </>
}

