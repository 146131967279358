import { Autocomplete, AutocompleteProps, IconButton, IconButtonProps, Select, SelectProps, TextField, lighten, styled } from "@mui/material";


export interface StyledButtonProps extends IconButtonProps {
    isActive?: boolean
}

export const StyledButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isActive' })<StyledButtonProps>(({ theme, isActive }) => {
    return {
        borderRadius: '10%',
        backgroundColor: isActive ? theme.palette.grey[500] : undefined,
        color: 'inherit'
    }
});

export const StyledSelect = styled(Select)<SelectProps>(({ theme }) => {
    return {
        color: 'inherit',
        backgroundColor: 'inherit'
    }
});

export const StyledAutocomplete = styled(Autocomplete)<AutocompleteProps<string, false, false, false>>(({ theme }) => {
    return {
        color: 'inherit',
        backgroundColor: 'inherit'
    }
});

export const StyledTextField = styled(TextField)(({ theme }) => {
    return {
        '& .MuiInputBase-root': {
            color: 'inherit'
        },
    }
});


