import React from "react";
import { useEffect, useRef } from "react";

/** 
 * Hook to cancel promises when a component unmounts
 * from https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f 
 */

export interface Cancelable<T> {
  promise: Promise<T>,
  cancel: () => void
}

function getCancelMessage(assertCleanup?: () => boolean) {
  const cleanupInfo = assertCleanup ? ` cleaned up: ${assertCleanup()}` : '';
  return 'canceled' + cleanupInfo
}


function makeCancelable<T>(promise: Promise<T>, cleanup?: () => void, assertCleanup?: () => boolean): Cancelable<T> {
  let isCanceled = false;
  const wrappedPromise =
    new Promise<T>((resolve, reject) => {
      promise
        .then((val) => (isCanceled ? reject(getCancelMessage(assertCleanup)) : resolve(val)))
        .catch((error) => (isCanceled ? reject(getCancelMessage(assertCleanup)) : reject(error))); //{ !isCanceled && reject(error) });//(isCanceled ? reject({ isCanceled }) : reject(error)));
    });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
      if (cleanup) {
        cleanup();
      }
    },
  };
}

export type MakeCancelable<T> = (promise: Promise<T>) => Promise<T>

export function useCancelable<T>(): (promise: Promise<T>, cleanup?: () => void, assertCleanup?: () => void) => Promise<T> {

  const promises = useRef<Cancelable<T>[]>();
  // think of useRef as member variables inside a hook
  // You cannot simply define promises array here, because it
  // would get initialized at every render refresh.

  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(() => {
    promises.current = promises.current || [];
    return function cancel() {
      if (promises.current?.length && process.env.NODE_ENV === 'development') {
        console.log("Cancel cancelable promises", promises.current);
      }
      promises.current?.forEach(p => p.cancel());
      promises.current = [];
    };
  }, []);

  return React.useCallback((p: Promise<T>, cleanup?: () => void) => {
    const cPromise = makeCancelable<T>(p, cleanup);
    promises.current?.push(cPromise);
    return cPromise.promise;
  }, []);
}