
import parse from 'html-react-parser';
import { memo } from 'react';
import './content.scss';

// const HtmlContent = memo((props: { 
//     html?: string,
//     style?: React.CSSProperties
// }) => <div style={props.style} className='HtmlContent'>{props.html ? parse(props.html) : null}</div>);
// HtmlContent.displayName = "HtmlContent";


const HtmlContent = memo((props: {
    html?: string,
    style?: React.CSSProperties
}) => <div className='HtmlContent' style={props.style}>{props.html ? parse(adjustCSS(props.html)) : null}</div>);
HtmlContent.displayName = "HtmlContent";
const adjustCSS = (html: string): string => {
    
    const output = html;
    
    output.replace("font-size: 11px", "font-size: 16px");
    output.replace("font-size:11px", "font-size:16px");
    
    return output;
}
export default HtmlContent;


