//const themes = makeAppThemes();
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import {useEffect} from "react";
import {loginRequest} from "../../authConfig";
import {useHistory} from "react-router-dom";
import {useActiveDirectorySignIn} from "./auth.services";

export default function ActiveDirectoryLogin(props: { onSuccessRedirectToPath: string }) {

    const {inProgress} = useMsal();

    // Wait if any interaction is currently in progress (logout f.ex)
    if (inProgress !== InteractionStatus.None) return <></>

    return <>
        <AuthenticatedTemplate>
            <RedirectToApp path={props.onSuccessRedirectToPath}/>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <RedirectToAuthentication/>
        </UnauthenticatedTemplate>
    </>;
}

// Login using ActiveDirectory redirect
function RedirectToAuthentication() {
    
    const {instance, inProgress} = useMsal();
    
    console.log("Redirected to authentication");
    
    useEffect(() => {
        
        if (inProgress !== InteractionStatus.None || !instance) return;

        console.log("acquireTokenRedirect");
        
        instance.acquireTokenRedirect({...loginRequest}).catch(e => {
            console.error(e);
        })
    }, [inProgress, instance])

    return <></>
}

function RedirectToApp(props: { path: string }) {

    const {instance, accounts, inProgress} = useMsal();
    const {replace: historyReplace} = useHistory();
    const {loginWithToken, isPending, error} = useActiveDirectorySignIn();

    const {path} = props;

    useEffect(() => {

        console.log("acquireTokenSilent");

        // Get Active Directory token 
        instance.acquireTokenSilent({...loginRequest, account: accounts[0]}).then((result) => {
            
            console.log("Log into app with Active Directory token");

            loginWithToken(result.accessToken)
                .then(() => historyReplace(path))
                .catch((error) => {
                    console.error(error)
                    alert(error?.Message ?? error?.message ?? error?.type ?? "Failed to connect to service")
                })
            
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    return <></>
}