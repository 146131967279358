import { useCallback, useMemo, useState } from 'react';

import {
  Box, Chip, FormControl, FormControlProps, MenuItem, InputLabel, Select, Stack, Paper, SelectChangeEvent, OutlinedInput, CircularProgress
} from '@mui/material';



export function MultipleChipsSelect<TModel extends { color?: string }>(props: Omit<FormControlProps, 'children' | 'onChange'> & {
    value: TModel[],
    name: string,
    label?: string,
    getOptionLabel: (option: TModel) => string,
    onChange: (value: TModel[]) => void,
    options: TModel[],
    isEqual: (a: TModel, b: TModel) => boolean,
    onOpen?: () => void,
    loading: boolean
}) {

    const { value, isEqual, onChange, options, getOptionLabel, label, name, loading, onOpen, ...otherProps } = props;

    const _options = useMemo(() => (options && value) ? options.filter(opt => !value.find(x => isEqual(x, opt))) : [], [options, value, isEqual]);

    console.log("CHIPS SELECT", props, "options", _options);

    const handleChange = useCallback((event: SelectChangeEvent<TModel[]>, child: React.ReactNode) => {
        if (onChange) {
            const { target: { value: selectValue } } = event;
            let newValue: TModel[];
            if (typeof selectValue === 'string') {
                const inputValueStrings = selectValue.split(',');
                newValue = options.filter(opt => inputValueStrings.includes(getOptionLabel(opt)));
            } else {
                newValue = selectValue;
            }
            onChange(newValue);
        }
    }, [onChange, getOptionLabel, options]);

    const handleDelete = useCallback((val: TModel) => {
        const newValue = value.filter(item => !isEqual(item, val));
        console.log("UPDATED CHIPS", newValue);
        onChange(newValue);
    }, [value, onChange, isEqual]);

    return <FormControl {...otherProps}>
        <InputLabel id={props.name + '-label'}>{props.label}</InputLabel>
        <Select
            //labelId={props.name + '-label'}
            id={props.name + '-multiple-chips-select'}
            name={props.name}
            multiple
            value={value}
            onChange={handleChange}
            input={<OutlinedInput id={name + '-input'} label="Chip" size='medium'/>}
            onOpen={onOpen}
            // TODO
            // sx={{
            //     '&.MuiOutlinedInput-input': {
            //         py: '14px'
            //     }
            // }}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((val, index) => (
                        <Chip key={index.toString()} 
                            size='medium' 
                            label={getOptionLabel(val)} 
                            sx={{ '&.MuiChip-root': { backgroundColor: val.color } }} 
                            onMouseDown={e => { 
                                e.stopPropagation(); // otherwise <Select>'s onOpen will be executed
                            }}
                            onDelete={() => handleDelete(val)}
                            onClick={(evt) => console.log("CLICK", evt)}
                        />
                    ))}
                </Box>
            )}
        >
            {_options.map((opt, index) => (
                <MenuItem
                    key={index.toString()}
                    value={opt as any}
                    style={{ background: opt.color }}
                >
                    {getOptionLabel(opt)}
                </MenuItem>
            ))}
        </Select>
        {loading && <CircularProgress />}
    </FormControl>
}