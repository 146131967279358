import {Controller, UseFormReturn} from "react-hook-form";
import {SwFaqFilters} from "./swFaq.models";
import {useTranslation} from "react-i18next";
import {Stack, Typography} from "@mui/material";
import {SoftwareAutoSelect} from "Entities/Software/SoftwareAutoSelect";
import {DescribedCodeAutocompleteRemote} from "Entities/DescribedCode/DescribedCodeAutocomplete";
import {HookedCheckbox} from "Components/HookedForm/controls/Checkbox";
import {ModuleAutocompleteRemote} from "Entities/SoftwareParts/ModuleAutocompletes";
import {FormAutocompleteRemote} from "Entities/SoftwareParts/FormsAutocompletes";
import {SimpleEntityAutocompleteRemote} from "Entities/SimpleEntity/SimpleEntityAutocomplete";
import {HookedTextField} from "Components/HookedForm/controls/TextField";


type SwFaqFilterFormProps = UseFormReturn<SwFaqFilters>;

export function SwFaqFiltersForm(props: SwFaqFilterFormProps) {

    const {control, watch} = props;

    const [t] = useTranslation();

    const softwareCode = watch('software.code');

    return (
        <Stack>
            <Controller
                control={control}
                name={'software'}
                render={({field, fieldState, formState}) => (
                    <SoftwareAutoSelect value={field.value ?? null} onChange={field.onChange} sx={{minWidth: 'auto'}}/>
                )}
            />

            <HookedTextField control={control} name={"faqsFilter.versionFrom"} label={t('releaseFrom')} />
            <HookedTextField control={control} name={"faqsFilter.versionTo"} label={t('releaseTo')} />

            <Controller control={control} name={"faqsFilter.status"}
                        render={({field, fieldState, formState,}) => {
                            return <DescribedCodeAutocompleteRemote name={"status"}
                                                                    label={t('status')}
                                                                    endpoint='baseData/ReleaseStatuses'
                                                                    multiple
                                                                    value={field.value}
                                                                    onChange={(evt, value) => field.onChange(value)}
                                                                    onBlur={field.onBlur}/>
                        }}/>


            {softwareCode === 'ERGOBUILD' &&
                <Typography><HookedCheckbox control={control} name='faqsFilter.ergoOne'/>ErgoONE</Typography>}

            <Controller control={control} name={'faqsFilter.module'}
                        render={({field}) => {
                            return (
                                <ModuleAutocompleteRemote name={'module'}
                                                          multiple
                                                          softwareCode={softwareCode}
                                                          value={field.value}
                                                          onChange={(evt, value) => {
                                                              field.onChange(value);
                                                          }}
                                                          onBlur={field.onBlur}/>
                            );
                        }}
            />

            <Controller control={control} name='faqsFilter.form'
                        render={({field}) => (
                            <FormAutocompleteRemote name={"form"}
                                                    multiple
                                                    softwareCode={softwareCode}
                                                    value={field.value}
                                                    onChange={(evt, value) => {
                                                        field.onChange(value);
                                                    }}
                                                    onBlur={field.onBlur}/>
                        )}
            />

            <Controller control={control} name={"faqsFilter.macroarea"}
                        render={({field, fieldState, formState,}) => {
                            return <SimpleEntityAutocompleteRemote name={"macroarea"}
                                                                   label={t('macroarea')}
                                                                   endpoint={`baseData/macroareas?softwareCode=${softwareCode}`}
                                                                   multiple
                                                                   value={field.value}
                                                                   onChange={(evt, value) => field.onChange(value)}
                                                                   onBlur={field.onBlur}/>
                        }}/>

            <Controller control={control} name={"faqsFilter.department"}
                        render={({field, fieldState, formState,}) => {
                            return <DescribedCodeAutocompleteRemote name={"department"}
                                                                    label={t('department')}
                                                                    endpoint='baseData/departments'
                                                                    multiple
                                                                    value={field.value}
                                                                    onChange={(evt, value) => field.onChange(value)}
                                                                    onBlur={field.onBlur}/>
                        }}/>

            <HookedTextField control={control} name='faqsFilter.content' label={t('content')} />
            
        </Stack>
    );
}