import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './Language/i18n';
//import './Themes/styleOverrides.css';
import App from './App';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'authConfig';
import GlobalStyles from './ThemeMinimal/globalStyles';
import { MsalProvider } from '@azure/msal-react';

const hist = createBrowserHistory();

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
      <CssBaseline />
      <GlobalStyles />    
      <Router history={hist}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Router>
    </StyledEngineProvider>,
  document.querySelector('#root'),
);

