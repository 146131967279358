
import { InputAdornment, Stack, Typography } from '@mui/material';
import { CollectionLayout } from 'Components/Collection/CollectionLayout';
import { StyledToolbar } from 'Components/Containers/StyledToolbar';
import { useGuardedExec } from 'Components/GuardService';
import Iconify from 'Components/iconify';
import { StyledSearch } from 'Components/Input/StyledSearch';
import { useMacroareas } from './macroarea.service';
import { SoftwareAutoSelect } from 'Entities/Software/SoftwareAutoSelect';
import { ChangeEvent, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddBar } from 'Components/RemoteEntities/Collection/CollectionUtils';
import { DraggableMacroareas } from './DraggableMacroareas';
import { MacroareaContainer } from './MacroareaContainer';
import {itemsReducer, ItemState} from "./macroareasManagerState";

//const EntityEditor = makeDescribedCodeEditor('baseData/macroareas', 'MacroareaEditor');


export function MacroareasManager(props: {
}) {

   const [search, setSearch] = useState<undefined | string>(undefined);
   const [softwareCode, setSoftwareCode] = useState<string | undefined>(undefined);

   //* Collection state management

   const [state, dispatch] = useReducer(itemsReducer, []);

   //* Init collection 

   const [collection, collectionApi] = useMacroareas({ softwareCode, search });

   const { isSuccess: collectionLoaded, entities } = collection;

   useEffect(() => dispatch(['INIT', entities]), [entities]);


   //* Filter by search string */

   // Use guardedExec because we don't want to reset displayed entities if they have unsaved changes

   const guardedExec = useGuardedExec();

   const handleSearchChanged = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
      guardedExec(() => setSearch(evt.target.value));
   }, [guardedExec]);


   //* Add new item

   const handleNewItemClick = useCallback(() => { dispatch(['OPEN', {id: 0, forDelete: false}]) }, []);

   const [t] = useTranslation();

   console.log("FILTERS", search, softwareCode);


   //* Change order
   const handleOrderChanged = useCallback((areaIds: number[]) => {

      dispatch(['REORDER', areaIds])

   }, [dispatch]);

   console.log("state", state);
   
   return <>
      <CollectionLayout
         id={'baseData/macroareas'}
         title={t('Menu.baseData.macroareas')}
         hasNewButton={false}
         isLoading={collection.isLoading}
      >
         {{
            controls:
               <StyledToolbar>
                  <Stack direction='row' justifyContent={'space-between'} sx={{ width: 1 }}>
                     <SoftwareAutoSelect value={softwareCode ?? null} onChange={(val) => setSoftwareCode(val?.code)} />
                     <StyledSearch
                        value={search ?? ''}
                        onChange={handleSearchChanged}
                        placeholder="Search..."
                        startAdornment={
                           <InputAdornment position="start">
                              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                           </InputAdornment>
                        }
                     />
                  </Stack>
               </StyledToolbar>,

            content: <>
               {softwareCode && (<>
                  <Stack direction={'row'} sx={{width: .90, mx: 2, my: 1}}>
                     <FieldHeader>{t('descriptionDe')}</FieldHeader>
                     <FieldHeader>{t('descriptionIt')}</FieldHeader>
                     <FieldHeader>{t('descriptionEn')}</FieldHeader>
                  </Stack>
                  <DraggableMacroareas software={softwareCode}
                     disableDrag={false}
                     onOrderChanged={handleOrderChanged}
                  >
                     {state.map((item) => ({
                        element: <MacroareaContainer
                           key={item.id}
                           state={item}
                           dispatch={dispatch}
                           softwareCode={softwareCode} />,
                        id: item.id
                     }))}
                  </DraggableMacroareas>
                  <AddBar onAddButtonClick={handleNewItemClick} />
               </>
               )}
            </>
         }}
      </CollectionLayout >
   </>
}


const FieldHeader = (props: { children: string }) => {
   return <Typography flexGrow={1} flexBasis={'30%'} textAlign='center'>{props.children}</Typography>
}



