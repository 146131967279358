
import { HookedChipsSelect } from 'Components/HookedForm/controls/ChipSelect';
import { Control, FieldValues, Path } from 'react-hook-form';
import { Label, useLabels } from './label.services';
import { useTranslation } from 'react-i18next';
import { Status } from 'Components/utils';
import { isEqual, useOptionLabelGetter } from '../DescribedCode/describedCode.models';

export function LabelSelect<
    TFieldValues extends FieldValues,
    TPath extends Path<TFieldValues>
>(props: {
    path: TPath, 
    control: Control<TFieldValues>, 
    getValues: (path: TPath) => Label[]
}) {

    const [state, fetch] = useLabels();

    const { control, getValues, path } = props;
    
    const [t] = useTranslation();

    return (
        <HookedChipsSelect<TFieldValues, TPath, Label> control={control}
            name={path} 
            label={t('label')}
            options={(state.status === Status.Success ? state.data : getValues(path)) as any}
            getOptionLabel={(option: Label) => option.descriptionIt}
            isEqual={isEqual}
            loading={state.status === Status.Pending}
            onOpen={() => { if (state.status === Status.Idle) fetch({}) }}
            sx={{ minWidth: 300 }}
        />
    )
}

import { MultipleChipsSelect } from 'Components/Input/MultipleChipsSelect';
import { useEffect } from 'react';

const labelFromCode = (code: string): Label => ({code: code, descriptionDe: code, descriptionEn: code, descriptionIt: code, color: '#AAAAAA'})

export function LabelCodeSelect<
    TFieldValues extends FieldValues,
    TPath extends Path<TFieldValues>
>(props: {
    value: string[],
    onChange: (value: string[]) => void,
    onBlur: () => void
}) {

    const [state, fetch] = useLabels();

    useEffect(() => { fetch({}) }, [fetch]);

    const handleChange = (value: Label[]) => props.onChange(value.map(x => x.code))

    const options = state.status === Status.Success ? state.data : (props.value?.map(x => labelFromCode(x))) ?? [];
    
    const [t] = useTranslation();

    const getOptionLabel = useOptionLabelGetter();

    return (
        <MultipleChipsSelect<Label>
            label={t('label')}
            name={'labels'} 
            options={options}
            getOptionLabel={getOptionLabel}
            isEqual={isEqual}
            loading={state.status === Status.Pending}
            sx={{ minWidth: 300 }} 
            value={((props.value?.map(x => options.find(opt => opt.code === x)).filter(q => !!q)) ?? []) as Label[]} 
            onChange={handleChange}
            onBlur={props.onBlur}/>
    )
}