import {useEffect} from "react";
import {Status} from "./reduxLogger";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faWrench} from "@fortawesome/pro-regular-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export const DownloadPdf = (props: {
    onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    blob: Blob | null,
    status: Status,
    filename: string,
    techIcon?: boolean,
}) => {

    const {filename, blob, status} = props;

    useEffect(() => {
        if (status === Status.Success && blob) {
            const pdfUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = filename + '.pdf';
            link.click();
        }
    }, [blob, status, filename]);

    return <Box sx={{position: 'relative', display: 'inline'}}>

        <IconButton disabled={status === Status.Pending} onClick={props.onClick}>
            <Box sx={{position: 'relative'}}>
                {<FontAwesomeIcon icon={faFilePdf as IconProp}/>}
                {props.techIcon && <Box sx={{position: 'absolute', fontSize: '80%', top: 13, left: 10}}><FontAwesomeIcon
                    icon={faWrench as IconProp}/></Box>}
            </Box>
        </IconButton>

        {status === Status.Pending && (
            <CircularProgress size={40}
                              sx={{position: 'absolute', top: -9, left: "-1px"}}
                              onClick={(evt) => {
                                  evt.stopPropagation();
                                  evt.preventDefault()
                              }}
            />)}
    </Box>;
}