import css from '!!css-loader?{"sourceMap":false}!Components/HtmlContent/content.css';
const styles = css[0][1];

export const ensureHtmlTags = (text?: string) => {
    if (!text || text.trim().length === 0 || text==="<p></p>") return undefined;
    if (text.startsWith("<!doctype html>") || (text.startsWith("<html>")))
        return text;
    else
        return `<!doctype html><html><head><style>${styles}</style></head><body class="HtmlContent">${text}</body></html>`
}


export const getHtmlBodyAndStyle = (text?: string) => {
    if (!text) return { body: text };
    if (text.startsWith("<!doctype html>") || (text.startsWith("<html>"))) {
        
        const bodyTagStartIndex = text.search(new RegExp('<body(\\s)*(\\w|\\=|-|")*>'));
        const bodyStartIndex = 1 + text.indexOf(">", bodyTagStartIndex);
        const bodyEndIndex = text.indexOf("</body>");
        const body = text.substring(bodyStartIndex, bodyEndIndex);
        
        const styleStartIndex = text.indexOf("<style>");
        const styleEndIndex = text.indexOf("</style>");
        const style = text.substring(styleStartIndex, styleEndIndex + 8);

        //console.log("Body:", bodyTagStartIndex, bodyStartIndex, bodyEndIndex);
        //console.log("Style:", style);
                  
        return {body, style};
    }
    else {
        return {body: text};
    }
}