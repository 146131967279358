import {ContentLayout} from 'Components/Containers/ContentLayout';
import {FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {DefaultValues, EmptyObject, FieldValues} from 'react-hook-form';

import {Box, IconButton} from '@mui/material';

import {EntityForm, EntityFormRenderProps} from '../../HookedForm/EntityForm';
import {EntityDeleteDialog} from './EntityDeleteDialog';
import {EntityEditorApiProps, useEntityEditorApi} from './useEntityEditorApi';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import {RemoteEntityResultHandler} from './RemoteEntityResultHandler';
import {Status} from 'Components/reduxLogger';


/**
 * REMOTE ENTITY EDITOR
 * Fetches or creates entity instance.
 * Renders custom inputs in EntityForm and, optionally, buttons to enable/disable the form.
 * Handles enable/disable/save/delete/cancel(go-back) actions.
 *
 * @param props
 * @returns
 */
export function EntityEditor<
    TModel extends FieldValues,
    TModelVerbose extends FieldValues = TModel,
    TContext = EmptyObject
>(
    props: Omit<EntityEditorApiProps<TModelVerbose, TModel, TContext>, 'isEditable'> & {

        defaultValues?: DefaultValues<TModelVerbose>, // will be used to initialize the form when no initial entity is available
        title: string, // header
        canToggleEdit?: boolean, // default: false
        initEditable?: boolean, // default: true
        context: TContext,
        onRead?: (entity: TModelVerbose) => void,
        onSaved?: (entity: TModelVerbose) => void,
        onDeleted?: () => void,
        onReadError?: (error: any) => void,
        onWriteError?: (error: any) => void,
        onExit: () => void,
        children: FunctionComponent<EntityFormRenderProps<TModelVerbose, TContext>>, // component that renders input fields for entity props
    }
): ReactElement {

    const {canToggleEdit, initEditable} = props;

    const [editable, setEditable] = useState(initEditable !== false);

    const handleEnterEditMode = useCallback(() => setEditable(val => !val), []);
    const handleExitEditMode = useCallback(() => {
        setEditable(false);
    }, []);

    const canDelete = props.isDeletable !== false && (editable || canToggleEdit);

    const editorApi = useEntityEditorApi({...props, isEditable: editable, isDeletable: canDelete});
    const {entityState} = editorApi;

    const buttonsContainer = useRef<HTMLElement | undefined>(undefined);

    const {readStatus, readError} = entityState;
    const {onReadError} = props;
    useEffect(() => {
        if (readStatus === Status.Failed && onReadError) onReadError(readError);
    }, [readStatus, readError, onReadError]);

    const {onRead} = props;
    const {data} = entityState;
    useEffect(() => {
        if (readStatus === Status.Success && onRead && data) {
            onRead(data);
        }
    }, [data, readStatus, onRead]);

    return <>
        {(entityState.readStatus === Status.Idle || entityState.readStatus === Status.Failed) && null

            || (entityState.readStatus === Status.Pending && <ProgressBackdrop open/>)

            || <ContentLayout rootId={editorApi.editorId}
                              title={props.title}
                              isLoading={entityState.writeStatus === Status.Pending}
                              onBackClicked={props.onExit}
                              customHeader={(
                                  <>
                                      <Box ref={buttonsContainer}/>

                                      {canToggleEdit && !editable &&
                                          <IconButton onClick={handleEnterEditMode}><EditIcon/></IconButton>}

                                      {editorApi.doDelete && <IconButton disabled={!canDelete}
                                                                         onClick={editorApi.doDelete}><DeleteIcon/></IconButton>}

                                  </>)}
            >
                <EntityForm<TModelVerbose, TContext>
                    rootId={props.editorId}
                    defaultValues={entityState.data as DefaultValues<TModelVerbose> ?? props.defaultValues}
                    context={props.context}
                    onSave={editorApi.doSave}
                    onDelete={editorApi.doDelete}
                    onCancel={canToggleEdit ? handleExitEditMode : undefined}
                    disabled={!editable}
                    buttonsContainer={buttonsContainer}
                >
                    {props.children}
                </EntityForm>
                <EntityDeleteDialog
                    isConfirmationPending={editorApi.isDeleteConfirmPending}
                    onAborted={editorApi.onDeleteAborted}
                    onConfirmed={editorApi.onDeleteConfirmed}
                />
            </ContentLayout>
        }
        <RemoteEntityResultHandler {...entityState}
                                   onSaved={props.onSaved}
                                   onDeleted={props.onDeleted}
                                   onError={props.onWriteError}/>
    </>

}
