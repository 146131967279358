import { DescribedCode } from "Entities/DescribedCode/describedCode.models";
import { useTranslation } from "react-i18next";

export interface Department extends DescribedCode {
    iosRole: string
}

export const endpoint = 'baseData/Departments';



