
import React from "react";
import { AccessLevels } from "Components/Remote/remote.service";
import { Theme } from "@mui/material";


/**
 * An item in the side menu
 */
export interface MenuItemDefinition {
  name: string,
  renderName?: React.FC,
  Icon?: React.FC<{ theme: Theme }>,
  disabled?: boolean
}

/**
 * Recursive definition of a navigation (sub)menu, i.e. a tree of menu items each of which 
 * may have sub-menus and cause navigation to an app location when clicked.
 */
export interface NavNodeDefinition extends MenuItemDefinition {
  path?: string,
  pathParams?: Record<string, string>,
  isExactPath?: boolean,  // translates to <Route isExact>
  component?: React.ComponentType<any>, 
    // Component that will be rendered in the app's <main> when the menu is selected. 
    // If undefined, the node has no content: clicking on it will expand its submenu but no content will be rendered in <main>
  children?: (NavNodeDefinition | CommandDefinition)[],
  priority?: number /* used to sort Routes in router Switch 
    so that we can have, for example, a menu BudgetForecast with items
      Budget
      Approve
      Surplus
    that is sorted differently in the router's Switch, which can be crucial for correct path matching:
      <Route path="budget.forecast.approve">
      <Route path="budget.forecast.surplus">
      <Route path="budget.forecast">   // not exact path, we want URLs 'budget.forecast.revenues' and 'budget.forecast.losses' to match this 
  */
}

/**
 * A menu item that does not navigate anywhere and does not have submenus. Clicking on it will execute a command
 */
export interface CommandDefinition extends MenuItemDefinition {
  command: () => void
}

export const isCommand = (item: MenuItemDefinition): item is CommandDefinition => {
  return (item as CommandDefinition).command != undefined;
}

/**
 * A node in a walkable navigation tree. Like NavNodeDefinition, but with extra props:
 * - full name in the form "menuA.submenuB.subSubMenuC[...]" that uniquely identifies the node's position in the tree
 * - absolute URL path 
 * - reference to parent node. 
 */
export class NavNode implements NavNodeDefinition {
  public parent?: NavNode;
  public name: string;
  public renderName?: React.FC;
  public relativePath?: string;
  public pathParams?: Record<string, string>;
  public isExactPath?: boolean;
  public Icon?: any;
  public component?: React.ComponentType<any>;
  public disabled?: boolean;
  public children: NavNode[] = [];
  public priority = 0;
  public command?: () => void;

  public constructor(nodeDef: NavNodeDefinition | CommandDefinition, parent?: NavNode) {
    this.name = nodeDef.name;
    this.renderName = nodeDef.renderName;
    this.parent = parent;
    this.Icon = nodeDef.Icon;
    this.disabled = !!parent?.disabled || nodeDef.disabled;
    
    if (isCommand(nodeDef)) {

      this.command = nodeDef.command;

    } else {

      this.relativePath = nodeDef.path;
      this.pathParams = nodeDef.pathParams;
      this.isExactPath = !!nodeDef.isExactPath
      this.priority = nodeDef.priority || 0;
      this.component = nodeDef.component;
      this.children = nodeDef.children?.map(child => new NavNode(child, this)) ?? [];
    }
  }

  public get fullName(): string {
    return this.parent
      ? `${this.parent.fullName}.${this.name}`
      : 'Menu';
  }

  public get absolutePath(): string {
    let parentRoute = this.parent?.absolutePath || '';
    if (parentRoute === '')
      parentRoute += '/';
    if (parentRoute != '/' && this.relativePath !== undefined && this.relativePath.length > 0)
      parentRoute = parentRoute + '/';
    return parentRoute + (this.relativePath || '');
  }

  public get absolutePathWithParamValues(): string {
    let path = this.absolutePath;
    if (this.pathParams !== undefined) {
      const pathParams: Record<string, string> = this.pathParams;
      Object.keys(pathParams).forEach((key: string) => {
        path = path.replace(':' + key, pathParams[key] ?? '')
      })
    }
    return path;
  }

  public isAncestorOf(nodeName: string): boolean {
    const myName = this.fullName;
    return nodeName.startsWith(myName) && (nodeName.length === myName.length || nodeName[myName.length] === '.');
  }
}

/**
 * Flattens a tree of navigation nodes into an array of nodes, including only the nodes that
 * have a component to render and are not disabled. For use in NavOutlet, to define <Route>s in ReactRouter's <Switch>.
 * @param node 
 * @returns array of NavNodes.
 */
export function flattenAndSortMenuNodesForRouter(node: NavNode | CommandDefinition): NavNode[] {
  let result: NavNode[] = [];
  if (isCommand(node)) {
    return [];
  }
  if (node.component && !node.disabled) { 
    result = [node];
  }
  if (node.children) {
    const childNodes = node.children?.flatMap(child => flattenAndSortMenuNodesForRouter(child).sort((m, k) => k.priority - m.priority));
    if (childNodes)
      result = [...result, ...childNodes];
  }
  return result;
}
