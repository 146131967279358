import { Box } from "@mui/material";

export  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    activeIndex: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, activeIndex, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={activeIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {activeIndex === index && (
                // <Box sx={{ p: 0 }}>
                //     {children}
                // </Box>
                children
            )}
        </div>
    );
}

export function tabA11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}