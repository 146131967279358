import { Status } from 'Components/utils';

export type State<TModel, TDefault extends TModel | null = TModel> = {
  data: TModel;
  status: Status.Success;
  error: undefined,
} | {
  data: TDefault;
  status: Status.Pending | Status.Failed | Status.Idle;
  error: any
}

export function makeInitialState<TModel, TDefault extends TModel | null = TModel>(defaultValue: TDefault): State<TModel, TDefault> {
  return {
    status: Status.Idle,
    error: undefined,
    data: defaultValue,
  };
}

export type Actions<TModel> =
  | ['CLEAR']
  | ['REQUEST']
  | ['SUCCESS', TModel]
  | ['FAILED', any]

type Reducer<TModel, TDefault extends TModel | null = null> = (state: State<TModel, TDefault>, action: Actions<TModel>) => State<TModel, TDefault>;

export function makeResourceReducer<TModel, TDefault extends TModel | null = TModel>(entityName: string, defaultValue: TDefault): Reducer<TModel, TDefault> {

  if (entityName === '') {
    throw 'Resource name needed to create reducer';
  }
  const reducerName = `[RemoteResource]${entityName}`;
  const tempObj = {
    [reducerName]: (state: State<TModel, TDefault> = makeInitialState<TModel, TDefault>(defaultValue), action: Actions<TModel>): State<TModel, TDefault> => {
      switch (action[0]) {
        case 'CLEAR':
          return {
            status: Status.Idle,
            error: undefined,
            data: defaultValue,
          };
        case 'REQUEST':
          return {
            data: defaultValue,
            status: Status.Pending,
            error: undefined,
          };
        case 'SUCCESS':
          return {
            data: action[1],
            status: Status.Success,
            error: undefined,
          };
        case 'FAILED':
          return {
            data: defaultValue,
            status: Status.Failed,
            error: action[1],
          };
        default:
          return state;
      }
    }
  }
  const reducer: Reducer<TModel, TDefault> = tempObj[reducerName];
  return reducer;
  //return process.env.NODE_ENV === 'development' ? withLogger<TModel, TDefault>(reducer) : reducer;
}

function withLogger<TModel, TDefault extends TModel | null = TModel>(reducer: Reducer<TModel, TDefault>): Reducer<TModel, TDefault> {
  return function (state: State<TModel, TDefault>, action: Actions<TModel>): State<TModel, TDefault> {
    const newState = reducer(state, action);
    console.log(`${reducer.name} ${action[0]} \npayload`, action.length > 0 ? action[1] : '', '\nold state', state, '\nnew state', newState);
    return newState;
  }
}



