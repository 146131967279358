import Copyright from 'Components/Copyright';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { NavOutlet } from './Navigation/NavOutlet';
import { NavigationProvider, NavigatorDefinition, useOpenMenu } from './Navigation/NavProvider';
import { HeadBar } from './HeadBar';
import { Drawer } from './Drawer';


interface DashboardProps {
  navMenu: NavigatorDefinition,
  TopMenu: React.ReactNode,
  contentSx?: SxProps<Theme>,
  drawerSx?:SxProps<Theme>,
  brandImage: any,
  brandSx?: SxProps<Theme>,
  headerSx?: SxProps<Theme>
}
export default function Dashboard(props: DashboardProps) {

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  // noSsr = No Server Side Rendering
  // If SSR is turned on, useMediaQuery will always return false on first invocation

  const [drawerIsOpen, setDrawerOpen] = useState(isLargeScreen);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerIsOpen);
  }

  const history = useHistory();

  const setOpenMenu = useOpenMenu()[1];

  return (
    <Box id='dashboard-root' height='100vh'>
      <Box id='dashboard-content' sx={{
        width: '100%',
        minHeight: 'calc(100% - 46px)', overflowY: 'visible',   // (viewport - footer) height
        display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch',
      }}>
        <NavigationProvider {...props.navMenu}>

          <Drawer id="dashboard-side"
            sx={props.drawerSx}
            brand={props.brandImage}
            brandSx={props.brandSx}
            onBrandClicked={() => {
              setOpenMenu(null);
              history.push('/');
            }}
            open={drawerIsOpen}
            toggleDrawer={toggleDrawer}
            // ModalProps={{
            //   keepMounted: true, // Better open performance on mobile.
            // }}
          />

          <Box id='dashboard-main'
            sx={{
              flexGrow: 1, flexShrink: 1,
              //height: '100%',
              minWidth: 0, // needed, otherwise it's 'auto' => large contents will force the flex container to grow, 
              // see https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              //background: theme.palette.grey[100]
            }}
          >

            <HeadBar id='dashboard-top' position='static' isDrawerOpen={drawerIsOpen} toggleDrawer={toggleDrawer} sx={props.headerSx}>
              {props.TopMenu}
            </HeadBar>

            <Container component="main" id='nav-outlet' maxWidth="lg" sx={{ flexShrink: 0, flexGrow: 1, pb: 1, ...props.contentSx }}>
              <NavOutlet />
            </Container>

          </Box>

        </NavigationProvider>
      </Box >
      <Box id='dashboard-footer' display='flex' justifyContent='center' pb={1} pt={2}>
        <Copyright />
      </Box>
    </Box>
  );
}

