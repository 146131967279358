import { useState } from 'react';
import { Button, Typography, ButtonProps } from '@mui/material';

import { lngNames } from './i18n';
import i18next from 'i18next';

export default function LanguageSwitch(props: ButtonProps) {

  const lngKeys = Object.keys(lngNames);
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);

  const changeLanguage = () => {
    i18next
      .changeLanguage(lngKeys[nextLanguageIndex], (err) => {
        if (err) return console.log('something went wrong loading language' + lngKeys[nextLanguageIndex], err);
        else {
          setCurrentLanguageIndex(nextLanguageIndex);
        }
      })
  }

  let nextLanguageIndex = currentLanguageIndex + 1;
  if (nextLanguageIndex >= lngKeys.length)
    nextLanguageIndex = 0;

  return (
    <Typography>
      <Button {...props}
        sx={{ mx: 3, px: 1, minWidth: 0, }}
        onClick={changeLanguage}
      >
        {lngNames[lngKeys[currentLanguageIndex]].short}
      </Button>
    </Typography>
  )
}