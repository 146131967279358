import { TablePagination } from "@mui/material";
import React from "react";
import { makePageSizeOptions } from "Components/utils";

export interface PaginatedList<TItem> {
  pageContent: TItem[],
  page: number,
  pageSize: number,
  totPages: number,
  totItems: number,
  setCollection: (items: TItem[]) => void,
  setPageSize: (size: number) => void,
  setPage: (page: number) => void
}

export function usePaginated<TItem>(): PaginatedList<TItem> {

  const [allItems, setAllItems] = React.useState<TItem[]>([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pageContent, setPageContent] = React.useState<TItem[]>([]);

  React.useEffect(() => {
    const pageStartIndex = page * pageSize;
    const pageEndIndex = Math.min(allItems.length, pageStartIndex + pageSize);
    setPageContent(allItems.slice(pageStartIndex, pageEndIndex));
  }, [allItems, pageSize, page])

  return {
    page,
    pageSize,
    pageContent,
    totItems: allItems.length,
    totPages: Math.floor(allItems.length / pageSize),

    setCollection: React.useCallback((items: TItem[]) => {
      setAllItems(items);
      setPage(0);
    }, []),

    setPageSize: React.useCallback((newSize: number) => {
      if (newSize > 0) {
        setPageSize(newSize);
        setPage(0);
      }
    }, []),

    setPage: React.useCallback((newPage: number) => {
      if (newPage >= 0 && newPage <= Math.floor(allItems.length / pageSize)) {
        setPage(newPage);
      }
    }, [allItems, pageSize])
  }
}


export function PaginationControls<TItem>(props: { paginatedList: PaginatedList<TItem> }) {
  const {paginatedList} = props;
  return <TablePagination
    component="div"
    showFirstButton showLastButton
    count={paginatedList.totItems}
    page={paginatedList.page}
    // @ts-ignore: noUnusedParameters
    onPageChange={(evt, page) => paginatedList.setPage(page)}
    rowsPerPage={paginatedList.pageSize}
    onRowsPerPageChange={evt => paginatedList.setPageSize(parseInt(evt.target.value, 10))}
    rowsPerPageOptions={makePageSizeOptions(paginatedList.pageSize)}
  />
}