export const InfomindsColors = {
  text: '#35312E',
  textDetail: '#6D716E',

  main: '#26d07c',

  teal: '#3AB398',
  tealDarker: '#40AC98',
  green: '#138B43',
  greenDarker: '#158C46',
  blue: '#1C8EC1',
  blueDarker: '#238BC0',
  yellow: '#EEB140',
  yellowDarker: '#F2B540',
  red: '#C5242B',
  redDarker: '#C3252D',
  purple: '#8E1957',
  purpleDarker: '#8C255D',
  grey: '#727474',
  greyDarker: '#6D716E',
  dark: '#35312E',
  darker: '#1C1D1F'
}