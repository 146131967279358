import { useRef } from 'react';


// Like useMemo, but this is guaranteed to never rerun.
// https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
    
export function useConst<T>(init: () => T) {
    const ref = useRef<T | null>(null);
    if (ref.current === null) {
        ref.current = init();
    }
    return ref.current;
}
