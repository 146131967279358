
import { useMemo } from 'react';

import { Box, Stack, styled, Typography, useTheme } from '@mui/material';

import NewsExplorer from './NewsExplorer';
import { makeBackgroundColor } from './style';


// const StyledContainer = styled(Container)(({ theme }) => ({
//     height: '100%',
//     //overflowY: 'clip',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-start',
//     backgroundColor: makeBackgroundColor(theme),
//     '& .MuiContainer-root': {
//         maxWidth: 'auto'
//     }
// }));

const StyledContainer = styled('div')(({ theme }) => ({
    height: '100%',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: makeBackgroundColor(theme),
}));

import { CustomerPage, getBrandImage } from '../CustomerPage';
import { useTranslation } from 'react-i18next';

export default function NewsfeedPage() {
    return  <CustomerPage>{NewsfeedPageContent}</CustomerPage>;
}

const NewsfeedPageContent = (props: { showHeader: boolean }) => {

    const theme = useTheme();
    const brand = useMemo(() => getBrandImage(theme), [theme]);
    const [t] = useTranslation();
    
    return (
        <StyledContainer id={'NewsFeed-root'}>

            {props.showHeader && (
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
                    <img src={brand} style={{ width: 200, height: 100 }} />
                    <Box id={'ReleaseNotes-title'} sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='h2' color={theme.palette.grey[500]}>{t('appTitle')}</Typography>
                    </Box>
                </Stack>
            )}

            <Box sx={{ flexShrink: 1, flexGrow: 1, overflow: 'visible' }}>
                <NewsExplorer />
            </Box>

        </StyledContainer>
    )
}




