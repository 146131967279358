import { fileToBase64 } from 'Components/fileToBase64';
import { useCallback, useRef, useState } from 'react';

//import { faH1, faH2, faH3, faH4 } from '@fortawesome/pro-regular-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import { Toolbar as MuiToolbar } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Editor } from '@tiptap/react';

import { FontFamilyControl, FontSizeControl } from './FontControls';
import { HighlightControls } from './HighlightControls';
import { IndentControls } from './IndentControls';
import { LinkControl } from './LinkControl';
import { StyledButton } from './StyledInputs';
import { TextAlignControls } from './TextAlignControls';
import { ImageControl } from './ImageControl';

// const StyledMenu = styled(Paper)(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     backgroundColor: theme.palette.grey[700],
//     color: theme.palette.getContrastText(theme.palette.grey[700]),
// }));

const StyledMenu = styled(MuiToolbar)(({ theme }) => ({
   flexWrap: 'wrap',
   backgroundColor: theme.palette.grey[700],
   color: theme.palette.getContrastText(theme.palette.grey[700]),
   '&.MuiToolbar-root': {
      paddingLeft: '4px',
      paddingRight: '4px',
   }
}));


/**
 * 
 * @param props EDITOR TOOLBAR
 * @returns 
 */
export const Toolbar = (props: {
   editor: Editor | null
   sx?: SxProps<Theme>
}) => {

   const { editor } = props;

   if (!editor) {
      return null
   }

   return (
      <StyledMenu sx={props.sx}>
         <StyledButton type="button"
            onClick={(evt) => {
               evt.preventDefault();
               editor.chain().focus().toggleBold().run()
            }}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
         //className={editor.isActive('bold') ? 'isActive' : ''}
         >
            <FormatBoldIcon />
         </StyledButton>

         <StyledButton type="button"
            onClick={(evt) => {
               evt.preventDefault();
               editor.chain().focus().toggleItalic().run()
            }}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
         >
            <FormatItalicIcon />
         </StyledButton>

         <StyledButton type="button"
            onClick={(evt) => {
               evt.preventDefault();
               editor.chain().focus().toggleStrike().run()
            }}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
         >
            <FormatStrikethroughIcon />
         </StyledButton>

         <StyledButton type="button"
            onClick={(evt) => {
               evt.preventDefault();
               editor.chain().focus().toggleCode().run()
            }}
            disabled={!editor.can().chain().focus().toggleCode().run()
            }
            isActive={editor.isActive('code')}
         >
            <CodeIcon />
         </StyledButton>

         <FontFamilyControl editor={editor} />

         <FontSizeControl editor={editor} />

         <TextAlignControls editor={editor} />

         <IndentControls editor={editor} />

         <HighlightControls editor={editor} />

         <StyledButton type="button"
            onClick={(evt) => { evt.preventDefault(); editor.chain().focus().toggleBulletList().run() }}
            isActive={editor.isActive('bulletList')}
         >
            <FormatListBulletedIcon />
         </StyledButton>

         <StyledButton type="button"
            onClick={(evt) => { evt.preventDefault(); editor.chain().focus().toggleOrderedList().run() }}
            isActive={editor.isActive('orderedList')}
         >
            <FormatListNumberedIcon />
         </StyledButton>

         <StyledButton type="button"
            onClick={(evt) => { evt.preventDefault(); editor.chain().focus().unsetAllMarks().run() }}
         >
            <FormatClearIcon />
         </StyledButton>

         <LinkControl editor={editor} />

         <ImageControl editor={editor} />
         
      </StyledMenu>)
}

/*<button type="button"
   onClick={() => editor.chain().focus().clearNodes().run()}>
   clear nodes
</button>

<button type="button"
   onClick={() => editor.chain().focus().setParagraph().run()}
   className={editor.isActive('paragraph') ? 'isActive' : ''}
>
   paragraph
</button>

<IconButton type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
   className={editor.isActive('heading', { level: 1 }) ? 'isActive' : ''}
>
   <FontAwesomeIcon icon={faH1 as IconProp} />
</IconButton>

<IconButton type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
   className={editor.isActive('heading', { level: 2 }) ? 'isActive' : ''}
>
   <FontAwesomeIcon icon={faH2 as IconProp} />
</IconButton>

<IconButton type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
   className={editor.isActive('heading', { level: 3 }) ? 'isActive' : ''}
>
   <FontAwesomeIcon icon={faH3 as IconProp} />
</IconButton>

<IconButton type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
   className={editor.isActive('heading', { level: 4 }) ? 'isActive' : ''}
>
   <FontAwesomeIcon icon={faH4 as IconProp} />
</IconButton> 

<button type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
   className={editor.isActive('heading', { level: 5 }) ? 'isActive' : ''}
>
   h5
</button>

<button type="button"
   onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
   className={editor.isActive('heading', { level: 6 }) ? 'isActive' : ''}
>
   h6
</button> 

<button type="button"
   onClick={() => editor.chain().focus().toggleCodeBlock().run()}
   className={editor.isActive('codeBlock') ? 'isActive' : ''}
>
   code block
</button>

<IconButton type="button"
   onClick={() => editor.chain().focus().toggleBlockquote().run()}
   className={editor.isActive('blockquote') ? 'isActive' : ''}
>
   <FormatQuoteIcon/>
</IconButton>

<button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
   horizontal rule
</button>

<button onClick={() => editor.chain().focus().setHardBreak().run()}>
   hard break
</button>

<button type="button"
   onClick={() => editor.chain().focus().undo().run()}
   disabled={
      !editor.can()
         .chain()
         .focus()
         .undo()
         .run()
   }
>
   undo
</button>

<button type="button"
   onClick={() => editor.chain().focus().redo().run()}
   disabled={
      !editor.can()
         .chain()
         .focus()
         .redo()
         .run()
   }
>
   redo
</button>

<button type="button"
   onClick={() => editor.chain().focus().setColor('#958DF1').run()}
   className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'isActive' : ''}
>
   purple
</button> */