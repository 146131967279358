import {ensureHtmlTags} from 'Components/HtmlContent/htmlAdapter';
import {CollectionState, useRemoteCollection} from 'Components/RemoteEntities/Collection/RemoteCollection';
import {RemoteEntityApi, useRemoteEntity} from 'Components/RemoteEntities/Entity/RemoteEntity';
import {
  useRemoteResourceQueryService,
  useRemoteResourceSubmissionService
} from 'Components/RemoteEntities/resource.services';
import {EmptyObject, Status} from 'Components/utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {minifyNote, Note, NoteAbstract, NotesFilter, NoteVerbose} from './note.models';


//************** NOTES COLLECTION of a given release */
export function useNoteAbstracts(props: {
  releaseId?: number,
  isNewRelease: boolean  
}) {

  const { releaseId, isNewRelease } = props;

  const { i18n: { language } } = useTranslation();

  const requiredQueryParams = React.useMemo(() => {
    if (releaseId && !isNewRelease)
      return { language, excerptLength: 0 };
    else return undefined; // if the release exists but it's just been created, skip fetching because there are no notes yet
  }, [releaseId, isNewRelease, language]);

  const [state, api] = useRemoteCollection<NoteAbstract>({
    endpoint: `releases/${releaseId}/notes`,
    requiresQueryParams: true,
    queryParams: requiredQueryParams,
    isPaginated: false
  });

  return state;
}


//************** FILTERED COLLECTION */

export const notesFilterToAPIQueryParams = (filter: NotesFilter) => ({

  module: filter.module ? filter.module.map(x => x.code) : undefined,
  form: filter.form ? filter.form.map(x => ((x.moduleCode ?? '') + '__' + (x.code ?? ''))) : undefined,
  macroarea: filter.macroarea ? filter.macroarea.map(x => x.id) : undefined,
  department: filter.department ? filter.department.map(x => x.code) : undefined,
  todo: filter.todo,
  content: filter.content,
  ergoOne: filter.ergoOne,
  contentType: filter.contentType
})

export function useFilteredNoteAbstractsFromFullCollection(props: {
  releaseId: number,
  isNewRelease: boolean,
  filter?: NotesFilter,
  fullCollection: CollectionState<NoteAbstract>
}) {

  const { releaseId, isNewRelease, filter } = props;
  const { i18n: { language } } = useTranslation();

  const queryParams = React.useMemo(() => {
    //console.log("filter", filter);
    if (releaseId && !isNewRelease && filter && Object.keys(filter).length > 0) {
      return { 
        language, 
        excerptLength: 0,
        ...notesFilterToAPIQueryParams(filter)
      };
    } else return undefined; // if the release exists but it's just been created, skip fetching because there are no notes yet
  }, [isNewRelease, language, filter, releaseId]);

  //console.log("FILTER", filter);
  
  const [filteredState, api] = useRemoteCollection<NoteAbstract>({
    endpoint: `releases/${releaseId}/notes`,
    requiresQueryParams: true,
    queryParams,
    isPaginated: false,
    autoFetch: false,
  });

  // Fetch filtered collection only after full collection has been fetched ()
  const fullStatus = props.fullCollection.status;
  useEffect(() => {
    if (fullStatus === Status.Success) {
      api.getPage();
    }
  }, [api, fullStatus]);

  return queryParams ? filteredState : props.fullCollection;
}



//************** SORT COLLECTION  */

export function useNotesSwappingService(props: { releaseId: number }) {
  return useRemoteResourceSubmissionService<{idA: number, idB: number, macroareaId: number}, number[]>({ endpoint: `releases/${props.releaseId}/notes/swap`, method: 'POST' });
}


//************** READONLY ENTITY */
export const useNoteReadonly = (props: { releaseId: number, noteId: number }) => {
  const { releaseId, noteId } = props;
  const endpoint = useMemo(() => `Releases/${releaseId}/Notes/${noteId}`, [releaseId, noteId]);
  return useRemoteResourceQueryService<NoteVerbose, null>({ endpoint, defaultValue: null });
}


//************** CRUD ENTITY */

export interface NoteCRUDContext {
  isClone: boolean;
  softwareCode: string;
  releaseId: number;
  newReleaseId?: number;
  isSoftwareChanged: boolean;
}
export function useNoteCRUD(props: NoteCRUDContext): RemoteEntityApi<NoteVerbose, EmptyObject, Note> {

  const { releaseId, newReleaseId, isSoftwareChanged, softwareCode } = props;

  const onBeforeSaving = useCallback((entity: NoteVerbose) => {
    
    entity.translations.forEach((t) => {
      t.html = ensureHtmlTags(t.html);
      t.htmlTech = ensureHtmlTags(t.htmlTech);
    });
    
    if (newReleaseId)
      entity.releaseId = newReleaseId; 
    
    return entity;
    
  }, [newReleaseId]);
  
  const defaultQueryParams = useMemo(() => ({ newSoftwareCode: isSoftwareChanged ? softwareCode : undefined }), [softwareCode, isSoftwareChanged]);

  return useRemoteEntity<NoteVerbose, {newSoftwareCode?: string}, Note>({
    idKey: 'id',
    remoteCreation: false,
    minify: minifyNote,
    endpoint: `Releases/${releaseId}/Notes`,
    copyToEndpoint: newReleaseId ? `Releases/${newReleaseId}/Notes` : undefined,
    beforeWriting: onBeforeSaving,
    defaultQueryParams
  });
}


export const useErgoOneSetterService = (props: {releaseId: number}) => {
  return useRemoteResourceSubmissionService<{id: number, ergoOne: boolean}, boolean>({endpoint: `Releases/${props.releaseId}/Notes/ergoOne`, method: 'POST'});
}




