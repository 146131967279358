import {styled, TableCellProps} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React, {useCallback, useContext} from "react";
import {useTranslation} from "react-i18next";
import {descriptionGetter} from "Entities/DescribedCode/describedCode.models";
import {Link, useHistory} from "react-router-dom";
import {HoverStyledTableRow} from "Components/Containers/ClickabkeRowsTable";
import {prettyDate} from "Entities/commons";
import {ReleasesManagerContext} from "./ReleasesManagerContext";
import {DownloadReleasePdf} from "./DownloadReleasePdf";
import {ReleaseHeadVerbose} from "./release.models";

export const ReleaseListItem = (props: { release: ReleaseHeadVerbose, withPdf?: boolean, onClick?: () => void }) => {

    const {release, onClick} = props;
    const releaseId = release.id;
    const {software} = release;
    const softwareCode = software.code;

    const [{urlBasePath}, dispatch] = useContext(ReleasesManagerContext);

    const href = `${urlBasePath}/${releaseId}`;

    const [, {language}] = useTranslation();
    const getDescription = descriptionGetter(language);

    const {push: historyPush} = useHistory();

    const handleClicked = useCallback(() => {
        if (onClick) onClick();
        dispatch(['SET_ACTIVE_RELEASE', release]);
        historyPush(href);
    }, [release, dispatch, historyPush, href, onClick]);

    return (
        <HoverStyledTableRow key={release.id} id={release.id.toString()} onClick={handleClicked}>
            <ReleaseLinkCell href={href}>{''}</ReleaseLinkCell>
            <ReleaseLinkCell href={href}>{release.releaseVersion}</ReleaseLinkCell>
            <ReleaseLinkCell href={href}>{prettyDate(release.releaseDate)}</ReleaseLinkCell>
            <ReleaseLinkCell href={href}>{getDescription(release.status)}</ReleaseLinkCell>
            <ReleaseLinkCell href={href}>{release.customerId}</ReleaseLinkCell>
            {props.withPdf && (
                <TableCell align='right'>
                    <DownloadReleasePdf releaseId={release.id} language={language}
                                        filename={`${software.description}_${release.releaseVersion}`}/>
                    <DownloadReleasePdf releaseId={release.id} language={language}
                                        filename={`${software.description}_${release.releaseVersion}`} techNotes/>
                </TableCell>
            )}
        </HoverStyledTableRow>
    )
}
const ReleaseLinkCell = (props: TableCellProps & { href: string }) => {
    const {children, href, ...otherProps} = props;
    return <TableCell size='small' {...otherProps}>
        <MyLink to={props.href} style={{display: 'block', height: '100%'}}>
            {children}
        </MyLink>
    </TableCell>
}
const MyLink = styled(Link)(({theme}) => ({
    color: 'inherit',
    textDecoration: 'none'
}))