import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import ProgressBackdrop from '../Feedback/ProgressBackdrop';


/**
 * Container for a scrollable collection view, with sticky headers to display title, 
 * custom controls (typically collection filters/sorters) and pagination controls. 
 * Can optionally display a "New" button with provided callback.
 */
export interface CollectionLayoutProps {
  id: string,
  title?: string,
  children: {
    controls?: React.ReactNode,
    pagination?: React.ReactNode,
    content: React.ReactNode,
  },
  isLoading?: boolean,
  hasNewButton: boolean,
  newButtonTextKey?: string,
  onNewClicked?: () => void,
  sx?: SxProps<Theme>,
  sxInner?: SxProps<Theme>
}
export function CollectionLayout(props: CollectionLayoutProps) {

  const { t } = useTranslation();
  const { id, children, hasNewButton } = props;

  return (
    <Box id={id + '-root'} sx={{
      height: '100%', overflowY: 'clip',
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
      ...props.sx
    }}
    >
      {props.title && (
        <Box id={id + '-title'} sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6' sx={{ /*color: theme.palette.text.secondary,*/ mb: 2 }}>{props.title}</Typography>
        </Box>
      )}

      <Card id={id + '-main'} sx={props.sxInner}>

        {children.controls}

        {(children.pagination || hasNewButton) && (
          <Box id={id + '-controls'} sx={{
            flexShrink: 0,
            m: 0, px: 2, pb: 1,
            display: 'flex', justifyContent: "flex-end", alignItems: "center"
          }}>

            {children.pagination || null}

            {hasNewButton && (
              <Button type="button" variant="contained" name="new" sx={{ ml: 2, my: 1 }}
                onClick={props.onNewClicked}
              >{t(props.newButtonTextKey ?? 'new')}
              </Button>
            )}
          </Box>)}

        <Box id={id + '-list'} sx={{ flexShrink: 1, flexGrow: 1, overflow: 'visible' }}>
          {
            children.content
          }
        </Box>

      </Card>
      <ProgressBackdrop open={!!props.isLoading} />
    </Box>
  )
}