import Dashboard from 'Components/Dashboard/Dashboard';
import { ErrorBoundary } from 'Components/ErrorBoundary';
import { GuardServiceProvider } from 'Components/GuardService';
import { useTranslation } from 'react-i18next';

import { Typography, useTheme } from '@mui/material';

import { useRelnotesDashboardNavigator } from './navigation';
import { useMemo } from 'react';

import { makeAppThemes } from 'Themes/makeAppThemes';
import { ThemesProvider } from 'Themes/ThemesProvider';
import { RemoteConsumer } from 'Components/Remote/RemoteProvider';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { useIOSUpdateService } from './BaseData/iosUpdate.service';
import { Status } from 'Components/reduxLogger';
import { SignInPage } from 'Pages/SignInPage';
import { getBrandImage } from '../brandImage';

import ActiveDirectoryLogout from "Components/Remote/ActiveDirectoryLogout";




const themes = makeAppThemes();

export default function DashboardPage() {   
    const [iosState, updateIos] = useIOSUpdateService();
  
    return (
        <ThemesProvider themes={themes}>
            <RemoteConsumer>{
                ([auth]) => {
                    if (auth.isAuthenticated) {
                        return <>
                            <RelnotesDashboard updateIos={updateIos} iosPending={iosState.status === Status.Pending}/>
                            <ProgressBackdrop open={iosState.status === Status.Pending}/>
                        </>
                    } else if (process.env.REACT_APP_USE_AZURE_LOGIN === 'false') {
                        if (auth.isPending) {
                            return <ProgressBackdrop open />;
                        } else {
                            return <SignInPage />
                        }
                    } else {
                        return <ActiveDirectoryLogout />
                    }
                }}
            </RemoteConsumer>
        </ThemesProvider >
    );
}

const RelnotesDashboard = (props: {updateIos: () => void, iosPending: boolean}) => {

    const {updateIos, iosPending} = props;
    const navigator = useRelnotesDashboardNavigator({updateIos, iosPending});

    const theme = useTheme();
    const brand = useMemo(() => getBrandImage(theme), [theme]);
    const { t } = useTranslation();

    console.log("THEME", theme.palette.mode, theme.palette.background.default);

    const bgColor = theme.palette.background.default;
    
    return <ErrorBoundary name='RelnotesDashboard'>

        <GuardServiceProvider>
            <Dashboard
                navMenu={navigator}
                TopMenu={<Typography fontSize={20} > {t('appTitle')}</Typography >}
                drawerSx={{'& .MuiDrawer-paper': {background: bgColor }}}
                contentSx={{
                    background: bgColor
                }}
                brandImage={brand}
                brandSx={{
                    backgroundColor: bgColor,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: ['100%', '100%'],
                    height: 120,
                    margin: 2
                }}
                headerSx={{
                    backgroundColor: bgColor                
                }}
            />
        </GuardServiceProvider>

    </ErrorBoundary>
}