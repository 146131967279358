import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteProps, AutocompleteRenderInputParams, Box, SxProps, Theme } from "@mui/material";
import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyledTextFieldAC } from "./StyledTextField";
import { Status } from "Components/utils";


export type AutocompleteOnBlurRemote<
    TModel,
    TQuery = Record<string, never>
> = Omit<
    AutocompleteProps<TModel, false, false, false>,
    'value' | 'options' | 'onChange' | 'renderInput' | 'getOptionLabel'
> & {
    name?: string,
    onChange?: (event: any, newValue: TModel | null, reason: AutocompleteChangeReason) => void,
    value?: TModel | null,
    label?: string,
    error?: boolean,
    sx?: SxProps<Theme>,
    endpoint: string,
    query: TQuery,
    isOptionEqualToInput: (option: TModel, textInput: string) => boolean,
    isOptionEqualToValue: (option: TModel, value: TModel) => boolean,
    getOptionLabel: (entity: TModel) => string
}

const debug = false;

/**
 * Fetches all options at mount. When input matches a single options, the option is selected on blur
 * @param props 
 * @returns 
 */
export function AutocompleteOnBlurRemote<
    TModel,
    TQuery = Record<string, never>
>(props: Omit<
    AutocompleteProps<TModel, false, false, false>,
    'value' | 'options' | 'renderInput' | 'getOptionLabel' | 'onChange' | 'onInputChange'
> & {
    name: string,
    onChange?: (event: any, newValue: TModel | null, reason: AutocompleteChangeReason) => void,
    value?: TModel | null,
    label?: string,
    error?: boolean,
    sx?: SxProps<Theme>,
    endpoint: string,
    query: TQuery,
    isOptionEqualToInput: (option: TModel, textInput: string) => boolean,
    isOptionEqualToValue: (option: TModel, value: TModel) => boolean,
    getOptionLabel: (entity: TModel) => string
}) {

    const { value, isOptionEqualToInput, isOptionEqualToValue, endpoint, query, getOptionLabel, onChange, onBlur, label, error, name, sx, disabled, ...otherProps } = props;

    if (debug) console.log(props.name, "AutocompleteOnBlurRemote", props);

    //* OPTIONS

    const [collection, fetch, cleanup] = useRemoteResourceQueryService<TModel[], TModel[], TQuery>({ endpoint, defaultValue: [] })

    const collectionStatus = collection.status;

    const isInitialized = collectionStatus !== Status.Idle || disabled;
    
    const ensureInitialized = useCallback(() => { 
        if (!isInitialized) {
            //console.log(name, "Fetching options");
            fetch(query); 
        }
    }, [fetch, query, isInitialized]);

    
    const isLoaded = collectionStatus !== Status.Idle && collectionStatus !== Status.Pending;

    const collectionData = collection.data;
    const options: TModel[] = useMemo(() => (value && !isLoaded) ? [value] : collectionData, [collectionData, value, isLoaded]);

    if (debug) {
        console.log(name, "AutocompleteOnBlurRemote VALUE", value && getOptionLabel(value));
        console.log(name, "AutocompleteOnBlurRemote OPTIONS", options);
    }


    //* TEXT INPUT VALUE 
    // value entered by the user in the text field. To be used to match options when options have been fetched

    const [inputValue, setInputValue] = useState<string | undefined>(undefined);

    const handleInputChange = useCallback((evt: React.SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
        //console.log('InputChange', newInputValue, reason);
        if (reason !== 'reset') {
            setInputValue(newInputValue);
            ensureInitialized(); // fetches if needed
        }
    }, [ensureInitialized]);

    useEffect(() => {
        if (value === null) {
            setInputValue('');
        }
    }, [value]);


    //* OPTION MATCHING

    const [newMatch, setNewMatch] = useState<TModel | null>(null);
    const [blurred, setBlurred] = useState<React.SyntheticEvent<Element> | null>(null);

    // Match current value with options, reset if not found
    useEffect(() => {
        if (value && isLoaded) {
            const match = options.find(option => isOptionEqualToValue(option, value));
            if (debug) console.log(name, "AutocompleteOnBlurRemote match value", value, match);
            if (!match) {
                onChange && onChange(null, null, 'clear');
                setNewMatch(null);
            }
        }
    }, [value, options, onChange, isOptionEqualToValue, isLoaded, name])

    // Match current input value with options, if unique match found, store it, so that we can use it as selected value when the control loses focus
    useEffect(() => {
        if (inputValue && isLoaded) {
            const matches = options.filter(option => isOptionEqualToInput(option, inputValue));
            if (matches.length === 1) {
                const match = matches[0];
                if (debug) console.log(name, "AutocompleteOnBlurRemote match input:", inputValue, match);
                if (blurred) {
                    onChange && onChange(null, match ?? null, 'selectOption');
                    setNewMatch(null);
                } else {
                    setNewMatch(match ?? null);
                }
            }
        }
    }, [inputValue, options, blurred, onChange, isOptionEqualToInput, isLoaded, name])


    //* HANDLE BLUR

    /* On blur, if there is a matching option select it */
    const handleBlur = useCallback((evt: React.FocusEvent<HTMLDivElement>) => {
        setBlurred(evt);
        if (debug) console.log(name, "Autocomplete blur, newMatch:", newMatch);
        if (newMatch && newMatch !== value) {
            onChange && onChange(evt, newMatch, 'selectOption');
            setNewMatch(null);
        }
        onBlur && onBlur(evt);
    }, [value, newMatch, onBlur, onChange, name]);


    //* HANDLE SELECTION CHANGE

    const handleChange = useCallback((
        evt: React.SyntheticEvent<Element, Event>,
        value: TModel | null,
        reason: AutocompleteChangeReason
    ) => {
        if (debug) console.log(name, "AutocompleteOnBlurRemote SELECTED", value, reason);
        setNewMatch(value);
        onChange && onChange(evt, value, reason);
    }, [onChange, name]);


    return (<>
        <Autocomplete<TModel, false, false, false>
            onOpen={ensureInitialized}
            options={options ?? []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={props.isOptionEqualToValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onFocus={() => setBlurred(null)}
            onBlur={handleBlur}
            value={value ?? null}
            loading={collection.status === Status.Pending}
            autoSelect
            autoComplete
            clearOnBlur={true}
            sx={{ mx: 1, ...sx }}
            disabled={disabled}
            {...otherProps}
            renderInput={(params) => (
                <StyledTextFieldAC {...params}
                    name={name}
                    label={label}
                    variant="outlined"
                    error={error}
                    inputProps={{
                        ...params.inputProps,
                        value: value ? getOptionLabel(value) : (inputValue ?? '')
                    }} />
            )}
        />
    </>)
}


