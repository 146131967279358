import { Editor } from "@tiptap/react";
import { StyledButton } from "./StyledInputs";
import IndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import IndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import { Box } from "@mui/system";




export const IndentControls = (props: { editor: Editor | null }) => {

    const { editor } = props;

    if (!editor) {
        return null
    }

    return <Box>
        
        <StyledButton type="button"
            onClick={(evt) => { 
                //evt.preventDefault(); 
                editor.chain().focus().indent().run();
            }}
        >
            <IndentIncreaseIcon/>
        </StyledButton>

        <StyledButton type="button"
            onClick={(evt) => { 
                //evt.preventDefault(); 
                editor.chain().focus().outdent().run();
            }}
        >
            <IndentDecreaseIcon/>
        </StyledButton>
        
    </Box>
}