import { TableCell, styled } from "@mui/material";

export const ClickableTableCell = styled(TableCell)(
    ({ theme }) => ({
        '&.MuiTableCell-root': {
            //padding: '6px',
            cursor: 'pointer',
            // '&:first-of-type': { paddingLeft: theme.spacing(3) },
            // '&:last-of-type': { paddingRight: theme.spacing(3) },
        },
        '&.MuiTableCell-root.MuiTableCell-head': {
            cursor: 'default',
        }
    })
)

