import { NonEditableField } from 'Components/FormUtils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Checkbox, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from '@mui/material';

import { User, UserProp, useUserSettingsBulkEditService, useUserUpsertService } from './userSettingsServices';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { Status } from 'Components/reduxLogger';
import { useCurrentUrlCredentials } from '../HashCredentialsProvider';


export const UserSettingsEditableTable = (props: {
    softwareCode: string,
    softwareName: string,
    customerCode: string,
    userCode?: string,
    entities: User[],
    onEntitiesUpdated: (data: User[]) => void
}) => {

    const { entities } = props;

    const [bulkProps, setBulkProps] = useState<Record<UserProp, boolean | undefined>>({
        showAtStartup: undefined,
        showOnForm: undefined,
        ownDepartmentsOnly: undefined,
        inactive: undefined
    });

    useEffect(() => {
        const data = entities;
        setBulkProps({
            showAtStartup: getBulkPropValue(data, 'showAtStartup'),
            showOnForm: getBulkPropValue(data, 'showOnForm'),
            ownDepartmentsOnly: getBulkPropValue(data, 'ownDepartmentsOnly'),
            inactive: getBulkPropValue(data, 'inactive'),
        });
    }, [entities]);

    const { onEntitiesUpdated } = props;

    const handleBulkPropChange = useCallback((bulkChange: Partial<Record<UserProp, boolean>>) => {

        setBulkProps(val => ({ ...val, ...bulkChange }));
        const updatedEntities = entities.map(user => {
            const updatedUser: User = { ...user };
            Object.keys(bulkChange).forEach(key => {
                const val = bulkChange[key as keyof typeof bulkChange];
                if (val !== undefined) // this is always the case
                    updatedUser[key as UserProp] = val;
            });
            return updatedUser;
        });
        onEntitiesUpdated(updatedEntities);
    }, [onEntitiesUpdated, entities]);

    const onUserChanged = (user: User) => {
        const index = entities.findIndex(x => x.id === user.id);
        const updatedEntities = [...entities];
        updatedEntities[index] = user;
        props.onEntitiesUpdated(updatedEntities); // no need, CollectionEditController has the updated User already
        setBulkProps({
            showAtStartup: getBulkPropValue(updatedEntities, 'showAtStartup'),
            showOnForm: getBulkPropValue(updatedEntities, 'showOnForm'),
            ownDepartmentsOnly: getBulkPropValue(updatedEntities, 'ownDepartmentsOnly'),
            inactive: getBulkPropValue(updatedEntities, 'inactive'),
        });
    }

    const [bulkPropsSubmissionState, submitBulkProps] = useUserSettingsBulkEditService();
    const { data: propsUpdateSuccess, status: bulkSubmitStatus } = bulkPropsSubmissionState;

    const [pendingBulkChange, setPendingBulkChange] = useState<Partial<Record<UserProp, boolean>>>({});

    console.log("Bulk props", bulkProps);

    const handleBulkApply = useCallback((bulkStatusChanges: Partial<Record<UserProp, boolean>>) => {
        const newBulkStatus = { ...bulkProps, ...bulkStatusChanges };
        setPendingBulkChange(newBulkStatus);
        submitBulkProps(newBulkStatus)
    }, [submitBulkProps, bulkProps]);

    useEffect(() => {
        if (propsUpdateSuccess && Object.keys(pendingBulkChange).length > 0) {
            handleBulkPropChange(pendingBulkChange);
            setPendingBulkChange({});
        }
    }, [pendingBulkChange, propsUpdateSuccess, handleBulkPropChange]);

    const { t } = useTranslation();

    const {asAdmin} = useCurrentUrlCredentials();
    console.log("asAdmin", asAdmin);

    return <>

        <TableContainer sx={{height: entities.length > 1 ? '100vh' : 'auto'}}>
            <Table aria-label="described-codes-table" size='medium' stickyHeader /*sx={{position: 'relative'}}*/>
                <TableHead sx={{}}>
                    <TableRow>
                        <StyledCell colSpan={2}>{null}</StyledCell>
                        <StyledCell colSpan={4} sx={{ verticalAlign: 'bottom', paddingBottom: 0, paddingTop: 2, '&.MuiTableCell-root': { textAlign: 'left' } }}>
                            {t('notificationSettingsHeader') + ':'}
                        </StyledCell>
                        <StyledCell>{null}</StyledCell>
                    </TableRow>
                    <TableRow>
                        <StyledCell sx={{ '&.MuiTableCell-root': { textAlign: 'left' } }}>{t('login')}</StyledCell>
                        <StyledCell sx={{ '&.MuiTableCell-root': { textAlign: 'left' } }}>{t('user')}</StyledCell>
                        <PropHeadCell propName='showAtStartup' bulkProps={bulkProps} onBulkPropsChange={handleBulkApply} softwareName={props.softwareName} softwareCode={props.softwareCode}/>
                        <PropHeadCell propName='showOnForm' bulkProps={bulkProps} onBulkPropsChange={handleBulkApply} softwareName={props.softwareName} softwareCode={props.softwareCode}/>
                        <PropHeadCell propName='ownDepartmentsOnly' bulkProps={bulkProps} onBulkPropsChange={handleBulkApply} softwareName={props.softwareName} softwareCode={props.softwareCode}/>
                        <PropHeadCell propName='inactive' bulkProps={bulkProps} onBulkPropsChange={handleBulkApply} softwareName={props.softwareName} softwareCode={props.softwareCode}/>
                        <StyledCell>{null}</StyledCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{maxHeight: 'calc(100% - 120px)'}}>
                    {entities.map(entity => <UserSettingsEditor key={entity.id} user={entity} onChanged={onUserChanged} asAdmin={!!asAdmin}/>)}
                </TableBody>
            </Table>
        </TableContainer>
        <ProgressBackdrop open={bulkSubmitStatus === Status.Pending} />
    </>
}


const getBulkPropValue = (data: User[], propName: UserProp): boolean | undefined => {
    const checkedItems = data.filter(x => x[propName] === true);
    const checkedCount = checkedItems.length;
    switch (checkedCount) {
        case 0:
            return false;
        case data.length:
            return true;
        default:
            return undefined;
    }
}

const StyledCell = styled(TableCell)(
    ({ theme }) => ({
        '&.MuiTableCell-root': {
            paddingLeft: '4px',
            paddingRight: '4px',
            maxWidth: 300,
            height: 1,
            textAlign: 'center'
        },
        '&.MuiTableCell-root:first-of-type': {
            paddingLeft: '16px',
        },
        '&.MuiTableCell-head': {
            verticalAlign: 'bottom',
            position: 'sticky',
            top: 0,
            zIndex: 2
        }
    })
);

const PropHeadCell = (props: {
    propName: UserProp,
    bulkProps: Record<UserProp, boolean | undefined>,
    onBulkPropsChange: (status: Partial<Record<UserProp, boolean>>) => void,
    softwareName: string,
    softwareCode: string
}) => {

    const { propName, onBulkPropsChange, softwareName, softwareCode } = props;

    const handleBulkApplyClick = useCallback((value: boolean) => {
        onBulkPropsChange({ [propName]: value })
    }, [onBulkPropsChange, propName]);

    const { t } = useTranslation();

    const translationKeyPostfix = softwareCode === 'ERGOME' ? '.Ergome' : '';

    return (
        <StyledCell variant='head' size='small'>
            <Stack height={1} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction='row' alignItems={'center'}>
                    <span>{t(`${propName}${translationKeyPostfix}`)}</span>
                    <Tooltip title={t(`${propName}${translationKeyPostfix}.tip`, {software: softwareName})}><InfoIcon/></Tooltip>
                </Stack>
                <div><Checkbox color='secondary'
                    checked={!!props.bulkProps[propName]}
                    indeterminate={props.bulkProps[propName] === undefined}
                    onClick={(evt) => handleBulkApplyClick((evt.target as any).checked)} /></div>
            </Stack>
        </StyledCell>
    );
}

interface UserSettingsEditorProps { user: User, onChanged: (user: User) => void, asAdmin: boolean }

function UserSettingsEditor(props: UserSettingsEditorProps) {

    const { user: value, onChanged } = props;

    const [saveState, save] = useUserUpsertService({asAdmin: props.asAdmin });

    const [newValue, setNewValue] = useState<User | undefined>(undefined);

    const handleChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newValue = { ...value, [evt.currentTarget.name]: checked }
        setNewValue(newValue);
        save(newValue);
    }

    const saveStatus = saveState.status;
    
    useEffect(() => {
        if (saveStatus === Status.Success && newValue) {
            onChanged(newValue);
            setNewValue(undefined);
        }
    }, [saveStatus, onChanged, newValue]);

    const [t] = useTranslation();

    //console.log("VALUE", value);

    return <TableRow>

        <StyledCell sx={{ '&.MuiTableCell-root': { textAlign: 'left' } }}>
            {<NonEditableField value={value?.userCode} plain />}
        </StyledCell>
        <StyledCell sx={{ pl: 0, '&.MuiTableCell-root': { textAlign: 'left' } }}>
            <NonEditableField value={value?.userName} plain />
            <NonEditableField value={value?.userEmail} plain />
        </StyledCell>
        <StyledCell>
            <Checkbox checked={value.showAtStartup} name={'showAtStartup'} onChange={handleChangeEvent} />
        </StyledCell>
        <StyledCell>
            <Checkbox checked={value.showOnForm} name={'showOnForm'} onChange={handleChangeEvent} />
        </StyledCell>
        <StyledCell sx={{position: 'relative'}}>
            <Checkbox checked={value.ownDepartmentsOnly} name={'ownDepartmentsOnly'} onChange={handleChangeEvent} />
            {value.profileURL && (
                <Box sx={{display: 'inline', position: 'absolute', top:28}}>
                    <a href={value.profileURL} target="_blank" rel="noreferrer">
                        {t(value.isProfiled ? 'seeOwnDepartments' : 'notProfiledYet')}
                    </a>
                </Box>
            )}
        </StyledCell>
        <StyledCell>
            <Checkbox checked={value.inactive} name={'inactive'} onChange={handleChangeEvent} />
        </StyledCell>
    </TableRow>
}







