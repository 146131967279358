import { useTranslation } from 'react-i18next';

import { TableHead } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import { Order, OrderByOptions } from './tableCommons';

export function NewsTableHead(props: {
    onSortChange: (event: React.MouseEvent<unknown>, sortBy: OrderByOptions) => void;
    order: Order;
    orderBy: OrderByOptions;
}) {

    const { order, orderBy, onSortChange: onSortChange } =
        props;

    const createSortHandler = (property: OrderByOptions) => (event: React.MouseEvent<unknown>) => onSortChange(event, property);

    const [t] = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell>{t('read')}</TableCell>

                <TableCell align={'left'} padding={'normal'}>{t('text')}</TableCell>

                <TableCell
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderBy === 'releaseVersion' ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === 'releaseVersion'}
                        direction={orderBy === 'releaseVersion' ? order : 'asc'}
                        onClick={createSortHandler('releaseVersion')}
                    >
                        {t('release')}
                        {orderBy === 'releaseVersion' ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>

                <TableCell width={120}>{t('hideForever')}</TableCell>

            </TableRow>
        </TableHead>
    );
}