import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)({
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        //borderColor: '#ffff00',
        borderWidth: 2,
    },
});

export const StyledTextFieldAC = styled(TextField)({
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        //borderColor: '#ffff00',
        borderWidth: 2,
    },
    marginLeft: 0,  //  otherwise TextField overflows, due to having set mx=8px on MuiFormControl root in Themes config
    marginRight: 0
    //width: 'calc(100% - 8px)'
});


export const DarkerDisabledTextField = styled(TextField, { shouldForwardProp: (prop) => true })(({ theme }) => ({
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
        WebkitTextFillColor: theme.palette.getContrastText(theme.palette.background.paper)
    },
}));