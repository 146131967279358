import { CollectionLayout } from 'Components/Collection/CollectionLayout';
import ToolbarWithSearchAndDrawer from 'Components/Containers/ToolbarWithSearchAndDrawer';
import { useGuardedExec } from 'Components/GuardService';
import {
    CollectionEditController, ItemEditProps, ItemViewProps, ItemsWrapperProps
} from 'Components/RemoteEntities/Collection/CollectionEditController';
import { RemoteCollectionApi } from 'Components/RemoteEntities/Collection/RemoteCollection';
import { EmptyObject } from 'Components/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PaginationController, usePaginationHandling } from './Pagination';
import ToolbarWithTitleAndSearch from 'Components/Containers/ToolbarWithTitleAndSearch';

/**
 * Container component to manage a remote collection. Renders
 * collection filters and pagination controls in a top sticky section,
 * followed by the filtered collection with controls to edit individual items
 */
export function CollectionManagerWithSearch<
  TModel extends Record<string, any>, 
  TFilter = EmptyObject, 
  TContext = EmptyObject
>(props: {
  collectionId: string,
  titleKey?: string,
  title?: string,
  showTitleInToolbar?: boolean,
  useCollection: (props: { filter?: TFilter, search?: string, context: TContext }) => RemoteCollectionApi<TModel>,
  onLoaded?: (data: TModel[]) => void,
  doPaginate?: boolean,
  canAdd?: boolean,
  context: TContext,
  initialFilter?: TFilter,
  children: {
    Filters?: React.FC<{ value?:TFilter, onChange: (filters: TFilter) => void, context: TContext }>,
    ItemsWrapper: React.FC<ItemsWrapperProps<TContext>>, // renders collection wrapper (typically, some kind of <Table> with headers)
    ItemEditor: React.FC<ItemEditProps<TModel, TContext>>,  // renders entity form
    ItemView: React.FC<ItemViewProps<TModel, TContext>>
  }
}): React.ReactElement {

  const {context} = props;

  const [filterDraft, setFilterDraft] = useState<TFilter | undefined>(props.initialFilter);
  const [filter, setFilter] = useState<TFilter | undefined>(props.initialFilter);
  
  const [search, setSearch] = useState<undefined | string>(undefined);
  
  const [collection, collectionApi] = props.useCollection({ filter, search, context });

  const { isSuccess: collectionLoaded, entities } = collection;

  const {onLoaded} = props;
  useEffect(() => { if (collectionLoaded && onLoaded) onLoaded(entities)}, [collectionLoaded, onLoaded, entities]);

  const pagination = usePaginationHandling<TModel>({entities, doPaginate: props.doPaginate});

  /* HANDLE FILTERS CHANGE */
  // Use guardedExec because we don't want to reset displayed entities if they have unsaved changes
  
  const guardedExec = useGuardedExec();

  const handleSearchChange = useCallback((value: string) => {
    guardedExec(() => setSearch(value));
  }, [guardedExec]);

  const handleFilterClosed = useCallback(() => {
    guardedExec(() => {
      if (filterDraft !== filter) {
        setFilter(filterDraft)
      }
    });
  }, [guardedExec, filter, filterDraft]);

  const {Filters} = props.children;

  console.log("FILTERS", search, filter);

  const { t } = useTranslation();

  const title = props.title ?? (props.titleKey ? t(props.titleKey) : undefined);

  return <>
    <CollectionLayout
      id={(props.collectionId || collectionApi.endpoint)}
      title={props.showTitleInToolbar ? undefined : title}
      hasNewButton={false}
      isLoading={collection.isLoading}
    >
      {{
        controls: Filters ? (
          <ToolbarWithSearchAndDrawer 
            searchValue={search} 
            onSearchChanged={handleSearchChange}
            onClose={handleFilterClosed}
          >
            {Filters && <Filters value={filterDraft} onChange={setFilterDraft} context={props.context}/>}
          </ToolbarWithSearchAndDrawer>
        ) : (
          <ToolbarWithTitleAndSearch 
            title={props.showTitleInToolbar ? title : undefined}
            searchValue={search} 
            onSearchChanged={handleSearchChange}/>
        ),

        pagination: <PaginationController 
          collection={collection} 
          collectionApi={collectionApi} 
          localPagination={pagination.localPagination} />,
        
        content:
          <CollectionEditController<TModel, TContext>
            rootId={props.collectionId}
            initialEntities={pagination.result}
            canAdd={props.canAdd}
            context={props.context}
          >{{
            ItemsWrapper: props.children.ItemsWrapper,
            ItemEditor: props.children.ItemEditor,
            ItemView: props.children.ItemView
          }}
          </CollectionEditController>
      }}
    </CollectionLayout>
  </>
}



