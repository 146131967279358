import { Theme, ThemeProvider } from "@mui/material/styles";

import React from "react";

interface IThemesContext {
  switchTheme: () => void
}


export const ThemesContext: React.Context<IThemesContext> = React.createContext({
  switchTheme: () => { /* */ },
});

ThemesContext.displayName = 'ThemesContext';

const getThemeIndex = (list: Record<string, Theme>, theme?: string) => {
  if (!theme) return 0;
  else {
    const index = Object.keys(list).indexOf(theme);
    return index > -1 ? index : 0
  }
}

export function ThemesProvider<Themes extends Record<string, Theme> & { default: Theme }>(props: {
  themes: Themes,
  initialTheme?: string,
  children: React.ReactNode,
}) {

  const [index, setIndex] = React.useState(getThemeIndex(props.themes, props.initialTheme));

  const { themes } = props;

  const context = React.useMemo(() => ({
    switchTheme: () => {
      setIndex(old => (old === (Object.keys(themes).length - 1) ? 0 : old + 1))
    }
  }), [setIndex, themes]);

  const themeToApply = props.themes[Object.keys(themes)[index]] ?? props.themes.default

  return (
    <ThemesContext.Provider value={context}>
      <ThemeProvider theme={themeToApply}>
        {props.children}
      </ThemeProvider>
    </ThemesContext.Provider>
  )
}