
import {useRemoteCollection} from "Components/RemoteEntities/Collection/RemoteCollection";
import {hasContent} from "Components/utils";
import {useTranslation} from "react-i18next";

import {Software} from "Entities/Software/software.services";
import { faqsFilterToAPIQueryParams } from "../Faqs/faq.services";
import {SwFaqFilters} from "./swFaq.models";

export type SoftwareWithFaqs = Software & { faqsCount: number };

const useFilterQuery = (filters: SwFaqFilters | null) => {
    
    // Important! Do not do this in useMemo, otherwise it won't catch changes in nested arrays 
    // (looks like react-form-hook returns the same parent object when nested prop change?)

    let queryParams: any = null;

    const [t, {language}] = useTranslation();
    
    if (filters) {
        
        let { faqsFilter} = filters;
        const { software } = filters;
        
        if (software) {
            queryParams = { software: [software.code] }
        }

        // ergoOne filter is meaningful only for ERGOBUILD. If used in other software, result will be empty list
        if (queryParams?.software !== 'ERGOBUILD' && faqsFilter.ergoOne !== undefined) {
            faqsFilter = {...faqsFilter, ergoOne: undefined}
        }

        if (hasContent(faqsFilter)) {
            queryParams = {
                ...queryParams,
                ...faqsFilterToAPIQueryParams(faqsFilter),
                language
            }
        }
    }
    return queryParams;
}

//************** SOFTWARES WITH FAQS */
export function useSoftwaresWithFaqs(props: SwFaqFilters | null) {

    const queryParams = useFilterQuery(props);
    
    //console.log("queryParams", queryParams);

    return useRemoteCollection<SoftwareWithFaqs>({
        queryParams,
        endpoint: 'faqs/softwares',
        requiresQueryParams: false,
        isPaginated: false
    });
}
