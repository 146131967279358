import { Autocomplete, AutocompleteProps, SxProps, Theme } from "@mui/material";
import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { useEffect, useMemo, useState } from "react";
import { StyledTextFieldAC } from "Components/Input/StyledTextField";
import { Status } from "Components/utils";
import { Form, FormKey, formFromKey } from "./form.services";
import { useDescriptionGetter } from "Entities/DescribedCode/describedCode.models";
import { useTranslation } from "react-i18next";



const useOptionLabelGetter = () => {
    const getDescription = useDescriptionGetter();
    return (entity?: Form) => (entity ? (`${entity.moduleCode} ${entity.code} - ${getDescription(entity)}`) : '');
}

const _isOptionEqualToValue = (option: Form, val: Form) => (option.moduleCode == val.moduleCode && option.code === val.code);

export function FormAutocomplete(props: Omit<AutocompleteProps<Form, boolean | undefined, boolean | undefined, false>, 'renderInput' | 'getOptionLabel'> & { name: string }) {

    const { value, multiple, disabled, name, sx, ...autocompleteProps } = props;

    const getOptionLabel = useOptionLabelGetter();

    const [clearTrigger, setClearTrigger] = useState(0);
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form clear.
    // This is needed because MUIAutocomplete's multiple-values mode with object options has a bug: 
    // it does not re-render when the passed value changes to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => {
        if (!value) setClearTrigger(val => val + 1);
    }, [value]);

    const _value = multiple
        ? (Array.isArray(value) ? value : (value ? [value] : []))
        : (value ?? null)

    //console.log("FormAutocomplete", name, value);

    const [t] = useTranslation();

    return (
        <Autocomplete<Form, boolean | undefined, boolean | undefined, false>
            multiple={multiple}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={_isOptionEqualToValue}
            autoSelect
            autoComplete
            clearOnBlur={true}
            key={clearTrigger}
            sx={{ mx: 1, ...sx }}
            {...autocompleteProps}
            value={_value}
            renderInput={(params) => {
                return <StyledTextFieldAC {...params} name={name} label={t('form')} variant="outlined" />
            }}
        />
    )
}



const debug = false;

export function FormAutocompleteRemote(props: Omit<AutocompleteProps<Form, boolean | undefined, boolean | undefined, false>, 'options' | 'renderInput' | 'getOptionLabel'> & {
    name: string,
    sx?: SxProps<Theme>,
    softwareCode: string
}) {

    const { softwareCode, value, multiple, disabled, name, sx, ...autocompleteProps } = props;

    const getOptionLabel = useOptionLabelGetter();

    //* FETCH OPTIONS

    const [collection, fetch, clearOptions] = useRemoteResourceQueryService<Form[], Form[]>({ endpoint: `baseData/software/${softwareCode}/forms`, defaultValue: [] });

    useEffect(() => { clearOptions(); }, [softwareCode, clearOptions]);

    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status !== Status.Idle;

    const handleOpen = () => { if (!isInitialized) fetch({}); };

    const remoteList = collection.data;

    const options: Form[] = useMemo(
        () => (value && !isLoaded) ? (Array.isArray(value) ? value : [value]) : remoteList,
        [remoteList, value, isLoaded]
    );

    if (debug) {
        console.log(name, "VALUE", value);
        console.log(name, "OPTIONS", options);
    }

    const [clearTrigger, setClearTrigger] = useState(0);
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form-clear.
    // This is needed because MUIAutocomplete in multiple-values mode with object options has a bug: 
    // it does not re-render when the value passed to it is reset to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => {
        if (!value) setClearTrigger((val) => val + 1);
    }, [value]);

    if (debug) console.log("FormAutocomplete", name, value, 'loaded?', isLoaded);

    const _value = multiple
        ? (Array.isArray(value) ? value : (value ? [value] : []))
        : (value ?? null)

    const [t] = useTranslation();

    return (
        <Autocomplete<Form, boolean | undefined, boolean | undefined, false>
            options={options ?? []}
            multiple={multiple}
            onOpen={handleOpen}
            onInputChange={handleOpen}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={_isOptionEqualToValue}
            loading={collection.status === Status.Pending}
            autoSelect
            autoComplete
            clearOnBlur={true}
            key={clearTrigger}
            value={_value}
            sx={{ mx: 1, minWidth: 100, ...sx }}
            {...autocompleteProps}
            renderInput={(params) => {
                return <StyledTextFieldAC {...params}
                    name={name} label={t('form')}
                    variant="outlined"
                />
            }}
        />
    )
}


export function FormKeyMultiAutocompleteRemote(props: {
    name: string,
    softwareCode: string,
    value: FormKey[],
    onChange: (value: FormKey[]) => void,
    onBlur?: () => void,
    sx?: SxProps<Theme>
}) {

    const { softwareCode, value, name, sx, onChange, ...autocompleteProps } = props;

    const getOptionLabel = useOptionLabelGetter();

    //* FETCH OPTIONS

    const [collection, fetch, clearOptions] = useRemoteResourceQueryService<Form[], Form[]>({ 
        endpoint: `baseData/software/${softwareCode}/forms`, 
        defaultValue: [] 
    });

    useEffect(() => { clearOptions(); }, [softwareCode, clearOptions]);

    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status !== Status.Idle;

    const handleOpen = () => { if (!isInitialized) fetch({}); };

    const remoteList = collection.data;

    const options: Form[] = useMemo(
        () => ((value && !isLoaded) 
                ? value.map(x => formFromKey(x.formCode, x.moduleCode, softwareCode)) 
                : remoteList),
        [remoteList, value, isLoaded, softwareCode]
    );

    const objValue = value
        .map(x => options.find(opt => opt.code === x.formCode && opt.moduleCode === x.moduleCode))
        .filter(x => x !== undefined) as Form[];

    if (debug) {
        console.log(name, "VALUE", value);
        console.log(name, "OPTIONS", options);
    }

    const [clearTrigger, setClearTrigger] = useState(0);
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form-clear.
    // This is needed because MUIAutocomplete in multiple-values mode with object options has a bug: 
    // it does not re-render when the value passed to it is reset to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => {
        if (!value) setClearTrigger((val) => val + 1);
    }, [value]);

    if (debug) console.log("FormAutocomplete", name, value, 'loaded?', isLoaded);

    const [t] = useTranslation();

    return (
        <Autocomplete<Form, true, boolean | undefined, false>
            options={options ?? []}
            multiple
            onOpen={handleOpen}
            onInputChange={handleOpen}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={_isOptionEqualToValue}
            loading={collection.status === Status.Pending}
            autoSelect
            autoComplete
            clearOnBlur
            key={clearTrigger}
            value={objValue}
            onChange={(evt, val, reason) => { props.onChange(val?.map(x => ({ formCode: x.code, moduleCode: x.moduleCode }))) }}
            sx={{ mx: 1, minWidth: 100, ...sx }}
            {...autocompleteProps}
            renderInput={(params) => {
                return <StyledTextFieldAC {...params} name={name} label={t('form')} variant="outlined" />
            }}
        />
    )
}

