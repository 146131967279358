import { AutocompleteOnBlurDynamic } from 'Components/Input/AutocompleteOnBlurDynamic';
import { DialogX } from 'Components/Containers/DialogX';
import { useCallback, useMemo, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolderMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/material';

import { Software } from './software.services';
import { SoftwareExplorer } from './SoftwareExplorer';

const _empty = {}


export function SoftwareAutoSelect(props: {
  value: Software | string | null,
  onChange: (value: Software | null) => void,
  sx?: SxProps<Theme>,
  disabled?: boolean,
  withExplorer?: boolean
}) {

  const [openDialog, setOpenDialog] = useState(false);

  const { onChange, value } = props;

  const handleSelectedFromExplorer = useCallback((value: false | Software) => {
    onChange(value ? value : null);
    setOpenDialog(false);
  }, [onChange]);

  const handleAutocompleteChange = useCallback((evt, value, reason) => {
    onChange(value);
  }, [onChange]);

  const _value = typeof props.value === 'string' ? { code: props.value, description: props.value, userDescription: props.value, family: '' } : props.value;

  return <Box display='flex' flexWrap={'nowrap'} sx={props.sx}>

    <AutocompleteOnBlurDynamic<Software>

      onChange={handleAutocompleteChange}
      value={_value}
      disabled={props.disabled}
      label='Software'
      sx={{ minWidth: 200, maxWidth: 600, flex: 1, mr: 0, ml: 1, ...props.sx }}
      endpoint='baseData/software'
      query={_empty}

      getOptionLabel={(option: Software) => option.description }

      isOptionEqualToInput={(option: Software | undefined, textInput: string) => {
        if (!option) return textInput === '';
        const input = textInput.toLowerCase();
        if (option.code.toLowerCase().includes(input)) {
          return true;
        }
        return option.description.toLowerCase().includes(input);
      }}

      isOptionEqualToValue={(option?: Software, value?: Software) => {
        if (option) return option.code === value?.code;
        else return value === undefined;
      }}
    />

    {props.withExplorer && <>
      <IconButton disabled={props.disabled} onClick={() => setOpenDialog(true)} sx={{}}>
        <FontAwesomeIcon icon={faFolderMagnifyingGlass as IconProp} />
      </IconButton>
      <DialogX open={openDialog} onClose={() => setOpenDialog(false)}>
        <SoftwareExplorer onSelected={handleSelectedFromExplorer} />
      </DialogX>
    </>}
  </Box>
}




