import { FormWrapperProps } from 'Components/HookedForm/CollectionForm';
import { HookedTextField } from 'Components/HookedForm/inputControls';
import { ItemEditProps, ItemsWrapperProps } from 'Components/RemoteEntities/Collection/CollectionEditController';
import { CollectionManagerWithSearch } from 'Components/RemoteEntities/Collection/CollectionManagerWithSearch';
import { useEntityEditorApi } from 'Components/RemoteEntities/Entity/useEntityEditorApi';
import { NonEditableField } from 'Components/FormUtils';
import { ColorPicker } from 'mui-color';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { emptyDescribedCode } from 'Entities/DescribedCode/describedCode.models';
import {
  makeDescribedCodeService, makeDescribedCodesSearchCollection
} from 'Entities/DescribedCode/describedCode.services';
import { StyledCell } from 'Entities/DescribedCode/DescribedCodesManager/Styled';
import { Label } from 'Entities/Label/label.services';
import { EntityFormCustom } from 'Components/HookedForm/EntityFormCustom';
import { EntityDeleteDialog } from 'Components/RemoteEntities/Entity/EntityDeleteDialog';


const endpoint = 'baseData/labels';

const useCollection = makeDescribedCodesSearchCollection<Label>({ endpoint });


export function LabelsManager() {

  return <CollectionManagerWithSearch<Label>
    collectionId={"Labels"}
    titleKey={'Menu.baseData.labels'}
    useCollection={useCollection}
    canAdd={true}
    context={{}} 
  >{{
    ItemsWrapper: Wrapper,
    ItemView: LabelView,
    ItemEditor: LabelEditor
  }}</CollectionManagerWithSearch>
}


import { AddBar } from 'Components/RemoteEntities/Collection/CollectionUtils';
import { RemoteEntityResultHandler } from 'Components/RemoteEntities/Entity/RemoteEntityResultHandler';

function Wrapper(props: ItemsWrapperProps) {
  const { t } = useTranslation();

  return <>
    <TableContainer sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <Table aria-label="described-codes-table" size='medium'>
        <TableHead>
          <TableRow>
            <StyledCell>{t('code')}</StyledCell>
            <StyledCell></StyledCell>
            <StyledCell>{t('descriptionDe')}</StyledCell>
            <StyledCell>{t('descriptionIt')}</StyledCell>
            <StyledCell>{t('descriptionEn')}</StyledCell>
            <StyledCell>{null}</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
    <AddBar onAddButtonClick={props.onAddItemClick}/>
  </>;
}

function LabelView(props: {
  entity: Label,
  onEditClick: () => void,
  onDeleteClick: () => void
}) {

  const { entity } = props;
  return (
    <TableRow>
      <StyledCell sx={{ pl: 4 }}>
        <NonEditableField value={entity.code} plain/>
      </StyledCell>
      <StyledCell>
        <Box sx={{ ml:'4px', width: 24, height: 24, backgroundColor: entity.color, borderRadius: .5}}></Box>
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionDe} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionIt} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionEn} plain />
      </StyledCell>
      <StyledCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={props.onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={props.onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </StyledCell>
    </TableRow>
  );
}


type LabelEditorProps = ItemEditProps<Label>;

function LabelEditor(props: LabelEditorProps) {

  const { entity } = props;

  //const form = useForm<Label>({ defaultValues: props.entity });

  const useEntityService = useMemo(() => makeDescribedCodeService<Label>({ endpoint }), []);

  const editorApi = useEntityEditorApi<Label>({
    editorId: endpoint,
    useRemoteEntity: useEntityService,
    idKey: 'code',
    model: entity ?? false,
    proposeDelete: props.doDelete,
    asNew: !props.entity,
    context: {}
  });

  return <>
    <EntityFormCustom<Label>
      name={editorApi.editorId}
      defaultValues={entity || emptyDescribedCode}
      context={{}}
      onSave={editorApi.doSave}
      onDelete={editorApi.doDelete}
      onCancel={props.onExit}
    >{
        ({ control, formState, onCancel, onSave }) => (
          <TableRow>
            <StyledCell>
              {entity?.code
                ? <NonEditableField value={entity.code} plain />
                : <HookedTextField name={'code'} control={control} rules={{ required: true }} />}
            </StyledCell>
            <StyledCell>
              <Controller name={'color'} control={control} rules={{ required: true }}
                render={(renderProps) => (
                  <ColorPicker
                    value={renderProps.field.value ?? "white"}
                    disableTextfield
                    hideTextfield
                    onChange={(color) => {
                      const hex = (color as any).hex;
                      const colorString = hex ? '#' + hex : "white";
                      renderProps.field.onChange(colorString);
                    }}
                  />
                )} />
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionDe'} control={control} rules={{ required: true }} />
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionIt'} control={control} rules={{ required: true }} />
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionEn'} control={control} />
            </StyledCell>
            <StyledCell sx={{ textAlign: 'right' }}>
              <IconButton onClick={onSave} disabled={!formState.isDirty}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </StyledCell>
          </TableRow>
        )}
    </EntityFormCustom>

    <EntityDeleteDialog
      isConfirmationPending={editorApi.isDeleteConfirmPending}
      onAborted={editorApi.onDeleteAborted}
      onConfirmed={editorApi.onDeleteConfirmed}
    />
    <RemoteEntityResultHandler {...editorApi.entityState} onSaved={props.onSaved} onDeleted={props.onDeleted}/>
  </>
}




