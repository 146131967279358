import { MouseEventHandler, useState } from 'react';

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import {
    Box, Button, ButtonProps, IconButton, Menu, MenuItem, Select, styled
} from '@mui/material';
import { Editor } from '@tiptap/react';

import { StyledButton } from './StyledInputs';

interface StyledButtonProps extends ButtonProps {
    isActive?: boolean,
    bgColor: string
}


const _colors = ['red', 'orange', 'yellow', 'lightgreen', 'lightblue', 'violet'];

export const HighlightControls = (props: { editor: Editor }) => {

    const { editor } = props;

    const currentColor = _colors.find((color) => editor.isActive('highlight', { color })) ?? 'none';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (color: string) => (event: React.MouseEvent<HTMLElement>) => {
        if (color === 'none') {
            editor.commands.unsetHighlight();
        } 
        else
        {
            editor.commands.toggleHighlight({ color });
        }
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <StyledButton onClick={handleButtonClick}><FormatColorFillIcon /></StyledButton>
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <MenuItem onClick={handleMenuItemClick('none')}>none</MenuItem>
            {_colors.map((color, index) => (
                <MenuItem key={color} 
                    selected={color === currentColor}
                    onClick={(handleMenuItemClick(color))}>
                    <Box sx={{ width: 24, height: 24, backgroundColor: color }}></Box>
                </MenuItem>))}
        </Menu>
    </>

    // return <Box>

    //     <StyledButton variant="contained"
    //         onClick={(evt) => { 
    //             evt.preventDefault(); 
    //             evt.stopPropagation();
    //             //editor.view.focus();
    //             editor.chain().unsetHighlight().run();
    //         }}
    //         isActive={!editor.isActive('highlight')}
    //         bgColor = 'inherit'
    //     >{''}</StyledButton>

    //     <ColorButton editor={editor} color='red'/>
    //     <ColorButton editor={editor} color='orange'/>
    //     <ColorButton editor={editor} color='yellow'/>
    //     <ColorButton editor={editor} color='lightgreen'/>
    //     <ColorButton editor={editor} color='lightblue'/>
    //     <ColorButton editor={editor} color='violet'/>

    // </Box>
}

// const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'bgColor' })<StyledButtonProps>(({ theme, isActive, bgColor }) => {
//     return {
//         borderRadius: '10%',
//         border: isActive ? 'solid 4px white' : undefined,
//         width: 0,
//         minWidth: 0,
//         height: 28,
//         backgroundColor: bgColor
//     }
// });

// const ColorButton = (props: { editor: Editor, color: string }) => {

//     const { color, editor } = props;
//     return (
//         <StyledButton variant="contained"
//             onClick={(evt) => {
//                 evt.preventDefault();
//                 evt.stopPropagation();
//                 const { from, to } = editor.state.selection;
//                 editor.commands.toggleHighlight({ color: color });
//                 // editor.view.focus();
//                 // editor.commands.setTextSelection({from, to});
//                 // editor.commands.focus();
//             }}
//             isActive={editor.isActive('highlight', { color })}
//             bgColor={color}
//         >{''}</StyledButton>
//     )
// }



