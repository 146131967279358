
import {
    dummyNaviDef, NavigatorDefinition, NavNodeDefinition
} from 'Components/Dashboard/Navigation/NavProvider';
import { AccessLevels } from 'Components/Remote/remote.service';
import { useAccessLevel } from 'Components/Remote/RemoteProvider';
import { SoftwareDeepExplorer } from './BaseData/Software/SoftwareDeepExplorer';
import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LabelIcon from '@mui/icons-material/Label';
import ListIcon from '@mui/icons-material/List';
import StorageIcon from '@mui/icons-material/Storage';
import SyncIcon from '@mui/icons-material/Sync';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { ListItemText } from '@mui/material';

import { DepartmentsManager } from './BaseData/Department/DepartmentsManager';
import { LabelsManager } from './BaseData/Label/LabelsManager';
import { MacroareasManager } from './BaseData/Macroareas/MacroareasManager';
import { MessageStatusesManager } from './BaseData/MessageStatus/MessageStatusesManager';
import { ReleaseStatusesManager } from './BaseData/ReleaseStatus/ReleaseStatusesManager';

import {ReleasesManager} from "./ReleaseNotes/Release/ReleasesManager";
import {SwFaqsManager} from "./Faqs/Softwares/SwFaqsManager";

const makeMenuTree = (props: { 
  userAccessLevel: AccessLevels, 
  updateIos: () => void, 
  iosPending: boolean 
}): NavNodeDefinition => ({
  name: 'Menu',
  path: 'dashboard',
  isExactPath: true,
  component: undefined,
  children: [
    {
      name: 'baseData',
      disabled: false,
      Icon: StorageIcon,
      children: [
        {
          name: 'labels',
          path: `baseData/labels`,
          isExactPath: false,
          component: LabelsManager,
          Icon: LabelIcon
        },
        {
          name: 'releaseStatuses',
          path: `baseData/releaseStatuses`,
          isExactPath: false,
          component: ReleaseStatusesManager,
          Icon: ListIcon
        },
        {
          name: 'messageStatuses',
          path: `baseData/messageStatuses`,
          isExactPath: false,
          component: MessageStatusesManager,
          Icon: ListIcon
        },
        {
          name: 'macroareas',
          path: `baseData/macroareas`,
          isExactPath: false,
          component: MacroareasManager,
          Icon: BusinessIcon
        },
        {
          name: 'departments',
          path: `baseData/departments`,
          isExactPath: false,
          component: DepartmentsManager,
          Icon: BusinessIcon
        },
        {
          name: 'software',
          //renderName: (function SoftwareDeepMenuName() { return <Box>Software<br/>Module<br/>Form</Box>; }),
          renderName: (function SoftwareDeepMenuName() { return <ListItemText primary='Software' secondary={'Module/Form'} />; }),
          path: `baseData/software`,
          isExactPath: false,
          component: SoftwareDeepExplorer,
          Icon: TextSnippetIcon,
        },
        {
          name: 'updateFromIos',
          Icon: SyncIcon,
          command: props.updateIos,
          disabled: props.iosPending
        },
      ]
    },
    {
      name: 'releaseNotes.root',
      path: `releases`,
      isExactPath: false,
      component: ReleasesManager,
      Icon: EventNoteIcon,
    },
    {
      name: 'faqs.root',
      path: `faqs`,
      isExactPath: false,
      component: SwFaqsManager,
      Icon: HelpCenterIcon,
    },

    // {
    //   name: 'messages',
    //   path: `notes/messages`,
    //   isExactPath: false,
    //   component: MessagesManager,
    //   Icon: EmailIcon,
    // },
  ]
});

export function useRelnotesDashboardNavigator(props: {updateIos: () => void, iosPending: boolean}): NavigatorDefinition {

  const userAccessLevel = useAccessLevel();

  const [menu, setMenu] = React.useState<NavigatorDefinition>(dummyNaviDef);

  const { updateIos, iosPending } = props;

  React.useEffect(() => {

    console.log("APP NAVI reset for userAccessLevel", userAccessLevel);

    setMenu({

      menuRoot: makeMenuTree({ userAccessLevel, updateIos, iosPending}),
      
      resetOpenMenu: (currentMenu: string) => {
        return currentMenu === 'Menu' ? "Menu.notes" : currentMenu;
      },
      
      doRedirectNonMatchingURL: true

    });
  }, [userAccessLevel, updateIos, iosPending]);

  return menu;
}


