import { Box, TextareaAutosize, TextareaAutosizeProps, Theme, useTheme, styled } from "@mui/material";
import { SxProps, lighten } from "@mui/system";

const MyTextareaAutosizeStyled = styled(TextareaAutosize)(({ theme }) => {
    const backgroundColor = theme.palette.mode === 'dark' ? lighten(theme.palette.common.black, .1) : theme.palette.background.paper;
    return {
        fontFamily: 'inherit',
        padding: '8px',
        //margin: '4px',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor,
        width: '100%',
        color: theme.palette.getContrastText(backgroundColor),
        ':focus': {
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: '2px'
        },
    };
})

export type TextareaAutosizeStyledProps = TextareaAutosizeProps & {
    label?: string,
    sx?: SxProps<Theme>
}

export const TextareaAutosizeStyled = (props: TextareaAutosizeStyledProps) => {
    const { label, sx, ...otherProps } = props;
    const theme = useTheme();
    return (
        <Box position='relative' sx={{ p: 2, ...sx }}>
            <Box sx={{
                position: 'absolute', top: '-1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.grey[500],
                fontSize: '85%',
                ml: 2,
                px: 1
            }}>{label}</Box>
            <MyTextareaAutosizeStyled {...otherProps} />
        </Box>
    )
}