import { useCancelable } from 'Components/cancelable';
import { useRemoteMethods } from 'Components/Remote/RemoteProvider';
import {
    Actions, makeInitialState, makeResourceReducer, State
} from 'Components/RemoteEntities/resource.state';
import { useConst } from 'Components/useConst';
import { objectToURLPathAndSearchString, objectToURLSearchString } from 'Components/utils';
import React from 'react';

export type { State } from 'Components/RemoteEntities/resource.state';


export type RemoteResourceQueryApi<TModel, TDefault extends TModel | null = TModel, TQueryParams = Record<string, never>> = [
  State<TModel, TDefault>,
  (queryParams: TQueryParams) => Promise<TDefault | TModel>,
  () => void
]

export function useRemoteResourceQueryService<
  TResource,
  TDefault extends TResource | null = TResource,
  TQueryParams = Record<string, never>,
>(props: {
  endpoint: string,
  defaultValue: TDefault,
  httpParams?: RequestInit,
}): RemoteResourceQueryApi<TResource, TDefault, TQueryParams> {

  const { endpoint, httpParams } = props;
  const defaultValue = useConst(() => props.defaultValue);

  const reducer = React.useMemo(() => makeResourceReducer<TResource, TDefault>(`${endpoint}`, defaultValue), [endpoint, defaultValue]);
  const initialState = React.useMemo(() => makeInitialState<TResource, TDefault>(defaultValue), [defaultValue]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { cleanFetch } = useRemoteMethods();
  const cancelable = useCancelable<TResource>();
  

  /* CLEAR local resource */
  const clear = React.useCallback(() => { dispatch(['CLEAR']); }, []);

  //const cleanup = React.useRef<undefined | (() => void)>(undefined);

  /* FETCH remote resource */
  const fetch = React.useCallback((queryParams: TQueryParams) => {

    // Create query string and dynamic route
    const { path, search } = objectToURLPathAndSearchString(queryParams, endpoint);

    if (path.includes(':')) {
      // there are route params that have not been resolved.
      // Skip fetch
      console.log("Skipping fetch because missing route params", path);
      return new Promise<TDefault>((resolve) => {
        dispatch(['CLEAR']);
        resolve(defaultValue);
      });
    }
    //console.log("FETCH", path, search);
    
    dispatch(['REQUEST']);
    
    const $result = cancelable(...cleanFetch(`${path}${search ? '?' + search : ''}`, httpParams))
      .then((payload: any) => {
        const entity: TResource = (payload as TResource)
        dispatch(['SUCCESS', entity]);
        return entity;
      })
      .catch((err: any) => {
        dispatch(['FAILED', err]);
        return defaultValue;
      });

    return $result;
    
  }, [endpoint, cleanFetch, cancelable, defaultValue, httpParams]);

  return [state, fetch, clear]; 
}



export interface RemoteResourceSubmitProps {
  endpoint: string,
  method?: 'PUT' | 'POST' | 'PATCH' | 'DELETE',
}
export type RemoteResourceSubmitApi<TRequest, TResult = boolean> = [
  State<TResult, null>,
  (data: TRequest) => Promise<TResult | void>,
  () => void,
  React.Dispatch<Actions<boolean>>
];

export function useRemoteResourceSubmissionService<TModel, TResult = boolean>(props: RemoteResourceSubmitProps) {

  const { endpoint, method: propsMethod } = props;
  const method = propsMethod || 'PUT';

  const reducer = React.useMemo(() => makeResourceReducer<TResult, null>(`${endpoint}`, null), [endpoint]);

  const [state, dispatch] = React.useReducer(reducer, makeInitialState<TResult, null>(null));

  const remote = useRemoteMethods();
  const cancelable = useCancelable<TResult>();
  const { cleanFetch } = remote;

  /* CLEAR local state */
  const clear = React.useCallback(() => { dispatch(['CLEAR']); }, []);

  /* POST REMOTE RESOURCE */
  const write = React.useCallback((data: TModel) => {

    dispatch(['REQUEST']);

    return cancelable(...cleanFetch(`${endpoint}`, {
      method,
      body: JSON.stringify(data)
    }))
      .then((result: TResult) => {
        dispatch(['SUCCESS', result]);
        return result;
      })
      .catch((err: any) => dispatch(['FAILED', err]))

  }, [cleanFetch, cancelable, endpoint, method]);


  return [state, write, clear, dispatch] as [
    State<TResult, null>,
    (data: TModel) => Promise<TResult | void>,
    () => void,
    React.Dispatch<Actions<boolean>>
  ];
}

