
import { User } from './remote.service';

export type AuthState =
	| { isPending: boolean, isAuthenticated: false, isChangingPassword: false, error: undefined }
	| { isPending: false, isAuthenticated: false, isChangingPassword: false, error: any }
	| { isPending: false, isAuthenticated: true, isChangingPassword: false, user: User, token: string, error: undefined }
	| { isPending: boolean, isAuthenticated: false, isChangingPassword: true, error: undefined }


export const nonAuthInitialState: AuthState = { isPending: false, isAuthenticated: false, isChangingPassword: false, error: undefined };

export type AuthActions =
	| { type: 'LOGIN_REQUEST' }
	| { type: 'LOGIN_SUCCESS', payload: { user: User, token: string } }
	| { type: 'LOGIN_FAILURE', payload: { error: any } }
	| { type: 'LOGIN_CHANGE_REQUEST' }
	| { type: 'LOGIN_CHANGE_SUCCESS' }
	| { type: 'LOGIN_CHANGE_FAILURE', payload: { error: any } }
	| { type: 'LOGOUT' }

export const authReducer = (state: AuthState, action: AuthActions): AuthState => {
	switch (action.type) {
		case "LOGIN_REQUEST":
			return {
				isPending: true,
				isAuthenticated: false,
				isChangingPassword: false,
				error: undefined
			};
		case "LOGIN_SUCCESS":
			return {
				isPending: false,
				isAuthenticated: true,
				isChangingPassword: false,
				error: undefined,
				...action.payload
			};
		case "LOGIN_FAILURE":
			return {
				isPending: false,
				isAuthenticated: false,
				isChangingPassword: action.payload.error?.Message === 'PasswordExpired',
				error: action.payload.error
			};
		case "LOGIN_CHANGE_REQUEST":
			return {
				isPending: true,
				isAuthenticated: false,
				isChangingPassword: true,
				error: undefined
			};
		case "LOGIN_CHANGE_SUCCESS":
			return {
				isPending: false,
				isAuthenticated: false,
				isChangingPassword: false,
				error: "PasswordChanged",
			}
		case "LOGIN_CHANGE_FAILURE":
			return {
				isPending: false,
				isAuthenticated: false,
				isChangingPassword: true,
				error: action.payload.error
			};
		case "LOGOUT":
			return nonAuthInitialState;

		default:
			return state;
	}
};




