import { useEffect, useMemo, useState } from 'react';

import { ReleaseNote } from '../newsFeed.models';
import { NewsTableSectionHead } from './NewsTableSectionHead';
import { NewsTableRow, RelnoteRef } from './NewsTableRow';
import { useReadHidePatchService } from '../newsFeed.services';
import { Status } from 'Components/reduxLogger';
import { ViewContext } from './tableCommons';
import { SimpleEntity } from 'Entities/SimpleEntity/simpleEntity.models';
export type { RelnoteRef } from './NewsTableRow';


export type ReReadableReleaseNote = ReleaseNote & { wasRead: boolean };

export type Section = SimpleEntity & { isHidden: boolean, wasHidden: boolean, notes: ReReadableReleaseNote[] };

export function NewsTableSection(props: ViewContext & {
    section: Section,
    order: 'asc' | 'desc',
    orderBy: 'releaseVersion' | false,
    initiallyOpen?: boolean,
    onChanged: (patch: Section) => void,
    onRemoteStatus: (status: Status) => void,
}) {

    const { section, order, orderBy, onChanged, onRemoteStatus } = props;
    const rows = section.notes;

    const readCount = useMemo(() => rows.filter(x => x.isRead).length, [rows]);
    const rowCount = rows.length;
    const isAllRead = useMemo(() => {
        if (rowCount === 0)
            return false;
        else {
            if (readCount > 0 && readCount < rowCount) return undefined;
            else return readCount === rowCount;
        }
    }, [rowCount, readCount]);

    const [remotePatchState, submitRemotePatch] = useReadHidePatchService();
    const { status: remotePatchStatus } = remotePatchState;

    const [updatedSection, setUpdatedSection] = useState<Section | undefined>(undefined);

    /* HANDLE NOTE(S) READ/UNREAD CHANGED */

    const handleNoteUpdates = (notesUpdated: ReReadableReleaseNote[]) => {

        setUpdatedSection({ ...section, notes: notesUpdated });

        submitRemotePatch({
            readNotes: notesUpdated.filter(x => !x.wasRead && x.isRead).map(x => ({ noteId: x.noteId, releaseId: x.releaseId })),
            unReadNotes: notesUpdated.filter(x => x.wasRead && !x.isRead).map(x => ({ noteId: x.noteId, releaseId: x.releaseId })),
        });
        onRemoteStatus(Status.Pending);
    }

    const handleToggleSingleReadStatus = (noteId: string) => {

        const index = rows.findIndex(x => x.noteId === noteId);
        const theNote = rows[index];
        const rowsUpdated = [
            ...rows.slice(0, index),
            { ...theNote, isRead: !theNote.isRead },
            ...rows.slice(index + 1)
        ];
        handleNoteUpdates(rowsUpdated);
    };

    const handleBulkToggleReadStatus = (event: React.ChangeEvent<HTMLInputElement>) => {

        const isRead = event.target.checked;
        const rowsUpdated = rows.map((x) => ({ ...x, isRead: isRead }));
        handleNoteUpdates(rowsUpdated);
    }

    /* HANDLE HIDE-FOREVER CHANGED */

    const handleToggleHideForever = (event: React.ChangeEvent<HTMLInputElement>) => {

        const isChecked = event.target.checked;

        setUpdatedSection({ ...section, isHidden: isChecked });

        submitRemotePatch({
            hideAreas: isChecked && !section.wasHidden ? [section.id] : undefined,
            unHideAreas: !isChecked && section.wasHidden ? [section.id] : undefined
        })
        onRemoteStatus(Status.Pending);
    }

    /* HANDLE REMOTE UPDATE SUCCESS RESULT  */

    useEffect(() => { onRemoteStatus(remotePatchStatus) }, [remotePatchStatus, onRemoteStatus]);

    useEffect(() => {
        if (remotePatchStatus === Status.Success && updatedSection) {
            onChanged(updatedSection);
            setUpdatedSection(undefined);
        }
    }, [remotePatchStatus, updatedSection, onChanged]);


    /* SORT ROWS */
    if (orderBy) {
        rows.sort((a, b) => a[orderBy].localeCompare(b[orderBy]) * ((order === 'desc') ? -1 : 1))
    }

    const [open, setOpen] = useState(!!props.initiallyOpen);

    return <>
        <NewsTableSectionHead
            isRead={isAllRead}
            section={props.section}
            entriesCount={props.section.notes.length}
            onToggleReadAllStatus={handleBulkToggleReadStatus}
            isHideChecked={props.section.isHidden}
            onToggleHideForever={handleToggleHideForever}
            isOpen={open}
            onToggleOpen={setOpen}
            softwareName={props.softwareName}
        />
        {open && rows.map((row, index) => {
            return <NewsTableRow
                key={index}
                index={index}
                data={row}
                isTechUser={props.isTechUser}
                onToggleReadStatus={handleToggleSingleReadStatus} />
        })}
    </>;
}