import { useProcessedRemoteCollection } from 'Components/RemoteEntities/Collection/RemoteCollection';
import { useRemoteEntity } from 'Components/RemoteEntities/Entity/RemoteEntity';
import { useRemoteResourceSubmissionService } from 'Components/RemoteEntities/resource.services';
import { includesIgnoreCase } from 'Components/utils';
import { Macroarea, macroareaDescriptionGetter } from 'Entities/Macroarea/macroarea.service';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


//* COLLECTION by software 

function makeSearchPredicate(filter?: { search?: string, language: string }) {
    if (!filter) {
        return undefined;
    } else {
        const { search, language } = filter;
        const _getDescription = macroareaDescriptionGetter(language);
        return (entity: Macroarea): boolean => {
            return includesIgnoreCase(_getDescription(entity), search);
        }
    }
}

export const useMacroareas = (props: { search?: string, softwareCode?: string }) => {

    const { i18n } = useTranslation();
    const { language } = i18n;
    const { search, softwareCode } = props;

    const filter = useMemo(() => ({ search, language }), [search, language]);

    return useProcessedRemoteCollection<Macroarea, { search?: string, language: string }>({
        endpoint: `baseData/macroareas`,
        isPaginated: false,
        requiresQueryParams: true,
        queryParams: softwareCode ? { softwareCode } : undefined,
        filterParams: filter,
        makeFilterPredicate: makeSearchPredicate,
    });
}


//* ENTITY CRUD

export interface SoftwareMacroarea extends Macroarea {
    softwareCode: string;
}

export const useMacroareaEditService = () => {
    return useRemoteEntity<SoftwareMacroarea>({
        endpoint: `baseData/macroareas`,
        idKey: 'id',
        remoteCreation: false
    });
}

//* SORTING

export function useMacroareaSwappingService(props: { software: string }) {
    return useRemoteResourceSubmissionService<{ idA: number, idB: number }, number[]>({ endpoint: `baseData/macroareas/${props.software}/swap`, method: 'POST' });
}
