
import { useRemoteCollection } from 'Components/RemoteEntities/Collection/RemoteCollection';
import { RemoteResourceQueryApi, useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';



export interface Software {
  code: string,
  description: string,
  userDescription: string,
  family: string
}

//************** COLLECTION WITH SEARCH KEY 
export const useSoftwares = (): RemoteResourceQueryApi<Software[], Software[], {search: string}> => {

  return useRemoteResourceQueryService<Software[], Software[], {search: string}>({
    endpoint: 'baseData/software',
    defaultValue: [],
  });
}

export const useSoftwareDetails = (props: {code: string}) => {
  const [state, fetch] = useSoftwares();
  const { code } = props;
  useEffect(() => { fetch({search: code })}, [code, fetch]);
  return state.data.find(x => x.code === code);
}

//************** PAGINATED COLLECTION WITH SEARCH KEY */
export function useSoftwaresPaginated(props: {
  search?: string,
}) {
  const lang = useTranslation().i18n.language;
  const { search } = props;

  return useRemoteCollection<Software>({
    endpoint: 'baseData/software/search',
    requiresQueryParams: false,
    optionalQueryParams: { search },
  });
}


export const useSoftwareService = () => useRemoteResourceQueryService<Software, null, { code: string }>({
  endpoint: 'baseData/software/:code',
  defaultValue: null
}) 






