import {Credentials} from "./remote.service";
import {useRemote} from "./RemoteProvider";
import {useCancelable} from "../cancelable";
import * as React from "react";
import {useMsal} from "@azure/msal-react";
import {useHistory} from "react-router-dom";

export interface UseSignIn {
    initCredentials?: Credentials, // "Remember Me" credentials from local storage
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
    rememberMe?: boolean, // state for "Remember Me" checkbox
    setRememberMe: (val: boolean) => void,
    isPending: boolean
}

const storageKey = 'credentials';

function getStoredCredentials(): Credentials | undefined {
    let credentials: Credentials | undefined = undefined;
    const text = localStorage.getItem(storageKey);
    if (text) {
        try {
            credentials = JSON.parse(text);
        } catch (err) {
            console.log('Error parsing credentials:', err);
        }
    }
    console.log("Credentials from storage: ", text, credentials);
    return credentials;
}

function storeCredentials(credentials: Credentials) {
    localStorage.setItem(storageKey, JSON.stringify(credentials));
}

function forgetCredentials() {
    localStorage.removeItem(storageKey);
}

export function useSignIn() {

    const [auth, methods] = useRemote();

    const fromStorage = getStoredCredentials();

    const cancelable = useCancelable<boolean>();

    const [rememberMe, setRememberMe] = React.useState<boolean>(fromStorage !== undefined);

    const loginWithFormData = React.useCallback((event: React.FormEvent<HTMLFormElement>, newPassword: boolean) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const credentials: Credentials = {
            username: formData.get('username')?.toString() || '',
            password: formData.get('password')?.toString() || '',
            newPassword: formData.get('newPassword')?.toString() || '',
            newPasswordRepeat: formData.get('newPasswordRepeat')?.toString() || '',
        };
        if (credentials !== undefined) {
            if (!rememberMe) {
                forgetCredentials();
            } else {
                storeCredentials(credentials);
            }
            
            const $response = newPassword
                ? methods.changePassword(credentials)
                : methods.login(credentials, rememberMe);
            
            cancelable($response)
                .catch(() => false)
                .then((isSuccess: boolean) => {
                    if (isSuccess && rememberMe && !newPassword) {
                        storeCredentials(credentials);
                    }
                    return true;
                })
        }
    }, [cancelable, methods, rememberMe]);

    return {
        loginWithFormData,
        rememberMe,
        setRememberMe,
        isPending: auth.isPending,
        initCredentials: fromStorage
    }
}

export function useActiveDirectorySignIn() {
    
    const [auth, methods] = useRemote();
    
    const cancelable = useCancelable<boolean>();

    async function _login(token: string) {
        
        const credentials: Credentials = {activeDirectoryToken: token};
        
        await cancelable(methods.activeDirectoryLogin(credentials))
    }

    return {
        loginWithToken: _login,
        isPending: auth.isPending,
        error: auth.error
    }
}

export function useSignOut() {

    const {instance, accounts} = useMsal();
    const history = useHistory();

    const [, methods] = useRemote();
    const cancelable = useCancelable<void>();

    if (process.env.REACT_APP_USE_AZURE_LOGIN === "true") {
        return () => instance.logout({account: accounts[0]}).catch(console.error).finally(() => history.replace("/logout"));
    } else {
        return () => cancelable(methods.logout());
    }
}

