import {Button, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Software, useSoftwareService} from "../../../../Entities/Software/software.services";
import {SoftwareAutoSelect} from "../../../../Entities/Software/SoftwareAutoSelect";

export const SoftwareLoader = (props: { code?: string | false, onLoaded: (value: Software) => void }) => {

    const [value, setValue] = useState<Software | null>(null);

    const [swState, swFetch] = useSoftwareService();

    const {code, onLoaded} = props;

    useEffect(() => {
        if (code) swFetch({code})
    }, [swFetch, code])

    const {data, status} = swState;

    useEffect(() => {
        if (data) onLoaded(data)
    }, [data, onLoaded]);

    const handleOkClicked = () => {
        if (value) onLoaded(value);
    }

    return <Stack direction='row' alignItems={'center'}>
        <SoftwareAutoSelect value={value} onChange={setValue} sx={{width: 500}}/>
        <Button disabled={!value} onClick={handleOkClicked}>OK</Button>
    </Stack>
}