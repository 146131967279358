//import { SoftwarePartCodes, softwarePartToQueryParam } from "Entities/SoftwareParts/softwareParts.services";
import { ReleaseNotesFeed, FeedQueryParams as FeedQueryParams, useReleaseNotesFeed } from "../newsFeed.services";
import { useCallback } from "react";
import { RemoteResourceQueryApi } from "Components/RemoteEntities/resource.services";
import { VersionRange } from "./VersionRangeSelect";
import { useCustomerPageURLQueryParams } from "../../CustomerPage";
import { CONTENT_TYPES, ContentType } from "Entities/commons";
import { FormKey, formKeyFromQueryParam, formKeyToQueryParam } from "Entities/SoftwareParts/form.services";

export interface RelnotesFilter {

    versionRange: VersionRange,
    customerId?: string,
    macroarea: number[],
    department: string[],
    module: string[],
    form: FormKey[], // => `${moduleCode?}__${formCode}`
    label: string[],
    content?: string,
    todo: string[],
    includeHidden?: boolean,
    includeInactiveUsers?: boolean,
    language: string,
    contentType?: ContentType,
    ergoOne?: boolean
}


export const isFiltersFormBlank = (filter: RelnotesFilter) => (
    !filter.customerId
    && filter.macroarea.length == 0
    && filter.department.length == 0
    && (!filter.content || filter.content.trim().length === 0)
    && filter.form.length == 0
    && filter.label.length == 0
    && filter.todo.length == 0
    //&& filter.ergoOne !== true
)

export const defaultFilter: Partial<RelnotesFilter> = {
    versionRange: { versionFrom: undefined, versionTo: undefined },
    macroarea: [] as number[],
    department: [] as string[],
    module: [] as string[],
    form: [] as FormKey[],
    label: [] as string[],
    todo: [] as string[],
    content: undefined,
    customerId: undefined,
}

export const urlQueryToFilter = (urlParams: URLSearchParams) => {

    const filter: any = {
        versionRange: { versionFrom: undefined, versionTo: undefined },
        macroarea: [] as number[],
        department: [] as string[],
        module: [] as string[],
        form: [] as FormKey[],
        label: [] as string[],
        todo: [],
        language: 'it',
        ergoOne: undefined
    }

    const params = urlParams.entries();
    let param = params.next();
    while (!param.done) {
        const keyValue = param.value;
        switch (keyValue[0].toLowerCase()) {
            case 'rvfrom':
                filter.versionRange.versionFrom = keyValue[1];
                break;
            case 'rvto':
                filter.versionRange.versionTo = keyValue[1];
                break;
            case 'department':
                filter.department.push(keyValue[1]);
                break;
            case 'area':
                const val = Number.parseInt(keyValue[1]);
                if (!Number.isNaN(val))
                    filter.macroarea.push(Number.parseInt(keyValue[1]));
                break;
            case 'module':
                filter.module.push(keyValue[1]);
                break;
            case 'form':
                const f = formKeyFromQueryParam(keyValue[1]);
                //console.log("ModuleForm from URL", keyValue[1], segments, mf);
                filter.form.push(f);
                break;
            case 'includehidden':
                filter.includeHidden = keyValue[1] !== 'false' && keyValue[1] !== '0';
                break;
            case 'includeinactiveusers':
                filter.includeInactiveUsers = keyValue[1] !== 'false' && keyValue[1] !== '0';
                break;
            case 'language':
                filter.language = keyValue[1];
                break;
            case 'contenttype':
                if (CONTENT_TYPES.map(x => x.toLowerCase()).includes(keyValue[1]))
                    filter.contentType = keyValue[1] as ContentType;
                else
                    console.warn("Unsupported value of filter parameter contentType", keyValue[1]);
                break;
            case 'ergoone':
                filter.ergoOne = keyValue[1] !== 'false' && keyValue[1] !== '0';
                break;
            case 'todo':
                filter.todo.push(keyValue[1]);
        }
        param = params.next();
    }
    console.log("FILTER", filter);
    return filter;
}

export const useUpdatedURLSearchString = (filter: RelnotesFilter): string => {

    const query = new URLSearchParams(useCustomerPageURLQueryParams());

    if (filter?.versionRange?.versionFrom) query.set('rvFrom', filter.versionRange.versionFrom);
    if (filter?.versionRange?.versionTo) query.set('rvTo', filter.versionRange.versionTo);
    if (filter?.macroarea) filter.macroarea.forEach(x => query.append('area', x.toString()));
    if (filter?.department) filter.department.forEach(x => query.append('department', x));
    if (filter?.module)
        filter.module.forEach(x => query.append('module', x));
    if (filter?.form)
        filter.form.forEach(f => query.append('form', formKeyToQueryParam(f)))
    if (filter?.content) query.set('content', filter.content);
    if (filter?.includeHidden) query.set('includeHidden', 'true');
    if (filter?.includeInactiveUsers) query.set('includeInactiveUsers', 'true');
    if (filter?.contentType) query.set('contentType', filter.contentType);
    if (filter?.ergoOne) query.set('ergoOne', 'true');
    if (filter?.todo) filter.todo.forEach(x => query.append('todo', x));

    return query.toString();
}

export const filterToApiQueryParams = (filter: RelnotesFilter): FeedQueryParams => {

    return {
        language: filter.language,
        versionFrom: filter.versionRange?.versionFrom,
        versionTo: filter.versionRange?.versionTo,
        macroarea: filter.macroarea.length > 0 ? filter.macroarea : undefined,
        department: filter.department.length > 0 ? filter.department : undefined,
        form: filter.form.map(mf => formKeyToQueryParam(mf)),
        content: filter.content,
        includeHidden: filter.includeHidden,
        includeInactiveUsers: filter.includeInactiveUsers,
        contentType: filter.contentType,
        label: filter.label.length > 0 ? filter.label : undefined,
        todo: filter.todo.length > 0 ? filter.todo : undefined,
        ergoOne: filter.ergoOne
    }
}

export const useFilteredReleaseNotes = (): RemoteResourceQueryApi<ReleaseNotesFeed, ReleaseNotesFeed, RelnotesFilter> => {

    const [_state, _fetch, ...rest] = useReleaseNotesFeed();

    const fetch = useCallback((filter: RelnotesFilter) => _fetch(filterToApiQueryParams(filter)), [_fetch]);

    return [_state, fetch, ...rest];
}


