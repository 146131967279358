import React, {useContext} from "react";
import {Software} from "Entities/Software/software.services";
import {SwFaqsManagerContext} from "./SwFaqsManagerContext";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {SoftwareLoader} from './SoftwareLoader';

export function SelectOtherSoftware(props: {container?: HTMLElement}) {

    const [context, dispatch] = useContext(SwFaqsManagerContext);
    
    const activeSwFamily = context.activeSoftware?.family;

    const [t] = useTranslation();
    
    const handleSoftwareLoaded = (software: Software) => {
        dispatch(['SET_ACTIVE_SOFTWARE', software]);
    }
    
    return <>
        <Typography sx={{flexGrow: 1}}>{t('faqClone.selectTarget')}</Typography>
        
        <SoftwareLoader onLoaded={handleSoftwareLoaded} />
    </>
}