
import {
  RemoteResourceQueryApi, useRemoteResourceQueryService
} from 'Components/RemoteEntities/resource.services';
import { useDescriptionGetter } from 'Entities/DescribedCode/describedCode.models';
import { useCallback, useMemo } from 'react';


export interface Form {
  softwareCode: string,
  moduleCode?: string,
  code: string,
  descriptionDe: string,
  descriptionEn: string,
  descriptionIt: string,
}

export const formFromKey = (code: string, moduleCode: string | undefined, softwareCode: string): Form => {
  return {
      code,
      softwareCode,
      moduleCode,
      descriptionDe: code,
      descriptionIt: code,
      descriptionEn: code
  };
}

export interface FormKey {
  moduleCode?: string,
  formCode: string,
}


export type ModuleId = {softwareCode: string | null, moduleCode: string | null};

export const useSoftwareFormsService = (): RemoteResourceQueryApi<Form[], Form[], {softwareCode: string}> => {

  return useRemoteResourceQueryService<Form[], Form[], {softwareCode: string}>({
    endpoint: 'baseData/software/:softwareCode/forms',
    //endpoint: 'baseData/forms/byModule',
    defaultValue: [],
  });
}

export const useModuleFormsService = (): RemoteResourceQueryApi<Form[], Form[], {softwareCode: string, moduleCode: string}> => {

  return useRemoteResourceQueryService<Form[], Form[], {softwareCode: string, moduleCode: string}>({
    endpoint: 'baseData/software/:softwareCode/modules/:moduleCode/forms',
    //endpoint: 'baseData/forms/byModule',
    defaultValue: [],
  });
}

//* Autocomplete props

const useOptionLabelGetter = () => {

  const getDescription = useDescriptionGetter();

  return useCallback(
    (entity?: Form) => entity ? (entity.code + ' ' + getDescription(entity)) : '',
    [getDescription]
  );
}

const useInputMatcher = () => {

  const getDescription = useDescriptionGetter();

  return useCallback((option: Form | undefined, textInput: string) => {
    if (!option) return textInput === '';
    const input = textInput.toLowerCase();
    if (option.code.toLowerCase().includes(input)) {
      return true;
    }
    const desc = getDescription(option);
    return desc.toLowerCase().includes(input);
  }, [getDescription])
}

const isOptionEqualToValue = (option?: Form, value?: Form) => {
  if (option) return option.softwareCode === value?.softwareCode && option.moduleCode === value?.moduleCode && option.code === value?.code;
  else return value === undefined;
}

const sx = { minWidth: 200, maxWidth: 600 }

export const useFormAutocompleteProps = () =>{

  return {
    sx,
    isOptionEqualToValue,
    getOptionLabel: useOptionLabelGetter()
  };
}

export const useFormByModuleRemoteAutocompleteProps = (props: { softwareCode: string | null, moduleCode: string | null }) => {

  const { softwareCode, moduleCode } = props;

  return {
    ...useFormAutocompleteProps(),
    //isOptionEqualToInput: useInputMatcher(),
    endpoint: 'baseData/software/:softwareCode/modules/:moduleCode/forms',
    query: useMemo(() => ({ softwareCode, moduleCode }), [softwareCode, moduleCode]),
  };
}

export const useFormBySoftwareRemoteAutocompleteProps = (props: { softwareCode: string | null }) => {

  const { softwareCode } = props;

  return {
    ...useFormAutocompleteProps(),
    //isOptionEqualToInput: useInputMatcher(),
    endpoint: 'baseData/software/:softwareCode/forms',
    query: useMemo(() => ({ softwareCode }), [softwareCode]),
  };
}



export const formKeyToQueryParam = (data: FormKey) => {
  return `${(data.moduleCode ?? '')}__${data.formCode}`;
}

export const formKeyFromQueryParam = (param: string): FormKey => {
  const segments = param.split('__');
  if (segments.length === 2) {
      return { moduleCode: segments[0], formCode: segments[1] }
  } else {
      return { formCode: segments[0] };
  } 
}



