import { Status, withLogger } from "Components/reduxLogger";

export interface PagingInfo {
  page?: number,
  pageSize?: number,
  hasPreviousPage?: boolean,
  hasNextPage?: boolean,
  totalPages?: number,
  totalItems?: number,
}
export interface CollectionState<TEntity> extends PagingInfo {
  status: Status,
	isLoading: boolean,
  isSuccess: boolean,
  isFailed: boolean, 
	error: any
	entities: TEntity[],
  isPaginated: boolean
}

export function makeInitialState<TEntity>(): CollectionState<TEntity> {
	return {
    status: Status.Idle,
		isLoading: false,
    isSuccess: false,
    isFailed: false, 
	  error: undefined,
	  entities: [] as TEntity[],
    isPaginated: false
	};
}

export interface CollectionPayload<TEntity> extends PagingInfo {
  entities: TEntity[];
  isPaginated: boolean;
}

export type CollectionActions<TEntity> =
	| { type: 'CLEAR', payload?: undefined }
	| { type: 'LOAD_REQUEST', payload?: undefined }
	| { type: 'LOAD_SUCCESS', payload: CollectionPayload<TEntity> }
	| { type: 'LOAD_FAILURE', payload: { error: unknown } }

export type CollectionReducer<TEntity> = (state: CollectionState<TEntity>, action: CollectionActions<TEntity>) => CollectionState<TEntity>

export function makeCollectionReducer<TEntity>(entityName: string): CollectionReducer<TEntity> {

  if (entityName === '') {
    throw 'Entity name needed to create reducer';
  }
	const reducerName = `CollectionReducer[${entityName}]`;
	const tempObj = {
    [reducerName]: (state: CollectionState<TEntity> = makeInitialState(), action: CollectionActions<TEntity>) => {
      switch (action.type) {
        case 'CLEAR':
          return makeInitialState<TEntity>();
        case 'LOAD_REQUEST':
          return {
            ...state,
            status: Status.Pending,
            isSuccess: false,
            isLoading: true,
            isFailed: false,
            error: undefined,
          };
        case 'LOAD_SUCCESS':
          return {
            status: Status.Success,
            isLoading: false,
            isSuccess: true,
            isFailed: false,
            error: undefined,
            ...action.payload
          };
        case 'LOAD_FAILURE':
          return {
            ...state,
            status: Status.Failed,
            isLoading: false,
            isSuccess: false,
            isFailed: true,
            error: action.payload.error,
          };
        default:
          return state;
      }
    }
  }
	const reducer = tempObj[reducerName];
  return process.env.NODE_ENV === 'development' ? withLogger<CollectionState<TEntity>, CollectionActions<TEntity>>(reducer) : reducer;
  //return reducer;
}