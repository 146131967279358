import {Note, NotesFilter} from "../Notes/note.models";
import {Software} from "Entities/Software/software.services";
import {DescribedCode} from "Entities/DescribedCode/describedCode.models";


export interface ReleaseHead {
  id: number,
  customerId?: string,
  softwareCode: string,
  releaseVersion: string,
  releaseDate?: string,
  statusCode: string,
  priority: number,
}

export interface Release extends ReleaseHead {
  notes: Note[];
}

export interface ReleaseHeadVerbose {
  id: number,
  customerId?: string,
  software: Software,
  status: DescribedCode,
  releaseVersion: string,
  releaseDate?: string,
  priority: number,
}


export type ReleasedSoftware = Software & { numberOfReleases: number };

export function minifyReleaseHead(model: ReleaseHeadVerbose): ReleaseHead {
  const { status, software, ...other} = model;
  const val = { ...other, 
    softwareCode: model.software?.code,
    statusCode: model.status.code,
  };
  return val;
}

export interface HeadFilter {

  // release head props
  software?: Software,
  softwareCode?: string,
  releaseVersion?: string[],
  releaseDateFrom?: string,
  releaseDateTo?: string,
  status?: DescribedCode[],
}

export interface ReleaseFilters {
  headFilter: HeadFilter,
  notesFilter: NotesFilter
}

export const emptyReleaseFilter: ReleaseFilters = {
  headFilter: {},
  notesFilter: {}
}


