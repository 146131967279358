import { useCallback } from "react";
import { StyledButton } from "./StyledInputs"
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Editor } from "@tiptap/react";


export const LinkControl = (props: { editor: Editor | null }) => {

    const { editor } = props;

    const setLink = useCallback(() => {

        if (editor) {
            const previousUrl = editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl);

            if (url === null) {
                return;
            }
            if (url === '') {
                editor.chain().focus().extendMarkRange('link').unsetLink().run();
            }
            // update link
            editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }

    }, [editor]);

    if (!editor) {
        return null;
    }
    return <>
        <StyledButton type="button"
            onClick={setLink}
            isActive={editor.isActive('link')}
        >
            <LinkIcon />
        </StyledButton>

        <StyledButton type="button"
            onClick={() => editor.chain().focus().unsetLink().run()}
            disabled={!editor.isActive('link')}
        >
            <LinkOffIcon />
        </StyledButton>
    </>
}