
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressBackdrop = (props: { open: boolean, color?: string }) => (
  <Backdrop
    sx={{ color: props.color ?? '#fff', zIndex: (theme) => theme.zIndex.drawer + 4 }}
    open={props.open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default ProgressBackdrop;


