
import { Software } from 'Entities/Software/software.services';
import { useSoftwaresPaginated } from 'Entities/Software/software.services';
import { CollectionExplorerWithSearch } from 'Components/RemoteEntities/Collection/CollectionExplorerWithSearch';
import { SoftwaresTable } from './SoftwareTable';


export function SoftwareExplorer(props: {onSelected: (item: Software | false) => void,}) {
  return (
    <CollectionExplorerWithSearch<Software> titleKey={'software'}
      useFilteredCollection={useSoftwaresPaginated}
      context={{}}
      onSelected={props.onSelected}
      hasNewButton={false}
    >{{
        CollectionView: SoftwaresTable,
    }}
    </CollectionExplorerWithSearch>
  )
}



