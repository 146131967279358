import {withArrayActionLogger, withLogger} from "Components/reduxLogger";
import {NoteAbstract, NoteVerbose} from "./note.models";
import {EmptyObject} from "react-hook-form";
import {deepFreeze} from "Components/utils";

interface NewNoteState {
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: EmptyObject, // hack to clear new-note form when a new note has been saved
    abstract: NoteAbstract | undefined,
    expanded: boolean,
    scrollY?: number
    newReleaseId?: number
}

const makeNewNoteSlot = (): NewNoteState => ({
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: {},
    abstract: undefined,
    expanded: false,
});

const makeClonedNoteSlot = (abstract: NoteAbstract, cloneToReleaseId?: number): NewNoteState => ({
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: {},
    abstract: JSON.parse(JSON.stringify(abstract)), // deep copy
    expanded: true,
    newReleaseId: cloneToReleaseId
});

export interface NoteViewState {
    show: boolean,
    abstract: NoteAbstract,
    expanded: boolean,
    details?: NoteVerbose,
    scrollY?: number
}

export type ExistingNoteState = NoteViewState & {
    id: number,
    isEditing: boolean,
}
export type NoteState = ExistingNoteState | NewNoteState;
export const getAbstracts = (state: NoteState[]) => state.filter(item => !isNew(item)).map(item => item.abstract) as NoteAbstract[];

export function isNew(item: NoteState): item is NewNoteState {
    return ((item as NewNoteState).id === 0);
}

export const getCloneSetting = (state: NoteState) => {
    if (isNew(state) && !!state.abstract) return {
        isClone: true,
        originReleaseId: state.abstract?.releaseId,
        isAcrossReleases: !!state.newReleaseId && state.abstract.releaseId != state.newReleaseId
    }
    else return {
        isClone: false,
    }
}

export type NotesCollectionAction =
    | ['INIT', { notes: NoteAbstract[], cloneModel?: NoteAbstract, cloneDestinationReleaseId?: number }]
    | ['OPEN', { id: number, scrollY?: number }]
    | ['CLONE', number]
    | ['CLOSE', number]
    | ['FILTER', number[]]
    | ['REORDER', number[]]
    | ['SAVE_NEW', NoteViewState]
    | ['UPDATE', { id: number, patch: Partial<NoteViewState> }]
    | ['DELETE', number]

const _itemsReducer = (state: NoteState[], action: NotesCollectionAction): NoteState[] => {

    switch (action[0]) {

        case 'INIT': {

            let initialClonedItem: NoteState | undefined = undefined;
            if (state.length > 0 && getCloneSetting(state[0]).isAcrossReleases) {
                initialClonedItem = state[0];
            } else if (action[1].cloneModel)
                initialClonedItem = makeClonedNoteSlot(action[1].cloneModel, action[1].cloneDestinationReleaseId);

            const newItems: NoteState[] = (action[1].notes.length > 0 || initialClonedItem)
                ? action[1].notes.map(abstract => deepFreeze({
                    id: abstract.id,
                    show: true,
                    abstract: Object.freeze(abstract),
                    expanded: false,
                    isEditing: false
                })) : [
                    makeNewNoteSlot()
                ];
            return !!initialClonedItem ? [initialClonedItem, ...newItems] : newItems;
        }

        case 'FILTER': {
            // sets show = false for items that are not in the given IDs array;
            // excepts for the new item (id === 0), which must always have show=true
            return state.map(item => isNew(item) ? item : ({...item, show: action[1].includes(item.id)}));
        }

        case 'REORDER': {  // re-arranges items according to the given IDs array            
            const reorderedIds = action[1];

            const newState: NoteState[] = [];

            reorderedIds.forEach(id => {
                const note = state.find(x => x.id === id);
                if (note) {
                    newState.push(note);
                }
            });

            if (newState.length !== state.length) {
                console.error("Missing IDs in collection re-sorting");
                return state;
            }
            return newState;
        }

        case 'OPEN': {  // sets editing mode on for given id
            const {id, scrollY} = action[1];
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                if (id === 0) {

                    return [makeNewNoteSlot(), ...state];

                } else {
                    console.warn(`Opening Item with id ${id} for editing: item not found`);
                }

            } else { // item found 

                if (id !== 0) { // is existing item

                    state[pos] = {...state[pos], scrollY, isEditing: true};
                    return [...state];

                } // else no change in state because new item has always isEditing === true
            }
            return state;
        }

        case 'CLONE': {
            const id = action[1];
            const original = state.find(x => x.id === id);
            if (!original) {
                console.warn(`Cloning Item with id ${id} for editing: item not found`);
            } else if (isNew(original)) {
                console.warn(`Cannot clone new note. Use '[OPEN 0]' instead`);
                return state;
            } else {
                return [makeClonedNoteSlot(original.abstract), ...state];
            }
            return state;
        }

        case 'CLOSE': {  // sets editing mode off for given id
            const id = action[1];
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.warn(`Closing Item with id ${id}: item not found`);
                return state;

            } else { // item found (existing or new, doesn't matter)

                const item = state[pos];
                const newState = [...state];
                if (!isNew(item)) {
                    newState[pos] = {...item, isEditing: false, scrollY: undefined};
                } else {
                    newState.splice(pos, 1);
                }
                return newState;
            }
        }

        case 'DELETE': {  // discards item
            const id = action[1];

            const pos = state.findIndex(x => x.id === id);

            if (pos === -1) {

                console.warn(`Deleting Item with id ${id}: slot not found`);
                return state;

            } else {

                const newState = [...state];
                newState.splice(pos, 1);
                return newState;
            }
        }


        case 'SAVE_NEW': {  // converts new item (id === 0, no abstract) into a legit item with id, abstract, details
            const data = action[1];
            const newId = data.abstract.id;

            const pos = state.findIndex(x => x.id === 0);
            if (pos === -1) {

                console.error("Creating Item: new-item slot not found");
                return state;

            } else {

                const newState = [...state];
                newState[pos] = {id: newId, ...data, isEditing: false}
                return newState;
            }
        }

        case 'UPDATE': {  // updates existing item 
            const {id, patch} = action[1];

            if (id === 0) {
                console.error(`UPDATE not allowed for new item, use 'CREATE' instead`);
            }

            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.error(`Updating Item with id ${id}: item not found`);
                return state;

            } else {

                const item = state[pos] as ExistingNoteState;
                const newState = [...state];
                newState[pos] = {...item, ...patch};
                return newState;
            }
        }

        default:
            return state;
    }
};


interface NotesManagerState {
    items: NoteState[],
    scrollY?: number
}

const _reducer = (
    state: NotesManagerState,
    action: NotesCollectionAction
): NotesManagerState => {

    if (action[0] === 'OPEN' || action[0] === 'CLONE') {
        return {
            scrollY: (action[0] == 'CLONE' || action[1].id == 0) ? 0 : undefined,
            items: _itemsReducer(state.items, action)
        }
    }
    if (action[0] === 'CLOSE') {
        const item = state.items.find(x => x.id === action[1]);
        if (item) {
            return {
                scrollY: item.scrollY,
                items: _itemsReducer(state.items, action)
            }
        }
    }
    return {...state, items: _itemsReducer(state.items, action)};
}

export const notesManagerReducer = withArrayActionLogger(_reducer, 'NotesManagerState');

