import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Paper} from '@mui/material';
import {IliasAuthProvider} from "Pages/IliasAuthProvider";
import {Faq} from '../faqFeed.models';
import {FaqSectionView, Section} from './FaqSectionView';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import {Status} from 'Components/reduxLogger';
import {SimpleEntity} from 'Entities/SimpleEntity/simpleEntity.models';


const _makeSections = (faqs: Faq[], language: string): Section[] => {
    return faqs
        .reduce((sections, faq) => {
            const areaId = faq.macroarea?.id ?? 'no-area';
            let section = sections.find(s => s.id === areaId);
            if (!section) {
                const _area = faq.macroarea ?? unspecifiedArea;
                section = {..._area, faqs: []/*, isHidden: false, wasHidden: false */};
                sections.push(section);
            }
            section.faqs.push(faq);
            return sections;
        }, [] as Section[])
        .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0));
}

const unspecifiedArea: SimpleEntity = {
    id: 0,
    sortIndex: 0,
    descriptionIt: 'Altro',
    descriptionEn: 'Other',
    descriptionDe: 'Other'
}


export default function FaqCollectionView(props: {
    faqs: Faq[],
    onStatusChanged: () => void,
    expandSections: boolean,
    softwareName: string,
    softwareCode: string
}) {
    const {faqs, onStatusChanged, expandSections, ...context} = props;

    const {language} = useTranslation()[1];

    //* DEFINE SECTIONS i.e. grouping by Macroarea

    const [sections, setSections] = useState<Section[]>(_makeSections([], language));

    useEffect(() => {
        setSections(_makeSections(faqs, language))
    }, [faqs, language]);

    console.log("SECTIONS", sections);
    const handleSectionStatusChanged = (section: Section) => {
        //console.log("SECTION CHANGED", section);
        setSections(_sections => {
            const index = _sections.findIndex(x => x.id === section.id);
            if (index < 0)
                return _sections;
            else
                return [..._sections.slice(0, index), section, ..._sections.slice(index + 1)];
        });
    };

    const [remoteStatus, setRemoteStatus] = useState(Status.Idle);

    return <>
        <IliasAuthProvider>
            <Paper sx={{width: "100%", maxHeight: "100%", mb: 2}} component={'div'} id="Faq-collection-container">
                {sections.map((section) => (
                    <FaqSectionView
                        key={section.id}
                        section={section}
                        expanded={props.expandSections}
                        {...context}                    />))}
            </Paper>
        </IliasAuthProvider>
        <ProgressBackdrop open={remoteStatus === Status.Pending}/>
    </>;
}
