import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { useEffect } from "react";


export interface SoftwareSettings {
    code: string,
    userDescription?: string,
    macroareaCodes: string[],
    showFormModule: boolean,
}

export interface SoftwareSettingsPatch {
    userDescription?: string,
    macroareaCodes: string[],
    showFormModule: boolean,
}

export const useSoftwareFirstVersion = (props: { softwareCode: string }) => {

    const [state, fetch] = useRemoteResourceQueryService<
        string | undefined,
        string | undefined,
        { softwareCode: string }
    >({ endpoint: 'baseData/software/:softwareCode/firstRelnoteVersion', defaultValue: undefined });

    const { softwareCode } = props;

    useEffect(() => {
        fetch({softwareCode: softwareCode})
    }, [fetch, softwareCode]);

    return state;
}