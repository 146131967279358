
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';

import { ReleaseNote } from '../newsFeed.models';
import NoteView from './NoteView';


export interface RelnoteRef {
    releaseId: string,
    noteId: string,
}


export function NewsTableRow(props: {
    index: number,
    data: ReleaseNote,
    isTechUser: boolean
    onToggleReadStatus: (noteId: string) => void
}) {

    const { index, data: row } = props;
    const isSelected = props.data.isRead;

    return (
        <MuiTableRow
            hover
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={row.noteId}
            selected={isSelected}
        >
            <TableCell padding="checkbox" sx={{verticalAlign: 'top', pt: 4}}>
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    onClick={() => props.onToggleReadStatus(props.data.noteId)}
                    inputProps={{
                        'aria-labelledby': `Note-read-checkbox-${index}`,
                    }}
                />
            </TableCell>

            <TableCell id={`Note-content-${index}`} padding="normal">
                <NoteView {...row} isTechUser={props.isTechUser}/>
            </TableCell>

            <TableCell id={`Release-version-${index}`}
                sx={{verticalAlign: 'top', pt: 5, fontWeight: 'bold'}}
                align="center"
            >
                {row.releaseVersion}
            </TableCell>

            <TableCell>{null}</TableCell>
        </MuiTableRow>
    );
}