import '../content.scss';
import './editor.scss';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//import ImageResize from 'tiptap-imagresize';

import { Box } from '@mui/system';
import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { FontSize } from './Extensions/FontSizeExtension';
import { Indent } from './Extensions/IndentExtension';
import { Toolbar } from './Menu/Toolbar';
//import { ImageResize } from './Extensions/ImageResize/ImageResizeExtension';
//import { ImageResize } from './Extensions/ImageResize2/ImageResize2';
//import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const TipTapEditor = (props: {
  id: string,
  value?: string | null,
  onChange?: (value?: string) => void,
}) => {

  //console.log("TIPTAP EDITOR", props.value);

  const [t] = useTranslation();

  const editor = useEditor({
    content: props.value,
    parseOptions: {
      preserveWhitespace: true
    },
    extensions: [
      StarterKit.configure({}),
      Link.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      Image.configure({
        allowBase64: true,
        inline: true
      }),
      // ImageResize.configure({
      //   allowBase64: true,
      //   inline: false,
      //   resizeIcon: <ZoomOutMapIcon />//<>ResizeMe</>
      // }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle,
      FontFamily,
      FontSize,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: t('textHint'),
      }),
      Indent,
      Highlight.configure({ multicolor: true })
    ]
  });

  let { value } = props;
  if (value?.length === 0) { value = null; }

  // useEffect(() => {
  //   if (editor && value) {
  //     editor.commands.setContent(value)
  //   }
  // }, [value])

  const { onChange } = props;
  const handleBlur = useCallback(() => {
    onChange && onChange(editor?.getHTML());
  }, [onChange, editor]);



  return (
    <Box id={`TiptapEditor-${props.id}`} width={1} height={1} onBlur={handleBlur}  >
      {/* <link rel="stylesheet" type="text/css" href={props.stylePath} /> */}
      {editor && <>
        <div id='tiptap-sticky-toolbar'
          style={{position: 'sticky', top: 0, zIndex: 1}} 
        >
          <Toolbar editor={editor}/>
        </div>
        <Box minHeight={'200px'} width={1} mx={0} id={`TipTapContent-${props.id}`} className='EditorContentWrapper'>
          {/* <ContextMenu editor={editor} /> */}
          <EditorContent editor={editor} />
        </Box>  
      </>}
    </Box>
  )
}


export default TipTapEditor;