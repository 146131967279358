import { includesIgnoreCase } from "Components/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface SimpleEntity {
  id: number;
  sortIndex: number;
  descriptionDe: string;
  descriptionEn: string;
  descriptionIt: string;
}

export const emptySimpleEntity = {
  id: 0,
  sortIndex: 0,
  descriptionDe: undefined,
  descriptionEn: undefined,
  descriptionIt: undefined,
};


export function getIdAndDescription(lang: string) {
  return (obj?: SimpleEntity) => {
    if (obj) return `${obj.id} - ${obj[('description' + lang[0].toUpperCase() + lang[1]) as any as keyof(SimpleEntity)]}`;
    else return '';
  }
}

export function descriptionGetter(lang: string) {
  return (obj?: SimpleEntity) => {
    if (obj) {
      const key = `description${lang[0].toUpperCase()}${lang[1]}`;
      const result = obj[key as any as keyof(SimpleEntity)].toString();
      if (result?.length > 0) 
        return result; //obj.sortIndex.toString() + ' ' + result;
      else
        return obj.id.toString(); //obj.sortIndex.toString() + ` (${obj.id.toString()})`;
    }
    else return '';
  }
}

export function useDescriptionGetter() {
  
  const { i18n } = useTranslation();
  const { language } = i18n;
//  console.log("language", language);
  return useMemo(() => descriptionGetter(language), [language]);
}

export const useOptionLabelGetter = () => {
  const getDescription = useDescriptionGetter();
  return (entity?: SimpleEntity) => (entity ? getDescription(entity) : '');
}


export interface SimpleEntityFilter {
  id?: string;
  description?: string;
}

export const makeFilterPredicate = (filter?: SimpleEntityFilter & { language: string }) => {
  if (!filter) {
    return undefined;
  } else {
    const f = filter as SimpleEntityFilter;
    const _getDescription = descriptionGetter(filter.language);
    return (entity: SimpleEntity): boolean => {
      return (!f.id || includesIgnoreCase(entity.id.toString(), f.id)) && (!f.description || includesIgnoreCase(_getDescription(entity), f.description));
    };
  }
};

export const isEqual = (a: SimpleEntity, b: SimpleEntity) => a?.id === b?.id;

export const dummySimpleEntityFromId = (id: number): SimpleEntity => {
  const desc = 'id:' + id.toString();
   return {
      id,
      sortIndex: 0,
      descriptionDe: desc,
      descriptionIt: desc,
      descriptionEn: desc
   };
}
