import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withArrayActionLogger } from 'Components/reduxLogger';


/**
 * Global guard service. Provides a method to execute a function
 * only after the user has confirmed in a confirmation dialog. 
 *
 * Typical use case: warn before navigating away from a form that has unsaved changes, example:
 * 
 * function MyFormComponent {
 * 
 *   const form = useSomeForm();
 *   const {isDirty} = form.state;
 *   const [raiseGuard, removeGuard] = useGuard();
 *   useEffect(() => {
 *      if (isDirty) {
 *        raiseGuard('form1'); // guardedExec (see below) will open a confirmation dialog before executing its argument
 *      } else {
 *        removeGuard('form1');
 *      }
 *   }, [isDirty, raiseGuard, removeGuard]}
 *   ...
 * } // end MyFormComponent
 * 
 * 
 * function AppMenuItem(props: {path: string}) {
 *  const history = useHistory(); // from 'react-router-dom'
 *  const guardedExec = useGuardedExec()
 *  return <Button onClick={() => guardedExec(() => history.push(props.path)}>Go somewhere</Button>
 * }  
 * 
 * function App() {
 *   return (
 *    <Router>
 *      <GuardProvider>
 *        <AppMenu>
 *          <MenuItem path="my/form">Some form</MenuItem>  
 *          <MenuItem path="my/other/form">Another form</MenuItem>  
 *        </AppMenu>
 *        <MainContent>
 *          <Route path='my/form' render=MyFormComponent/>
 *        </MainContent>
 *      </GuardProvider>
 *    </Router> 
 *   )
 * }
 */


function ConfirmationDialog(props: {
  open: boolean;
  onConfirmed: () => void;
  onCanceled: () => void;
}) {

  const { t } = useTranslation();
  return (
    <Dialog id="guard-service-dialog"
      open={props.open}
      onClose={props.onCanceled}
      aria-labelledby="dialog.confirmation.title"
      aria-describedby="dialog.confirmation.content"
    >
      <DialogTitle id="guard-service-dialog-title">
        {t('guardService.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="guard-service-dialog-content">{t('guardService.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onConfirmed} autoFocus>{t('continue')}</Button>
        <Button onClick={props.onCanceled}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}


interface GuardState {
  activeIds: string[],
  pendingAction?: () => void,
}
const defaultGuardState: GuardState = {
  activeIds: [],
  pendingAction: undefined,
}
type GuardActionTypes = 'RAISE' | 'REMOVE' | 'EXEC' | 'CANCELED' | 'CONFIRMED';
type GuardActions = ['RAISE', string] | ['REMOVE', string] | ['EXEC', { action: () => void }] | ['CANCELED'] | ['CONFIRMED'];

let guardReducer = (state: GuardState, action: GuardActions): GuardState => {
  switch (action[0]) {

    case 'RAISE': {
      const activeIds = [...state.activeIds];
      if (!state.activeIds.includes(action[1])) {
        activeIds.push(action[1]);
        console.log("Removing guard", action[1]);
        return { ...state, activeIds };
      }
      else return state;
    }

    case 'REMOVE': {
      const activeIds = [...state.activeIds];
      const foundIndex = state.activeIds.indexOf(action[1]);
      if (foundIndex > -1) {
        console.log("Removing guard", action[1]);
        activeIds.splice(foundIndex, 1);
        return { ...state, activeIds };
      }
      else return state;
    }

    case 'EXEC':
      if (state.pendingAction === undefined) {
        return {
          ...state,
          pendingAction: action[1].action
        };
      }
      else return state;

    case 'CANCELED':
      if (state.pendingAction !== undefined) {
        return {
          ...state,
          pendingAction: undefined,
        }
      }
      else return state;

    case 'CONFIRMED':
      return {
        ...state,
        //activeIds: [],
        pendingAction: undefined,
      }
  }
}
if (process.env.NODE_ENV === 'development' && false) {
  guardReducer = withArrayActionLogger<GuardState, GuardActionTypes, GuardActions>(guardReducer);
}


const dummyGuardMethods = {
  raiseGuard: () => {/**/ },
  removeGuard: () => {/**/ },
  guardedExec: (action: () => void) => action()
};

const GuardServiceContext = React.createContext<{
  raiseGuard: (id: string) => void,
  removeGuard: (id: string) => void,
  guardedExec: (action: () => void) => void
}>(dummyGuardMethods);



export function GuardServiceProvider(props: { children: React.ReactNode }) {

  const [state, dispatch] = React.useReducer(guardReducer, defaultGuardState);
  const isGuardRaised = state.activeIds.length > 0;

  console.log("GUARD SERVICE raised?", isGuardRaised, state);

  const raiseGuard = React.useCallback((id: string) => {
    dispatch(['RAISE', id]);
  }, [dispatch]);

  const removeGuard = React.useCallback((id: string) => {
    dispatch(['REMOVE', id]);
  }, [dispatch]);

  const guardedExec = React.useCallback((action: () => void) => {
    if (isGuardRaised) {
      dispatch(['EXEC', { action }]);
    } else {
      action();
    }
  }, [dispatch, isGuardRaised]);

  const { pendingAction } = state;

  const handleDialogCancel = React.useCallback(() => {
    dispatch(['CANCELED']);
  }, []);


  const handleDialogConfirmed = React.useCallback(() => {

    dispatch(['CONFIRMED']);

    // execute only after state has been updated. Otherwise, if onConfirmed() calls guardedExec then the confirmation dialog will open again even though the guard is down.
    setTimeout(() => {
      pendingAction && pendingAction()
    }, 0);

  }, [pendingAction]);


  const context = useMemo(() => ({ raiseGuard, removeGuard, guardedExec }), [raiseGuard, removeGuard, guardedExec]);

  return (
    <GuardServiceContext.Provider value={context}>
      {props.children}
      <ConfirmationDialog
        open={!!pendingAction}
        onCanceled={handleDialogCancel}
        onConfirmed={handleDialogConfirmed}
      />
    </GuardServiceContext.Provider>
  );
}

export function useGuardedExec(): (action: () => void) => void {
  return useContext(GuardServiceContext).guardedExec;
}

export function useGuard(): [(id: string) => void, (id: string) => void] {
  const context = useContext(GuardServiceContext);
  return [context.raiseGuard, context.removeGuard];
}
