import { State, useRemoteResourceSubmissionService } from "Components/RemoteEntities/resource.services";
import { useCallback } from "react";
import { EmptyObject } from "react-hook-form";

export const useIOSUpdateService = () => {
    const [state, _submit] = useRemoteResourceSubmissionService<EmptyObject, boolean>({
        endpoint: 'ios/pull/all', 
        method:'POST'
    });
    const submit = useCallback(() => { _submit({}); }, [_submit]);
    return [state, submit] as [State<boolean, null>, ()=>void] 
}
