import { Autocomplete, AutocompleteRenderInputParams, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { StyledSelect, StyledTextField, StyledAutocomplete } from "./StyledInputs";


const _families = ['Verdana', 'Arial', 'Helvetica', 'Roboto', 'sans-serif', 'Courier', 'Lucida Console', 'monospace', 'Times', 'serif', 'Comic Sans MS, Comic Sans', 'cursive'];


export function FontFamilyControl(props: { editor: Editor }) {

    const { editor } = props;

    const currentValue = _families.find((family) => editor.isActive('textStyle', { fontFamily: family })) ?? null;

    //console.log("currentValue", currentValue);

    const handleChange = useCallback((evt, value) => {
        evt.preventDefault();
        evt.stopPropagation();
        editor.view.focus();
        //const { from, to } = editor.state.selection;
        if (value) {
            editor.commands.setFontFamily(value);
            //editor.commands.setTextSelection({ from, to });
        } else {
            editor.chain().focus().setFontFamily(_families[0]).run();
        }
    }, [editor]);

    return (
        <StyledAutocomplete size='small'
            value={currentValue}
            onChange={handleChange}
            options={_families}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <StyledTextField {...params} size='small' sx={{ minWidth: 200 }} />)
            } />
    )
}


// Define a range of font-sizes in px:

const _sizes = [''];
for (let i = 6; i < 33; i++) {
    _sizes.push(i.toString() + 'px');
}

export function FontSizeControl(props: { editor: Editor }) {

    const { editor } = props;

    const currentValue = _sizes.find((val) => editor.isActive('textStyle', { fontSize: val })) ?? '';

    const handleChange = useCallback((evt, value) => {
        evt.preventDefault();
        if (value) {
            editor.chain().setFontSize(value).run();
        } else {
            editor.commands.unsetFontSize();
        }
    }, [editor]);

    return (
        <StyledAutocomplete size='small'
            value={currentValue}
            onChange={handleChange}
            options={_sizes}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <StyledTextField {...params} size='small' sx={{ width: 120 }} />)
            } />
    )
}