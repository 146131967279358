import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Button, Dialog, DialogContent, IconButton, Stack} from "@mui/material";
import {NoteAbstract} from "./note.models";
import {Dispatch, useContext, useRef, useState} from "react";
import {styled} from "@mui/system";
import {useTranslation} from "react-i18next";
import {NotesCollectionAction} from "./notesManagerState";
import {ReleasesManagerContext} from "../Release/ReleasesManagerContext";

import {SelectOtherRelease} from "../Release/SelectOtherRelease";

export const NoteCloneDialog = (props: {
    dispatch: Dispatch<NotesCollectionAction>,
    abstract: NoteAbstract,
}) => {

    const [open, setOpen] = useState(false);

    const [t] = useTranslation();

    return <>
        <IconButton onClick={() => setOpen(true)}><ContentCopyIcon/></IconButton>

        <Dialog open={open} onClose={() => setOpen(false)} keepMounted={false}
                sx={{'& .MuiPaper-root-MuiDialog-paper': {maxWidth: 'auto'}}}>
            <NoteCloneDialogContent {...props} onClose={() => setOpen(false)}/>
        </Dialog>
    </>
}


const NoteCloneDialogContent = (props: {
    dispatch: Dispatch<NotesCollectionAction>,
    abstract: NoteAbstract,
    onClose: () => void
}) => {

    const {abstract, dispatch, onClose} = props;

    const [openReleaseSelector, setOpenReleaseSelector] = useState(false);

    const [, shareDispatch] = useContext(ReleasesManagerContext);

    const handleCloneIntoSameRelease = () => {
        dispatch(['CLONE', abstract.id])
        props.onClose();
    };

    const handleCloneIntoOtherRelease = () => {
        shareDispatch(['SET_NOTE_MODEL', abstract]);
        setOpenReleaseSelector(true);
    }
    
    const handleAbortCloningToOtherRelease = () => {
        shareDispatch(['SET_NOTE_MODEL', undefined]);
        props.onClose();
    }

    const [t] = useTranslation();

    const myRef = useRef<HTMLElement>();

    return <DialogContent ref={myRef}>
        {!openReleaseSelector ? <>

            <p>{t('noteClone.question')}</p>

            <Stack direction={'column'}>
                <StyledButton variant='contained' onClick={handleCloneIntoSameRelease}>
                    {t('noteClone.thisRelease')}
                </StyledButton>
                <StyledButton variant='contained' onClick={handleCloneIntoOtherRelease}>
                    {t('noteClone.otherRelease')}
                </StyledButton>
                <StyledButton variant='outlined' onClick={onClose}>
                    {t('cancel')}
                </StyledButton>
            </Stack>

        </> : <>
            <SelectOtherRelease container={myRef.current}/>
            <Stack>
                <StyledButton variant='outlined' onClick={handleAbortCloningToOtherRelease}>
                    {t('cancel')}
                </StyledButton>
            </Stack>
        </>}
    </DialogContent>
}

const StyledButton = styled(Button)(() => ({
    margin: '12px 6px',
    textTransform: 'none'
})); 

