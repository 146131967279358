import { ErrorBoundary } from 'Components/ErrorBoundary';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
    Button, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, IconButton, Toolbar
} from '@mui/material';
import { styled, SxProps, Theme } from '@mui/material/styles';

import { NavMenu } from './Navigation/NavMenu';

export const drawerWidth = 220; //240;
const drawerClosedWidth = () => 0; //theme.spacing(7);

const MuiDrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-root': {

    },
    '& .MuiDrawer-paper': {
      position: 'static',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      width: drawerWidth,
      minHeight: '100vh',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: theme.palette.background.default,//theme.palette.grey[100],
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: drawerClosedWidth(),
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }),
    },
  }),
);


export interface DrawerProps extends MuiDrawerProps {
  open: boolean,
  toggleDrawer: () => void,
  onBrandClicked?: () => void,
  sx?: SxProps<Theme>,
  brand: any,
  brandSx?: SxProps<Theme>
}

export function Drawer(props: DrawerProps) {

  const { open, id } = props;

  return <ErrorBoundary name='Drawer'>
    <MuiDrawerStyled variant='permanent' id={id} open={open} sx={props.sx}>

      <Toolbar id='drawer-top-bar'
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          px: [1],
          backgroundImage: (props.brand && props.open) ? `url(${props.brand})` : 'none',
          mb: 6,
          ...props.brandSx
        }}
      >
        {props.open && (
          <>
            {props.onBrandClicked && <Button onClick={props.onBrandClicked} sx={{ m: 0, flexGrow: 1, height: '100%' }} />}

            <IconButton onClick={props.toggleDrawer} sx={{ minHeight: '64px' }}>
              <ChevronLeftIcon />
            </IconButton>
          </>)}
      </Toolbar>

      <NavMenu />

    </MuiDrawerStyled>
  </ErrorBoundary>
}





