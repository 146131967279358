import { HookedAutocomplete, HookedAutocompleteOnBlurRemote, HookedTextField } from 'Components/HookedForm/inputControls';
import { ItemEditProps, ItemsWrapperProps } from 'Components/RemoteEntities/Collection/CollectionEditController';
import { CollectionManagerWithSearch } from 'Components/RemoteEntities/Collection/CollectionManagerWithSearch';
import { useEntityEditorApi } from 'Components/RemoteEntities/Entity/useEntityEditorApi';
import { NonEditableField } from 'Components/FormUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { emptyDescribedCode } from 'Entities/DescribedCode/describedCode.models';
import {
  makeDescribedCodeService, makeDescribedCodesSearchCollection
} from 'Entities/DescribedCode/describedCode.services';
import { StyledCell } from 'Entities/DescribedCode/DescribedCodesManager/Styled';
import {Department, endpoint} from 'Entities/Department/department.services';
import { EntityFormCustom } from 'Components/HookedForm/EntityFormCustom';
import { EntityDeleteDialog } from 'Components/RemoteEntities/Entity/EntityDeleteDialog';



const useCollection = makeDescribedCodesSearchCollection<Department>({ endpoint });


export function DepartmentsManager() {

  return <CollectionManagerWithSearch<Department>
    collectionId={"Departments"}
    titleKey={'Menu.baseData.departments'}
    useCollection={useCollection}
    canAdd={true}
    context={{}} 
  >{{
    ItemsWrapper: Wrapper,
    ItemView: DepartmentView,
    ItemEditor: DepartmentEditor
  }}</CollectionManagerWithSearch>
}


import { AddBar } from 'Components/RemoteEntities/Collection/CollectionUtils';
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { Status } from 'Components/reduxLogger';
import { RemoteEntityResultHandler } from 'Components/RemoteEntities/Entity/RemoteEntityResultHandler';

function Wrapper(props: ItemsWrapperProps) {
  const { t } = useTranslation();

  return <>
    <TableContainer sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <Table aria-Department="described-codes-table" size='medium'>
        <TableHead>
          <TableRow>
            <StyledCell>{t('code')}</StyledCell>
            <StyledCell>{t('descriptionDe')}</StyledCell>
            <StyledCell>{t('descriptionIt')}</StyledCell>
            <StyledCell>{t('descriptionEn')}</StyledCell>
            <StyledCell>{'IOS Role'}</StyledCell>
            <StyledCell>{null}</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
    <AddBar onAddButtonClick={props.onAddItemClick}/>
  </>;
}

function DepartmentView(props: {
  entity: Department,
  onEditClick: () => void,
  onDeleteClick: () => void
}) {

  const { entity } = props;
  return (
    <TableRow>
      <StyledCell sx={{ pl: 4 }}>
        <NonEditableField value={entity.code} plain/>
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionDe} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionIt} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.descriptionEn} plain />
      </StyledCell>
      <StyledCell>
        <NonEditableField value={entity.iosRole} plain />
      </StyledCell>
      <StyledCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={props.onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={props.onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </StyledCell>
    </TableRow>
  );
}


type DepartmentEditorProps = ItemEditProps<Department>;

function DepartmentEditor(props: DepartmentEditorProps) {

  const { entity } = props;

  const useEntityService = useMemo(() => makeDescribedCodeService<Department>({ endpoint }), []);

  const editorApi = useEntityEditorApi<Department>({
    editorId: endpoint,
    useRemoteEntity: useEntityService,
    idKey: 'code',
    model: entity ?? false,
    proposeDelete: props.doDelete,
    asNew: !props.entity,
    context: {}
  });

  const { entityState } = editorApi;

  const [iosRolesState, fetchIosRoles] = useRemoteResourceQueryService<string[]>({endpoint: 'baseData/departments/roles', defaultValue: []});
  const { data: iosRoles, status: iosRolesStatus } = iosRolesState;
  
  return <>
    <EntityFormCustom<Department>
      name={editorApi.editorId}
      defaultValues={entity || emptyDescribedCode}
      context={{}}
      onSave={editorApi.doSave}
      onDelete={editorApi.doDelete}
      onCancel={props.onExit}
    >{
        ({ control, formState, onCancel, onSave }) => (
          <TableRow>
            <StyledCell>
              {entity?.code
                ? <NonEditableField value={entity.code} plain />
                : <HookedTextField name={'code'} control={control} rules={{ required: true }} />}
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionDe'} control={control} rules={{ required: true }} />
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionIt'} control={control} rules={{ required: true }} />
            </StyledCell>
            <StyledCell>
              <HookedTextField name={'descriptionEn'} control={control} />
            </StyledCell>
            <StyledCell>
              <HookedAutocomplete name={'iosRole'} control={control} rules={{ required: true }} 
                options={iosRoles} 
                loading={iosRolesStatus === Status.Pending} 
                onOpen={() => {if (iosRolesStatus === Status.Idle) fetchIosRoles({}); }}
                getOptionLabel={(option: string) => option}
                isOptionEqualToValue={(option, value) => {
                  console.log('try match', option, value);
                  return option.toLowerCase() === value.toLowerCase();
                }}
                />
            </StyledCell>
            <StyledCell sx={{ textAlign: 'right' }}>
              <IconButton onClick={onSave} disabled={!formState.isDirty}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </StyledCell>
          </TableRow>
        )}
    </EntityFormCustom>

    <EntityDeleteDialog
      isConfirmationPending={editorApi.isDeleteConfirmPending}
      onAborted={editorApi.onDeleteAborted}
      onConfirmed={editorApi.onDeleteConfirmed}
    />
    <RemoteEntityResultHandler {...editorApi.entityState} onSaved={props.onSaved} onDeleted={props.onDeleted}/>
  </>
}




