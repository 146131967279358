import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack, useTheme } from '@mui/material';
import ThemeToggler from 'Themes/ThemeToggler';
import LanguageSwitch from 'Language/LanguageSwitch';
import { useRemote } from 'Components/Remote/RemoteProvider';
import { ErrorBoundary } from 'Components/ErrorBoundary';
import {useSignOut} from "../Remote/auth.services";


interface HeadBarProps extends MuiAppBarProps {
  isDrawerOpen?: boolean;
  toggleDrawer: () => void;
}
export function HeadBar(props: HeadBarProps) {

  const { isDrawerOpen, toggleDrawer, ...otherProps } = props;
  const [auth, remote] = useRemote();
  const username = auth.isAuthenticated ? auth.user.username : undefined;
  const theme = useTheme();
  
  const handleSignOut = useSignOut();
  
  return <ErrorBoundary name='TopBar'>
    <MuiAppBar position="static" elevation={0} {...otherProps}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(isDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Stack sx={{ flexGrow: 1, color: 'inherit' }}>{props.children}</Stack>

        <ThemeToggler color='inherit' />

        <LanguageSwitch color='inherit' />

        <Typography sx={{ ml: 1 }} color='inherit'>{username}</Typography>
        
        <IconButton color={'inherit'} sx={{ ml: 1 }} onClick={handleSignOut}><LogoutIcon /></IconButton>
      </Toolbar>
    </MuiAppBar>
  </ErrorBoundary>;
}

