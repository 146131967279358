import { getHtmlBodyAndStyle } from "Components/HtmlContent/htmlAdapter";

export interface TranslationHead {
   id: number,
   faqId: number,
   language: string,
   title: string,
}

export interface TranslationAbstract extends TranslationHead {
   html?: string
}

export interface Translation extends TranslationHead {
   html?: string,
   htmlTech?: string
}

export const getTranslatedTitle = (language: string, translations?: TranslationHead[]) => {
   if (!translations) return '';
   let result = '';
   let foundTranslation = translations.find(x => x.language === language);
   if (foundTranslation) {
      result = foundTranslation?.title;
   }
   if (!(result?.length)) {
      foundTranslation = translations.find(x => x.title?.length > 0);
      result = foundTranslation ? foundTranslation.title : '';
   }
   return result;
}

export function getTranslatedTitleAndHtml(language: string, translations?: TranslationAbstract[] | Translation[]): { title: string, html?: string} {

   if (!translations) return { title: '', html: undefined };

   let foundTranslation = translations.find(x => x.language === language);
   if (!foundTranslation || foundTranslation.title.length === 0) {
      foundTranslation = translations.find(x => x.title.length > 0);
   }

   if (!foundTranslation) {
      return { title: '', html: undefined }
   }

   let html = foundTranslation.html ?? '';
   const htmlTech = (foundTranslation.hasOwnProperty("htmlTech") ? (foundTranslation as Translation).htmlTech : '') ?? '';
   if (htmlTech?.length > html?.length) {
      html = htmlTech;
   }
   const { body, style } = getHtmlBodyAndStyle(html);
   return { title: foundTranslation.title, html: body };
}




