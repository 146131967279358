
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { Status } from 'Components/reduxLogger';
import { useUrlQuery } from 'Components/useUrlQuery';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FiltersToolbar } from './Filters/FiltersToolbar';
import NewsTable from './NewsTable/NewsTable';
import { useConst } from 'Components/useConst';
import { RelnotesFilter, useUpdatedURLSearchString, urlQueryToFilter, useFilteredReleaseNotes, isFiltersFormBlank } from './Filters/filterHelpers';
import { useCurrentUrlCredentials } from '../HashCredentialsProvider';
import { useSoftwareDetails } from 'Entities/Software/software.services';


export default function NewsExplorer() {

    //* Initialize filters from URL query string

    const query = useUrlQuery();
    
    const initialFiltersFromQuery = useConst(() => urlQueryToFilter(query));
    
    const software = useCurrentUrlCredentials().software;

    const [filters, setFilters] = useState<RelnotesFilter>(initialFiltersFromQuery);
    
    const [collection, fetch] = useFilteredReleaseNotes();
    const { status: collectionStatus } = collection;    

    //* Fetch filtered release notes

    useEffect(() => { fetch(filters); }, [filters, fetch]);

    //* When collection is updated (because filters were changed), update URL query from current filters

    const location = useLocation();
    const history = useHistory();
    const locationPathname = location.pathname;
    const { push: historyPush } = history;

    const urlQueryString = useUpdatedURLSearchString(filters);

    useEffect(() => {
        if (collectionStatus === Status.Success) {
            historyPush({ 
                pathname: locationPathname, 
                search: urlQueryString 
            })
        }
    }, [collectionStatus, filters, historyPush, urlQueryString, locationPathname]);

    const isTechUser = collection.data.includesTechnical;

    //* Reload on read status update

    const handleStatusChanged = useCallback(() => { fetch(filters); }, [fetch, filters]);

    const softwareCode = useCurrentUrlCredentials().software;
    const softwareDetails = useSoftwareDetails({ code: softwareCode});
    const softwareName = softwareDetails?.userDescription ?? softwareCode;

    //* RENDER
    console.log("FILTERS", filters);
    console.log(collection.data.notes)
    
    return <>
        <FiltersToolbar 
            software={software} 
            value={filters} 
            onChange={setFilters} 
            isTechUser={isTechUser}/>
        <NewsTable softwareName={softwareName}
            notes={collection.data.notes} 
            onStatusChanged={handleStatusChanged} 
            expandSections={!isFiltersFormBlank(filters)} 
            viewTech={filters.contentType === 'Technical'}
            isTechUser={isTechUser}
        />
        <ProgressBackdrop open={collection.status === Status.Pending} />
    </>
}