import { MultipleChipsSelect } from 'Components/Input/MultipleChipsSelect';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { FormControlProps } from '@mui/material';

import { Rules } from './commons';

export type { Option, Rules } from './commons';

type FieldValue<TFieldValues extends FieldValues, TPath extends Path<TFieldValues>> = TFieldValues[Extract<keyof TFieldValues, TPath>];

export function HookedChipsSelect<
  TFieldValues extends FieldValues,
  TPath extends Path<TFieldValues>,
  TItem extends { color?: string }
>(props:
  Omit<FormControlProps, "children">
  & {
    name: Path<TFieldValues>,
    control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
    rules?: Rules<TFieldValues, Path<TFieldValues>>,
    options: (FieldValue<TFieldValues, TPath> & TItem)[],
    label?: string,
    getOptionLabel: (option: TItem) => string,
    isEqual: (a: TItem, b: TItem) => boolean,
    loading: boolean,
    onOpen: () => void
  }
) {
  const { name, control, rules, options, label, disabled, onChange, getOptionLabel, isEqual, ...otherProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field }) => {
        return (
          <MultipleChipsSelect<TItem>
            label={label}
            id={name}
            aria-label={props.label}
            name={field.name}
            options={options}
            getOptionLabel={getOptionLabel}
            isEqual={isEqual}
            disabled={disabled}
            value={field.value ?? []}
            // ref={field.ref}
            onBlur={field.onBlur}
            onChange={(value: TItem[]) => {
              field.onChange(value);
              //console.log("CHIPS SELECT CHANGE", value);
              //onChange && onChange(evt[0].target.value);
            }}
            {...otherProps}
          />
        )
      }}
    />
  );
}





