import React, { ErrorInfo } from "react";

export class ErrorBoundary extends React.Component<{ children: React.ReactNode, name: string  }, { hasError: boolean }> {

  private name: string;

  constructor(props: { children: React.ReactNode, name: string }) {
    super(props);
    this.name = props.name;
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/*_: Error*/) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(this.name, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h4>Something went wrong</h4>;
    }

    return this.props.children; 
  }
}