import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_BACKEND_URL !== undefined ? process.env.REACT_APP_BACKEND_URL + `/api` : `/api`;

export interface Credentials {
  username?: string;
  password?: string;
  newPassword?: string;
  newPasswordRepeat?: string;
  activeDirectoryToken?: string;
}

export enum AccessLevels {
  Guest = -1,
  Administrator = 0,
  Standard = 2,
  Supervisor = 3,
}

export enum Languages {
  de = 0,
  it = 1,
}
// export const localToRemoteLanguage = {
//   de: Languages.German,
//   it: Languages.Italian
// }

export interface User {
  id: string;
  username: string;
  accessLevel: AccessLevels;
  language: string;
}

function login(credentials: Credentials): Promise<{ user: User; token: string }> {
  if (credentials.activeDirectoryToken) {
    return _login(credentials, "Authenticate/login/activeDirectory");
  }
  return _login(credentials, "Authenticate/login");
}

function changePasswordAndLogin(credentials: Credentials): Promise<{ user: User; token: string }> {
  return _login(credentials, "Auth/changePasswordAndLogin");
}

function _login(credentials: Credentials, authMethod: string): Promise<{ user: User; token: string }> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  if (credentials.activeDirectoryToken) headers.append("Authorization", `Bearer ${credentials.activeDirectoryToken}`);
  const $response = fetch(`${apiUrl}/${authMethod}`, {
    method: "POST",
    mode: "cors",
    headers,
    body: JSON.stringify(credentials),
  });
  return $response.then((response) => {
    const $payload = response.json();
    if (response.ok) {
      return $payload.then((data: { token: string }) => {
        const token = data.token;
        const decoded: any = jwt_decode(token);
        return {
          user: {
            id: decoded.userId,
            accessLevel: parseInt(decoded.accessLevel),
            username: decoded.userName,
            language: decoded.language,
          },
          token: token,
        };
      });
    } else {
      return $payload.then(
        (payload) => {
          throw payload;
        },
        () => {
          throw {
            type: `${response.status} ${response.statusText}`,
          };
        }
      );
    }
  });
}

function changePassword(credentials: Credentials): Promise<boolean> {
  const $response = fetch(`${apiUrl}/auth/changePassword`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(credentials),
  });
  return $response.then((response) => {
    const $payload = response.json();
    if (response.ok) {
      return $payload;
    } else {
      return $payload.then(
        (payload) => {
          throw payload;
        },
        () => {
          throw {
            type: `${response.status} ${response.statusText}`,
          };
        }
      );
    }
  });
}

async function myFetch(endpoint: string, params: RequestInit = {}): Promise<any> {
  return fetch(`${apiUrl}/${endpoint}`, {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    ...params,
  });
}

/**
 * Fetch with Authorization header
 * @param endpoint string
 * @param authToken string
 * @param params fetch API's RequestInit object
 * @returns
 */
async function authFetch(endpoint: string, authToken: string, params: RequestInit = {}): Promise<any> {
  return fetch(`${apiUrl}/${endpoint}`, {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    ...params,
  });
}

export type AssertAborted = () => boolean; // says if a fetch has been aborted
export type Abort = () => void;
/* aborts a fetch with AbortController https://developer.mozilla.org/en-US/docs/Web/API/AbortController
  For use in React.useEffect() like this:
  useEffect(() => {
    const [$response, abort] = abortableFetch(....);
    $response.then(result => { ... })
    return abort; //  abort() will run on unmount and abort the fetch
  }) 
*/
export type FetchPromise<T> = [Promise<T>, Abort, AssertAborted];

export function abortableFetch(endpoint: string, params: RequestInit = {}): FetchPromise<any> {
  const abortController = new AbortController(); // creating an AbortController
  return [fetch(endpoint, { ...params, signal: abortController.signal }), abortController.abort, () => abortController.signal.aborted] as [
    Promise<any>,
    Abort,
    AssertAborted
  ];
}

function abortableLogin(credentials: Credentials): [Promise<{ user: User; token: string }>, Abort, AssertAborted] {
  const [$response, abort, assertAborted] = abortableFetch(`${apiUrl}/Authenticate/login`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(credentials),
  });
  return [
    $response.then((response) => {
      if (response.ok) {
        return response.json().then((data: { token: string }) => {
          const token = data.token;
          const decoded: any = jwt_decode(token);
          console.log("TOKEN", decoded);
          return {
            user: {
              accessLevel: parseInt(decoded.accessLevel),
              username: decoded.firmaId,
              language: decoded.language,
            },
            token: token,
          };
        });
      } else throw response;
    }),
    abort,
    assertAborted,
  ];
}

export const service = { fetch: myFetch, abortableLogin, abortableFetch, authFetch, login, changePasswordAndLogin, changePassword };
