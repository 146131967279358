
import React, { memo } from "react";
import { flattenAndSortMenuNodesForRouter, NavNode, NavNodeDefinition as MenuDefinition } from "./navTree";
import { OpenMenuProvider } from "./OpenMenuProvider";
export { useOpenMenu } from "./OpenMenuProvider";
export * from "./navTree";


export interface NavigatorDefinition {

  menuRoot: MenuDefinition,

  resetOpenMenu: (currentOpenMenu: string) => string,
  // menu subtree that will be opened when new NavContext is established
  
  doRedirectNonMatchingURL: boolean // if true, 
    // will redirect to root node when URL path doesn't match any node's path. 
    // If the app routes are defined conditionally to some fetched context, this will cause
    // redirection to the app root upon page refresh. To avoid this, set this prop to false
    // until the context needed to define the navigation is established.
}

const dummyMenuDef: MenuDefinition = { // used only to initialize NavContext's React Context
  name: '<empty>',
  path: '',
  children: [] as MenuDefinition[]
}

export const dummyNaviDef: NavigatorDefinition = {
  menuRoot: dummyMenuDef,
  //transformUrlPath: (oldUrlPath: string) => oldUrlPath,
  resetOpenMenu: (oldOpenMenu: string) => oldOpenMenu,
  doRedirectNonMatchingURL: false
}


/**********************
 * Navigator (Navigation Context)
 **********************/
export type Navigator = Omit<NavigatorDefinition, 'menuRoot'> & {
  rootNode: NavNode,  
  menuListForRouter: NavNode[];  
}

const dummyMenuRoot = new NavNode(dummyMenuDef); // empty menu used to initialize React.Context

const dummyNavigator: Navigator = {
  rootNode: dummyMenuRoot,
  menuListForRouter: flattenAndSortMenuNodesForRouter(dummyMenuRoot),
  resetOpenMenu: () => dummyMenuRoot.name,
  doRedirectNonMatchingURL: false
}

const NavigationContext: React.Context<Navigator> = React.createContext(dummyNavigator);
NavigationContext.displayName = 'NavigatorContext';

export const NavigationConsumer = NavigationContext.Consumer;

/**********************
 * NavigatorProvider
 **********************/
interface NavigatorProviderProps extends NavigatorDefinition { children: React.ReactNode }

const NavigatorProvider = (props: NavigatorProviderProps) => {

  //console.log("NavigationProvider render", props);
  const [navigator, setNavigator] = React.useState<Navigator>(dummyNavigator);

  const { menuRoot, resetOpenMenu, doRedirectNonMatchingURL/*, transformUrlPath: transformUrlPath*/ } = props;

  React.useEffect(() => {
    const newRootNode = new NavNode(menuRoot);
    const nodeList = flattenAndSortMenuNodesForRouter(newRootNode);
    
    //console.log("NavigationProvider reset, menu:", menuRoot.subMenus);
    console.log("NavigationProvider reset", newRootNode);
    setNavigator({
      rootNode: newRootNode,
      menuListForRouter: nodeList,
      resetOpenMenu: resetOpenMenu,
      doRedirectNonMatchingURL
    });
  }, [menuRoot, resetOpenMenu, doRedirectNonMatchingURL]);
  
  return (
    <NavigationContext.Provider value={navigator}>
      <OpenMenuProvider rootName={navigator.rootNode.name} resetOpenMenu={resetOpenMenu} enabledNodes={navigator.menuListForRouter}>
        
          {props.children}
        
      </OpenMenuProvider>
    </NavigationContext.Provider>
  );
};
NavigatorProvider.displayName = 'NavigationProvider';
export { NavigatorProvider as NavigationProvider };

export const useNavigator = () => React.useContext(NavigationContext);







