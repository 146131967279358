import { tabA11yProps, TabPanel } from 'Components/Containers/TabPanel';
import { HookedTextField } from 'Components/HookedForm/inputControls';
import TiptapEditor from 'Components/HtmlContent/TipTapEditor/TipTapEditor';
import { useState } from 'react';
import { Controller, FieldArrayWithId, UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Paper, Portal, Stack, Tab, Tabs, useTheme } from '@mui/material';

import { NoteVerbose, ReleaseContext } from './note.models';
import { EntityFormRenderProps } from 'Components/HookedForm/EntityForm';
import { getHtmlBodyAndStyle } from 'Components/HtmlContent/htmlAdapter';


const indexOfLonger = (a?: string, b?: string): number => {
   if (!b) return 0;
   else if (!a) return 1;
   else return a.length >= b.length ? 0 : 1;
}

export type NoteTranslationFormProps = EntityFormRenderProps<NoteVerbose, ReleaseContext> & { titleContainer: HTMLElement | null };

type TranslationsFieldArray = UseFieldArrayReturn<NoteVerbose, 'translations', '_fk'>;
type TranslationField = FieldArrayWithId<NoteVerbose, "translations", "_fk">;

export default function NoteTranslationForm(props: NoteTranslationFormProps & {
   fieldArray: TranslationsFieldArray,
   field: TranslationField
   index: number,
   path: `translations.${number}`,
   key: string,
   onRemove: () => void
}) {

   const { control, path, getValues, titleContainer } = props;

   const [t] = useTranslation();

   const theme = useTheme();

   const id = getValues(`${path}.id`)?.toString();

   //* Initial active tab: html or html tech depending on which is longer
   const initialActiveTab = indexOfLonger(getValues(`${path}.html`), getValues(`${path}.htmlTech`));

   const [activeTab, setActiveTab] = useState(initialActiveTab);
   const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
   };

   return <>
      {/* TITLE VIEW */}
      {titleContainer && (
         <Portal container={titleContainer}>{getValues(`${path}.title`)}</Portal>
      )}

      {/* TITLE FIELD */}
      <Stack direction='row' sx={{ m: 1, mt: 2 }}>

         <HookedTextField control={control}
            name={`${path}.title`} label={t('title')}
            rules={{ required: true }}
            sx={{
               minWidth: 300,
               '& .MuiInputBase-root': { font: 'Arial', fontSize: '13px', fontWeight: 'bold' }
            }}
         />
      </Stack>

      {/* TEXT CONTENTS (HTML) */}
      <Paper id={`TranslationForm-${id?.toString() ?? 'new'}`} variant='outlined' sx={{ width: 'calc(100% - 32px)', mx: '16px', my: 2 }}>

         <Box sx={{ borderBottom: 1, borderColor: theme.palette.divider }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="text-tabs">
               <Tab label={t('textHtml')} {...tabA11yProps(0)} />
               <Tab label={t('textHtmlTech')} {...tabA11yProps(1)} />
            </Tabs>
         </Box>

         <TabPanel index={0} activeIndex={activeTab}>
            <Controller control={control} name={`${path}.html`}
               render={({ field }) => {
                  const { body, style } = getHtmlBodyAndStyle(field.value);
                  return <TiptapEditor id={`${id}-html`} onChange={field.onChange} value={body} />
               }}
            />
         </TabPanel>

         <TabPanel index={1} activeIndex={activeTab}>
            <Controller control={control} name={`${path}.htmlTech`}
               render={({ field }) => {
                  const { body, style } = getHtmlBodyAndStyle(field.value);
                  return <TiptapEditor id={`${id}-htmlTech`} onChange={field.onChange} value={body} />
               }}
            />
         </TabPanel>
      </Paper>
   </>
}

