
import { NonEditableField } from 'Components/FormUtils';
import { EntityFormCustom, EntityFormCustomRenderProps } from 'Components/HookedForm/EntityFormCustom';
import { HookedTextField } from 'Components/HookedForm/inputControls';
import { ItemEditProps } from 'Components/RemoteEntities/Collection/CollectionEditController';
import { EntityDeleteDialog } from 'Components/RemoteEntities/Entity/EntityDeleteDialog';
import { useEntityEditorApi } from 'Components/RemoteEntities/Entity/useEntityEditorApi';
import React, { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, TableRow } from '@mui/material';

import { DescribedCode, emptyDescribedCode } from '../describedCode.models';
import { makeDescribedCodeService } from '../describedCode.services';
import { StyledCell } from './Styled';
import { RemoteEntityResultHandler } from 'Components/RemoteEntities/Entity/RemoteEntityResultHandler';
import { DefaultValues, EmptyObject, UseFormReturn } from 'react-hook-form';


const StyledInput = (props: any) => <HookedTextField {...props} sx={{ mx: 0 }} />

export const makeDescribedCodeEditor = (endpoint: string, componentDisplayName: string) => {

  const Component: React.FC<ItemEditProps<DescribedCode>> = (props) => {

    const { entity } = props;
   
    const useEntityService = useMemo(() => makeDescribedCodeService({ endpoint }), []);

    const editorApi = useEntityEditorApi<DescribedCode>({
      editorId: endpoint,
      useRemoteEntity: useEntityService,
      idKey: 'code',
      model: entity ?? false,
      proposeDelete: props.doDelete,
      asNew: !props.entity,
      context: {}
    });

    return <>
      <EntityFormCustom<DescribedCode>
        name={editorApi.editorId}
        defaultValues={entity || emptyDescribedCode}
        context={{}}
        onSave={editorApi.doSave}
        onDelete={editorApi.doDelete}
        onCancel={props.onExit}
      >{RenderForm}</EntityFormCustom>

      <EntityDeleteDialog
        isConfirmationPending={editorApi.isDeleteConfirmPending}
        onAborted={editorApi.onDeleteAborted}
        onConfirmed={editorApi.onDeleteConfirmed}
      />
      <RemoteEntityResultHandler {...editorApi.entityState} onSaved={props.onSaved} onDeleted={props.onDeleted}/>
    </>
  }
  Component.displayName = componentDisplayName;
  return Component;
}


//export type RenderFormProps<TModel extends DescribedCode = DescribedCode> = EntityFormCustomRenderProps<TModel>;

export function RenderForm<TModel extends DescribedCode = DescribedCode>(props: UseFormReturn<TModel> & {
  rootName: string,
  disabled?: boolean,
  context: EmptyObject,
  defaultValues: DefaultValues<TModel> | null,
  onPendingChanges: (isDirty: boolean) => void,
  onSave?: () => void, // handles Save button click
  onCancel?: () => void, // handles Back button click 
  onDelete?: () => void, // handles Delete button click 
  onReset?: () => void   // callback when form is reset to initial values
}) {

  const { defaultValues } = props;

  const isNewEntity = !defaultValues?.code;

  return <TableRow>
    <StyledCell sx={{ pl: 4 }}>
      {!isNewEntity
        ? <NonEditableField value={defaultValues.code} plain sx={{ mr: 3 }} />
        : <StyledInput name={'code'} control={props.control} rules={{ required: true }} />}
    </StyledCell>
    <StyledCell>
      <StyledInput name={'descriptionDe'} control={props.control} rules={{ required: true }} />
    </StyledCell>
    <StyledCell>
      <StyledInput name={'descriptionIt'} control={props.control} rules={{ required: true }} />
    </StyledCell>
    <StyledCell>
      <StyledInput name={'descriptionEn'} control={props.control} rules={{ required: false }} />
    </StyledCell>
    <StyledCell sx={{ textAlign: 'right' }}>
      <IconButton onClick={props.onSave} disabled={!props.formState.isDirty}>
        <SaveIcon />
      </IconButton>
      <IconButton onClick={props.onCancel}>
        <CloseIcon />
      </IconButton>
    </StyledCell>
  </TableRow>;
}

