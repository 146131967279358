import {
    minifyReleaseHead,
    ReleasedSoftware,
    ReleaseFilters,
    ReleaseHead,
    ReleaseHeadVerbose
} from "../Release/release.models";
import {useRemoteCollection} from "Components/RemoteEntities/Collection/RemoteCollection";
import {
    RemoteEntityApi,
    RemoteEntityProps,
    useRemoteEntity
} from "Components/RemoteEntities/Entity/RemoteEntity";
import {EmptyObject, hasContent} from "Components/utils";
import React from "react";
import {useTranslation} from "react-i18next";
import {notesFilterToAPIQueryParams} from "../Notes/note.services";

const useFilterQuery = (filters: ReleaseFilters | null) => {

    // Important! Do not do this in useMemo, otherwise it won't catch changes in nested arrays 
    // (looks like react-form-hook returns the same parent object when nested prop change?)

    let queryParams: any = null;

    const [t, {language}] = useTranslation();

    if (filters) {
        const {headFilter} = filters;
        let {notesFilter} = filters;

        if (hasContent(headFilter)) {
            queryParams = {
                software: headFilter?.softwareCode ?? headFilter?.software?.code,
                releaseVersion: headFilter?.releaseVersion,
                releaseDateFrom: headFilter?.releaseDateFrom,
                releaseDateTo: headFilter?.releaseDateTo,
                status: headFilter?.status?.map(x => x.code),
            }
        }

        // ergoOne filter is meaningful only for ERGOBUILD. If used in other software, result will be empty list
        if (queryParams?.software !== 'ERGOBUILD' && notesFilter.ergoOne !== undefined) {
            notesFilter = {...notesFilter, ergoOne: undefined}
        }

        if (hasContent(notesFilter)) {
            queryParams = {
                ...queryParams,
                ...notesFilterToAPIQueryParams(notesFilter),
                language
            }
        }
    }
    return queryParams;
}

//************** RELEASED SOFTWARES COLLECTION */
export function useReleasedSoftwares(props: ReleaseFilters | null) {

    const queryParams = useFilterQuery(props);
    
    //console.log("queryParams", queryParams);

    return useRemoteCollection<ReleasedSoftware>({
        queryParams,
        endpoint: 'releases/softwares',
        requiresQueryParams: false,
        isPaginated: false
    });
}

//************** RELEASE COLLECTION */
export function useReleases(props: { filters: ReleaseFilters | null, pageSize: number }) {

    const filterQuery = useFilterQuery(props.filters);

    const doFilterByNote = hasContent(props?.filters?.notesFilter);

    return useRemoteCollection<ReleaseHeadVerbose>({
        pageSize: props.pageSize,
        queryParams: filterQuery,
        endpoint: doFilterByNote ? 'releases/searchNotes' : 'releases',
        isPaginated: !doFilterByNote,
        requiresQueryParams: true,
    });
}


//************** ENTITY */
export const defaultRemoteHeadProps: RemoteEntityProps<ReleaseHeadVerbose, EmptyObject, ReleaseHead> = {
    endpoint: 'Releases',
    idKey: 'id',
    remoteCreation: false,
    minify: minifyReleaseHead
}

export function useReleaseHeadDetails(): RemoteEntityApi<ReleaseHeadVerbose, EmptyObject, ReleaseHead> {

    const remoteProps = React.useMemo(() => {
        return {...defaultRemoteHeadProps}
    }, []);

    return useRemoteEntity<ReleaseHeadVerbose, EmptyObject, ReleaseHead>(remoteProps);
}