import { CollectionLayout } from 'Components/Collection/CollectionLayout';
import ToolbarWithSearchAndDrawer from 'Components/Containers/ToolbarWithSearchAndDrawer';
import { RemoteCollectionApi } from 'Components/RemoteEntities/Collection/RemoteCollection';
import React, { useState } from 'react';
import { EmptyObject } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PaginationController, usePaginationHandling } from './Pagination';

export interface SelectionContext<TModel, TFilter> {
  pool: TModel[],
  filter?: TFilter
}


/**
 * Renders an item-selectable view of a UseRemoteCollection's content,
 * with a top sticky section containing collection filters and pagination controls. 
 */
export function CollectionExplorerWithSearch<
  TModel extends Record<string, any>, 
  TFilter = EmptyObject, 
  TContext = Record<string, never>
>(props: {
    id?: string,
    titleKey: string,
    useFilteredCollection: (props: { filter?: TFilter, search?: string, context: TContext }) => RemoteCollectionApi<TModel>,
    doPaginate?: boolean,
    onSelected: (item: TModel | false, pool?: TModel[]) => void,
    hasNewButton: boolean,
    context: TContext,
    initialFilter?: TFilter,
    children: {
      Filters?: React.FC<{ value?: TFilter, onChange: ((filters: TFilter) => void), disabled?: boolean, context: TContext }>,
      CollectionView: React.FC<{ data: TModel[], onSelected?: (item: TModel) => void }>,
    }
  }): React.ReactElement {

  const {context, doPaginate} = props;

  const [filterDraft, setFilterDraft] = useState<TFilter | undefined>(props.initialFilter);
  const [filter, setFilter] = useState<TFilter | undefined>(props.initialFilter);
  
  const handleFilterClosed = () => {
    setFilter(filterDraft);
  }
  
  const [search, setSearch] = useState<undefined | string>(undefined);

  console.log("FILTER", search, filter, filterDraft);
  
  const [collection, collectionApi] = props.useFilteredCollection({ filter, search, context });

  const { t } = useTranslation();

  const { entities } = collection;

  const pagination = usePaginationHandling<TModel>({entities, doPaginate: props.doPaginate});
    
  const {CollectionView, Filters} = props.children;

  return (
    <CollectionLayout
      id={props.id || collectionApi.endpoint + '-explorer'}
      title={t(props.titleKey)}
      hasNewButton={props.hasNewButton}
      onNewClicked={() => props.onSelected(false)}
      isLoading={collection.isLoading}
    >
      {{
        controls: (
          <ToolbarWithSearchAndDrawer 
            searchValue={search} 
            onSearchChanged={setSearch}
            onClose={handleFilterClosed}
          >
            {Filters && <Filters value={filterDraft} onChange={setFilterDraft} context={props.context}/>}
          </ToolbarWithSearchAndDrawer>
        ),

        pagination: <PaginationController 
          collection={collection} 
          collectionApi={collectionApi} 
          localPagination={pagination.localPagination} />,
        
        content:
          <CollectionView data={pagination.result} onSelected={props.onSelected}/>,
      }}
    </CollectionLayout>
  )
}



