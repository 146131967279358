import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'Components/ErrorBoundary';

import { useNavigator } from './NavProvider';

export function NavOutlet() {
  
  const navigator = useNavigator();
  
  const { rootNode, menuListForRouter, doRedirectNonMatchingURL } = navigator;
  
  return (
    <ErrorBoundary name='NavOutlet'>
      <Switch>
        {
          menuListForRouter.map((menuNode, index) => (
            <Route key={index}
              exact={menuNode.isExactPath}
              path={menuNode.absolutePath}
            >{
                menuNode.component
                  ? (
                    <ErrorBoundary name={menuNode.component.displayName ?? menuNode.fullName}>
                      <menuNode.component />
                    </ErrorBoundary>
                  ) : null
              }
            </Route>
          ))
        }
        {doRedirectNonMatchingURL && (
          <Route render={() => <Redirect to={rootNode.absolutePath} />} />
        )}
      </Switch>
    </ErrorBoundary>
  );
}

