import React, { useEffect, useState } from "react";
import { useRemote, Remote } from '../../Remote/RemoteProvider';
import { Abort } from "../../Remote/remote.service";
import { deep1Equal, deep2Equal, objectToURLSearchString, removeBlanksAndUndefined } from 'Components/utils';
import { CollectionActions, CollectionState, makeCollectionReducer, makeInitialState } from './collection.state';
export type { CollectionState } from './collection.state';

/**
 * useRemoteCollection()
 * Hook to read a (possibly paginated) remote collection of entities.
 * Methods getPage(pageNumber = 1), nextPage(), previousPage()
 */
export interface RemoteCollectionProps {
  endpoint: string,
  isPaginated?: boolean,
  requiresQueryParams: boolean
  queryParams?: any,
  optionalQueryParams?: any,
  autoFetch?: boolean, // default: true
  pageSize?: number
}

export interface RemoteCollectionMethods {
  endpoint: string;
  nextPage(): Abort | void;
  previousPage(): Abort | void;
  getPage(page?: number, pageSize?: number): Abort | void;
}

function makeActionPayloadFromRawData(rawData: any): any {
  if (Array.isArray(rawData)) {
    return { entities: [...rawData], isPaginated: false };
  } else {
    // we assume that if raw data is not a plain array, then it is a CollectionPayload (with pagination info)
    return {
      isPaginated: true,
      ...rawData,
    }
  }
}

interface ApiProps<TModel> extends Omit<RemoteCollectionProps, 'autoFetch' | 'pageSize'> {
  remote: Remote,
  dispatch: React.Dispatch<CollectionActions<TModel>>,
}
class CollectionMethodsImpl<TModel>
  implements ApiProps<TModel>, RemoteCollectionMethods {

  readonly endpoint: string;
  readonly isPaginated?: boolean;
  private pageSize = 30;
  readonly requiresQueryParams: boolean;
  readonly queryParams?: any;
  readonly optionalQueryParams?: any;
  readonly remote: Remote;
  readonly dispatch: React.Dispatch<CollectionActions<TModel>>;
  _state: CollectionState<TModel>;

  constructor(props: ApiProps<TModel>) {
    this.endpoint = props.endpoint;
    this.isPaginated = props.isPaginated;
    this.requiresQueryParams = props.requiresQueryParams;
    this.queryParams = props.queryParams;
    this.optionalQueryParams = props.optionalQueryParams;
    this.remote = props.remote;
    this.dispatch = props.dispatch;
    this._state = makeInitialState();
  }

  getPage(page?: number, pageSize?: number) {

    const { dispatch, remote, requiresQueryParams: hasRequiredQueryParams, queryParams: requiredQueryParams, endpoint, optionalQueryParams } = this;

    if (hasRequiredQueryParams && !requiredQueryParams) {
      //console.log(endpoint, "abort fetching because missing required queryParams");
      return;
    }

    this.pageSize = pageSize ?? this.pageSize;

    const queryString = objectToURLSearchString({
      ...requiredQueryParams,
      ...optionalQueryParams,
      ...(this.isPaginated !== false ? {
        page: (page || this._state?.page),
        pageSize: this.pageSize
      } : {})
    });

    dispatch({ type: 'LOAD_REQUEST' });

    const [auth, remoteMethods] = remote;

    const [$fetch, abort] = remoteMethods.cleanFetch(`${endpoint}` + (queryString?.length ? `?${queryString}` : ''));

    $fetch.then(
      (data: any) => dispatch({ type: 'LOAD_SUCCESS', payload: makeActionPayloadFromRawData(data) }),
      (error: any) => dispatch({ type: 'LOAD_FAILURE', payload: { error } })
    );
    return abort;
  }

  previousPage() {
    const targetPage = (this._state.page ?? 0) - 1;
    if (targetPage >= 0) {
      return this.getPage(targetPage);
    }
  }

  nextPage() {
    const targetPage = (this._state.page ?? 0) + 1;
    if (targetPage <= (this._state.totalPages ?? 0)) {
      return this.getPage(targetPage);
    }
  }
}

const _apiCache = new Map();

function _getCachedApi<TModel>(arg: ApiProps<TModel>): CollectionMethodsImpl<TModel> {

  let cached = _apiCache.get(arg.dispatch);
  if (cached) {
    // extract from cached object only the props that exist in the arg
    const sharedProps = Object.fromEntries(
      Object.entries(cached).filter(([key]) => Object.keys(arg).includes(key))
    );
    if (!deep2Equal(arg, sharedProps)) {
      console.log('_getCachedApi not equal: ', arg, sharedProps)
      cached = undefined;
    }
  }
  if (!cached) {
    cached = new CollectionMethodsImpl(arg);
    console.log(`Created new RemoteCollectionApi for ${arg.endpoint}`, cached);
    _apiCache.set(arg.dispatch, cached);
  }
  return cached;
}

/* *************************************************************** */
/* COLLECTION fetched from given endpoint with query parameters
/* *************************************************************** */

export type RemoteCollectionApi<TModel> = [
  CollectionState<TModel>,
  RemoteCollectionMethods
]
export function useRemoteCollection<TModel>(props: RemoteCollectionProps): RemoteCollectionApi<TModel> {

  const { endpoint } = props;

  const reducer = React.useMemo(() => makeCollectionReducer<TModel>(endpoint), [endpoint]);
  const initialState = React.useMemo(() => makeInitialState<TModel>(), []);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { autoFetch, pageSize, ...apiProps } = props;

  const remote = useRemote();
  const methodsApi = _getCachedApi<TModel>({ 
    ...apiProps, 
    queryParams: removeBlanksAndUndefined(props.queryParams),
    optionalQueryParams: removeBlanksAndUndefined(props.optionalQueryParams),
    dispatch, 
    remote 
  });
  methodsApi._state = state;
  
  React.useEffect(() => {
    if (autoFetch === true || autoFetch === undefined) {
      methodsApi.getPage(1, pageSize);
    }
  }, [methodsApi, autoFetch, pageSize]);

  return [state, methodsApi];
}


/* ***************************************************** */
/* PROCESSED COLLECTION 
/* with local sorting/filtering capabilities
/* ***************************************************** */

export interface SortingDef<TModel> {
  by: keyof TModel,
  desc?: boolean
}
export type SortingSpecs<TModel> = keyof TModel | SortingDef<TModel> | SortingDef<TModel>[]

export type FilterPredicateMaker<TModel, TFilter> = (filter?: TFilter) => undefined | ((entity: TModel) => boolean);

export function useProcessedRemoteCollection<TModel, TFilter = any>(props: RemoteCollectionProps & {
  sort?: SortingSpecs<TModel>;
  filterParams?: TFilter,
  makeFilterPredicate?: FilterPredicateMaker<TModel, TFilter>
}): RemoteCollectionApi<TModel> {

  const { sort, filterParams, makeFilterPredicate, ...remoteProps} = props;

  const [_filterParams, _setFilterParams] = useState<TFilter | undefined>(undefined);

  const [state, methods] = useRemoteCollection<TModel>(remoteProps);

  const originalEntities = state.entities;

  // Memoize filterParams, so we can detect when the same values are passed in a new object
  useEffect(() => {
    _setFilterParams((old) => {
      return deep1Equal(old, filterParams) ? old : filterParams;
    });
  }, [filterParams]);

  // FILTER
  const filteredEntities = React.useMemo(() => {
    if (originalEntities.length === 0) {
      return originalEntities;
    } else {
      const filterPredicate = makeFilterPredicate ? makeFilterPredicate(_filterParams) : undefined;
      return filterPredicate !== undefined
        ? originalEntities.filter(x => filterPredicate(x))
        : originalEntities;
    }
  }, [originalEntities, makeFilterPredicate, _filterParams]);

  // SORT
  const sortedEntities = React.useMemo(() => {
    if (sort && filteredEntities.length > 0) {
      const copy = [...filteredEntities];
      doSort(copy, sort);
      return copy;
    } else {
      return filteredEntities;
    }

  }, [filteredEntities, sort]);

  const processedState = React.useMemo(() => {

    return ({ ...state, entities: sortedEntities });

  }, [sortedEntities, state]);

  //console.log("Processed collection:", processedState);

  return [processedState, methods];
}

function doSort<TModel>(entities: TModel[], sortingSpecs: SortingSpecs<TModel>) {

  if (typeof sortingSpecs === 'string') {

    const spec = { by: sortingSpecs as keyof TModel };
    doSort(entities, spec);

  } else if (Array.isArray(sortingSpecs)) {

    sortingSpecs.forEach(spec => doSort(entities, spec));

  } else {

    const def = sortingSpecs as SortingDef<TModel>;
    const { by: sortBy, desc } = def;
    const sample = entities[0][sortBy];
    switch (typeof sample) {
      case 'number':
        entities.sort((a, b) => desc
          ? ((b[sortBy] || 0) as any as number) - ((a[sortBy] || 0) as any as number)
          : ((a[sortBy] || 0) as any as number) - ((b[sortBy] || 0) as any as number)
        );
        break;
      case 'string':
        entities.sort((a, b) => desc
          ? ((b[sortBy] || '') as string).localeCompare((a[sortBy] || '') as string)
          : ((a[sortBy] || '') as string).localeCompare((b[sortBy] || '') as string)
        );
        break;
      case 'boolean':
        entities.sort((a, b) => {
          const _a = ((a || false) as boolean);
          const _b = ((b || false) as boolean);
          return _a === _b ? 0 : (_a ? 1 : -1);
        });
        break;
    }
  }
}


