import React, {useContext} from "react";
import {useReleasedSoftwares} from "./release.services";
import {useTranslation} from "react-i18next";
import {CollectionLayout} from "Components/Collection/CollectionLayout";
import {Status} from "Components/reduxLogger";
import {RemoteCollectionPagination} from "Components/RemoteEntities/Collection/Pagination";
import {ReleasesBySoftware} from "./ReleasesBySoftware";
import {ReleaseFiltersToolbar} from "./ReleaseFiltersToolbar";
import {ReleasesManagerContext} from "./ReleasesManagerContext";

export function SelectRelease() {

    const [context] = useContext(ReleasesManagerContext);

    const [softwares, softwaresApi] = useReleasedSoftwares(context.filters);

    const [t] = useTranslation();

    return (
        <CollectionLayout id='ReleasesExplorer'
                          title={t('releases')}
                          hasNewButton={false}
                          isLoading={softwares.status === Status.Pending}
        >
            {{
                controls: <ReleaseFiltersToolbar/>,
                pagination: softwares.isPaginated
                    ? <RemoteCollectionPagination paginated={softwares} methods={softwaresApi}/>
                    : null,

                content: <ReleasesBySoftware softwares={softwares.entities} withPdf filters={context.filters}/>
            }}
        </CollectionLayout>

    )
}