import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';

import { Rules } from './commons';

export function HookedCheckboxWithLabel<TFieldValues extends FieldValues>(props: Omit<FormControlLabelProps, "control" | "ref" | "onChange" | "onBlur"> & {
  name: Path<TFieldValues>,
  control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
  rules?: Rules<TFieldValues, Path<TFieldValues>>,
  inputProps?: CheckboxProps,
  block?: boolean,
  onChange?: (isChecked: boolean) => void
}) {
  const { name, disabled, block, control, rules, inputProps, onChange, ...otherProps } = props;
  if (block) {
    otherProps.sx = {display: 'block', ...otherProps.sx}
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field }) => {
        return (
          <FormControlLabel
            {...otherProps}
            sx={{...otherProps.sx}}
            control={<Checkbox
              {...inputProps}
              disabled={disabled}
              name={field.name}
              //value={field.value}
              checked={!!field.value}
              ref={field.ref}
              onBlur={field.onBlur}
              onChange={(evt, checked) => { 
                field.onChange(evt, checked); 
                onChange && onChange(checked);
              }}
            />}
          />
        )
      }}
    />
  );
}

export function HookedCheckbox<TFieldValues extends FieldValues>(props: Omit<CheckboxProps, "ref" | "onChange" | "onBlur"> & {
  name: Path<TFieldValues>,
  control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
  rules?: Rules<TFieldValues, Path<TFieldValues>>,
  block?: boolean,
  onChange?: (isChecked: boolean) => void
}) {
  const { name, disabled, block, control, rules, inputProps, onChange, ...otherProps } = props;
  if (block) {
    otherProps.sx = {display: 'block', ...otherProps.sx}
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field }) => {
        return (
          <Checkbox
              {...otherProps}
              disabled={disabled}
              name={field.name}
              //value={field.value}
              checked={!!field.value}
              ref={field.ref}
              onBlur={field.onBlur}
              onChange={(evt, checked) => { 
                field.onChange(evt, checked); 
                onChange && onChange(checked);
              }}
            />
        )
      }}
    />
  );
}

