
import { Status } from 'Components/reduxLogger';
import { purple } from '@mui/material/colors';
import { useErgoOneSetterService } from './note.services';
import { Checkbox, CircularProgress } from '@mui/material';


export const ErgoOneFlag = (props: { noteId: number, releaseId: number, value: boolean, onChange: (value: boolean) => void}) => {

    const { onChange, releaseId } = props;
    
    const [state, submit] = useErgoOneSetterService({releaseId});
 
    const { status, data } = state;

    //const handleChange = useCallback((val: boolean) => { onChange(val); }, [onChange]);
 
   //  useEffect(() => {
   //     debugger;
   //     if (status === Status.Success) {
   //       handleChange(data);
   //     }
   //  }, [status, data, handleChange]);
 
    return <>
       <span style={{ color: purple[600], marginLeft: 16 }}>
          <Checkbox checked={!!props.value}
             sx={{ pr: 1, color: purple[800], '&.Mui-checked': { color: purple[600] } }}
             onChange={ () => submit({id: props.noteId, ergoOne: !props.value}).then((val) => { if (typeof val === 'boolean') onChange(val) }) }
          />
          One
       </span>
       {status === Status.Pending && <CircularProgress sx={{position: 'relative', left: -20}}/>}
    </>
 }