import { Editor } from "@tiptap/react";
import { StyledButton } from "./StyledInputs";
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustify';
import { ReactNode } from "react";
import { Box } from "@mui/material";


const AlignButton = (props: { 
    editor: Editor, 
    value: 'left' | 'right' | 'center' | 'justify', 
    children: ReactNode
}) => {
    return <StyledButton type="button"
        onClick={(evt) => { evt.preventDefault(); props.editor.chain().focus().setTextAlign(props.value).run() }}
        isActive={props.editor.isActive({ textAlign: props.value })}
    >
        {props.children}
    </StyledButton>
}

export const TextAlignControls = (props: { editor: Editor }) => {

    const { editor } = props;

    return <Box>
        <AlignButton editor={editor} value="left"><FormatAlignLeft /></AlignButton>
        <AlignButton editor={editor} value="center"><FormatAlignCenter /></AlignButton>
        <AlignButton editor={editor} value="right"><FormatAlignRight /></AlignButton>
        <AlignButton editor={editor} value="justify"><FormatAlignJustify /></AlignButton>
    </Box>
}