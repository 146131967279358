import { MakeCancelable, useCancelable } from 'Components/cancelable';
import { useRemoteMethods } from 'Components/Remote/RemoteProvider';
import { deep1Equal, deep2Equal, objectToURLPathAndSearchString, objectToURLSearchString, Status } from 'Components/utils';
import React from 'react';

import { Actions, makeEntityReducer, makeInitialState, RemoteEntityState } from './entity.state';
import { RemoteMethods } from 'Components/Remote/remoteMethods';

/**
 * useRemoteEntity
 * Hook that allows to manipulate remote entities with methods 
 * create(), open(), save(), patch(), delete(),
 * given an endpoint that supports corresponding HTTP methods
 * POST, GET, PUT, PATCH, DELETE
 */

export interface RemoteEntityProps<TModel, TQueryParams = Record<string, never>, TModelMini = TModel> {
	endpoint: string,
	copyToEndpoint?: string,
	instanceId?: string,
	idKey: keyof TModel,
	minify?: (model: TModel) => TModelMini,
	minifyPartial?: (model: Partial<TModel>) => Partial<TModelMini>,
	defaultValue?: TModel,
	defaultQueryParams?: TQueryParams,
	addDefaultQueryParamsToBody?: boolean,
	remoteCreation?: boolean,
	updateMethod?: 'PUT' | 'PATCH',
	afterReading?: (value: TModel) => TModel,  
	beforeWriting?: (value: TModel) => TModel  
}

export interface RemoteEntityReadOptions { 
	asNew?: boolean, 
	newId?: string | number, 
	remoteClone?: boolean,
}

export interface RemoteEntityMethods<TModel, TQueryParams = any> {
	// The following async methods return a function that aborts the HTTP request, to be used by React components on unmounting.
	create(model?: TModel): void,
	read(id: string | number, queryParams?: TQueryParams, options?: RemoteEntityReadOptions): void,
	save(data: TModel, options?: {asNew?: boolean, method?: 'PUT' | 'PATCH'}): void,
	delete(): void
}

interface ApiProps<TModel, TQueryParams = any, TModelMini = TModel> extends RemoteEntityProps<TModel, TQueryParams, TModelMini> {
	remote: RemoteMethods,
	dispatch: React.Dispatch<Actions<TModel>>,
	cancelable: MakeCancelable<any>
}
class EntityMethodsImpl<TModel, TQueryParams = Record<string, never>, TModelMini = TModel>
	implements ApiProps<TModel, TQueryParams, TModelMini>, RemoteEntityMethods<TModel, TQueryParams> {

	readonly endpoint: string;
	readonly copyToEndpoint?: string;
	readonly instanceId?: string;
	readonly idKey: keyof TModel;
	readonly remoteCreation: boolean;
	readonly defaultValue?: TModel;
	readonly defaultQueryParams?: TQueryParams; 
	readonly addDefaultQueryParamsToBody?: boolean;
	readonly updateMethod?: 'PUT' | 'PATCH';
	readonly minify: (model: TModel) => TModelMini;
	readonly minifyPartial: (model: Partial<TModel>) => Partial<TModelMini>;
	readonly afterReading?: (value: TModel) => TModel;
	readonly beforeWriting?: (value: TModel) => TModel;
	readonly remote: RemoteMethods;
	readonly dispatch: React.Dispatch<Actions<TModel>>;
	readonly cancelable: MakeCancelable<any>;
	_state: RemoteEntityState<TModel>;

	constructor(props: ApiProps<TModel, TQueryParams, TModelMini>) {
		this.instanceId = props.instanceId;
		this.endpoint = props.endpoint;
		this.copyToEndpoint = props.copyToEndpoint;
		this.idKey = props.idKey;
		this.remoteCreation = !!props.remoteCreation;
		this.defaultValue = props.defaultValue;
		this.defaultQueryParams = props.defaultQueryParams;
		this.addDefaultQueryParamsToBody = props.addDefaultQueryParamsToBody;
		this.updateMethod = props.updateMethod ?? 'PUT';
		this.minify = props.minify ?? ((model: TModel) => (model as any as TModelMini));
		this.minifyPartial = props.minifyPartial ?? ((model: Partial<TModel>) => (model as any as Partial<TModelMini>));
		this.beforeWriting = props.beforeWriting;
		this.afterReading = props.afterReading;
		this.remote = props.remote;
		this.dispatch = props.dispatch;
		this.cancelable = props.cancelable;

		this._state = makeInitialState(props.defaultValue);
	}

	create(model?: TModel) {
		const { dispatch, remote } = this;
		if (!model && this.remoteCreation) {
			const url = this.makeUrl(undefined, this.defaultQueryParams, this.copyToEndpoint);
			dispatch(['READ_REQUEST']);
			const [$result, abort] = remote.cleanFetch(url);
			$result.then(
				(data: TModel) => {
					let processedData = data;
					if (this.afterReading) {
						processedData = this.afterReading(data);
					}
					dispatch(['READ_SUCCESS', processedData]);
				},
				(error: any) => dispatch(['READ_FAILURE', error])
			);
			return abort;
		} else {
			this.dispatch(['CREATE', model || undefined]);
		}
	}

	read = (id: string | number, queryParams?: TQueryParams, options?: RemoteEntityReadOptions) => {
		const { dispatch, remote } = this;
		this.dispatch(['READ_REQUEST']);
		
		const _queryParams: any = { ...this.defaultQueryParams, ...queryParams,  };
		if (options?.remoteClone) {
			_queryParams['clone'] = true;
		}
		
		const url = this.makeUrl(id, _queryParams);
		
		const [$result, abort] = remote.cleanFetch(url);
		
		$result.then(
			(data: TModel) => {
				let processedData = data;
				if (this.afterReading) {
					processedData = this.afterReading(data);
				}
				if (options?.asNew) {
					processedData[this.idKey] = options.newId as any
				}
				dispatch(['READ_SUCCESS', processedData]);
			},
			(error: any) => dispatch(['READ_FAILURE', error])
		);
		return abort;
	}

	save = (data: TModel, options?: {asNew?: boolean}) => {
		const { dispatch, remote, _state: state } = this;
		if (state.readError) {
			dispatch(['UPSERT_FAILURE', 'No entity to update']);
		}
		
		let id = data[this.idKey] as any;
		if (options?.asNew)
			id = undefined;
		
		let _data = data;
		if (this.beforeWriting) {
			_data = this.beforeWriting(data);
		}
		
		let bodyData = this.minify ? this.minify(_data) : _data;
		let queryParams = this.defaultQueryParams;
		if (this.addDefaultQueryParamsToBody) {
			queryParams = undefined;
			if (this.defaultQueryParams)
				bodyData = {...bodyData, ...this.defaultQueryParams}
		}
		
		const url = this.makeUrl(id, queryParams, this.copyToEndpoint);
		
		const body = JSON.stringify(bodyData);

		dispatch(['UPSERT_REQUEST']);
		
		return this.cancelable(remote.fetch(url, { 
			method: id ? this.updateMethod : 'POST', 
			body 
		}).then(
			(result: any) => {
				let saved = { ...data };
				if (typeof result === 'object') {
					saved = {...data, ...result}
				}
				else if (typeof result === 'string' || typeof result === 'number') {
					saved[this.idKey] = result as any;
				}
				dispatch(['UPSERT_SUCCESS', saved]);
			},
			(error: any) => {
				dispatch(['UPSERT_FAILURE', error]);
			}
		));
	}

	delete = () => {
		const { dispatch, remote, _state: state } = this;
		const id = state.data ? state.data[this.idKey] : undefined;
		if (!id || state.readError) {
			dispatch(['DELETE_FAILURE', 'No entity to delete']);
		}
		const url = this.makeUrl(id as any, this.defaultQueryParams, this.copyToEndpoint);
		dispatch(['DELETE_REQUEST']);
		this.cancelable(remote.fetch(url, { method: 'DELETE' })).then(
			(isSuccess: boolean) => {
				if (isSuccess) {
					dispatch(['DELETE_SUCCESS']);
				} else {
					dispatch(['DELETE_FAILURE', "Remote service failed to delete entity"]);
				}
				return true;
			},
			(error: any) => {
				dispatch(['DELETE_FAILURE', error]);
				return false;
			}
		);
	}

	private makeUrl = (id?: string | number, queryParams?: any, alternativeURL?: string): string => {
		let queryString = '';
		let urlPath = '';
		const _endpoint = alternativeURL ?? this.endpoint;
		if (this.endpoint.includes(':id')) { // parametric route
			if (id) {
				// Create query string and dynamic route
				const { path, search } = 
					objectToURLPathAndSearchString({...queryParams, id}, _endpoint);
				queryString = search;
				urlPath = path;	
			}
			else {
				throw new Error("Missing id for dynamic URL path " + this.endpoint);
			}
		} else {
			queryString = objectToURLSearchString(queryParams);
			urlPath = _endpoint;
			if (id)
				urlPath += `/${id}`;
		}
		return urlPath + (queryString.length ? `?${queryString}` : ''); 
	}
}



const _apiCache = new Map();

function _getCachedApi<TModel, TQueryParams = Record<string, never>, TModelMini = TModel>(props: ApiProps<TModel, TQueryParams, TModelMini>): EntityMethodsImpl<TModel, TQueryParams, TModelMini> {

	let cachedApi = _apiCache.get(props.dispatch);
	if (cachedApi) {
		// extract from cached object only the props that exist in the arg
		const sharedProps = Object.fromEntries(
			Object.entries(cachedApi).filter(([key]) => Object.keys(props).includes(key))
		);
		if (!deep2Equal(props, sharedProps)) {
			cachedApi = undefined;
		}
	}
	if (!cachedApi) {
		cachedApi = new EntityMethodsImpl(props);
		console.log(`Created new RemoteEntityApi for ${props.endpoint} -> ${props.copyToEndpoint ?? props.endpoint}`, cachedApi);
		_apiCache.set(props.dispatch, cachedApi);
	}
	return cachedApi;
}

export type RemoteEntityApi<TModel, TQueryParams = Record<string, never>, TModelMini = TModel> = [
	RemoteEntityState<TModel>,
	RemoteEntityMethods<TModel, TQueryParams>
]

export function useRemoteEntity<
	TModel,
	TQueryParams = Record<string, never>,
	TModelMini = TModel
>(props: RemoteEntityProps<TModel, TQueryParams, TModelMini>): RemoteEntityApi<TModel, TQueryParams, TModelMini> {

	const { endpoint, defaultValue } = props;

	const reducer = React.useMemo(() => makeEntityReducer<TModel>(endpoint), [endpoint]);
	const initialState = React.useMemo(() => makeInitialState(defaultValue), [defaultValue]);

	const [state, dispatch] = React.useReducer(reducer, initialState);

	const remote = useRemoteMethods();

	const cancelable = useCancelable<any>();

	const methods = _getCachedApi<TModel, TQueryParams, TModelMini>({
		...props,
		dispatch,
		remote,
		cancelable
	});
	methods._state = state;

	return [state, methods];
}

