import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

type FontSizeOptions = {
    types: string[];
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontSize: {
            /**
             * Set the font size attribute
             */
            setFontSize: (size: string) => ReturnType;
            /**
             * Unset the font size attribute
             */
            unsetFontSize: () => ReturnType;
        };
    }
}

export const FontSize = Extension.create<FontSizeOptions>({
    name: 'fontSize',

    addOptions(): FontSizeOptions {
        return {
            types: ['textStyle'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => { 
                            let val = element.style.fontSize.replace(/['"]+/g, ''); 
                            if (val.length === 0) val = '11';
                            //console.log('PARSE FONT SIZE', val); 
                            return val; 
                        },
                        renderHTML: attributes => {
                            if (!attributes.fontSize) {
                                return {};
                            }
                            //console.log('RENDER FONT SIZE', attributes.fontSize); 
                            return {
                                style: `font-size: ${attributes.fontSize}`,
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setFontSize: fontSize => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize })
                    .run();
            },
            unsetFontSize: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .run();
            },
        };
    },
});