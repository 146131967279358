import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@mui/material';

import { Option, Rules } from './commons';

export type { Option, Rules } from './commons';

export function HookedSelect<
  TFieldValues extends FieldValues, 
  Key extends Path<TFieldValues>
  >(props: 
    Omit<FormControlProps, "children"> 
    & {
      name: Path<TFieldValues>,
      control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
      rules?: Rules<TFieldValues, Path<TFieldValues>>,
      options: Option<TFieldValues, Key>[],
      label?: string
    }
) {
  const { name, control, rules, options, label, disabled, onChange, ...otherProps } = props;
  return (
    <FormControl {...otherProps}>
      <InputLabel id={name + '-label'}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field }) => {
          const validValues = options.map(x => x.value);
          return (
            <Select
              labelId={name + '-label'}
              label={label}
              id={name}
              aria-label={props.label}
              name={field.name}
              disabled={disabled}
              value={field.value !== undefined && validValues.includes(field.value) ? field.value : ''}
              ref={field.ref}
              onBlur={field.onBlur}
              onChange={(...evt) => {
                field.onChange(...evt);
                const val = evt[0].target.value;
                //console.log("SELECT CHANGE", val, evt);
                //onChange && onChange(evt[0].target.value);
              }}
            >
              {props.options.map(
                (option, index) => <MenuItem key={index.toString()} value={option.value}>{option.label}</MenuItem>
              )}
            </Select>
          )
        }}
      />
    </FormControl>
  );
}



