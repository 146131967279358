import {Button} from "@mui/material";
import ProgressBackdrop from "Components/Feedback/ProgressBackdrop";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DialogX} from "../Components/Containers/DialogX";
import {useRemoteResourceQueryService} from "../Components/RemoteEntities/resource.services";
import {Status} from "../Components/utils";
import {useCustomerPageURLQueryParams} from "./CustomerPage";
import {useCurrentUrlCredentials, useHashAuthorizedApiRoute} from "./HashCredentialsProvider";

export interface IliasAuthState {
    credentialsQueryString?: string,
    error?: string,
    userProfileUrl?: string
}

const IliasContext = React.createContext<(IliasAuthState & { isReady: true }) | { isReady: false }>({isReady: false});

export const IliasAuthProvider = (props: { children: React.ReactNode }) => {

    const urlQueryParams = useCustomerPageURLQueryParams();
    const authPath = useHashAuthorizedApiRoute();
    const [state, fetch] = useRemoteResourceQueryService<IliasAuthState, null>({
        endpoint: `${authPath}/iliasCredentials?language=${urlQueryParams.language}`,
        defaultValue: null
    });

    useEffect(() => {
        if (state.status === Status.Idle) {
            fetch({});
        }
    });

    return <IliasContext.Provider value={{
        ...state.data,
        isReady: state.status !== Status.Idle && state.status !== Status.Pending
    }}>{props.children}</IliasContext.Provider>
}

export const useIliasContext = () => React.useContext(IliasContext);

/**
 * There are two formats of InfoLearn URLs:
 * 1) https://training.infominds.eu/ilias.php?baseClass=ilLMPresentationGUI&ref_id=779&cmd=resume  
 *    This does not work. Authentication is succsesful, but then redirects to the user's home in training.infominds.eu
 * 2) https://training.infominds.eu/ilias.php?target=lm_779
 *    This does work.
 * So we need to rewrite URLs like 1) as 2) 
 * @param url
 */
const normalizeUrl = (url: string): string => {
    
    const pattern = /ref_id=(\d+)/;
    const _match = url.match(pattern);
    if (_match) {
        const refId = _match[1];
        url = `https://training.infominds.eu/ilias.php?target=lm_${refId}&client_id=infominds`; 
    }
    return url;
}
export const IliasLinkHandler = (props: { children: React.ReactNode }) => {

    const iliasContext = useIliasContext();
    const [showDialog, setShowDialog] = useState<{ profileUrl: string } | null>(null);
    const [showNotReady, setShowNotReady] = useState(false);
    const [t] = useTranslation();
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        
        const target = e.nativeEvent.target as HTMLElement;
        
        let url: string | undefined = undefined;
        
        if (target.tagName == 'A') {
            url = (target as HTMLAnchorElement).href;
        }
        if (!url) return;

        if (url.includes("training.infominds.eu")) {

            if (iliasContext.isReady) {
                if (iliasContext?.credentialsQueryString) {
                    url = normalizeUrl(url); // if url has ref_id query param, it is the format that does not work (https://training.infominds.eu/ilias.php?baseClass=ilLMPresentationGUI&ref_id=779&cmd=resume)
                    const urlWithCredentials = url + '&' + iliasContext?.credentialsQueryString;
                    console.log(`Navigating to ${urlWithCredentials}`);
                    window.open(urlWithCredentials);
                } else if (!!iliasContext?.userProfileUrl) {
                    setShowDialog({profileUrl: iliasContext.userProfileUrl});
                }
            } else {
                setShowNotReady(true);
            }
            e.stopPropagation();
            e.preventDefault();
        }
    }

    const isReady = iliasContext.isReady;
    useEffect(() => {
        if (isReady) setShowNotReady(false);
    }, [isReady]);
    const onClickYes = () => {
        const url = showDialog?.profileUrl;
        if (!!url) {
            console.log(`Navigating to ${url}`);
            window.open(url);
            setShowDialog(null);
        }
    }
    const onClickNo = () => {
        setShowDialog(null);
    }

    return <div onClick={handleClick} className='InfoLearnLinkHandler'>
        {props.children}
        <ProgressBackdrop open={showNotReady}/>
        <DialogX title={'InfoLearn'}
                 open={showDialog !== null}
                 bottomToolbar={<>
                     <Button onClick={onClickYes}>{t('yes')}</Button>
                     <Button onClick={onClickNo}>{t('no')}</Button>
                 </>}
        >
            <p>{t('askToProfileForInfoLearn')}</p>
        </DialogX>
    </div>
}