import Iconify from 'Components/iconify';
import { StyledSearch } from 'Components/Input/StyledSearch';
import { useCallback } from 'react';

import { InputAdornment, Typography } from '@mui/material';

import { StyledToolbar } from './StyledToolbar';

// ----------------------------------------------------------------------

export default function ToolbarWithTitleAndSearch(props: {
   title?: string,
   searchValue?: string,
   onSearchChanged: (value: string) => void,
}) {

   const { onSearchChanged } = props;
   
   const handleSearchChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((evt) => { onSearchChanged(evt.currentTarget.value)}, [onSearchChanged]);

   return (
      <StyledToolbar>
         <Typography variant='h5'>{props.title}</Typography>
         <StyledSearch
            value={props.searchValue ?? ''}
            onChange={handleSearchChanged}
            placeholder="Search..."
            startAdornment={
               <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
               </InputAdornment>
            }
         />
      </StyledToolbar>
   );
}

