import { SxProps, Theme } from "@mui/material";
import { useCallback, useMemo } from "react";

import { SimpleEntity } from "./simpleEntity.models";
import { useDescriptionGetter } from "./simpleEntity.models";
import { Control, FieldValues, Path } from "react-hook-form";
import { HookedAutocompleteOnBlurRemote, Rules } from "Components/HookedForm/inputControls";



const _empty = {};
const _valueMatcher = (option?: SimpleEntity, value?: SimpleEntity) => {
    if (option) return option.id === value?.id;
    else return value === undefined;
};

export function SimpleEntityAutocompleteHookedOnBlur<TFieldValues extends FieldValues, TPath extends Path<TFieldValues>>(props: {
    control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
    name: Path<TFieldValues>,
    endpoint: string,
    rules?: Rules<TFieldValues, Path<TFieldValues>>,
    label?: string,
    disabled?: boolean,
    sx?: SxProps<Theme>
}) {

    const { sx, ...otherProps } = props;
    const _sx = useMemo(() => ({maxWidth: 600, minWidth: 300, ...sx}), [sx]);

    const getDescription = useDescriptionGetter();

    const getOptionLabel = useCallback((entity?: SimpleEntity) => entity ? getDescription(entity) : '', [getDescription]);

    const isOptionEqualToInput = useCallback((option: SimpleEntity | undefined, textInput: string) => {
        if (!option) return textInput === '';
        const input = textInput.toLowerCase();
        if (option.id.toString().includes(input)) {
            return true;
        }
        const desc = getDescription(option);
        return desc.toLowerCase().includes(input);
    }, [getDescription]);

    return <HookedAutocompleteOnBlurRemote<TFieldValues, TPath> 
        sx={_sx}
        query={_empty}
        isOptionEqualToValue={_valueMatcher}
        getOptionLabel={getOptionLabel}
        isOptionEqualToInput={isOptionEqualToInput}
        disabled={props.disabled}
        {...otherProps} 
    />
}
