import React, { useCallback } from 'react';
import NumberFormat, {
  NumberFormatPropsBase, NumberFormatValues, SourceInfo
} from 'react-number-format';

import {
  Box, BoxProps, darken, lighten, Paper, PaperProps, TableCell, useTheme
} from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';

export const getGreyStyle = (theme: Theme) => {
  const bg = (theme.palette.mode === 'light' ? darken : lighten)(theme.palette.background.paper, 0.3);
  return {
    backgroundColor: bg,
    color: theme.palette.getContrastText(bg)
  };
}
export const useGreyStyle = () => {
  const theme = useTheme();
  return getGreyStyle(theme);
}

export const getLightGreyStyle = (theme: Theme) => {
  const bg = (theme.palette.mode === 'light' ? darken : lighten)(theme.palette.background.paper, 0.1);
  return {
    backgroundColor: bg,
    color: theme.palette.getContrastText(bg)
  };
}

export const getYellowStyle = (theme: Theme) => {
  const bg = theme.palette.mode === 'light' ? '#f9ed86' : '#f4dc0b';//  //yellow[200] : yellow[800];
  return {
    backgroundColor: bg,
    color: theme.palette.getContrastText(bg)
  };
}

export const computedValuesBackground = (theme: Theme) => (theme.palette.mode === 'light' ? lighten : darken)(theme.palette.warning.main, .7);


export function FormCard(props: {
  children: React.ReactNode,
  actions?: React.ReactNode,
  vertical?: boolean,
  title?: string,
  rootProps?: PaperProps,
  titleSize?: 'small' | 'normal'
} & PaperProps) {
  const { children, vertical, title, titleSize, rootProps, ...rest } = props;
  const theme = useTheme();
  return (
    <Box {...props.rootProps} sx={{ 
      position: 'relative', 
      my: props.actions ? 2 : 1, 
      mx: 1, 
      ...props.rootProps?.sx 
    }}>
      {props.title &&
        <Box sx={{
          position: 'absolute', top: '-10px', left: '12px',
          px: 1,
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.background.paper,
          fontSize: props.titleSize === 'small' ? '80%' : '100%'
        }}>
          {title}
        </Box>}
      {props.actions &&
        <Paper sx={{ position: 'absolute', top: '-15px', right: '4px'}}>
          {props.actions}
        </Paper>}
      <Paper variant='outlined' {...rest} sx={{
        p: 1,
        my: 0,
        ...(!!vertical
          ? { display: 'block' }
          : { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }),
        ...rest?.sx
      }}>
        {children}
      </Paper>
    </Box>
  );
}


export interface NonEditableFieldProps {
  value: string | number | null | undefined,
  plain?: boolean,
  inline?: boolean
  sx?: SxProps<Theme>
}
export const NonEditableField = (props: NonEditableFieldProps) => {
  let sx: SxProps<Theme> = {
    display: props.inline ? 'inline-block' : 'block',
    // If empty, needs pseudo-content to align vertically as expected 
    '&:empty::before': {
      content: '""',
      display: 'inline-block'
    }
  }
  if (!props.plain) {
    sx = {
      minHeight: '28px',
      borderStyle: 'solid',
      borderWidth: 0.5,
      borderColor: 'rgba(0,0,0,.2)',
      borderRadius: 1,
      py: '3px',
      px: 3,
      m: 2,
      ...sx,
    }
  }
  return <Box component='span' sx={{ ...sx, ...props.sx }}>
    {props.value === null || props.value === undefined ? ' ' : props.value}
  </Box>;
};


export type FormatNumberProps = NumberFormatPropsBase & {
  //value: string | number | null | undefined,
  decimals?: number,
  forceDecimals?: boolean,
  thousandsSep?: string | false,
  currency?: boolean,
  plain?: boolean,
  inline?: boolean,
  sx?: SxProps<Theme>
}

export const FormatNumber = (props: FormatNumberProps) => {
  const { sx, inline, plain, decimals, forceDecimals, thousandsSep, currency, value, ...otherProps } = props;
  const decimalScale = decimals !== undefined && decimals >= 0 ? decimals : (currency ? 2 : undefined);
  const fixedDecimalScale = !!forceDecimals || !!currency;
  return (
    <NumberFormat
      customInput={NonEditableField}
      suffix={currency ? ' \u20AC' : undefined}
      thousandSeparator={thousandsSep === false ? undefined : (thousandsSep || '.')}
      decimalSeparator=','
      {...{ decimalScale, fixedDecimalScale }}
      value={value ?? ''}
      sx={{ textAlign: 'right', whiteSpace: 'nowrap', ...sx }}
      plain={props.plain}
      inline={props.inline}
      {...otherProps}
    />
  )
}



