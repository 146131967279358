import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function DialogX(props: Omit<DialogProps, 'onClose'> & {
  onClose?: () => void
  title?: string,
  titleProps?: DialogTitleProps,
  bottomToolbar?: React.ReactNode
}) {
  if (!props.onClose && !props.title) {
    return null;
  }
  const { onClose, title, titleProps, bottomToolbar, ...otherProps } = props;
  return (
    <Dialog onClose={onClose} maxWidth='lg' {...otherProps}>
      <DialogTitle sx={{ m: 0, p: 2 }} {...titleProps}>
        {title}
        {onClose ? (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'hidden' }}>
        {props.children}
      </DialogContent>
      {props.bottomToolbar && (
        <DialogActions>
          {props.bottomToolbar}
        </DialogActions>
      )}
    </Dialog>)
}