import { withArrayActionLogger, withLogger } from "Components/reduxLogger";
import { FaqAbstract, FaqVerbose } from "./faq.models";
import { EmptyObject } from "react-hook-form";
import { deepFreeze } from "Components/utils";

interface NewFaqState {
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: EmptyObject, // hack to clear new-faq form when a new faq has been saved
    abstract: FaqAbstract | undefined,
    expanded: boolean,
    scrollY?: number
    newSoftwareCode?: string 
}

const makeNewFaqSlot = (): NewFaqState => ({
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: {},
    abstract: undefined,
    expanded: false,
});

const makeClonedFaqSlot = (abstract: FaqAbstract, cloneToSoftwareCode?: string): NewFaqState => ({
    id: 0,
    show: true,
    isEditing: true,
    clearEntity: {},
    abstract: JSON.parse(JSON.stringify(abstract)), // deep copy
    expanded: true,
    newSoftwareCode: cloneToSoftwareCode
});

export interface FaqViewState {
    show: boolean,
    abstract: FaqAbstract,
    expanded: boolean,
    details?: FaqVerbose,
    scrollY?: number
}

export type ExistingFaqState = FaqViewState & {
    id: number,
    isEditing: boolean,
}
export type FaqState = ExistingFaqState | NewFaqState;
export const getAbstracts = (state: FaqState[]) => state.filter(item => !isNew(item)).map(item => item.abstract) as FaqAbstract[];

export function isNew(item: FaqState): item is NewFaqState {
    return ((item as NewFaqState).id === 0);
}

export const getCloneSetting = (state: FaqState) => {
    if (isNew(state) && !!state.abstract) return {
        isClone: true,
        originSoftwareCode: state.abstract?.softwareCode,
        isAcrossSoftwares: !!state.newSoftwareCode && state.abstract.softwareCode != state.newSoftwareCode
    }
    else return {
        isClone: false,
    }
}

export type FaqsCollectionAction =
    | ['SET_COLLECTION', FaqAbstract[]]
    | ['OPEN', { id: number, scrollY?: number }]
    | ['CLONE', number]
    | ['SET_CLONE_MODEL', { cloneModel: FaqAbstract, cloneDestinationSoftwareCode: string  }]
    | ['CLOSE', number]
    | ['FILTER', number[]]
    | ['REORDER', number[]]
    | ['SAVE_NEW', FaqViewState]
    | ['UPDATE', { id: number, patch: Partial<FaqViewState> }]
    | ['DELETE', number]

const _itemsReducer = (state: FaqState[], action: FaqsCollectionAction): FaqState[] => {

    switch (action[0]) {
        
        case 'SET_COLLECTION': {
            
            let initialClonedItem: FaqState | undefined = undefined;
            if (state.length > 0 && getCloneSetting(state[0]).isAcrossSoftwares) {
                initialClonedItem = state[0];
            }
            
            const newItems: FaqState[] = (action[1].length > 0 || initialClonedItem) 
                ? action[1].map(abstract => deepFreeze({
                    id: abstract.id,
                    show: true,
                    abstract: Object.freeze(abstract),
                    expanded: false,
                    isEditing: false
                })) : [makeNewFaqSlot()];
            
            return !!initialClonedItem ? [initialClonedItem, ...newItems] : newItems;
        }

        case 'SET_CLONE_MODEL': {

            if (getCloneSetting(state[0]).isAcrossSoftwares) {
                return state; // we do not create a new clone if there is one open already
            }

            const initialClonedItem = makeClonedFaqSlot(action[1].cloneModel, action[1].cloneDestinationSoftwareCode);

            return [initialClonedItem, ...state];
        }

        case 'FILTER': {
            // sets show = false for items that are not in the given IDs array;
            // excepts for the new item (id === 0), which must always have show=true
            return state.map(item => isNew(item) ? item : ({ ...item, show: action[1].includes(item.id)}));
        }

        case 'REORDER': {  // re-arranges items according to the given IDs array            
            const reorderedIds = action[1];

            const newState: FaqState[] = [];

            reorderedIds.forEach(id => {
                const faq = state.find(x => x.id === id);
                if (faq) {
                    newState.push(faq);
                }
            });

            if (newState.length !== state.length) {
                console.error("Missing IDs in collection re-sorting");
                return state;
            }
            return newState;
        }

        case 'OPEN': {  // sets editing mode on for given id
            const {id, scrollY} = action[1];
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                if (id === 0) {

                    return [makeNewFaqSlot(), ...state];

                } else {
                    console.warn(`Opening Item with id ${id} for editing: item not found`);
                }

            } else { // item found 

                if (id !== 0) { // is existing item

                    state[pos] = {...state[pos], scrollY, isEditing: true};
                    return [...state];

                } // else no change in state because new item has always isEditing === true
            }
            return state;
        }

        case 'CLONE': {
            const id = action[1];
            const original = state.find(x => x.id === id);
            if (!original) {
                console.warn(`Cloning Item with id ${id} for editing: item not found`);
            } else if (isNew(original)) {
                console.warn(`Cannot clone new faq. Use '[OPEN 0]' instead`);
                return state;
            } else {
                return [makeClonedFaqSlot(original.abstract), ...state];
            }
            return state;
        }

        case 'CLOSE': {  // sets editing mode off for given id
            const id = action[1];
            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.warn(`Closing Item with id ${id}: item not found`);
                return state;

            } else { // item found (existing or new, doesn't matter)

                const item = state[pos];
                const newState = [...state];
                if (!isNew(item)) {
                    newState[pos] = {...item, isEditing: false, scrollY: undefined};
                } else {
                    newState.splice(pos, 1);
                }
                return newState;
            }
        }

        case 'DELETE': {  // discards item
            const id = action[1];

            const pos = state.findIndex(x => x.id === id);

            if (pos === -1) {

                console.warn(`Deleting Item with id ${id}: slot not found`);
                return state;

            } else {

                const newState = [...state];
                newState.splice(pos, 1);
                return newState;
            }
        }

        
        case 'SAVE_NEW': {  // converts new item (id === 0, no abstract) into a legit item with id, abstract, details
            const data = action[1];
            const newId = data.abstract.id;

            const pos = state.findIndex(x => x.id === 0);
            if (pos === -1) {

                console.error("Creating Item: new-item slot not found");
                return state;

            } else {

                const newState = [...state];
                newState[pos] = {id: newId, ...data, isEditing: false}
                return newState;
            }
        }

        case 'UPDATE': {  // updates existing item 
            const {id, patch} = action[1];

            if (id === 0) {
                console.error(`UPDATE not allowed for new item, use 'CREATE' instead`);
            }

            const pos = state.findIndex(x => x.id === id);
            if (pos === -1) {

                console.error(`Updating Item with id ${id}: item not found`);
                return state;

            } else {

                const item = state[pos] as ExistingFaqState;
                const newState = [...state];
                newState[pos] = {...item, ...patch};
                return newState;
            }
        }

        default:
            return state;
    }
};



interface FaqsManagerState {
    items: FaqState[],
    scrollY?: number
}

const _reducer = (
    state: FaqsManagerState, 
    action: FaqsCollectionAction
): FaqsManagerState => {

    if (action[0] === 'OPEN' || action[0] === 'CLONE') {
        return {
            scrollY: (action[0] == 'CLONE' || action[1].id == 0) ? 0 : undefined,
            items: _itemsReducer(state.items, action)
        }
    }
    if (action[0] === 'CLOSE') {
        const item = state.items.find(x => x.id === action[1]);
        if (item) {
            return {
                scrollY: item.scrollY,
                items: _itemsReducer(state.items, action)
            }
        }
    }
    return {...state, items: _itemsReducer(state.items, action)};
}

export const faqsManagerReducer = withArrayActionLogger(_reducer, 'FaqsManagerState');

