import { useGuardedExec } from 'Components/GuardService';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

import {
  Box, Collapse, darken, Icon, lighten, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemIconProps, ListItemText, Theme,
} from '@mui/material';

import { NavNode, useOpenMenu, useNavigator } from './NavProvider';
import { isCommand } from './navTree';


const getNameTranslationKey = (menu: NavNode) => menu.children.length > 0 ? menu.fullName + '.root' : menu.fullName;


//************************************************************* */
//* Menu Item

const defaultIndentStep = 16;

type ListItemButtonStyledProps = ListItemButtonProps & {
  active: boolean,
  nestingLevel: number
}

const ListItemButtonStyled = styled(ListItemButton, { shouldForwardProp: (prop: any) => !['active', 'nestingLevel'].includes(prop) })<ListItemButtonStyledProps>(
  ({ theme, active, nestingLevel }) => ({
    height: 48,
    marginLeft: 2 + nestingLevel <= 1 ? 0 : (nestingLevel) * defaultIndentStep,
    // py: 0.5,
    // mt: nestingLevel === 0 ? 2 : 0,
    // alignItems: 'baseline',
    '& .MuiListItemText-root': {
      whiteSpace: 'break-spaces'
    },
    // '& .MuiTypography-root': {
    //   lineHeight: 1.2,
    // },
    //textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: 1,
    ...active ? {
      color: 'text.primary',
      bgcolor: 'action.selected',
      fontWeight: 'fontWeightBold',
    } : {}
  })
);

const ListItemIconStyled = styled(ListItemIcon)(() => ({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))


interface NavMenuItem {
  node: NavNode,
  nestingLevel: number
  key?: number,
}
export function NavMenuItem(props: NavMenuItem) {

  const { node, nestingLevel } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const amCommand = isCommand(node);

  const routeMatch = useRouteMatch();

  let amActive = false;
  if (!amCommand) {
    amActive = routeMatch
      && routeMatch.url === node.absolutePathWithParamValues
      && (routeMatch.isExact || !node.isExactPath)
      && !!node.component;
  }

  const setOpenMenu = useOpenMenu()[1];

  const history = useHistory();
  const { push: historyPush } = history;

  const guardedExec = useGuardedExec();

  const handleClick = () => {
    if (isCommand(node)) {
      node.command();
    } else {
      setOpenMenu(node.fullName);
      if (node.component) {
        guardedExec(() => historyPush(node.absolutePathWithParamValues));
      }
    }
  }

  return (
    <ListItemButtonStyled disableGutters
      id={node.fullName}
      onClick={handleClick}
      disabled={node.disabled}
      active={amActive}
      nestingLevel={nestingLevel}
    >
      <ListItemIconStyled>
        {node.Icon ? <node.Icon theme={theme} /> : null}
      </ListItemIconStyled>

      {
        node.renderName
          ? <node.renderName/>
          : <ListItemText primary={t(getNameTranslationKey(node))}
              //primaryTypographyProps={{ sx: { color, paddingLeft: '12px', fontWeight: fontWeight ?? 'normal' } }}
            />
      }
    </ListItemButtonStyled>
  );

  // return node.relativePath === undefined
  // ? renderedMenuItem
  // : <Link
  //     component={NavLink}
  //     to={node.absolutePath as string} // Router links do not support relative paths
  //     underline='none'
  //     color={styling.color}
  //     classes={{ root: `color=${styling.color}` }}
  //     TypographyClasses={{ root: `color=${styling.color}` }}
  //   >
  //     {renderedMenuItem}
  //   </Link>
}

//************************************************************* */
//* Menu Tree
//  Renders a menu and sub-menus recursively in a collapsible list

interface NavMenuTreeProps {
  node: NavNode,
  nestingLevel: number
  key?: number,
}
function NavMenuTree(props: NavMenuTreeProps) {
  const { node } = props;
  //console.log("MenuTreeView", node.fullName);
  return (
    <Route path={node.absolutePath}>
      {({ match, location, history }) => <>
        {!!node.parent && // render menu item only if it's not the root node of the tree
          <NavMenuItem
            node={node}
            nestingLevel={props.nestingLevel}
          />
        }
        {!!node.children && <NavMenuChildren {...props} />}
      </>
      }
    </Route>
  )
}

function NavMenuChildren(props: NavMenuTreeProps) {

  const { node, nestingLevel } = props;
  const openMenuFullName = useOpenMenu()[0];

  //console.log("MENU CHILDREN VIEW", openMenuFullName, node.fullName);

  const isExpanded = !!openMenuFullName && node.isAncestorOf(openMenuFullName);

  return (
    <Collapse in={isExpanded}>
      <List sx={{ pt: 0, pb: 0, mr: '4px' }} id={node.fullName + '-children'}>
        {node.children.map((child: NavNode, index: number) => {
          return isCommand(node)
            ? <> </>
            : <NavMenuTree
              key={1 + index}
              nestingLevel={1 + nestingLevel}
              node={child}
            />
        })}
      </List>
    </Collapse>
  );
}

export function NavMenu() {
  const context = useNavigator();
  return <NavMenuTree node={context.rootNode} nestingLevel={-1} />;
}
