import {Status} from "Components/utils";
import {useReleases} from "./release.services";
import {ReleasedSoftware, ReleaseFilters, ReleaseHeadVerbose} from "./release.models";
import React, {useContext, useState} from "react";
import {CircularProgress, ListItemButton, ListItemText, TableCell, TableRow} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {ReleaseListItem} from "./ReleaseListItem";
import {RemoteCollectionPagination} from "Components/RemoteEntities/Collection/Pagination";
import {ReleasesManagerContext} from "./ReleasesManagerContext";

export const SoftwareReleaseList = (props: { software: ReleasedSoftware, withPdf?: boolean, filters: ReleaseFilters }) => {

    const {software} = props;

    const [context, dispatchContext] = useContext(ReleasesManagerContext);

    const [open, setOpen] = useState(context.expandedSoftwares.includes(software.code));
    
    //const activeFilters = open ? props.filters : null;
    const activeFilters = open ? {
        headFilter: {...props.filters.headFilter, software},
        notesFilter: props.filters.notesFilter,
    } : null;

    const [collection, collectionApi] = useReleases({filters: activeFilters, pageSize: 5});

    console.log("collection", collection);
    console.log("filters", props.filters);
    console.log("activeFilters", activeFilters);

    const {entities, isPaginated, status} = collection;
    const toggleOpenClose = () => {
        setOpen(val => !val);
        dispatchContext(['EXPAND_COLLAPSE_SOFTWARE', software.code]);
    }

    return <>
        <TableRow key={software.code} id={software.code}>
            <TableCell key={software.code} colSpan={6} size={'small'} sx={{py: 0}}>
                <ListItemButton onClick={toggleOpenClose} sx={{py: 1, justifyContent: 'flex-start'}}>
                    <ListItemText
                        sx={{'&.MuiListItemTextRoot': {flex: '1 0 auto'}}}>{`${software.description} (${software.numberOfReleases})`}
                    </ListItemText>
                    {status === Status.Pending && <CircularProgress />}
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </TableCell>
        </TableRow>
        {open && <>
            {entities.map((release: ReleaseHeadVerbose) => (
                <ReleaseListItem withPdf={props.withPdf} key={release.id} release={release}/>
            ))}
            {isPaginated && entities.length > 0 && (
                <TableRow>
                    <TableCell colSpan={6} size={'small'}>
                        <div><RemoteCollectionPagination paginated={collection}
                                                         methods={collectionApi}
                                                         pageSizeOptions={[5, 10, 15]}/></div>
                    </TableCell>
                </TableRow>
            )}
        </>}
    </>
}