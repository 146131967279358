
import { RemoteResourceQueryApi, useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { Status } from 'Components/utils';
import { useEffect } from 'react';


export interface Module {
  code: string,
  softwareCode: string,
  descriptionDe: string,
  descriptionEn: string,
  descriptionIt: string,
}

export const moduleFromCode = (code: string, softwareCode: string): Module => {
  return {
      code,
      softwareCode: softwareCode,
      descriptionDe: code,
      descriptionIt: code,
      descriptionEn: code
  };
}

export const useSoftwareModulesService = (): RemoteResourceQueryApi<Module[], Module[], {softwareCode: string | null}> => {

  return useRemoteResourceQueryService<Module[], Module[], {softwareCode: string | null}>({
    endpoint: 'baseData/software/:softwareCode/modules',
    defaultValue: [],
  });
}

export const useSoftwareModules = (props: { softwareCode: string | null}) => {
  const [state, fetch] = useSoftwareModulesService();
  const {softwareCode} = props;
  useEffect(() => { if (softwareCode) fetch({softwareCode}); }, [softwareCode, fetch]);
  switch (state.status) {
    case Status.Success:
      return state.data;
      case Status.Failed:
        return [];
      default:
        return undefined;
  }
}


//* Autocomplete props

import { useDescriptionGetter, useOptionLabelGetter } from 'Entities/DescribedCode/describedCode.models';
import { useCallback, useMemo } from 'react';


export const useInputMatcher = () => {

  const getDescription = useDescriptionGetter();

  return useCallback((option: Module | undefined | null, textInput: string) => {
    if (!option) return textInput === '';
    const input = textInput.toLowerCase();
    if (option.code.toLowerCase().includes(input)) {
      return true;
    }
    const desc = getDescription(option);
    return desc.toLowerCase().includes(input);
  }, [getDescription]);
}

export const isOptionEqualToValue = (option?: Module, value?: Module) => {
  console.log("isOptionEqualToValue", option, value);
  if (option) return (option.softwareCode === value?.softwareCode) && (option.code === value?.code);
  else return !value;
}

const sx = { minWidth: 300, maxWidth: 600 }


export const useModuleAutocompleteProps = () => {

  const getOptionLabel = useOptionLabelGetter();
  
  return {
    sx,
    isOptionEqualToValue,
    getOptionLabel,
  };
}


export const useModuleRemoteAutocompleteProps = (props: { softwareCode: string }) => {

  const { softwareCode } = props;

  const query = useMemo(() => ({ softwareCode }), [softwareCode]);

  const autocompleteProps = useModuleAutocompleteProps();

  //const isOptionEqualToInput = useInputMatcher();
  
  return useMemo(() => ({
    endpoint: 'baseData/software/:softwareCode/modules',
    //isOptionEqualToInput,
    query,
    ...autocompleteProps
  }), [query, autocompleteProps]);
}






