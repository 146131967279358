import dayjs, {Dayjs} from 'dayjs';
import brandImage from 'Assets/img/infominds_logo.png';
import brandImageDark from 'Assets/img/infominds_logo_dark.png';

import {Theme} from '@mui/material';

export function getLocalizedProp(lang: string, propName: string) {
  return (obj: any) => obj[('description' + lang[0] + lang[1])];
}

export const CONTENT_TYPES = ['Empty', 'NonTechnical', 'Technical', 'Both'];
export type ContentType = 'Empty'|'NonTechnical'|'Technical'|'Both';

export function getContentTypeShortCode(note: { contentType?: ContentType }): string {
  let typeString = 'N';
  const {contentType} = note;
  if (contentType === 'Technical') {
    typeString = 'T';
  } else if (contentType === 'Both') {
    typeString = 'NT'
  }
  return typeString;
}

export const AccessLevelsTranslationKeys: string[] = ['admin', '', 'standard', 'supervisor'];

export const prettyDate = (dateTime?: string | null) => {
  if (dateTime) {
    return dayjs(dateTime).format('DD.MM.YYYY');
  } else {
    return '';
  }
}

export const prettyDayjs = (dateTime?: Dayjs | null) => {
  if (dateTime) {
    return dateTime.format('DD.MM.YYYY');
  } else {
    return '';
  }
}

export const todayString = () => dayjs().format('YYYY-MM-DD');

const stringIsNumber = (value: any) => isNaN(Number(value)) === false;

export function getArray(e: any) {
  return Object.keys(e)
        .filter(stringIsNumber)
        .map(key => e[key]);
}

export const formatEuro = (val?: number) => (val === undefined || val === null) ? ' ' : (new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR'})).format(val);

export const format2dec = (val?: number) => (val === undefined || val === null) ? ' ' : (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2})).format(val);

export const Languages = {
  'de': 'Deutsch',
  'it': 'Italiano',
  'en': 'English',
};

export interface LocalDescription {
  descriptionDe: string;
  descriptionEn: string;
  descriptionIt: string;
}

export function descriptionGetter(lang: string) {
  return (obj?: LocalDescription) => {
    if (obj) {
      const key = `description${lang[0].toUpperCase()}${lang[1]}`;
      const result = obj[key as any as keyof(LocalDescription)];
      if (result?.length > 0) 
        return result;
      else
        return obj.descriptionEn;
    }
    else return '';
  }
}





