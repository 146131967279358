import DraggableList from 'Components/DraggableList/DraggableList';
import { ElementWithId } from 'Components/DraggableList/DraggableListItem';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { swapListItems, Status } from 'Components/utils';
import { useMacroareaSwappingService } from './macroarea.service';
import { useCallback, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface DraggableMacroareasProps {
   software: string,
   disableDrag: boolean
   onOrderChanged: (ids: number[]) => void,
   children: { element: JSX.Element, id: number }[],
}

export function DraggableMacroareas(props: DraggableMacroareasProps) {

   const { children, onOrderChanged } = props;

   //* Initialize list of draggables

   const sortedList = useMemo(() => {
      return children.map(x => ({
         element: x.element,
         id: x.id.toString()
      }))
   }, [children]);


   //* New sorting. If initialized, will be used temporarily while saving, until the same new sorting is received through props

   const [newSortedList, setNewSortedList] = useState<null | ElementWithId[]>(null);
   // If we don't use this, the old sorting will be shown for a short interval after the user drops an item in a new position, 
   // until the updated order is received through the children prop.


   //* Service to save new sorting   
   const [saveState, save] = useMacroareaSwappingService({ software: props.software });


   //* Handle sorting-changed event (i.e., item dragged-and-dropped)

   const handleDragEnd = useCallback(({ destination, source }: DropResult) => {
      
      // dropped outside the list or at the current position
      if (!destination || destination.index === source.index) return;

      const idA = Number.parseInt(sortedList[source.index].id);
      const idB = Number.parseInt(sortedList[destination.index].id);

      const newList = swapListItems(sortedList, source.index, destination.index);
      setNewSortedList(newList);

      save({idA, idB}).then((newIdArray) => { if (newIdArray) onOrderChanged(newIdArray) });

   }, [sortedList, save, onOrderChanged]);


   //* When new order has been persisted remotely, pass it back to parent

   const isUpdating = saveState.status === Status.Pending;

   return props.disableDrag
      ? <>{sortedList.map(item => item.element)}</>   // drag&drop not allowed when a note is in editing mode
      : <>
         <DraggableList 
            items={(isUpdating && newSortedList) ? newSortedList : sortedList} 
            onDragEnd={handleDragEnd} 
            dragdropId='Macroareas' 
            itemSx={{borderTop: '1pt solid lightgrey'}}
         />
         <ProgressBackdrop open={saveState.status === Status.Pending} />
      </>
}


