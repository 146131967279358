import shadows from 'ThemeMinimal/shadows';

import { lighten, PaletteMode, Theme } from '@mui/material';
import createPalette, { Palette, PaletteOptions } from '@mui/material/styles/createPalette';
import createTheme from '@mui/material/styles/createTheme';

import { CustomThemeProps } from '../definitions/createTheme';
import componentsOverrides from '../ThemeMinimal/overrides';
import typography from '../ThemeMinimal/typography';
import { InfomindsColors } from './infomindsColors';
import { makeMuiComponentsStyling } from './muiComponentsStyles';

import type { } from '@mui/lab/themeAugmentation';
import customShadows from 'ThemeMinimal/customShadows';

const defaultThemeProps = {
  typography: {
    fontSize: 12,
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  spacing: 4
}

import paletteMinimal from '../ThemeMinimal/palette';

function createCustomTheme(colors: PaletteOptions, mode: PaletteMode): Theme {

  const palette = createPalette({
    //...paletteMinimal,
    ...colors,
    mode: mode
  });
  
  const theme = createTheme({
    palette,
    shape: { borderRadius: 6 },
    typography: typography as any,
    shadows: shadows() as any,
    customShadows: customShadows() as any,
    //...defaultThemeProps,
    //components: makeMuiComponentsStyling()
  });
  theme.components = componentsOverrides(theme);
  return theme;
}



// themes.light.components = {
//   ...themes.light.components,
//   MuiAppBar: {
//     styleOverrides: {
//       root: {
//         backgroundImage: `linear-gradient(${lighten(InfomindsColors.main, 0.3)}, ${lighten(InfomindsColors.yellow, 0.8)})`,
//         color: themes.light.palette.text.primary
//       }
//     }
//   },
// };

export default createCustomTheme