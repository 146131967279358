import React from "react";
import {Route} from "react-router-dom";
import {SelectRelease} from "./SelectRelease";
import {Switch} from "react-router";
import {ReleaseEditor} from "./ReleaseEditor";
import {ReleasesManagerContext, useReleasesManagerReducer} from "./ReleasesManagerContext";


export const ReleasesManager = () => {

    const [context, dispatchContext] = useReleasesManagerReducer();
    
    const {urlBasePath} = context;

    return (
        <ReleasesManagerContext.Provider value={[context, dispatchContext]}>
            <Switch>
                <Route path={`${urlBasePath}/:releaseId`}><ReleaseEditor/></Route>
                <Route path={`${urlBasePath}/new`}><ReleaseEditor/></Route>
                <Route path={urlBasePath}><SelectRelease/></Route>
            </Switch>
        </ReleasesManagerContext.Provider>
    );
}

