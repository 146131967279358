import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {IliasLinkHandler} from "../../IliasAuthProvider";
import { Faq } from "../faqFeed.models";
import HtmlContent from 'Components/HtmlContent/HtmlContent';
import {useEffect, useState} from "react";
import { useFaqFull } from "../faqFeed.services";
import { Status } from "Components/reduxLogger";
import { useTranslation } from "react-i18next";
import { getHtmlBodyAndStyle } from "Components/HtmlContent/htmlAdapter";

export default function FaqView(props: Faq & {expanded?: boolean}) {
    
    const [expanded, setExpanded] = useState(props.expanded);

    const [{ status: loadStatus, data: fullNote }, loadFullNote] = useFaqFull({ softwareCode: props.softwareCode, faqId: props.id });

    const toggleExpand = () => {
        setExpanded(val => !val)
    }
    
    useEffect(() => {
        if (loadStatus === Status.Idle && expanded) loadFullNote({});
    }, [loadStatus, expanded]);

    const [t] = useTranslation();

    let htmlBody: string | undefined = props.html;
    if (expanded && fullNote) {
        const { body, style } = getHtmlBodyAndStyle(fullNote.html);
        htmlBody = body;
    }
    
    //console.log("HTML body", htmlBody);

    return <Box>
        <Typography>
            <Button onClick={toggleExpand} sx={{fontSize: '130%', textAlign: 'left', textTransform: 'none' }}>
                {props.title}
            </Button>
        </Typography>
        <Box sx={expanded ? {} : {
            maxHeight: 300,
            overflowY: 'hidden',
            textOverflow: 'ellipsis',
            WebkitMaskImage: 'linear-gradient(180deg, #000 50%, transparent)'
        }}>
            <IliasLinkHandler>
                <HtmlContent html={htmlBody}/>
            </IliasLinkHandler> 
            {loadStatus === Status.Pending && <CircularProgress />}
        </Box>
    </Box>
}

