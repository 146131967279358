import {Box} from '@mui/system';
import {Status} from 'Components/reduxLogger';
import {EntityEditor} from 'Components/RemoteEntities/Entity/EntityEditor';
import dayjs from 'dayjs';
import {useReleaseStatusesService} from 'Pages/Dashboard/BaseData/ReleaseStatus/ReleaseStatusesManager';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from "react-router-dom";
import {NotesManager} from '../Notes/NotesManager';
import {ReleaseHead, ReleaseHeadVerbose} from './release.models';
import {useReleaseHeadDetails} from "./release.services";
import {ReleaseHeadForm} from './ReleaseHeadForm';
import {ReleasesManagerContext} from "./ReleasesManagerContext";


export function ReleaseEditor() {

    const [{urlBasePath, activeRelease, filters }, dispatchContext] = useContext(ReleasesManagerContext);

    const routeMatch = useRouteMatch<{ releaseId: string }>();
    const { releaseId: releaseIdFromUrl } = routeMatch.params;
    
    const {push: historyPush} = useHistory();
    
    
    const headInitializer = activeRelease ?? (releaseIdFromUrl === 'new' ? false : releaseIdFromUrl);
    
    const [headModel, setHeadModel] = useState<ReleaseHeadVerbose | undefined>(activeRelease);

    // When both headModel and headInitializer are Release objects but different releases, set model = initializer. 
    // This allows to switch to another release when cloning notes  
    useEffect(() => {
        if (headModel && typeof headInitializer === 'object' && headInitializer.id !== headModel.id)
            setHeadModel(headInitializer )
    }, [headModel, headInitializer]);

    // console.log("headInitializer", headInitializer);
    // console.log("headModel", headModel);


    
    const handleExit = useCallback(() => {
        dispatchContext(['SET_ACTIVE_RELEASE', undefined]);
        historyPush(urlBasePath);
    }, [dispatchContext, historyPush, urlBasePath]);

    const handleRead = useCallback((data: ReleaseHeadVerbose) => {
        setHeadModel(data);
    }, []); 
    
    const handleSaved = useCallback((data: ReleaseHeadVerbose) => {
        setHeadModel(data);
        historyPush(`${urlBasePath}/${data.id}`);
    }, [historyPush, urlBasePath]);  
    
    
    const headFormDefaults = useReleaseHeadDefaults({defaultsNeeded: !headModel});

    const [t] = useTranslation();
    
    return <Box id={'Release-root'}>
        <EntityEditor<ReleaseHead, ReleaseHeadVerbose>
            editorId="ReleaseHeadEditor"
            model={headModel ?? headInitializer}
            idKey='id'
            defaultValues={headFormDefaults}
            useRemoteEntity={useReleaseHeadDetails}
            title={t('release')}
            onExit={handleExit}
            isDeletable
            initEditable={!headModel}
            canToggleEdit={!!headModel /*&& editingSection != 'notes'*/}
            context={{}}
            onRead={handleRead}
            onSaved={handleSaved}
            onDeleted={handleExit}
            onReadError={handleExit}
        >{ReleaseHeadForm}</EntityEditor>

        {typeof headModel === 'object' && (
            <Box ml={2} id={'ReleaseNotes-root'}>
                <NotesManager releaseId={headModel.id} 
                    softwareCode={headModel.software.code} 
                    isNewRelease={releaseIdFromUrl === 'new'} 
                    isEditable={true /*editingSection != 'head'*/} 
                    initialFilter={filters.notesFilter}
                />
            </Box>
        )}
    </Box>
}


const useReleaseHeadDefaults = (props: {defaultsNeeded: boolean}) => {

    const { defaultsNeeded } = props;
    const [statuses, fetchStatuses] = useReleaseStatusesService();
    const statusesFetched = statuses.status === Status.Success;
    useEffect(() => {
        if (defaultsNeeded && !statusesFetched) {
            fetchStatuses({});
        }
    }, [fetchStatuses, statusesFetched, defaultsNeeded]);
    
    return statuses.status === Status.Success ? {

        status: statuses.data.find(x => x.code === 'PROCESS'),
        releaseDate: dayjs().format('YYYY-MM-DD')

     } : undefined;
}
