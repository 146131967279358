import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteProps, SxProps, Theme } from "@mui/material";
import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { useEffect, useMemo, useState } from "react";
import { StyledTextFieldAC } from "Components/Input/StyledTextField";
import { Status } from "Components/utils";
import { DescribedCode } from "./describedCode.models";
import { useDescriptionGetter } from "./describedCode.models";


const useOptionLabelGetter = () => {
    const getDescription = useDescriptionGetter();
    return (entity?: DescribedCode) => (entity ? (`${entity.code} - ${getDescription(entity)}`) : '');
}

export const isOptionEqualToValue = (option: DescribedCode, val: DescribedCode) => (option.code === val.code);



export function DescribedCodeAutocomplete(props: Omit<AutocompleteProps<DescribedCode, boolean | undefined, boolean | undefined, false>, 'renderInput' | 'getOptionLabel'> & {
    name: string,
    label?: string,
    error?: boolean,
}) {

    const { value, multiple, label, disabled, error, name, sx, ...autocompleteProps } = props;
    
    const getOptionLabel = useOptionLabelGetter();

    const [clearTrigger, setClearTrigger] = useState(0); 
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form clear.
    // This is needed because MUIAutocomplete's multiple-values mode with object options has a bug: 
    // it does not re-render when the passed value changes to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => { 
        if (!value) setClearTrigger(val => val+1);
    }, [value]);

    const _value = multiple 
        ? (Array.isArray(value) ? value : (value ? [value] : [])) 
        : (value ?? null)

    //console.log("DescribedCodeAutocomplete", name, value);

    return (
        <Autocomplete<DescribedCode, boolean | undefined, boolean | undefined, false>
            multiple={multiple}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            autoSelect
            autoComplete
            clearOnBlur={true}
            key={clearTrigger}
            sx={{ mx: 1, ...sx }}
            {...autocompleteProps}
            value={_value}
            renderInput={(params) => {
                return <StyledTextFieldAC {...params} name={name} label={label} variant="outlined" error={error} />
            }}
        />
    )
}



const debug = false;

export function DescribedCodeAutocompleteRemote(props: Omit<AutocompleteProps<DescribedCode, boolean | undefined, boolean | undefined, false>, 'options' | 'renderInput' | 'getOptionLabel'> & {
    name: string,
    label?: string,
    error?: boolean,
    sx?: SxProps<Theme>,
    endpoint: string
}) {

    const { value, multiple, label, disabled, error, name, sx, ...autocompleteProps } = props;
    
    const getOptionLabel = useOptionLabelGetter();

    //* FETCH OPTIONS

    const [collection, fetch] = useRemoteResourceQueryService<DescribedCode[], DescribedCode[]>({ endpoint: props.endpoint, defaultValue: [] });
    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status !== Status.Idle;
    
    const handleOpen = () => { if (!isInitialized) fetch({}); };

    const remoteList = collection.data;

    const options: DescribedCode[] = useMemo(
        () => (value && !isLoaded) ? (Array.isArray(value) ? value : [value]) : remoteList,
        [remoteList, value, isLoaded]
    );

    if (debug) {
        console.log(name, "VALUE", value);
        console.log(name, "OPTIONS", options);
    }

    const [clearTrigger, setClearTrigger] = useState(0); 
    // hack to force the Autocomplete to re-render when the value passed to it changes upon form-clear.
    // This is needed because MUIAutocomplete in multiple-values mode with object options has a bug: 
    // it does not re-render when the value passed to it is reset to null or [].
    // But it does clear if we pass a new key prop.
    // (https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value)
    useEffect(() => { 
        if (!value) setClearTrigger((val) => val+1);
    }, [value]);

    if (debug) console.log("DescribedCodeAutocomplete", name, value, 'loaded?', isLoaded);

    const _value = multiple 
        ? (Array.isArray(value) ? value : (value ? [value] : [])) 
        : (value ?? null)

    return (
        <Autocomplete<DescribedCode, boolean | undefined, boolean | undefined, false>
            options={options ?? []}
            multiple={multiple}
            onOpen={handleOpen}
            onInputChange={handleOpen}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            loading={collection.status === Status.Pending}
            autoSelect
            autoComplete
            clearOnBlur={true}
            key={clearTrigger}
            value={_value}
            sx={{ mx: 1, minWidth: 100, ...sx }}
            {...autocompleteProps}
            renderInput={(params) => {
                return <StyledTextFieldAC {...params}
                    name={name} label={label}
                    variant="outlined"
                    error={error}
                />
            }}
        />
    )
}

