import { Typography, TypographyProps, Link } from "@mui/material";

export default function Copyright(props: TypographyProps) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.infominds.eu" target="_blank" >Infominds</Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}