import {EntityFormCustomRenderProps} from 'Components/HookedForm/EntityFormCustom';
import {EntityEditorCustom} from 'Components/RemoteEntities/Entity/EntityEditorCustom';
import { Faq, FaqAbstract, FaqVerbose, faqVerboseToAbstract } from './faq.models';
import {FaqCRUDContext, useFaqCRUD} from './faq.services';
import {Dispatch, FC, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import BackspaceIcon from '@mui/icons-material/Backspace';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import {FaqForm} from './FaqForm';
import {Typography, Button, IconButton, Paper, Stack, styled} from '@mui/material';
import {useConst} from 'Components/useConst';
import {isNew, FaqsCollectionAction, FaqState, getCloneSetting} from "./faqsManagerState";
import {useReleaseStatusesService} from "../../BaseData/ReleaseStatus/ReleaseStatusesManager";
import {Status} from "../../../../Components/reduxLogger";
import dayjs from "dayjs";

export interface FaqsEditorProps {
    softwareCode: string,
    dispatch: Dispatch<FaqsCollectionAction>,
    item: FaqState,
}

export function FaqEditor(props: FaqsEditorProps) {

    const {item, dispatch, softwareCode} = props;
    const {id, abstract} = item;

    const handleFaqSaved = useConst(() => (fullFaq: FaqVerbose) => {
        const {id} = item;
        const abstract: FaqAbstract = faqVerboseToAbstract(fullFaq);

        if (isNew(item)) {
            dispatch(['SAVE_NEW', {abstract, details: fullFaq, expanded: true, show: true}]);
            dispatch(['CLOSE', fullFaq.id]);
        } else {
            dispatch(['UPDATE', {id, patch: {abstract, details: fullFaq}}]);
            dispatch(['CLOSE', id]);
        }
    });

    const handleFaqDeleted = useConst(() => () => dispatch(['DELETE', id]));

    const handleFaqClose = useConst(() => () => dispatch(['CLOSE', id]));

    const {isClone, originSoftwareCode} = getCloneSetting(item);
    
    console.log("CLONING", isClone, "from", originSoftwareCode, "to", softwareCode);

    const clearData = isNew(item) ? item.clearEntity : undefined;
    
    const defaults = useFaqDefaults({softwareCode, needed: !abstract});

    return (
        <EntityEditorCustom<Faq, FaqVerbose, FaqCRUDContext>
            editorId={'FaqEditor-' + (abstract?.id ?? 'new')}
            useRemoteEntity={useFaqCRUD}
            context={{
                softwareCode: originSoftwareCode ?? softwareCode,
                isClone,
                newSoftwareCode: softwareCode,
            }}
            idKey='id'
            model={abstract?.id ?? false}
            remoteClone={isClone}
            defaultValues={defaults}
            onSaved={handleFaqSaved}
            onDeleted={handleFaqDeleted}
            onExit={handleFaqClose}
        >{CustomFaqForm}</EntityEditorCustom>);
}

const useFaqDefaults = (props: {needed: boolean, softwareCode: string}) => {

    const { needed } = props;
    const [statuses, fetchStatuses] = useReleaseStatusesService();
    const statusesFetched = statuses.status === Status.Success;
    useEffect(() => {
        if (needed && !statusesFetched) {
            fetchStatuses({});
        }
    }, [fetchStatuses, statusesFetched, needed]);

    return statuses.status === Status.Success ? {

        status: statuses.data.find(x => x.code === 'PROCESS'),
        softwareCode: props.softwareCode,
        ergoOne: false,
        translations: []

    } : undefined;
}

type FaqFormProps = EntityFormCustomRenderProps<FaqVerbose, FaqCRUDContext>;

const CustomFaqForm: FC<FaqFormProps> = (props: FaqFormProps) => {

    const [t] = useTranslation();

    const {disabled} = props;

    const {formState: {isDirty}, getValues} = props;

    const titleContainerRef = useRef<HTMLElement | null>(null);

    console.log("FAQ FORM context", props.context);
    console.log("FAQ FORM values", getValues());

    return (
        <Paper variant='outlined' sx={{my: 3, width: 1}}>
            <Stack direction='row' justifyItems={'space-between'} alignItems='center'>
                <Typography variant='h5' flex={'1 1 auto'} id='faq-translation-title' sx={{mx: 2}}
                            ref={titleContainerRef}></Typography>
                <Stack flex='0 1 auto' direction='row' sx={{my: 2}} justifyContent='flex-end'>
                    {!disabled && <>
                        {<IconButton onClick={props.onCancel}><BackspaceIcon/></IconButton>}
                        {<IconButton onClick={props.onDelete}><DeleteIcon/></IconButton>}
                        {<IconButton disabled={!isDirty} onClick={props.onSave}><SaveIcon/></IconButton>}
                    </>}
                </Stack>
            </Stack>

            <FaqForm {...props} 
                     context={{softwareCode: props.context.newSoftwareCode}} 
                     titleContainer={titleContainerRef.current}/>

            <Stack direction='row-reverse' sx={{m: 2}}>
                <StyledButton variant='contained' onClick={props.onCancel}>{t('exit')}</StyledButton>
                <StyledButton variant='contained' onClick={props.onSave}>{t('saveAndClose')}</StyledButton>
                <StyledButton variant='contained' onClick={props.onDelete}>{t('delete')}</StyledButton>
            </Stack>
        </Paper>);
}


const StyledButton = styled(Button)(() => ({
    margin: '4px'
}));

