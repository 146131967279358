import {useEffect} from "react";
import {Status} from "Components/reduxLogger";
import {useRemoteResourceQueryService} from "Components/RemoteEntities/resource.services";

export const useSoftwareVersionsList = (props: { softwareCode?: string, default1?: string, default2?: string }) => {

    const { softwareCode} = props;
    
    const [{data: remoteData, status}, fetchOptions] = useRemoteResourceQueryService<string[]>({
        endpoint: `releases/${props.softwareCode}/versions`,
        defaultValue: []
    });

    useEffect(() => {
        if (status === Status.Idle && !!softwareCode) {
            fetchOptions({});
        }
    }, [status, softwareCode, fetchOptions]);

    console.log("data", remoteData, status);

    const {default1, default2} = props;
    let defaults: string[] = [];
    if (default1) defaults = [default1];
    if (default2) defaults.push(default2);

    const values = (remoteData && remoteData.length > 0) ? remoteData : defaults;

    return {values, status};
}