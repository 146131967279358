import {useContext} from "react";
import {useForm} from "react-hook-form";
import {emptyReleaseFilter, ReleaseFilters} from "./release.models";
import {useTranslation} from "react-i18next";
import ToolbarWithDrawer from "Components/Containers/ToolbarWithDrawer";
import {Box, Button, Stack} from "@mui/material";
import {Link} from "react-router-dom";
import {ReleaseFiltersForm} from "./ReleaseFiltersForm";
import {ReleasesManagerContext} from "./ReleasesManagerContext";

export function ReleaseFiltersToolbar() {

    const [context, dispatch] = useContext(ReleasesManagerContext);

    const form = useForm<ReleaseFilters>({defaultValues: context.filters});
    const {control, reset, watch} = form;

    const [t] = useTranslation();

    const softwareCode = watch('headFilter.software.code');
    //console.log("SOFTWARE", softwareCode, watch('headFilter.software'));

    const handleFiltersChange = (value: ReleaseFilters) => dispatch(['SET_FILTERS', value]);

    return (
        <ToolbarWithDrawer id={'ReleaseFilterToolbar'}
                           onClose={() => handleFiltersChange({...watch()})}
                           onClear={() => { reset(emptyReleaseFilter); }}
            //keepDrawerMounted // this prevents all the filter options being fetched again
        >{{
            main: <Stack direction={'row'} justifyContent={'space-between'} sx={{width: 1}}>
                <Box>
                    <Button component={Link} to={`${context.urlBasePath}/new`}>Crea nuova release</Button>
                </Box>
                <Box marginRight={3}>
                    <Button onClick={() => {
                        reset(emptyReleaseFilter);
                        handleFiltersChange(emptyReleaseFilter);
                    }}>{t('removeFilters')}</Button>
                </Box>
            </Stack>,

            drawer: <Box sx={{overflowY: 'scroll'}}><ReleaseFiltersForm {...form} /></Box>

        }}</ToolbarWithDrawer>
    )
}