
import { EntityFormRenderProps } from 'Components/HookedForm/EntityForm';
import { HookedDatePicker, HookedTextField } from 'Components/HookedForm/inputControls';
import { SoftwareAutoSelect } from 'Entities/Software/SoftwareAutoSelect';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { DescribedCodeAutocompleteHookedOnBlur } from 'Entities/DescribedCode/DescribedCodeAutocompleteHookedOnBlur';


import {ReleaseHeadVerbose} from "./release.models";

type ReleaseHeadFormProps = EntityFormRenderProps<ReleaseHeadVerbose>;

export const ReleaseHeadForm: React.FC<ReleaseHeadFormProps> = (props: ReleaseHeadFormProps) => {

  const [t] = useTranslation();

  const { control, getValues, disabled } = props;
  
  const isNewRelease = !getValues('id');

  return <Stack sx={{m: 1, flexWrap: 'wrap'}} direction='row'>

      {/* SOFTWARE */}
      <Controller<ReleaseHeadVerbose, 'software'>
        control={control}
        name={'software'}
        render={({ field, fieldState, formState }) => (
          <SoftwareAutoSelect 
            value={field.value} 
            onChange={field.onChange} 
            disabled={disabled || !isNewRelease}
            sx={{ minWidth: '400px'}}/>
        )}
      />

      {/* STATUS */}
      <DescribedCodeAutocompleteHookedOnBlur<ReleaseHeadVerbose, 'status'> control={control}
        name={"status"}
        label={t('status')}
        disabled={disabled}
        endpoint={'baseData/releaseStatuses'}
      />

      {/* RELEASE VERSION */}
      <HookedTextField control={control}
        name={"releaseVersion"} label={t('version')}
        rules={{ required: true }}
        disabled={disabled}
        sx={{ minWidth: 100 }}
      />

      {/* RELEASE DATE */}
      <HookedDatePicker control={control}
        name='releaseDate'
        label={t('releaseDate')}
        disabled={disabled}
        convertToString
      />

      {/* CUSTOMER ID */}
      <HookedTextField control={control}
        name='customerId'
        label={t('customerId')}
        disabled={disabled}
      />

      {/* <NumberInput control={control}
        name='priority'
        label={t('priority')}
        disabled={disabled}
      /> */}
    </Stack>
}