import { NotificationParams, SnackbarNotify } from 'Components/Feedback/SnackbarNotify';
import React, { ReactElement, ReactNode, ReactNodeArray, useCallback, useEffect, useMemo, useState } from 'react';
import { EmptyObject } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';



export interface ItemsWrapperProps<TContext = EmptyObject> {
  children: React.ReactNode,
  editingIndex: number | null,
  onAddItemClick: () => void,
  context: TContext
}
export interface ItemEditProps<TModel, TContext = EmptyObject> {
  entity?: TModel,
  context: TContext,
  doDelete: boolean,
  onExit: () => void,  // exit edit mode
  onDeleted: () => void,
  onSaved: (model: TModel) => void,
}

export interface ItemViewProps<TModel, TContext = EmptyObject> {
  entity: TModel,
  context: TContext,
  onEditClick: () => void,
  onDeleteClick: () => void
}

/**
 * Container for a collection of items, with logic for
 * - selecting an item for editing
 * - creating a new item
 * - deleting items
 * 
 * TModel: type of the collection items
 * TContext: additional custom data
 * 
 * @param props see below
 * @returns 
 */
export function CollectionEditController<TModel, TContext = EmptyObject>(props: {

  rootId: string,
  sx?: SxProps<Theme>,
  initialEntities: TModel[],
  context: TContext,
  children: {
    ItemsWrapper: React.FC<ItemsWrapperProps<TContext>> // renders collection wrapper (typically, some kind of <Table> with headers, but might also be a simple <div>{children}</div>)
    ItemView: React.FC<ItemViewProps<TModel, TContext>>,
    ItemEditor: React.FC<ItemEditProps<TModel, TContext>>,  // renders controls for entity fields
  }
  disabled?: boolean, // disables editing
  canAdd?: boolean,   // enables adding new entity
  openNew?: boolean   // if there are no initial entities and editing is not disabled, shows new empty entity in editing mode
}) {

  const { initialEntities, openNew, disabled } = props;

  const [items, setItems] = useState<TModel[]>([]);
  const itemsLength = items.length;

  useEffect(() => setItems(initialEntities ?? []), [initialEntities]);

  /* Item in edit mode */
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [isDeleteMode, setDeleteMode] = useState(false);

  const { t } = useTranslation();

  if (editIndex !== undefined) console.log("IsEditing", editIndex, "isNewItem", editIndex === items.length);

  /* HANDLE ITEM SELECTED */

  const handleItemSelected = useCallback((params: { index: number, doDelete: boolean }) => {
    setEditIndex(params.index);
    setDeleteMode(params.doDelete);
  }, []);


  /* HANDLE ADD-ITEM */

  const { canAdd } = props;

  const handleAddNewClick = useCallback(() => { if (canAdd && !disabled) setEditIndex(itemsLength); }, [itemsLength, canAdd, disabled]);

  useEffect(() => {
    if (openNew && itemsLength === 0 && !disabled) {
      setEditIndex(itemsLength);
    }
  }, [openNew, itemsLength, disabled]);


  /* HANDLE EXIT EDIT MODE */

  const handleExitEdit = useCallback(() => { setEditIndex(undefined); }, []);

  /* HANDLE ITEM DELETED */

  const handleItemDeleted = useCallback(() => {
    if (editIndex !== undefined && editIndex < itemsLength) {
      setItems(oldItems => {
        const updatedItems = [...oldItems];
        updatedItems.splice(editIndex, 1);
        return updatedItems;
      })
    }
    setEditIndex(undefined);
  }, [editIndex, itemsLength]);


  /* HANDLE ITEM SAVED */

  const handleEntitySaved = useCallback((entity: TModel) => {
    if (editIndex !== undefined && editIndex < itemsLength) {
      setItems(oldItems => {
        const updatedItems = [...oldItems];
        updatedItems.splice(editIndex, 1, entity);
        return updatedItems;
      })
    } else { // the saved entity is a newly created one
      setItems(oldItems => oldItems.concat(entity));
    }
    setEditIndex(undefined);
  }, [editIndex, itemsLength]);


  /* RENDER COLLECTION */

  return (
    <Box sx={props.sx} id={`${props.rootId ?? 'Collection'}-EditController-root`} >
      <props.children.ItemsWrapper
        context={props.context}
        editingIndex={editIndex ?? null}
        onAddItemClick={handleAddNewClick}
      ><>
          {items.map((item, index) => editIndex !== index
            ? (<props.children.ItemView
              key={index}
              entity={item}
              context={props.context}
              onEditClick={() => handleItemSelected({ index, doDelete: false })}
              onDeleteClick={() => handleItemSelected({ index, doDelete: true })}
            />)
            : (<props.children.ItemEditor
              key={index}
              entity={item as TModel}
              context={props.context}
              doDelete={isDeleteMode}
              onSaved={handleEntitySaved}
              onDeleted={handleItemDeleted}
              onExit={handleExitEdit}
            />))}
          {editIndex === items.length && (
            <props.children.ItemEditor
              context={props.context}
              doDelete={false}
              onSaved={handleEntitySaved}
              onDeleted={handleItemDeleted}
              onExit={handleExitEdit}
            />)}
        </>
      </props.children.ItemsWrapper>

      {/* {!!notification && <SnackbarNotify {...notification} onClose={() => setNotification(null)} />} */}
    </Box>
  );
}

