
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';
//import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { ListItem, SxProps, Theme } from '@mui/material';


export interface ElementWithId {
    id: string,
    element: React.ReactNode
}

const useStyles = makeStyles({
    draggingListItem: {
        background: 'rgb(235,235,235)'
    }
});

export type DraggableListItemProps = {
    item: ElementWithId;
    index: number;
    sx?: SxProps<Theme>
};

const DraggableListItem = ({ item, index, sx }: DraggableListItemProps) => {
    const classes = useStyles();
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : ''}
                    sx={{width: 1, overflow: 'hidden', display:'block', ...sx}}
                >
                    {item.element}
                </ListItem>
            )}
        </Draggable>
    );
};
DraggableListItem.displayName = "DraggableListItem";

export default DraggableListItem;
