import { Button, Typography } from "@mui/material";
import { DialogX } from "Components/Containers/DialogX";
import { useTranslation } from "react-i18next";


export function EntityDeleteDialog(props: {
    isConfirmationPending: boolean,
    onConfirmed: () => void,
    onAborted: () => void
}) {

    const [t] = useTranslation();

    return <DialogX title={t('delete')} open={props.isConfirmationPending} onClose={props.onAborted}
        bottomToolbar={<>
            <Button onClick={props.onAborted}>{t('cancel')}</Button>
            <Button onClick={props.onConfirmed}>{t('ok')}</Button>
        </>}>
        <Typography variant='h5' m={8}>{t('delete') + '?'}</Typography>
    </DialogX>
}
