import { CollectionLayout } from 'Components/Collection/CollectionLayout';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import {hasContent, Status} from 'Components/utils';
import {FaqFilter} from './faq.models';
import { useFilteredFaqAbstractsFromFullCollection, useFaqAbstracts } from './faq.services';
import {useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Portal, Stack } from '@mui/material';

import { FaqsFilterToolbar } from './FaqsFilterToolbar';
import { useGroupedFaqs } from './useGroupedFaqs';
import { FaqsGroupContainer } from './FaqsGroupContainer';
import {getAbstracts, faqsManagerReducer} from "./faqsManagerState";


import {SwFaqsManagerContext} from "../Softwares/SwFaqsManagerContext";


export function FaqsManager(props: { softwareCode: string, initialFilter: FaqFilter }) {

   const { softwareCode } = props;

   //* Collection state management

   const [state, dispatch] = useReducer(faqsManagerReducer, { items: [] });
   
   //* Pick clone model from another software

   const [sharedContext, shareDispatch] = useContext(SwFaqsManagerContext);
   //console.log("FAQs manager context", state);
   //console.log("FAQSW manager context", sharedContext);

   const _faqToClone = sharedContext.faqModel;
   const faqToClone = _faqToClone?.softwareCode !== softwareCode ? _faqToClone : undefined;
   //console.log("Faq to clone from other software", faqToClone);

   //  remove clone model from shared context so it's not picked again when we open another software
   useEffect(() =>  {
      if (faqToClone) shareDispatch(['SET_FAQ_MODEL', undefined]);
   }, [faqToClone, shareDispatch]);

   //* Init collection 

   const completeCollection = useFaqAbstracts({ softwareCode });
   const allEntities = completeCollection.entities;
   const isLoaded = completeCollection.status === Status.Success;

   useEffect(() => { if (isLoaded) dispatch(['SET_COLLECTION', allEntities]) }, [allEntities, isLoaded]);

   useEffect(() => {
      if (faqToClone) {
         dispatch(['SET_CLONE_MODEL', {
            cloneModel: faqToClone,
            cloneDestinationSoftwareCode: softwareCode
         }]);   
      }
   }, [faqToClone, softwareCode]);

   //* Filter

   const [filter, setFilter] = useState<FaqFilter | undefined>(props.initialFilter);

   const filteredCollection = useFilteredFaqAbstractsFromFullCollection({ softwareCode, filter, fullCollection: completeCollection });
   const { entities: filteredEntities } = filteredCollection;

   useEffect(() => dispatch(['FILTER', filteredEntities.map(x => x.id)]), [filteredEntities]);

   //* Add new blank faq

   const handleNewItemClick = useCallback(() => { dispatch(['OPEN', { id: 0 }]) }, []);

   //* Group by macroarea

   const [faqGroups, handleOrderChangedWithinGroup] = useGroupedFaqs({ faqs: state.items, dispatch });


   //* RENDER

   const toolbarRef = useRef<HTMLElement>(null);
   const isLoading = completeCollection.status === Status.Pending || filteredCollection.status === Status.Pending;
   const isEditing = useMemo(() => !!state.items.find(item => item.isEditing), [state]);
   const [t] = useTranslation();

   const { scrollY: targetScrollY } = state;
   
   useEffect(() => {
      if (targetScrollY !== undefined) {
         console.log("Scroll back to", targetScrollY);
         window.scrollTo({top: targetScrollY, behavior: "smooth"})
      }
   }, [isEditing, targetScrollY]);
   
   const expandListView = hasContent(filter);
   console.log("FAQ FILTER", filter, expandListView);
   
   return (
      <CollectionLayout id={'FaqsManager'}
         hasNewButton={false}
         sx={{ position: 'relative', overflow: 'visible', '& .MuiBoxRoot': { overflow: 'clip' } }}
         sxInner={{ overflow: 'clip', '&.MuiCard-root': { background: 'inherit' } }}>
         {{
            controls: (
               <FaqsFilterToolbar softwareCode={softwareCode}
                  allFaqs={getAbstracts(state.items)}
                  filter={filter}
                  onFilterChange={setFilter}
                  ref={toolbarRef}
                  showErgoOne={props.softwareCode === 'ERGOBUILD'}
               />
            ),
            pagination: undefined,
            content: isLoading ? <ProgressBackdrop open /> : <>
               {
                  toolbarRef.current && <Portal container={toolbarRef.current}>
                     <Button variant={'outlined'} onClick={handleNewItemClick} disabled={isEditing}>
                        {t('createNewFaq')}
                     </Button>
                  </Portal>
               }
               
               {faqGroups
                  .filter(group => group.faqs.find(n => n.show)) // render only groups that have faqs to show (not filtered out)
                  .map(group => (
                     <FaqsGroupContainer
                        key={group.key.id}
                        faqs={group.faqs}
                        groupKey={group.key}
                        isEditing={isEditing}
                        onOrderChanged={(ids) => handleOrderChangedWithinGroup(ids, group.key.id)}
                        dispatch={dispatch}
                        expanded={expandListView}
                        {...props} />
                  ))}

               {(state.items.length > 2 && (
                  <Stack direction='row' sx={{ m: 2 }}>
                     <Button variant={'outlined'} onClick={handleNewItemClick} disabled={isEditing}>
                        {t('createNewFaq')}
                     </Button>
                  </Stack>
               ))}
            </>
         }}
      </CollectionLayout>
   );
}







