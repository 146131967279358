import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { FormCard } from 'Components/FormUtils';
import HtmlContent from 'Components/HtmlContent/HtmlContent';
import { DarkerDisabledTextField } from 'Components/Input/StyledTextField';
import { Status } from 'Components/reduxLogger';
import { DescribedCode, descriptionGetter } from 'Entities/DescribedCode/describedCode.models';
import {
   getTranslatedTitle, getTranslatedTitleAndHtml
} from './translation.model';
import { memo, useEffect, useMemo } from 'react';
import { EmptyObject } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, IconButton, Paper, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { FaqAbstract } from './faq.models';  
import { useFaqReadonly } from './faq.services';
import { ErgoOneFlag } from './ErgoOneFlag';
import { macroareaDescriptionGetter } from 'Entities/Macroarea/macroarea.service';
import { FaqCloneDialog } from './FaqCloneDialog';
import {FaqsCollectionAction, FaqViewState} from "./faqsManagerState";
import {getContentTypeShortCode} from "Entities/commons";

const useFaqDetails = (props: {
   softwareCode: string,
   faqId: number,
   dispatch: React.Dispatch<FaqsCollectionAction>
}) => {

   const [state, fetch] = useFaqReadonly({ softwareCode: props.softwareCode, faqId: props.faqId });

   const { dispatch, faqId } = props;
   const { data, status } = state;

   useEffect(() => {
      if (status === Status.Success) {
         dispatch(['UPDATE', { id: faqId, patch: { details: data ?? undefined } }])
      }
   }, [status, data, dispatch, faqId]);

   return [status, fetch] as [Status, (params: EmptyObject) => void];
}

const FaqViewer = (props: FaqViewState & {
   dispatch: React.Dispatch<FaqsCollectionAction>,
}) => {

   const [t, { language }] = useTranslation();

   const { dispatch, ...state } = props;
   const { abstract, details, expanded } = state;
   const { softwareCode, id } = abstract;

   const { translations: abstractTranslations } = state.abstract;
   const title = useMemo(() => getTranslatedTitle(language, abstractTranslations), [language, abstractTranslations]);

   const [loadStatus, loadDetails] = useFaqDetails({ softwareCode, faqId: id, dispatch });

   const handleExpand = () => {
      dispatch(['UPDATE', { id, patch: { expanded: true } }])
      if (!state.details && loadStatus === Status.Idle) {
         loadDetails({});
      }
   }

   const handleShrink = () => {
      dispatch(['UPDATE', { id, patch: { expanded: false } }])
   }

   const handleEditClick = () => { dispatch(['OPEN', { id: abstract.id, scrollY: window.scrollY }]) };

   const { title: _, html } = useMemo(() => {
      const fullTranslations = details?.translations ?? [];
      return getTranslatedTitleAndHtml(language, fullTranslations)
   }, [language, details]);

   return (
      <Paper variant='outlined' sx={{ width: 'calc(100% - 32px)', p: 2, position: 'relative' }} id={'FaqViewer-' + (abstract.id ?? 0).toString()}>
         <Box width={'calc(100% - 40px)'} display='block'>
            <Stack direction={'row'} alignItems={'center'}>
               
               <Typography variant='h5' mt={1}>
                  {title}
                  <Button onClick={expanded ? handleShrink : handleExpand} color='secondary' sx={{ fontWeight: 'normal' }}>{t(expanded ? 'lessDetails' : 'moreDetails')}</Button>
               </Typography>

            </Stack>

            {expanded && <DetailedFaqView abstract={abstract} html={html} isLoading={loadStatus === Status.Pending} />}

         </Box>

         <Stack direction='row' sx={{ position: 'absolute', right: 0, top: 0 }}>
            
            <Box sx={{my: 1}}>({getContentTypeShortCode(abstract)})</Box>

            {props.abstract.softwareCode === 'ERGOBUILD' && (
                <ErgoOneFlag faqId={id} softwareCode={softwareCode}
                             value={props.abstract.ergoOne}
                             onChange={(val) => dispatch(['UPDATE', {id, patch: { abstract: {...abstract, ergoOne: val } }}])}/>
            )}
            
            <IconButton onClick={handleEditClick}><EditIcon /></IconButton>
            <FaqCloneDialog abstract={abstract} dispatch={dispatch}/>
         </Stack>

      </Paper>);
}
FaqViewer.displayName = "FaqViewer";
export { FaqViewer };


const DetailedFaqView = memo((props: { abstract: FaqAbstract, html: string | undefined, isLoading: boolean }) => {

   const [t, { language }] = useTranslation();

   const getDescription = descriptionGetter(language);
   const getMacroareaDescription = macroareaDescriptionGetter(language);
   const getFormDescription = (x: DescribedCode) => x.code + ' ' + descriptionGetter(language)(x);

   const { abstract, html } = props;

   return <>
      <Stack direction='row' width='100%' sx={{ justifyContent: 'space-between', mb: 0, alignItems: 'flex-start' }}>

         <SimpleField label={t('macroarea')} value={getMacroareaDescription(abstract.macroarea)} />
         
         <ChipsBox label={t('department')} values={abstract.departments} getDescription={getDescription} />
         
         <SimpleField label={t('module')} value={getDescription(abstract.module)} />
         
         <ChipsBox label={t('form')} values={abstract.forms} getDescription={getFormDescription} />
         
      </Stack>

      <Box>
         {props.isLoading ? <ProgressBackdrop open={true} /> : <HtmlContent html={html} />}
      </Box>
   </>
});
DetailedFaqView.displayName = "DetailedFaqView";

const SimpleField = memo((props: { label: string, value: string }) => (
   <DarkerDisabledTextField disabled
      size='small'
      label={props.label}
      defaultValue={props.value}
      sx={{ flex: '1 1 content' }} />
));
SimpleField.displayName = "MyTextField";


const ChipsBox = memo((props: { label: string, values?: DescribedCode[], getDescription: (item: DescribedCode) => string }) => {
   const values: DescribedCode[] = props.values ?? [];
   console.log("ChipBox", props.label, values);
   return (
      <FormCard vertical title={props.label} titleSize='small' sx={{ minWidth: 100, minHeight: 40 }}>
         {
            values.map((value, index) => <Chip size="small" key={index} label={props.getDescription(value)} />)
         }
      </FormCard>
   );
});
ChipsBox.displayName = "ChipBox";

