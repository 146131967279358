import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { Faq } from "./faqFeed.models";
import { useHashAuthorizedApiRoute } from "../HashCredentialsProvider";
import { EmptyObject } from "Components/utils";
import { useTranslation } from "react-i18next";

export type { FeedQueryParams } from './faqFeed.models';
import { FeedQueryParams } from './faqFeed.models';


export interface FaqFeed {
    faqs: Faq[],
}
export const useFaqFeed = () => {

    const authRoute = useHashAuthorizedApiRoute();

    return useRemoteResourceQueryService<FaqFeed, FaqFeed, FeedQueryParams>({
        endpoint: `${authRoute}/faqs`,
        defaultValue: { faqs: [] }
    });
}

export const useFaqFull = (props: { softwareCode: string, faqId: number, language?: string }) => {

    const authRoute = useHashAuthorizedApiRoute();

    const _language = useTranslation()[1].language;
    const language  = props.language ?? _language;
    
    return useRemoteResourceQueryService<Faq, null, EmptyObject>({
        endpoint: `${authRoute}/faqs/${props.faqId}?language=${language}&technical=${false}`,
        defaultValue: null
    });
}



