import {Box, Stack, Typography} from '@mui/material';
import {HookedAutocomplete, HookedCheckbox, HookedTextField} from 'Components/HookedForm/inputControls';
import {DepartmentsAutocomplete} from 'Entities/Department/DepartmentsAutocomplete';
import {DescribedCodeAutocompleteHookedOnBlur} from "Entities/DescribedCode/DescribedCodeAutocompleteHookedOnBlur";
import {SimpleEntityAutocompleteHookedOnBlur} from 'Entities/SimpleEntity/SimpleEntityAutocompleteHookedOnBlur';
import {FormAutocompleteRemote} from 'Entities/SoftwareParts/FormsAutocompletes';
import {ModuleAutocompleteRemote} from 'Entities/SoftwareParts/ModuleAutocompletes';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import ProgressBackdrop from "Components/Feedback/ProgressBackdrop";
import {Status} from "Components/reduxLogger";
import {useSoftwareVersionsList} from "Entities/Release/useSoftwareVersionsList";
import {FaqVerbose} from './faq.models';

import {FaqTranslationFormProps} from './FaqTranslationForm';
import TranslationFormTabs from './FaqTranslationsContainer';


export type FaqFormProps = FaqTranslationFormProps;


export const FaqForm: React.FC<FaqFormProps> = (props: FaqFormProps) => {

    const [t] = useTranslation();

    const {
        control,
        getValues,
        watch,
        context: {softwareCode}
    } = props;

    /* Hack for ERGO: in new faq, when macroarea gets set, add all departments (they prefer to show  the faq in unrelated depts rather than risk not showing it in related depts) */

    let doAddAllDepts = false;
    if (softwareCode === 'ERGOBUILD') {
        const isNewFaq = getValues('id') == 0;
        const hasMacroarea = watch('macroarea') !== undefined;
        const departments = watch('departments');
        doAddAllDepts = isNewFaq && hasMacroarea && (departments === undefined || (departments.length === 0));
    }

    const releases = useSoftwareVersionsList({softwareCode, default1: getValues('releaseFrom'), default2: getValues('releaseTo')});

    return <Box>

        <Stack id="VersionRange" direction={'row'} alignItems={'center'} sx={{/*width: '100%'*/flexGrow: 1}} flexWrap={'wrap'}>
        
            {/* RELEASE RANGE */}
            <Typography sx={{ml: 2}}>{t('release')}</Typography>
            <Typography sx={{mx: 2}}>{t('from')}</Typography>
            <HookedAutocomplete control={control} name={"releaseFrom"} options={releases.values} sx={{minWidth: 150, maxWidth: 250}}/>
            <Typography sx={{mx: 2}}>{t('to')}</Typography>
            <HookedAutocomplete control={control} name={"releaseTo"} options={releases.values} sx={{minWidth: 150, maxWidth: 250}}/>
            <ProgressBackdrop open={releases.status === Status.Pending}/>

            {/* CUSTOMER */}
            <HookedTextField control={control}  name={"customerId"} label={t('customerId')} sx={{minWidth: 50}} />

            {/* ERGO ONE */}
            {props.context.softwareCode === 'ERGOBUILD' && (
                <Typography mr={2}><HookedCheckbox control={control} name='ergoOne'/>Ergo ONE</Typography>
            )}

        </Stack>
        
        {/*<ReleaseRangeSelect {...props} />*/}

        <Stack flexDirection='row' alignItems={'stretch'} id='Faqs-filters'>
            <Stack flex={'1 0 50%'}>

                {/* STATUS */}
                <DescribedCodeAutocompleteHookedOnBlur<FaqVerbose, 'status'> control={control}
                                                                             name={"status"}
                                                                             label={t('status')}
                                                                             rules={{required: true}}
                                                                             endpoint={'baseData/releaseStatuses'}/>

                {/* MACROAREA */}
                <SimpleEntityAutocompleteHookedOnBlur<FaqVerbose, 'macroarea'> control={control}
                                                                               name={"macroarea"}
                                                                               label={t('macroarea')}
                                                                               endpoint={`baseData/macroareas?softwareCode=${softwareCode}`}
                                                                               sx={{
                                                                                   maxWidth: 'auto',
                                                                                   minWidth: 'auto'
                                                                               }}/>

                {/* DEPARTMENTS */}
                <Controller control={control} name={"departments"}
                            render={({field}) => {
                                return <DepartmentsAutocomplete
                                    value={field.value ?? []}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    selectAll={doAddAllDepts}/>
                            }}/>
            </Stack>

            <Stack flex={'1 0 50%'} sx={{pr: 1}}>

                {/* MODULE */}
                <Controller control={control} name={'module'}
                            render={({field}) => {
                                return (
                                    <ModuleAutocompleteRemote name={'module'}
                                                              softwareCode={softwareCode}
                                                              value={field.value}
                                                              onChange={(evt, value) => {
                                                                  field.onChange(value);
                                                              }}
                                                              onBlur={field.onBlur}/>
                                );
                            }}/>

                {/* FORMS */}
                <Controller control={control} name='forms'
                            render={({field}) => (
                                <FormAutocompleteRemote name={"form"}
                                                        multiple
                                                        softwareCode={softwareCode}
                                                        value={field.value}
                                                        onChange={(evt, value) => {
                                                            field.onChange(value);
                                                        }}
                                                        onBlur={field.onBlur}/>
                            )}/>
            </Stack>
        </Stack>

        <TranslationFormTabs  {...props} />
    </Box>
}
