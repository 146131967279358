
import { makeDescribedCodesSearchCollection } from 'Entities/DescribedCode/describedCode.services';
import {
    makeDescribedCodeEditor
} from 'Entities/DescribedCode/DescribedCodesManager/DescribedCodeEditor';
import {
    DescribedCodesManager
} from 'Entities/DescribedCode/DescribedCodesManager/DescribedCodesManager';

const useCollection = makeDescribedCodesSearchCollection({endpoint: 'baseData/messageStatuses'});

const EntityEditor = makeDescribedCodeEditor('baseData/messageStatuses', 'MessageStatusEditor');

export function MessageStatusesManager() {

  return <DescribedCodesManager 
    titleKey='Menu.baseData.messageStatuses'
    useCollection={useCollection}> 
    {EntityEditor}
   </DescribedCodesManager>
}




