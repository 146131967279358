import { ColoredLabel } from 'Components/Feedback/ColoredLabel';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { FormCard } from 'Components/FormUtils';
import HtmlContent from 'Components/HtmlContent/HtmlContent';
import { DarkerDisabledTextField } from 'Components/Input/StyledTextField';
import { Status } from 'Components/reduxLogger';
import { DescribedCode, descriptionGetter } from 'Entities/DescribedCode/describedCode.models';
import {
   getTranslatedTitle, getTranslatedTitleAndHtml
} from './translation.model';
import { memo, useEffect, useMemo } from 'react';
import { EmptyObject } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, IconButton, Paper, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { NoteAbstract } from './note.models';
import { useNoteReadonly } from './note.services';
import { ErgoOneFlag } from './ErgoOneFlag';
import { Form } from 'Entities/SoftwareParts/form.services';
import { macroareaDescriptionGetter } from 'Entities/Macroarea/macroarea.service';
import { NoteCloneDialog } from './NoteCloneDialog';
import {NotesCollectionAction, NoteViewState} from "./notesManagerState";
import {getContentTypeShortCode} from "Entities/commons";

const useNoteDetails = (props: {
   releaseId: number,
   noteId: number,
   dispatch: React.Dispatch<NotesCollectionAction>
}) => {

   const [state, fetch] = useNoteReadonly({ releaseId: props.releaseId, noteId: props.noteId });

   const { dispatch, noteId } = props;
   const { data, status } = state;

   useEffect(() => {
      if (status === Status.Success) {
         dispatch(['UPDATE', { id: noteId, patch: { details: data ?? undefined } }])
      }
   }, [status, data, dispatch, noteId]);

   return [status, fetch] as [Status, (params: EmptyObject) => void];
}

const NoteViewer = (props: NoteViewState & {
   dispatch: React.Dispatch<NotesCollectionAction>,
   releaseId: number
}) => {

   const [t, { language }] = useTranslation();

   const { dispatch, releaseId, ...state } = props;
   const { abstract, details, expanded } = state;

   const { id } = abstract;

   const { translations: abstractTranslations } = state.abstract;
   const title = useMemo(() => getTranslatedTitle(language, abstractTranslations), [language, abstractTranslations]);

   const [loadStatus, loadDetails] = useNoteDetails({ releaseId, noteId: id, dispatch });

   const handleExpand = () => {
      dispatch(['UPDATE', { id, patch: { expanded: true } }])
      if (!state.details && loadStatus === Status.Idle) {
         loadDetails({});
      }
   }

   const handleShrink = () => {
      dispatch(['UPDATE', { id, patch: { expanded: false } }])
   }

   const handleEditClick = () => { dispatch(['OPEN', { id: abstract.id, scrollY: window.scrollY }]) };

   const getDescription = descriptionGetter(language);// useDescriptionGetter();

   const { title: _, html } = useMemo(() => {
      const fullTranslations = details?.translations ?? [];
      return getTranslatedTitleAndHtml(language, fullTranslations)
   }, [language, details]);

   return (
      <Paper variant='outlined' sx={{ width: 'calc(100% - 32px)', p: 2, position: 'relative' }} id={'NoteViewer-' + (abstract.id ?? 0).toString()}>
         <Box width={'calc(100% - 40px)'} display='block'>
            <Stack>
               <Typography>

                  {abstract.labels.map((label) =>
                     <ColoredLabel key={label.code} color={label.color}>{getDescription(label)}</ColoredLabel>
                  )}
                  
                  <span style={{ paddingLeft: 16 }}>{abstract.todo}</span>
                  
                  <span>&nbsp;({getContentTypeShortCode(abstract)})</span>

                  {props.abstract.softwareCode === 'ERGOBUILD' && (
                     <ErgoOneFlag noteId={id} releaseId={props.releaseId}
                        value={props.abstract.ergoOne} 
                        onChange={(val) => dispatch(['UPDATE', {id, patch: { abstract: {...abstract, ergoOne: val } }}])}/>
                  )}

               </Typography>
               
               <Typography variant='h5' mt={1}>
                  {title}
                  <Button onClick={expanded ? handleShrink : handleExpand} color='secondary' sx={{ fontWeight: 'normal' }}>{t(expanded ? 'lessDetails' : 'moreDetails')}</Button>
               </Typography>

            </Stack>

            {expanded && <DetailedNoteView abstract={abstract} html={html} isLoading={loadStatus === Status.Pending} />}

         </Box>

         <Stack direction='row' sx={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton onClick={handleEditClick}><EditIcon /></IconButton>
            <NoteCloneDialog abstract={abstract} dispatch={dispatch}/>
         </Stack>

      </Paper>);
}
NoteViewer.displayName = "NoteViewer";
export { NoteViewer };


const DetailedNoteView = memo((props: { abstract: NoteAbstract, html: string | undefined, isLoading: boolean }) => {

   const [t, { language }] = useTranslation();

   const getDescription = descriptionGetter(language);
   const getMacroareaDescription = macroareaDescriptionGetter(language);
   const getFormDescription = (x: DescribedCode) => x.code + ' ' + descriptionGetter(language)(x);

   const { abstract, html } = props;

   return <>
      <Stack direction='row' width='100%' sx={{ justifyContent: 'space-between', mb: 0, alignItems: 'flex-start' }}>

         <SimpleField label={t('macroarea')} value={getMacroareaDescription(abstract.macroarea)} />
         
         <ChipsBox label={t('department')} values={abstract.departments} getDescription={getDescription} />
         
         <SimpleField label={t('module')} value={getDescription(abstract.module)} />
         
         <ChipsBox label={t('form')} values={abstract.forms} getDescription={getFormDescription} />
         
      </Stack>

      <Box>
         {props.isLoading ? <ProgressBackdrop open={true} /> : <HtmlContent html={html} />}
      </Box>
   </>
});
DetailedNoteView.displayName = "DetailedNoteView";

const SimpleField = memo((props: { label: string, value: string }) => (
   <DarkerDisabledTextField disabled
      size='small'
      label={props.label}
      defaultValue={props.value}
      sx={{ flex: '1 1 content' }} />
));
SimpleField.displayName = "MyTextField";


const ChipsBox = memo((props: { label: string, values?: DescribedCode[], getDescription: (item: DescribedCode) => string }) => {
   const values: DescribedCode[] = props.values ?? [];
   console.log("ChipBox", props.label, values);
   return (
      <FormCard vertical title={props.label} titleSize='small' sx={{ minWidth: 100, minHeight: 40 }}>
         {
            values.map((value, index) => <Chip size="small" key={index} label={props.getDescription(value)} />)
         }
      </FormCard>
   );
});
ChipsBox.displayName = "ChipBox";

