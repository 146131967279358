import {
    AutocompleteOnBlurDynamic, AutocompleteOnBlurDynamicProps
} from 'Components/Input/AutocompleteOnBlurDynamic';
import { AutocompleteOnBlurRemote } from 'Components/Input/AutocompleteOnBlurRemote';
import { StyledTextFieldAC } from 'Components/Input/StyledTextField';
import {
    Control, Controller, ControllerFieldState, ControllerRenderProps, FieldPathValue, FieldValues,
    Path, UnpackNestedValue
} from 'react-hook-form';

import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material';

import { Rules } from './commons';

export type FieldValue<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;


const debug = false;

export function HookedAutocomplete<
   TFieldValues extends FieldValues,
   TName extends Path<TFieldValues>,
   DisableClearable extends boolean | undefined = undefined,
>(props:
   Omit<AutocompleteProps<
      FieldValue<TFieldValues, TName>, undefined, false, false
   >, 'onChange' | 'renderInput'>
   & {
      name: Path<TFieldValues>,
      control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
      rules?: Rules<TFieldValues, Path<TFieldValues>>,
      label?: string,
   }
) {
   const { name, control, rules, label, options, loading, value, ...otherProps } = props;
   return (
      <Controller
         name={name}
         control={control}
         rules={rules}
         render={(renderProps: {
            field: ControllerRenderProps<TFieldValues>,
            fieldState: ControllerFieldState
         }) => {
            const { field, fieldState } = renderProps;
            if (debug) console.log(name, "VALUE", field.value);
            if (debug) console.log(name, "OPTIONS", options);
            return (
               <Autocomplete<FieldValue<TFieldValues, TName>, undefined, false, false>
                  options={loading ? (value ? [value] : []) : options}
                  value={field.value ?? null}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={(evt, newValue, reason) => field.onChange(newValue)}
                  {...otherProps}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                     <StyledTextFieldAC {...params} 
                        name={name} label={label}
                        error={fieldState.invalid}
                        variant="outlined"
                     />)}
               />)
         }}
      />
   )
}


//* REMOTE AUTOCOMPLETE: fetches options and allows selection by tab */

export type HookedAutocompleteOnBlurRemoteProps<
   TFieldValues extends FieldValues, 
   TName extends Path<TFieldValues>,
   TQuery = Record<string, never>
>
   = AutocompleteOnBlurRemote<FieldValue<TFieldValues, TName>, TQuery>

export function HookedAutocompleteOnBlurRemote<
   TFieldValues extends FieldValues,
   TName extends Path<TFieldValues>,
   TQuery = Record<string, never>
>(props: HookedAutocompleteOnBlurRemoteProps<TFieldValues, TName, TQuery>
   & {
      name: Path<TFieldValues>,
      control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
      rules?: Rules<TFieldValues, Path<TFieldValues>>,
      label?: string,
   }
) {
   const { name, control, rules, onChange, ...otherProps } = props;

   return (
      <Controller
         name={name}
         control={control}
         rules={rules}
         render={(renderProps: {
            field: ControllerRenderProps<TFieldValues>,
            fieldState: ControllerFieldState
         }) => {
            const { field, fieldState } = renderProps;
            return (
               <AutocompleteOnBlurRemote<FieldValue<TFieldValues, TName>, TQuery>
                  name={name}
                  value={field.value !== undefined ? field.value : null}
                  error={fieldState.invalid}
                  onBlur={field.onBlur}
                  onChange={(evt, newValue, reason) => {
                     field.onChange(newValue);
                     onChange && onChange(evt, newValue, reason);
                  }}
                  {...otherProps} />)
         }}
      />
   )
}


//* DYNAMIC REMOTE AUTOCOMPLETE: FETCHES OPTIONS DYNAMICALLY based on text input */

export function HookedAutocompleteOnBlurDynamic<
   TFieldValues extends FieldValues,
   TName extends Path<TFieldValues>,
   TQuery = Record<string, never>
>(props: AutocompleteOnBlurDynamicProps<FieldValue<TFieldValues, TName>, TQuery>
   & {
      control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
      name: Path<TFieldValues>,
      rules?: Rules<TFieldValues, Path<TFieldValues>>,
      label?: string,
   }
) {
   const { name, control, rules, onChange, ...otherProps } = props;
   return (
      <Controller
         name={name}
         control={control}
         rules={rules}
         render={(renderProps: {
            field: ControllerRenderProps<TFieldValues>,
            fieldState: ControllerFieldState
         }) => {
            const { field, fieldState } = renderProps;
            return (
               <AutocompleteOnBlurDynamic<FieldValue<TFieldValues, TName>, TQuery>
                  value={field.value !== undefined ? field.value : null}
                  //ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={(evt, newValue, reason) => {
                     field.onChange(newValue);
                     onChange && onChange(evt, newValue, reason);
                  }}
                  error={fieldState.invalid}
                  {...otherProps}
               />)
         }}
      />
   )
}

