import { CollectionLayout } from 'Components/Collection/CollectionLayout';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { Status } from 'Components/utils';
import {NotesFilter, ReleaseContext} from './note.models';
import {
   useFilteredNoteAbstractsFromFullCollection, useNoteAbstracts
} from './note.services';
import {useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Portal, Stack } from '@mui/material';

import { NotesFilterToolbar } from './NotesFilterToolbar';
import { useGroupedNotes } from './useGroupedNotes';
import { NotesGroupContainer } from './NotesGroupContainer';
import {getAbstracts, notesManagerReducer} from "./notesManagerState";


import {ReleasesManagerContext} from "../Release/ReleasesManagerContext";


export function NotesManager(props: ReleaseContext & {
   isNewRelease: boolean,
   isEditable: boolean,
   initialFilter: NotesFilter,
}) {

   const { isNewRelease, isEditable, ...context } = props;
   const { releaseId, softwareCode } = context;

   console.log("release:", softwareCode, releaseId);

   //* Collection state management

   const [state, dispatch] = useReducer(notesManagerReducer, { items: [] });
   
   //* Pick clone model from another release

   const [sharedContext, shareDispatch] = useContext(ReleasesManagerContext);
   console.log("release context", context);
   console.log("shared context", sharedContext);

   const _noteToClone = sharedContext.noteModel;
   const noteToClone = _noteToClone?.releaseId !== releaseId ? _noteToClone : undefined;
   console.log("Note to clone from other release", noteToClone);

   //  remove clone model from shared context so it's not picked again when we open another release
   useEffect(() =>  {
      if (noteToClone) shareDispatch(['SET_NOTE_MODEL', undefined]);
   }, [noteToClone, shareDispatch]);

   //* Init collection 

   const completeCollection = useNoteAbstracts({ releaseId, isNewRelease });
   const allEntities = completeCollection.entities;

   useEffect(() => {
      dispatch(['INIT', { 
         notes: allEntities,
         cloneModel: noteToClone,
         cloneDestinationReleaseId: releaseId
      }]);
   }, [noteToClone, allEntities, releaseId]);

   //* Filter

   const [filter, setFilter] = useState<NotesFilter | undefined>(props.initialFilter);

   const filteredCollection = useFilteredNoteAbstractsFromFullCollection({ releaseId, isNewRelease, filter, fullCollection: completeCollection });
   const { entities: filteredEntities } = filteredCollection;

   useEffect(() => dispatch(['FILTER', filteredEntities.map(x => x.id)]), [filteredEntities]);

   //* Add new blank note

   const handleNewItemClick = useCallback(() => { dispatch(['OPEN', { id: 0 }]) }, []);

   //* Group by macroarea

   const [noteGroups, handleOrderChangedWithinGroup] = useGroupedNotes({ notes: state.items, dispatch });


   //* RENDER

   const toolbarRef = useRef<HTMLElement>(null);
   const isLoading = completeCollection.status === Status.Pending || filteredCollection.status === Status.Pending;
   const isEditing = useMemo(() => !!state.items.find(item => item.isEditing), [state]);
   const [t] = useTranslation();

   const { scrollY: targetScrollY } = state;
   
   useEffect(() => {
      console.log("Scroll back", targetScrollY);
      if (targetScrollY !== undefined) {
         window.scrollTo({top: targetScrollY, behavior: "smooth"})
      }
   }, [isEditing, targetScrollY]);

   return (
      <CollectionLayout id={'NotesManager'}
         title={t('notes')}
         hasNewButton={false}
         sx={{ position: 'relative', overflow: 'visible', '& .MuiBoxRoot': { overflow: 'clip' } }}
         sxInner={{ overflow: 'clip', '&.MuiCard-root': { background: 'inherit' } }}>
         {{
            controls: (
               <NotesFilterToolbar
                  allNotes={getAbstracts(state.items)}
                  filter={filter}
                  onFilterChange={setFilter}
                  ref={toolbarRef}
                  showErgoOne={props.softwareCode === 'ERGOBUILD'}
                  releaseId={props.releaseId}
               />
            ),
            pagination: undefined,
            content: isLoading ? <ProgressBackdrop open /> : <>
               {
                  toolbarRef.current && <Portal container={toolbarRef.current}>
                     <Button variant={'outlined'} onClick={handleNewItemClick} disabled={isEditing}>
                        {t('createNewNote')}
                     </Button>
                  </Portal>
               }
               
               {noteGroups
                  .filter(group => group.notes.find(n => n.show)) // render only groups that have notes to show (not filtered out)
                  .map(group => (
                     <NotesGroupContainer
                        key={group.key.id}
                        notes={group.notes}
                        groupKey={group.key}
                        isEditing={isEditing}
                        onOrderChanged={(ids) => handleOrderChangedWithinGroup(ids, group.key.id)}
                        dispatch={dispatch}
                        {...context} />
                  ))}

               {(state.items.length > 2 && (
                  <Stack direction='row' sx={{ m: 2 }}>
                     <Button variant={'outlined'} onClick={handleNewItemClick} disabled={isEditing}>
                        {t('createNewNote')}
                     </Button>
                  </Stack>
               ))}
            </>
         }}
      </CollectionLayout>
   );
}







