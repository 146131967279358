import { InfomindsColors } from './infomindsColors';

import type { } from '@mui/lab/themeAugmentation';
import createCustomTheme from './createCustomTheme';
import { rgbToHex } from '@mui/system';


const colors = {
  primary: {
    main: InfomindsColors.main,
  },
  secondary: {
    main: InfomindsColors.blue,
  },
  error: {
    main: InfomindsColors.red
  },
  warning: {
    main: InfomindsColors.yellow
  },
  success: {
    main: InfomindsColors.green
  }
}

const makeSoftwareColors = (software?: string) => {
  switch (software) {
    case 'ERGOME': {
      return {
        ...colors,
        primary: {
          main: rgbToHex('rgb(139,194,136)'),
        },
        secondary: {
          main: rgbToHex('rgb(139,194,136)'),
        }
      }
    }
    default:
      return colors;
  }
}

export const makeAppThemes = (software?: string) => {
  const colors = makeSoftwareColors(software);
  return {
    default: createCustomTheme(colors, 'light'),
    dark: createCustomTheme(colors, 'dark')
  };
}


export const makeAppTheme = (software?: string, themeName?: string) => {
  const colors = makeSoftwareColors(software);
  return themeName === 'dark' ? createCustomTheme(colors, 'dark') : createCustomTheme(colors, 'light');
}

