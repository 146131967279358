//import { WithCodeAndDescription } from "Pages/appCommons";
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { DescribedCode } from 'Entities/DescribedCode/describedCode.models';

export interface Label extends DescribedCode {
    color: string
}

export const useLabels = () => useRemoteResourceQueryService<Label[], Label[]>({
    endpoint: 'baseData/labels',
    defaultValue: []
}); 
