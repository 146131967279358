import {Controller, UseFormReturn} from "react-hook-form";
import {ReleaseFilters} from "./release.models";
import {useTranslation} from "react-i18next";
import {Autocomplete, Box, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import {SoftwareAutoSelect} from "Entities/Software/SoftwareAutoSelect";
import {StyledTextField} from "Components/Input/StyledTextField";
import {DescribedCodeAutocompleteRemote} from "Entities/DescribedCode/DescribedCodeAutocomplete";
import {HookedDatePicker} from "Components/HookedForm/controls/DatePicker";
import {HookedCheckbox} from "Components/HookedForm/controls/Checkbox";
import {ModuleAutocompleteRemote} from "Entities/SoftwareParts/ModuleAutocompletes";
import {FormAutocompleteRemote} from "Entities/SoftwareParts/FormsAutocompletes";
import {SimpleEntityAutocompleteRemote} from "Entities/SimpleEntity/SimpleEntityAutocomplete";
import {HookedTextField} from "Components/HookedForm/controls/TextField";


type ReleaseFilterFormProps = UseFormReturn<ReleaseFilters>;

export function ReleaseFiltersForm(props: ReleaseFilterFormProps) {

    const {control, watch} = props;

    const [t] = useTranslation();

    const softwareCode = watch('headFilter.software.code');
    console.log("SOFTWARE", softwareCode, watch('headFilter.software'));

    return <>
        <Card>
            <CardHeader title='Release'/>
            <CardContent>
                <Stack>
                    <Controller
                        control={control}
                        name={'headFilter.software'}
                        render={({field, fieldState, formState}) => (
                            <SoftwareAutoSelect value={field.value ?? null} onChange={field.onChange}/>
                        )}
                    />

                    <Controller control={control} name={"headFilter.releaseVersion"}
                                render={({field, fieldState, formState,}) => {
                                    return <Autocomplete<string, true, undefined, true>
                                        freeSolo
                                        multiple
                                        options={field.value ?? []}
                                        value={field.value ?? []}
                                        onChange={(evt, value) => field.onChange(value)}
                                        onBlur={field.onBlur}
                                        sx={{minWidth: 100, maxWidth: 300}}
                                        renderInput={(params) => <StyledTextField {...params} variant="outlined"
                                                                                  label={t('version')}/>}
                                    />;
                                }}
                    />

                    <Controller control={control} name={"headFilter.status"}
                                render={({field, fieldState, formState,}) => {
                                    return <DescribedCodeAutocompleteRemote name={"status"}
                                                                            label={t('status')}
                                                                            endpoint='baseData/releaseStatuses'
                                                                            multiple
                                                                            value={field.value}
                                                                            onChange={(evt, value) => field.onChange(value)}
                                                                            onBlur={field.onBlur}/>
                                }}/>


                    <HookedDatePicker<ReleaseFilters> control={control}
                                                      name='headFilter.releaseDateFrom'
                                                      label={t('distributionDate') + ' ' + t('from')}
                                                      convertToString
                    />
                    <HookedDatePicker<ReleaseFilters> control={control}
                                                      name='headFilter.releaseDateTo'
                                                      label={t('distributionDate') + ' ' + t('to')}
                                                      convertToString
                    />
                </Stack>
            </CardContent>
        </Card>

        <Card>
            <CardHeader title='Note'/>
            <CardContent>
                <Stack>

                    {softwareCode === 'ERGOBUILD' &&
                        <Typography><HookedCheckbox control={control} name='notesFilter.ergoOne'/>ErgoONE</Typography>}

                    <Controller control={control} name={'notesFilter.module'}
                                render={({field}) => {
                                    return (
                                        <ModuleAutocompleteRemote name={'module'}
                                                                  multiple
                                                                  softwareCode={softwareCode}
                                                                  value={field.value}
                                                                  onChange={(evt, value) => {
                                                                      field.onChange(value);
                                                                  }}
                                                                  onBlur={field.onBlur}/>
                                    );
                                }}
                    />

                    <Controller control={control} name='notesFilter.form'
                                render={({field}) => (
                                    <FormAutocompleteRemote name={"form"}
                                                            multiple
                                                            softwareCode={softwareCode}
                                                            value={field.value}
                                                            onChange={(evt, value) => {
                                                                field.onChange(value);
                                                            }}
                                                            onBlur={field.onBlur}/>
                                )}
                    />

                    <Controller control={control} name={"notesFilter.macroarea"}
                                render={({field, fieldState, formState,}) => {
                                    return <SimpleEntityAutocompleteRemote name={"macroarea"}
                                                                           label={t('macroarea')}
                                                                           endpoint={`baseData/macroareas?softwareCode=${softwareCode}`}
                                                                           multiple
                                                                           value={field.value}
                                                                           onChange={(evt, value) => field.onChange(value)}
                                                                           onBlur={field.onBlur}/>
                                }}/>

                    <Controller control={control} name={"notesFilter.department"}
                                render={({field, fieldState, formState,}) => {
                                    return <DescribedCodeAutocompleteRemote name={"department"}
                                                                            label={t('department')}
                                                                            endpoint='baseData/departments'
                                                                            multiple
                                                                            value={field.value}
                                                                            onChange={(evt, value) => field.onChange(value)}
                                                                            onBlur={field.onBlur}/>
                                }}/>

                    <Controller control={control} name={"notesFilter.todo"}
                        //render={({ field }) => <FreeMultiTextField value={field.value} onChange={field.onChange} onBlur={field.onBlur}/>}
                                render={({field}) => (
                                    <Autocomplete<string, true, undefined, true>
                                        freeSolo
                                        multiple
                                        options={field.value ?? []}
                                        value={field.value ?? []}
                                        onChange={(evt, value) => field.onChange(value)}
                                        onBlur={field.onBlur}
                                        sx={{minWidth: 100, maxWidth: 300}}
                                        renderInput={(params) => <StyledTextField {...params} variant="outlined"
                                                                                  label={t('todo')}/>}
                                    />)}
                    />

                    <HookedTextField control={control} name='notesFilter.content' label={t('content')}/>

                </Stack>
            </CardContent>
        </Card>
    </>
}