import {ContentType} from 'Entities/commons';
import {DescribedCode} from 'Entities/DescribedCode/describedCode.models';
import {Label} from 'Entities/Label/label.services';
import {Form} from 'Entities/SoftwareParts/form.services';
import {Module} from 'Entities/SoftwareParts/module.services';

import {Translation, TranslationAbstract} from './translation.model';
import {Macroarea} from 'Entities/Macroarea/macroarea.service';

export interface FormKey {moduleCode?: string, formCode: string}

interface NoteBase {
  releaseId: number,
  softwareCode: string,
  customerId?: string,
  priority: number,
  todo?: string,
  ergoOne: boolean
}

export interface NoteHead extends NoteBase {
  id: number,
  moduleCode?: string,
  forms?: FormKey[],
  departmentCodes?: string[],
  macroareaId?: number,
  labelCodes?: string[]
}

export interface NoteAbstract extends NoteBase {
  id: number,
  module?: Module,
  forms?: Form[],
  departments?: DescribedCode[],
  macroarea?: Macroarea,
  labels: Label[],
  translations: TranslationAbstract[],
  contentType?: ContentType
}

export interface Note extends NoteHead {
  translations: Translation[]
  contentType?: ContentType
}

export interface NoteVerbose extends Omit<NoteAbstract, 'translations'> {
  translations: Translation[]
}


export const makeEmptyNote = (props: { releaseId: number, softwareCode: string }): NoteVerbose => ({
  id: 0,
  releaseId: props.releaseId,
  softwareCode: props.softwareCode,
  module: undefined,
  priority: 0,
  contentType: 'Empty',
  ergoOne: false,
  labels: [],
  translations: []
});


export function minifyNote(model: NoteVerbose): Note {
  return {
    id: model.id,
    releaseId: model.releaseId,
    softwareCode: model.softwareCode,
    todo: model.todo,
    customerId: model.customerId,
    priority: model.priority,
    moduleCode: model.module?.code,
    forms: model.forms?.map(x => ({moduleCode: x.moduleCode, formCode: x.code})),
    departmentCodes: model.departments?.map(x => x.code),
    macroareaId: model.macroarea?.id,
    ergoOne: model.ergoOne,
    labelCodes: model.labels.map(label => label.code),
    translations: model.translations
  };
}

export function noteVerboseToAbstract(note: NoteVerbose): NoteAbstract {
  const {translations, ...other} = note;
  return {
    ...other,
    translations: translations.map(x => { 
      const {html, htmlTech, ...head} = x; 
      return {
        ...head,
        html: `${html ?? ''}${htmlTech ?? ''}`
      };
    })
  }
}

export interface ReleaseContext {
  releaseId: number,
  softwareCode: string,
}

export interface NotesFilter {
  module?: Module[]
  form?: Form[],
  macroarea?: Macroarea[],
  department?: DescribedCode[],
  todo?: string[],
  content?: string,
  ergoOne?: boolean,
  contentType?: ContentType
}


