import { StyledTextFieldAC } from 'Components/Input/StyledTextField';
import { useRemoteResourceQueryService } from 'Components/RemoteEntities/resource.services';
import { Status } from 'Components/utils';
import { useCallback, useEffect, useMemo } from 'react';

import {
    Autocomplete, AutocompleteChangeReason, 
    SxProps, Theme
} from '@mui/material';

import { DescribedCode, describedCodeFromCode, useOptionLabelGetter } from './describedCode.models';

const debug = false;


export function DescribedCodeAutocompleteMultiCodes(props: {
    name: string,
    value: string[],
    onChange?: (newValue: string[]) => void,
    label?: string,
    error?: boolean,
    sx?: SxProps<Theme>,
    endpoint: string
}) {

    const { value: codeValues, onChange, label, error, name, sx, ...autocompleteProps } = props;

    const getOptionLabel = useOptionLabelGetter();

    if (debug) console.log("AutocompleteRemote", props);

    //* FETCH OPTIONS

    const [collection, fetch] = useRemoteResourceQueryService<DescribedCode[], DescribedCode[]>({ endpoint: props.endpoint, defaultValue: [] });
    const isLoaded = collection.status === Status.Success;
    const isInitialized = collection.status != Status.Idle;

    // fetch immediately otherwise description of initial value cannot be shown
    useEffect(() => { if (!isInitialized) fetch({}); })

    const remoteList = collection.data;

    const options: DescribedCode[] = useMemo(
        () => (codeValues && !isLoaded) ? codeValues.map(code => describedCodeFromCode(code)) : remoteList,
        [remoteList, codeValues, isLoaded]
    );

    const objValues: DescribedCode[] = useMemo(() => codeValues
        ? codeValues.map(code => options.find(opt => opt.code === code)).filter(x => x !== undefined).map(x => x as DescribedCode)
        : [],
        [codeValues, options])

    if (debug) {
        console.log(name, "VALUE", codeValues);
        console.log(name, "OPTIONS", options);
    }

    //* HANDLE SELECTION CHANGE

    const handleChange = useCallback((
        evt: React.SyntheticEvent<Element, Event>,
        value: DescribedCode[] | null,
        reason: AutocompleteChangeReason
    ) => {
        if (!onChange) return;
        if (value) {
            onChange(value.map(x => x.code));
        } else {
            onChange([]);
        }
    }, [onChange]);

    return <Autocomplete<DescribedCode, true, false, false> multiple
        value={objValues}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option: DescribedCode, value?: DescribedCode) => (option.code === value?.code)}
        onChange={handleChange}
        loading={collection.status === Status.Pending}
        autoSelect
        autoComplete
        clearOnBlur={true}
        sx={{ mx: 1, ...sx }}
        {...autocompleteProps}
        renderInput={(params) => (
            <StyledTextFieldAC {...params} name={name} label={label} variant="outlined" error={error} />
        )}
    />

}


