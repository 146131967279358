import {useTranslation} from "react-i18next";

import { Faq } from '../faqFeed.models';
import FaqView from "./FaqView";
import {SimpleEntity, useDescriptionGetter} from 'Entities/SimpleEntity/simpleEntity.models';
import {Accordion, AccordionDetails, AccordionSummary, styled} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type Section = SimpleEntity & { /*isHidden: boolean, wasHidden: boolean, */ faqs: Faq[] };

export function FaqSectionView(props: {
    section: Section,
    expanded?: boolean,
    softwareCode: string
}) {

    const { section } = props;
    const rows = section.faqs;

    const getDescription = useDescriptionGetter();
    const [t] = useTranslation();
    
    return <Accordion defaultExpanded={props.expanded}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            <Title>{props.section ? getDescription(props.section) : t('moduleNotSpecified')}</Title>
            <Number>{rows.length}</Number>
        </AccordionSummary>
        <AccordionDetails>
            {rows.map((row, index) => <FaqView key={index} {...row} expanded={props.expanded}/>)}
        </AccordionDetails>
    </Accordion>;
}

const Title = styled("span")(({ theme }) => ({
    fontSize: '120%',
    fontWeight: 'bold'
}))

const Number = styled("span")(({ theme }) => ({
    marginLeft: 12,
    color: theme.palette.grey[500],
    fontSize: '120%',
    fontWeight: 'bold'
}))

