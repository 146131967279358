import {useContext} from "react";
import {useForm} from "react-hook-form";
import {emptySwFaqFilter, SwFaqFilters} from "./swFaq.models";
import {useTranslation} from "react-i18next";
import ToolbarWithDrawer from "Components/Containers/ToolbarWithDrawer";
import {Box, Button, Stack} from "@mui/material";
import {Link} from "react-router-dom";
import {SwFaqFiltersForm} from "./SwFaqFiltersForm";
import {SwFaqsManagerContext} from "./SwFaqsManagerContext";

export function SwFaqFiltersToolbar() {

    const [context, dispatch] = useContext(SwFaqsManagerContext);

    const form = useForm<SwFaqFilters>({defaultValues: context.filters});
    const {control, reset, watch} = form;

    const [t] = useTranslation();

    //console.log("SOFTWARE", softwareCode, watch('software'));

    const handleFiltersChange = (value: SwFaqFilters) => dispatch(['SET_FILTERS', value]);

    return (
        <ToolbarWithDrawer id={'SwFaqFilterToolbar'}
                           onClose={() => handleFiltersChange({...watch()})}
                           onClear={() => reset(emptySwFaqFilter)}
        >{{
            main: <Stack direction={'row'} justifyContent={'space-between'} sx={{width: 1}}>
                <Box>
                    <Button component={Link} variant='outlined' to={`${context.urlBasePath}/new`}>{t('createNewSwFaqs')}</Button>
                </Box>
                <Box marginRight={3}>
                    <Button onClick={() => {
                        reset(emptySwFaqFilter);
                        handleFiltersChange(emptySwFaqFilter);
                    }}>{t('removeFilters')}</Button>
                </Box>
            </Stack>,

            drawer: <Box id={'SwFaqFilterControls'} sx={{overflowY: 'scroll', pr: 2}}><SwFaqFiltersForm {...form} /></Box>

        }}</ToolbarWithDrawer>
    )
}