import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteProps, AutocompleteRenderInputParams, Box, SxProps, Theme } from "@mui/material";
import { useRemoteResourceQueryService } from "Components/RemoteEntities/resource.services";
import { useDebounce } from "Components/useDebounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyledTextFieldAC } from "./StyledTextField";
import { Status } from "Components/utils";


const debug = false;

function getSingleMatch<TModel>(options: TModel[], matcher: (option: TModel, textInput: string) => boolean, textInput?: string): undefined | TModel {
  if (!textInput || options.length !== 1) {
    return undefined;
  } else if (matcher(options[0], textInput)) {
    return options[0];
  } else {
    return undefined;
  }
}

export type AutocompleteOnBlurDynamicProps<
  TModel, 
  TQuery = Record<string, never>
> = Omit<
  AutocompleteProps<TModel, undefined, undefined, undefined>,
  'value' | 'options' | 'renderInput' | 'getOptionLabel' //| 'error' | 'sx'
> & {
  name?: string,
  value?: TModel | null,
  label?: string,
  error?: boolean,
  sx?: SxProps<Theme>,
  endpoint: string,
  query: TQuery,
  isOptionEqualToInput: (option: TModel, textInput: string) => boolean,
  isOptionEqualToValue: (option: TModel, value: TModel) => boolean,
  onPendingStatusChange?: (isPending: boolean) => void,
  getOptionLabel: (entity: TModel) => string // in MUI Autocomplete this is optional
}

/**
 * * Fetches options based on input. When input matches a single options, the option is selected on blur.
 * @param props 
 * @returns 
 */
export function AutocompleteOnBlurDynamic<
  TModel,
  TQuery = Record<string, never>
>(props: Omit<
  AutocompleteProps<TModel, undefined, undefined, undefined>,
  'value' | 'options' | 'renderInput' | 'getOptionLabel'
> & {
  name?: string,
  value?: TModel | null,
  label?: string,
  error?: boolean,
  sx?: SxProps<Theme>,
  endpoint: string,
  query: TQuery,
  isOptionEqualToInput: (option: TModel, textInput: string) => boolean,
  isOptionEqualToValue: (option: TModel, value: TModel) => boolean,
  onStatusChange?: (isPending: boolean) => void,
  getOptionLabel: (entity: TModel) => string // in MUI Autocomplete this is optional
}) {
  
  const { value, query, isOptionEqualToInput, isOptionEqualToValue, endpoint, getOptionLabel, onChange, onBlur, label, error, name, onStatusChange: onPendingStatusChange, sx, ...autocompleteProps } = props;

  //* TEXT INPUT VALUE */

  // value entered by the user in the text field. To be used as filter to fetch list
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const handleInputChange = useCallback((evt: React.SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (debug) console.log('InputChange', newInputValue, reason);
    if (reason !== 'reset') {
      //console.log("NEW INPUT VALUE");
      setInputValue(newInputValue);
    }
  }, []);

  useEffect(() => {
    if (value === null) {
        setInputValue('');
    }
}, [value]);

  
  //* FETCH COLLECTION
  
  const [collection, fetch, clear] = useRemoteResourceQueryService<TModel[], TModel[], TQuery>({ endpoint, defaultValue: [] })

  const debouncedInputValue = useDebounce(inputValue, 300);
  useEffect(() => {
    if (debug) console.log("FETCH", query, debouncedInputValue);
    if (debouncedInputValue) {
      if (debouncedInputValue.length > 2) {
        fetch({ search: debouncedInputValue, ...query})
      }
      else if (debouncedInputValue.length === 0) {
        clear();
      }
    }
  }, [debouncedInputValue, fetch, query, clear]);

  const collectionStatus = collection.status;
  useEffect(() => {
    if (!!onPendingStatusChange && collectionStatus != Status.Idle) {
      onPendingStatusChange(collectionStatus === Status.Pending);
    }
  }, [onPendingStatusChange, collectionStatus])

  const list = collection.data;

  
  //* SET OPTIONS LIST
  
  const options: TModel[] = useMemo(() => value ? list.concat(value) : list, [list, value]);
  if (debug) {
    console.log("options", options);
    console.log("VALUE", value && getOptionLabel(value));  
  }
    
  //* MATCH OPTION

  const [newMatch, setNewMatch] = useState<TModel | null>(null);
  const [blurred, setBlurred] = useState<React.SyntheticEvent<Element, Event> | null>(null);

  /* Match input text to newly fetched options when input is blurred */
  useEffect(() => {
    if (debug) console.log("inputValue", inputValue, 'blurred?', !!blurred);
    const singleMatch = getSingleMatch(options, isOptionEqualToInput, inputValue);
    if (singleMatch) {
      if (debug) console.log("singleMatch", singleMatch);
      if (blurred) {
        onChange && onChange(blurred, singleMatch, 'selectOption');
        setNewMatch(null);
      } else {
        setNewMatch(singleMatch);
      }
    }
  }, [inputValue, options, blurred, onChange, isOptionEqualToInput]);



  //* HANDLE BLUR: if there is a matching option select it

  const handleBlur = useCallback((evt: React.FocusEvent<HTMLDivElement>) => {
    setBlurred(evt);
    if (debug) console.log("BLUR", newMatch);
    if (newMatch && newMatch !== value) {
      onChange && onChange(evt, newMatch, 'selectOption');
      setNewMatch(null);
    }
    onBlur && onBlur(evt);
  }, [value, newMatch, onBlur, onChange]);


  //* HANDLE SELECTION CHANGE

  const handleChange = useCallback((
    evt: React.SyntheticEvent<Element, Event>,
    value: TModel | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TModel>
  ) => {
    if (debug) console.log("CHANGE", value, reason, details);
    setNewMatch(value);
    if (onChange) onChange(evt, value, reason, details);
  }, [onChange]);

  return (<>
    <Autocomplete<TModel>
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={props.isOptionEqualToValue}
      clearOnBlur
      autoSelect
      autoHighlight
      autoComplete
      onInputChange={handleInputChange}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={() => setBlurred(null)}
      value={value}
      loading={collection.status === Status.Pending}
      popupIcon={""}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextFieldAC {...params}
          name={name}
          label={label}
          variant="outlined"
          error={error}
          inputProps={{
            ...params.inputProps,
            value: value ? getOptionLabel(value) : (inputValue ?? '')
          }} />
      )}
      {...autocompleteProps}
      sx={{mx: 2, flexGrow: 1, minWidth: 300, ...sx}}
    />
  </>)
}