import ToolbarWithDrawer from 'Components/Containers/ToolbarWithDrawer';
import {HookedAutocomplete, HookedCheckbox, HookedSelect, HookedTextField} from 'Components/HookedForm/inputControls';
import {deduplicate, hasContent, Status} from 'Components/utils';
import {
    DescribedCode, isEqual, useDescriptionGetter
} from 'Entities/DescribedCode/describedCode.models';
import {
    DescribedCodeAutocomplete,
    DescribedCodeAutocompleteRemote
} from 'Entities/DescribedCode/DescribedCodeAutocomplete';
import { SimpleEntityAutocomplete } from 'Entities/SimpleEntity/SimpleEntityAutocomplete';
import { FormAutocomplete } from 'Entities/SoftwareParts/FormsAutocompletes';
import { Module } from 'Entities/SoftwareParts/module.services';
import { ModuleAutocomplete } from 'Entities/SoftwareParts/ModuleAutocompletes';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {Box, Button, Card, Stack, Typography} from '@mui/material';
import ProgressBackdrop from "Components/Feedback/ProgressBackdrop";
import {useSoftwareVersionsList} from "Entities/Release/useSoftwareVersionsList";

import {FaqAbstract, FaqFilter} from './faq.models';
import { isSameMacroarea, Macroarea } from 'Entities/Macroarea/macroarea.service';


const useFilterOptionsFromFaqs = (props: {faqs: FaqAbstract[]}) => {

    const { faqs } = props;
    
    return {
        statuses: deduplicate((faqs?.flatMap(faq => faq.status).filter(x => !!x) ?? []) as DescribedCode[], isEqual),
        macroareas: deduplicate((faqs?.map(faq => faq.macroarea).filter(x => !!x) ?? []) as Macroarea[], isSameMacroarea)
                    .sort((a,b) => a.sortIndex - b.sortIndex),
        departments: deduplicate((faqs?.flatMap(faq => faq.departments).filter(x => !!x) ?? []) as DescribedCode[], isEqual),
        modules: deduplicate((faqs?.map(faq => faq.module).filter(x => !!x) ?? []) as Module[], isEqual),
        forms: deduplicate((faqs?.flatMap(faq => faq.forms).filter(x => !!x) ?? []) as Module[], isEqual),
    };
}

export interface FaqsFilterToolbarProps {
    softwareCode: string,
    allFaqs: FaqAbstract[], // needed to get filter options
    filter?: FaqFilter,
    onFilterChange: (filters?: FaqFilter) => void,
    showErgoOne: boolean,
}

export const FaqsFilterToolbar = forwardRef<HTMLElement, FaqsFilterToolbarProps>((props: FaqsFilterToolbarProps, ref: ForwardedRef<HTMLElement>) => {

    const { allFaqs, softwareCode } = props;

    const options = useFilterOptionsFromFaqs({faqs: allFaqs});

    console.log("INITIAL FILTER", props.filter);

    const form = useForm<FaqFilter>({ defaultValues: props.filter });
    const { control, getValues, reset, watch } = form;

    const { onFilterChange } = props;

    const handleClose = useCallback(() => {
        // propagate filter if it has values, otherwise propagate filter = undefined
        const values = getValues();
        if (hasContent(values)) {
            onFilterChange(values);
        }
        else {
            onFilterChange(undefined);
        }
    }, [onFilterChange, getValues]);

    const [t] = useTranslation();

    const releases = useSoftwareVersionsList({softwareCode, default1: getValues('versionFrom'), default2: getValues('versionTo')});

    return (
        <ToolbarWithDrawer id='FaqFilterToolbar' 
                           onClose={handleClose}                    
                           onClear={() => reset({})} >
            {{
                main: <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: 1 }}>
                    <Box ref={ref} />
                    <Box marginRight={3}>
                        <Button onClick={() => {
                            reset({});
                            onFilterChange(undefined);
                        }}>{t('removeFilters')}</Button>
                    </Box>
                </Stack>,
                drawer: (
                    <Stack id={'FaqFilterControls'} sx={{overflowY: 'scroll', pr: 2}}>
                        
                        {/* RELEASE RANGE */}
                        <HookedAutocomplete control={control} name={"versionFrom"} options={releases.values} label={t('releaseFrom')} sx={{ mx: 1 }}/>
                        <HookedAutocomplete control={control} name={"versionTo"} options={releases.values} label={t('releaseTo')} sx={{ mx: 1 }}/>
                        <ProgressBackdrop open={releases.status === Status.Pending}/>
                        
                        <Controller control={control} name={"status"}
                                    render={({field, fieldState, formState,}) => {
                                        return <DescribedCodeAutocompleteRemote name={"status"}
                                                                                label={t('status')}
                                                                                endpoint='baseData/ReleaseStatuses'
                                                                                multiple
                                                                                value={field.value}
                                                                                onChange={(evt, value) => field.onChange(value)}
                                                                                onBlur={field.onBlur}/>
                                    }}/>
                        <Controller control={control} name="macroarea"
                            render={({ field }) => (
                                <SimpleEntityAutocomplete name='macroarea'
                                    label={t('macroarea')}
                                    multiple
                                    options={options.macroareas}
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />)}
                        />
                        <Controller control={control} name="department"
                            render={({ field }) => (
                                <DescribedCodeAutocomplete name='department'
                                    multiple
                                    label={t('department')}
                                    options={options.departments}
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />)}
                        />

                        <Controller control={control} name='module'
                            render={({ field }) => (
                                <ModuleAutocomplete multiple  name={'module'}
                                    options={options.modules}  
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />
                            )}
                        />
                        <Controller control={control} name='form'
                            render={({ field }) => (
                                <FormAutocomplete multiple name={'form'}
                                    options={options.forms} 
                                    value={field.value}
                                    onChange={(evt, val, reason) => field.onChange(val)}
                                    onBlur={field.onBlur} />
                            )}
                        />

                        <HookedTextField control={control} name={"customerId"} label={t('customerId')} />

                        {props.showErgoOne && <Typography><HookedCheckbox control={control} name='ergoOne' />ErgoONE</Typography>}

                        <HookedSelect control={control} name={'contentType'} label={t('faqContentType')}
                            options={[{ value: 'NonTechnical', label: 'NonTechnical' }, { value: 'Technical', label: 'Technical' }]}
                        />

                        <HookedTextField control={control} name='content' label={t('content')} />

                    </Stack>
                )
            }}
        </ToolbarWithDrawer>
    )
});