import {SoftwareWithFaqs, SwFaqFilters} from "./swFaq.models";
import {useTranslation} from "react-i18next";
import {
    ListItemButton,
    ListItemText,
} from "@mui/material";

import {useCallback, useContext} from "react";
import {SwFaqsManagerContext} from "./SwFaqsManagerContext";
import {useHistory} from "react-router-dom";
import {Software} from "../../../../Entities/Software/software.services";

export function FaqSoftwaresList(props: { softwares: SoftwareWithFaqs[], withPdf?: boolean, filters: SwFaqFilters }) {

    const {softwares} = props;

    const [t] = useTranslation();

    return <>
        {softwares.map((software) => (
            <SoftwareFaqInfo key={software.code} software={software} withPdf={props.withPdf} filters={props.filters}/>
        ))}
    </>
}

const SoftwareFaqInfo = (props: { software: SoftwareWithFaqs, withPdf?: boolean, filters: SwFaqFilters }) => {

    const {software} = props;

    const [ {urlBasePath}, dispatchContext] = useContext(SwFaqsManagerContext);

    //console.log("filters", props.filters);

    const {push: historyPush} = useHistory();

    const handleClicked = useCallback((sw: Software) => {
        dispatchContext(['SET_ACTIVE_SOFTWARE', sw]);
        historyPush(urlBasePath + '/' + sw.code);
    }, [urlBasePath, dispatchContext, historyPush]);

    return <ListItemButton onClick={() => handleClicked(software)} sx={{py: 1, justifyContent: 'flex-start'}}>
        <ListItemText
            sx={{'&.MuiListItemTextRoot': {flex: '1 0 auto'}}}>{`${software.description} (${software.faqsCount})`}
        </ListItemText>
    </ListItemButton>
}