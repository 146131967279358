import { ClickableTableCell } from 'Components/Containers/ClickableTableCell';
import { SimpleEntity, useDescriptionGetter } from 'Entities/SimpleEntity/simpleEntity.models';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Alert, Card, styled, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface NewsTableSectionHeadProps {
    section: SimpleEntity | null,
    entriesCount: number,
    isOpen: boolean;
    onToggleOpen: (val: boolean) => void;
    isRead: boolean | undefined;
    onToggleReadAllStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isHideChecked: boolean;
    onToggleHideForever: (event: React.ChangeEvent<HTMLInputElement>) => void;
    softwareName: string;
}

export function NewsTableSectionHead(props: NewsTableSectionHeadProps) {

    const { onToggleReadAllStatus, isOpen } = props;

    const getDescription = useDescriptionGetter();

    const [showHideForeverAlert, setShowHideForeverAlert] = useState(false);

    
    const handleToggleHideForever = (evt: ChangeEvent<HTMLInputElement>) => {
        if (evt.target.checked) {
            setShowHideForeverAlert(true);
            setTimeout(() => { setShowHideForeverAlert(false) }, 30000);    
        }
        props.onToggleHideForever(evt);
    }

    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={props.isRead === undefined}
                    checked={!!props.isRead}
                    onChange={onToggleReadAllStatus}
                    inputProps={{
                        'aria-label': 'mark all as read',
                    }}
                />
            </TableCell>

            <ClickableTableCell align={'left'} padding={'normal'}
                onClick={() => props.onToggleOpen(!props.isOpen)}
                aria-label="expand row" 
            >
                <IconButton  size="small">
                    {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Title>{props.section ? getDescription(props.section) : t('moduleNotSpecified')}</Title>
                <Number>{props.entriesCount}</Number>
            </ClickableTableCell>

            <TableCell>{null}</TableCell>

            <TableCell padding="checkbox" sx={{position: 'relative'}}>
                <Checkbox
                    color="primary"
                    checked={props.isHideChecked}
                    onChange={handleToggleHideForever}
                    inputProps={{'aria-label': 'do not show module again'}}
                />
                {showHideForeverAlert && (
                    <Card sx={{position: 'absolute', top: 0, left: -300, maxWidth: 300, background: theme.palette.background.default, zIndex: 10}}>
                        <Alert severity='warning' onClose={()=>{setShowHideForeverAlert(false)}}>{t('hideForeverAlert')}</Alert>
                        <Alert severity='info'>{t('hideForeverInfo', {software: props.softwareName})}</Alert>
                    </Card>
                )}
            </TableCell>
        </TableRow>
    );
}


const Title = styled("span")(({ theme }) => ({
    fontSize: '120%',
    fontWeight: 'bold'
}))

const Number = styled("span")(({ theme }) => ({
    marginLeft: 12,
    color: theme.palette.grey[500],
    fontSize: '120%',
    fontWeight: 'bold'
}))