import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, lighten, darken, TableCellProps, TableProps, TableContainerProps } from "@mui/material";
import React from "react";
import { styled } from '@mui/material';
import { ClickableTableCell } from "Components/Containers/ClickableTableCell";
import { arrayToDict } from "Components/utils";

export interface Column<R extends Row> {
  id: string,
  label?: string,
  align?: 'right' | 'center',
  width?: number | string,
  format?: (value: any) => string,
  render?: (row: R) => React.ReactNode,
  cellProps?: TableCellProps
  headProps?: TableCellProps
}

export interface Row {
  [key: string]: any
  id?: number | string
}

export type RowIdGetter<R extends Row> = (x: R) => string;

export const HoverStyledTableRow = styled(TableRow)(({ theme }) => {
  const hoverBackground= (theme.palette.mode === 'light' ? lighten : darken)(theme.palette.primary.main, .7);
  return {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: (theme.palette.mode === 'light' ? darken : lighten)(theme.palette.background.paper, 0.03),
    // },
    // hide last border
    '&:last-of-type td, &:last-of-type th': {
      border: 0,
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: hoverBackground,
      color: theme.palette.getContrastText(hoverBackground)
    },
    '&.MuiTableRow-root:hover': {
      backgroundColor: hoverBackground,
      color: theme.palette.getContrastText(hoverBackground)
    }
  };
});



/*******************************************
 * ROW-SELECTABLE TABLE
 *******************************************/
export function ClickableRowsTable<R extends Row>(props: {

  columns: Column<R>[],
  rows: R[],
  rowIdGetter?: RowIdGetter<R>,
  onRowClicked?: (row: R) => void,
  rootProps?: TableContainerProps,
  tableProps?: TableProps,
  cellProps?: TableCellProps,
  dontShowEmptyTable?: boolean,
}): React.ReactElement {

  const rowIdGetter = props.rowIdGetter || ((row: R) => (row as any).id);

  const { rows } = props;
  if ((!rows || rows.length === 0) && props.dontShowEmptyTable) {
    return <div></div>;
  }

  const rowsDict = arrayToDict(props.rows, props.rowIdGetter);
  
  const handleRowClicked = (evt: React.SyntheticEvent<HTMLTableRowElement>) => {
    if (props.onRowClicked) {
      props.onRowClicked(rowsDict[evt.currentTarget.id])
    }
  }

  const { columns, tableProps, cellProps, rootProps } = props;
  return (
    <TableContainer {...rootProps}
      sx={{ maxHeight: 1, maxWidth: 1, overflowY: 'auto', overflowX: 'auto', ...rootProps?.sx }} >
      <Table stickyHeader aria-label="table-header" {...tableProps} >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <ClickableTableCell
                key={column.id}
                align={column.align}
                {...cellProps}
                {...column.headProps}
              >
                {column.label}
              </ClickableTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const _id = rowIdGetter(row);
            const id = _id === undefined ? index.toString() : _id;
            return (
              <HoverStyledTableRow hover role="checkbox" tabIndex={-1}
                key={id}
                id={id}
                onClick={handleRowClicked}
              >
                {columns.map((column) => {
                  return (
                    <ClickableTableCell key={column.id}
                      align={column.align}
                      {...cellProps}
                      {...column.cellProps}
                    >
                      <CellContent column={column} row={row} />
                      {/*{renderCellContent(column, row)}*/}
                    </ClickableTableCell>
                  );
                })}
              </HoverStyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function renderCellContent<R extends Row>(column: Column<R>, row: R): React.ReactNode {
  if (column.render) {
    return column.render(row);
  } else {
    const value = row[column.id];
    return column.format ? column.format(value) : value;
  }
}

const CellContent = <R extends Row>(props: {column: Column<R>, row: R}) => {
  const { column, row } = props;
  if (column.render) {
    return column.render(row);
  } else {
    const value = row[column.id];
    return column.format ? column.format(value) : value;
  }
}







