import { Alert, Box, List, ListItem as MuiListItem, ListItemText, Typography, styled } from "@mui/material"
import { useSSR, useTranslation } from "react-i18next"
import { User } from "./userSettingsServices";
import { useMemo } from "react";
import { useSoftwareFirstVersion } from "Pages/Dashboard/BaseData/Software/software.services";

export const UserSettingsInfo = (props: {
    softwareCode: string,
    softwareName: string,
    user: User,
    asAdmin: boolean
}) => {


    const { user, asAdmin, softwareCode } = props;
    const isNotProfiled = !(user.isProfiled);

    const hasNoRelnoteSettings = useMemo(() => {
        if (user != undefined) {
            const mySettings = user;
            if (!mySettings.inactive && !mySettings.showAtStartup && !mySettings.showOnForm) {
                return true;
            }
        }
        return false;
    }, [user]);

    const [t] = useTranslation();

    const firstVersion = useSoftwareFirstVersion({softwareCode });

    return <>
        {hasNoRelnoteSettings && (
            <Alert severity='warning'>
                { !!firstVersion?.data 
                    ? <Typography>{t('MissingSettingsMessage.1a', { version: firstVersion.data , software: props.softwareName })}</Typography>
                    : <Typography>{t('MissingSettingsMessage.1b', { software: props.softwareName })}</Typography>
                }
                <Typography mt={3}>{t(asAdmin ? 'MissingSettingsMessage.2b' : 'MissingSettingsMessage.2a', { software: props.softwareName })}</Typography>
                <Typography>{t('MissingSettingsMessage.3', { software: props.softwareName })}</Typography>
                <List sx={{}}>
                    <ListItem><ListItemText><ItemName>{t('showAtStartup')}</ItemName>{t('MissingSettingsMessage.4', { software: props.softwareName })}</ListItemText></ListItem>
                    <ListItem><ListItemText><ItemName>{t('showOnForm')}</ItemName>{t('MissingSettingsMessage.5')}</ListItemText></ListItem>
                    <ListItem><ListItemText><ItemName>{t('ownDepartmentsOnly')}</ItemName>{t('MissingSettingsMessage.6')}&nbsp;<a href={user.profileURL} target="_blank" rel="noreferrer">{t('userProfile')}</a></ListItemText></ListItem>
                    {/* <ListItem><ListItemText><ItemName>{t('inactive')}</ItemName>{t('MissingSettingsMessage.7', { software: props.softwareName })}<br />{t('MissingSettingsMessage.8')}</ListItemText></ListItem> */}
                    <ListItem><ListItemText><ItemName>{t('inactive')}</ItemName>{t('MissingSettingsMessage.7', { software: props.softwareName })}</ListItemText></ListItem>
                </List>
            </Alert>)}
        {isNotProfiled && <Alert severity='warning'><Typography>{t('MissingSettingsMessage.10', { software: props.softwareName })}&nbsp;<a href={user.profileURL} target="_blank" rel="noreferrer">{t('here')}</a></Typography></Alert>}
        {hasNoRelnoteSettings && <Alert severity='info'><Typography>{t('MissingSettingsMessage.9')}</Typography></Alert>}
    </>
}


const ItemName = styled('span')(({ theme }) => ({
    display: 'inline',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    //fontSize: 14
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0
}));



