import { StyledTextField } from 'Components/Input/StyledTextField';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import DesktopDatePicker, { DesktopDatePickerProps } from '@mui/lab/DesktopDatePicker';
import { TextField, TextFieldProps } from '@mui/material';

import { Rules } from './commons';

type ControlledDesktopDatePickerProps = Omit<DesktopDatePickerProps, 'value' | 'onChange' | 'renderInput' | 'inputFormat'>;

const debug = false;


export function HookedDatePicker<TFieldValues extends FieldValues>(props: ControlledDesktopDatePickerProps & {
  name: Path<TFieldValues>,
  control: Control<TFieldValues, Record<string, unknown>>, // returned by react-hook-form's useForm(). Controller uses this to register input components into React Hook Form
  rules?: Rules<TFieldValues, Path<TFieldValues>>,
  label?: string,
  format?: string,
  convertToString?: boolean,
  inputFormats?: string[],
  mask?: string,
  helperText?: string,
  InputProps?: any
}) {
  const { name, control, rules, ...otherProps } = props;

  const isDate = (value: any) => (value !== 'Invalid Date');
  let validators: any = isDate;
  if (rules?.validate) {
    if (typeof rules.validate === 'function') {
      validators = { isDate, isValid: rules.validate }
    } else {
      validators = { isDate, ...rules.validate }
    }
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, validate: validators }}
      render={({ field, fieldState }) => {
        if (debug) console.log("DatePickerInput field value", field.value);
        return (
          <MyDatePicker
            name={field.name}
            ref={field.ref}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value || null}
            isInvalid={fieldState.invalid}
            {...otherProps}
          />)
      }}
    />
  )
}


type MyDatePickerProps = ControlledDesktopDatePickerProps & {
  name: string,
  //ref: RefCallBack,
  onChange: (...event: any[]) => void,
  onBlur: () => void,
  value?: string | null,
  isInvalid: boolean,

  fieldFormat?: string;
  inputFormats?: string[];
  convertToString?: boolean,
  helperText?: string,
  InputProps?: any,
  label?: string
}

const MyDatePicker = forwardRef<HTMLInputElement, MyDatePickerProps>((props: MyDatePickerProps, ref: React.ForwardedRef<HTMLInputElement>) => {

  const { value, isInvalid, onChange, onBlur, fieldFormat, inputFormats, mask, convertToString, helperText, InputProps, label, ...otherProps } = props;

  const _inputFormats = useMemo(() => (inputFormats || ["DD.MM.YYYY", "DD.MM.YY"]), [inputFormats]); // default support for 4-digits and 2-digits year

  const timeValue = (!value || value == '')
    ? null
    : (typeof value === 'string' ? dayjs(value, fieldFormat ?? 'YYYY-MM-DD') : value);

  if (debug) {
    console.log("MyDatePicker value prop", value);
    console.log("MyDatePicker time value", timeValue);  
  }

  const handleDatepickerChange = useCallback((valFromPicker: any, keyboardInputValue?: string) => {

    if (debug) console.log("MyDatePicker change from picker", valFromPicker, keyboardInputValue);

    if (typeof (valFromPicker?.isValid) === 'function') {
      if (valFromPicker.isValid()) {
        onChange(convertToString ? valFromPicker.format(fieldFormat || 'YYYY-MM-DD') : valFromPicker);
      }
      else if (_inputFormats !== undefined) {
        // check the input string matches one of the provided alternative input formats (MUI DesktopDatePicker supports only one)
        for (let i = 1; i < _inputFormats.length; i++) { // skip the first format because it had been tried by MUI DesktopDatePicker
          const parsedDate = dayjs(keyboardInputValue, _inputFormats[i], true);
          if (parsedDate.isValid()) {
            valFromPicker = parsedDate;
            onChange(convertToString ? parsedDate.format(fieldFormat || 'YYYY-MM-DD') : parsedDate)
            break;
          } else {
            onChange(null);
          }
        }
      }
    } else {
      if (debug) console.log("MyDatePicker change from picker not date", valFromPicker, keyboardInputValue);
      onChange(null);
    }
  }, [_inputFormats, onChange, convertToString, fieldFormat]);

  const valToDatepicker = timeValue ?? null;
  if (debug) console.log("MyDatepicker value to DatePicker:", valToDatepicker);

  return (
    <DesktopDatePicker
      ref={ref}
      onChange={handleDatepickerChange}
      //onBlur={handleDatepickerBlur}
      inputFormat={_inputFormats.at(0)}
      mask={mask || '__.__.____'}
      value={valToDatepicker}
      renderInput={(params: TextFieldProps) => {
        if (debug) console.log("DatePicker value to TextField:", params.inputProps?.value);
        const _inputProps = {...params.inputProps, value: params.inputProps?.value ?? null};
        return (
          <StyledTextField {...params}
            inputProps={_inputProps}
            InputProps={{ ...params.InputProps, ...props.InputProps }}
            error={isInvalid /* || myValue === 'Invalid Date' */}
            helperText={helperText}
          />)
      }}
      label={label}
      {...otherProps}
    />
  )
});
MyDatePicker.displayName = "MyDatePicker";



