import React from 'react';
import {Redirect, Route, Switch} from 'react-router';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {RemoteProvider} from './Components/Remote/RemoteProvider';
import DashboardPage from 'Pages/Dashboard/DashboardPage';
import NewsFeedPage from 'Pages/NewsFeed/NewsFeedPage';

import UserSettingsPage from 'Pages/UserSettings/UserSettingsPage';

import ActiveDirectoryLogout from "./Components/Remote/ActiveDirectoryLogout";
import ActiveDirectoryLogin from "./Components/Remote/ActiveDirectoryLogin";
import {FaqFeedPage, SingleFaqPage} from "./Pages/FaqFeed/FaqFeedPage";


export default function App() {

    console.log("RELNOTE RELNOTE process.env", process.env);
    return (
        <React.StrictMode>
            <RemoteProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Switch>
                        <Route exact path='/'><Redirect to={process.env.REACT_APP_USE_AZURE_LOGIN === "true" ? '/login' : '/dashboard'}/></Route>
                        <Route path='/dashboard'><DashboardPage/></Route>
                        <Route path='/customer/:customer/sw/:software/ADMIN/users/:hash'><UserSettingsPage/></Route>
                        <Route path='/customer/:customer/sw/:software/user/:user/settings/:hash'><UserSettingsPage/></Route>
                        <Route path='/customer/:customer/sw/:software/user/:user/news/:hash'><NewsFeedPage/></Route>
                        <Route path='/customer/:customer/sw/:software/user/:user/faq/:faqId/:hash'><SingleFaqPage/></Route>
                        <Route path='/customer/:customer/sw/:software/user/:user/faq/:hash'><FaqFeedPage/></Route>
                        <Route path='/customer/:customer/sw/:software/user/:user/:hash'><NewsFeedPage/></Route>
                        {(process.env.REACT_APP_USE_AZURE_LOGIN === "true") && <>
                          <Route path='/login'><ActiveDirectoryLogin onSuccessRedirectToPath={'/dashboard'}/></Route>
                          <Route path='/logout'><ActiveDirectoryLogout /></Route>
                        </>}
                    </Switch>
                </LocalizationProvider>
            </RemoteProvider>
        </React.StrictMode>
    );
}
