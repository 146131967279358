import React from "react";
import {Route} from "react-router-dom";
import {SelectFaqSoftware} from "./SelectFaqSoftware";
import {Switch} from "react-router";
import {SoftwareFaqsEditor} from "./SoftwareFaqsEditor";
import {SwFaqsManagerContext, useSwFaqsManagerReducer} from "./SwFaqsManagerContext";


export const SwFaqsManager = () => {

    const [context, dispatchContext] = useSwFaqsManagerReducer();
    
    const {urlBasePath} = context;

    return (
        <SwFaqsManagerContext.Provider value={[context, dispatchContext]}>
            <Switch>
                <Route path={`${urlBasePath}/:softwareCode`}><SoftwareFaqsEditor/></Route>
                {/*<Route path={`${urlBasePath}/new`}><SoftwareFaqsEditor/></Route>*/}
                <Route path={urlBasePath}><SelectFaqSoftware/></Route>
            </Switch>
        </SwFaqsManagerContext.Provider>
    );
}

