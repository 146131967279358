import { IconButton } from '@mui/material';
import { ThemesContext } from './ThemesProvider';
//import PaletteIcon from '@mui/icons-material/Palette';
import Brightness4Icon from '@mui/icons-material/Brightness4';

function ThemeToggler(props: any) {
  return (
    <ThemesContext.Consumer>
      {({switchTheme: changeTheme}) => (
        <IconButton {...props}  onClick={changeTheme}><Brightness4Icon/></IconButton>
      )}
    </ThemesContext.Consumer>
  );
}

export default ThemeToggler;