import { useHashAuthorizedApiRoute } from "../HashCredentialsProvider";
import { useProcessedRemoteCollection } from "Components/RemoteEntities/Collection/RemoteCollection";
import { useRemoteEntity } from "Components/RemoteEntities/Entity/RemoteEntity";
import { RemoteResourceSubmitApi, State, useRemoteResourceSubmissionService } from "Components/RemoteEntities/resource.services"
import { includesIgnoreCase } from "Components/utils";
import { useTranslation } from "react-i18next";

export interface User {
    id: string,
    isNew: boolean,
    userCode: string,
    userName: string,
    userEmail: string,
    inactive: boolean,
    showAtStartup: boolean,
    showOnForm: boolean,
    ownDepartmentsOnly: boolean,
    userDepartments: string[], 
    isProfiled?: boolean,
    profileURL?: string  
}

export interface UserSettings {
    userCode: string,
    inactive: boolean,
    showAtStartup: boolean,
    showOnForm: boolean,
    ownDepartmentsOnly: boolean,
}

export const defaultUser = {
    id: '',
    userCode: '',
    userName: '',
    userEmail: '',
    inactive: false,
    showAtStartup: true,
    showOnForm: true,
    ownDepartmentsOnly: true,
    userDepartments: []
}

function makeSearchPredicate(filter?: { search?: string }) {
    if (!filter) {
        return undefined;
    } else {
        const { search } = filter;
        return (entity: User): boolean => {
            if (!search) return true;
            return includesIgnoreCase(entity.userCode, search) || includesIgnoreCase(entity.userName, search) || includesIgnoreCase(entity.userEmail, search);
        }
    }
}

export const useUserSettings = (props: { search?: string, asAdmin?: boolean }) => {
    
    const authRoute = useHashAuthorizedApiRoute();
    const { i18n } = useTranslation();
    const { language } = i18n;

    return useProcessedRemoteCollection<User, { search?: string }>({
        endpoint: `${authRoute}/users`,
        requiresQueryParams: true,
        queryParams: { asAdmin: props.asAdmin, language },
        filterParams: { search: props.search },
        isPaginated: false,
        makeFilterPredicate: makeSearchPredicate,
    });
}

export const useUserSettingsEditService = (props: {instanceId: string}) => {

    const authRoute = useHashAuthorizedApiRoute();

    return useRemoteEntity<User>({
        instanceId: props.instanceId ?? '',
        endpoint:`${authRoute}/users`,
        idKey: 'id',
        remoteCreation: false,
        updateMethod: 'PATCH'
    });
}

export const useUserUpsertService = (props: {asAdmin: boolean}) => {

    const authRoute = useHashAuthorizedApiRoute();

    const [state, _submit] = useRemoteResourceSubmissionService<UserSettings, boolean>({endpoint: `${authRoute}/users?asAdmin=${props.asAdmin}`, method: 'POST'});

    const submit = (user: User) => {
        const {inactive, showAtStartup, showOnForm, ownDepartmentsOnly, userCode, ...other} = user;
        return _submit({userCode, inactive, showAtStartup, showOnForm, ownDepartmentsOnly});
    };

    return [state, submit] as [State<boolean>, (user: User) => void];
}


export type UserProp =  'showAtStartup' | 'showOnForm' | 'ownDepartmentsOnly' | 'inactive';

export type BulkUpdateRequest = Partial<Record<UserProp, boolean>>

export const useUserSettingsBulkEditService = (): RemoteResourceSubmitApi<BulkUpdateRequest, boolean> => {

    const authRoute = useHashAuthorizedApiRoute();

    return useRemoteResourceSubmissionService<BulkUpdateRequest, boolean>({
        endpoint: `${authRoute}/users/bulk?asAdmin=true`,
        method: 'POST'
    })
} 