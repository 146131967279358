import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Box, Button, Drawer, Stack, Divider } from '@mui/material';
import Scrollbar from 'Components/scrollbar';
// component
import Iconify from 'Components/iconify';
import { useCallback, useState } from 'react';
import { StyledSearch } from 'Components/Input/StyledSearch';
import { useTranslation } from 'react-i18next';


const StyledRoot = styled(Toolbar)(({ theme }) => ({
   height: 96,
   display: 'flex',
   justifyContent: 'space-between',
   padding: theme.spacing(0, 1, 0, 3),
}));


export default function ToolbarWithSearchAndDrawer(props: {
   searchValue?: string,
   onSearchChanged: (value: string) => void,
   onClose?: () => void,
   children?: React.ReactNode
}) {

   const { onSearchChanged } = props;
   
   const handleSearchChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((evt) => { onSearchChanged(evt.currentTarget.value)}, [onSearchChanged]);

   return (
      <StyledRoot>
         <StyledSearch
            value={props.searchValue ?? ''}
            onChange={handleSearchChanged}
            placeholder="Search..."
            startAdornment={
               <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
               </InputAdornment>
            }
         />
         {props.children && (
            <Sidebar onClose={props.onClose}>
               {props.children}
            </Sidebar>
            )}
      </StyledRoot>
   );
}

function Sidebar(props: { children: React.ReactNode, onClose?: () => void }) {

   const [open, setOpen] = useState(false);

   const handleOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      if (props.onClose) props.onClose();
      setOpen(false);
   };

   const [t] = useTranslation();
   
   return (
      <>
         <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={handleOpen}>
            Filters&nbsp;
         </Button>

         <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
               sx: { width: 280, border: 'none', overflow: 'hidden' },
            }}
         >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
               <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  Filters
               </Typography>
               <IconButton onClick={handleClose}>
                  <Iconify icon="eva:close-fill" />
               </IconButton>
            </Stack>

            <Divider />

            {props.children}
            
            <Box sx={{ p: 3 }}>
               <Button
                  fullWidth
                  size="large"
                  type="submit"
                  color="inherit"
                  variant="outlined"
                  startIcon={<Iconify icon="ic:round-clear-all" />}
               >
                  {t('clearAll')}
               </Button>
            </Box>
         </Drawer>
      </>
   );
}
