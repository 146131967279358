import {ContentType} from 'Entities/commons';
import {DescribedCode} from 'Entities/DescribedCode/describedCode.models';
import {Form} from 'Entities/SoftwareParts/form.services';
import {Module} from 'Entities/SoftwareParts/module.services';

import {Translation, TranslationAbstract} from './translation.model';
import {Macroarea} from 'Entities/Macroarea/macroarea.service';
import {Software} from "Entities/Software/software.services";
import {useReleaseStatusesService} from "../../BaseData/ReleaseStatus/ReleaseStatusesManager";
import {Status} from "../../../../Components/reduxLogger";
import {useEffect} from "react";
import dayjs from "dayjs";

export interface FormKey {moduleCode?: string, formCode: string}

interface FaqBase {
  softwareCode: string,
  releaseFrom?: string,
  releaseTo?: string,
  customerId?: string,
  ergoOne: boolean,
}

export interface FaqHead extends FaqBase {
  id: number,
  statusCode?: string,
  moduleCode?: string,
  forms?: FormKey[],
  departmentCodes?: string[],
  macroareaId?: number,
}

export interface FaqAbstract extends FaqBase {
  id: number,
  status?: DescribedCode,
  module?: Module,
  forms?: Form[],
  departments?: DescribedCode[],
  macroarea?: Macroarea,
  translations: TranslationAbstract[],
  contentType?: ContentType
}

export interface Faq extends FaqHead {
  translations: Translation[]
  contentType?: ContentType
}

export interface FaqVerbose extends Omit<FaqAbstract, 'translations'> {
  translations: Translation[]
}


export function minifyFaq(model: FaqVerbose): Faq {
  return {
    id: model.id,
    softwareCode: model.softwareCode,
    customerId: model.customerId,
    releaseFrom: model.releaseFrom,
    releaseTo: model.releaseTo,
    statusCode: model.status?.code,
    moduleCode: model.module?.code,
    forms: model.forms?.map(x => ({moduleCode: x.moduleCode, formCode: x.code})),
    departmentCodes: model.departments?.map(x => x.code),
    macroareaId: model.macroarea?.id,
    ergoOne: model.ergoOne,
    translations: model.translations
  };
}

export function faqVerboseToAbstract(faq: FaqVerbose): FaqAbstract {
  const {translations, ...other} = faq;
  return {
    ...other,
    translations: translations.map(x => { 
      const {html, htmlTech, ...head} = x; 
      return {
        ...head,
        html: `${html ?? ''}${htmlTech ?? ''}`
      };
    })
  }
}


export interface FaqFilter {
  software?: Software,
  softwareCode?: string,
  versionFrom?: string,
  versionTo?: string,
  status?: DescribedCode[],
  module?: Module[]
  form?: Form[],
  macroarea?: Macroarea[],
  department?: DescribedCode[],
  customerId?: string,
  content?: string,
  ergoOne?: boolean,
  contentType?: ContentType
}