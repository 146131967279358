import {propsToClassKey} from '@mui/styles';

export const dictToArray = <T, >(obj: Record<string, T>) => Object.keys(obj).reduce((result, key) => {
    result.push(obj[key]);
    return result
}, [] as T[]);

export type Copy<T> = { [K in keyof T]: T[K] }

export function deduplicateByRef(array: any[]) {
    // return only elements that are the first occurence
    return array.filter((val, index) => array.indexOf(val) === index);
}

export function deduplicate<T>(array: T[], isEqual?: (a: T, b: T) => boolean) {
    return array.filter((val, index) => {
        const firstIndex = array.findIndex((otherVal: T) => (isEqual ? isEqual(val, otherVal) : (val === otherVal)));
        return firstIndex === index;
    })
}

export function coalesce<T extends Record<string, any>>(obj: T, defaults: Partial<T>): T {
    return Object.keys(obj).reduce(
        (result: T, key: string) => {
            type K = keyof T;
            const theKey = key as K;
            result[key as keyof T] = (obj[theKey] === undefined || obj[theKey] === null) ? (defaults[theKey] ?? obj[theKey]) : obj[theKey];
            return result;
        }, {} as T
    )
}

export function replaceEmptyStringWithNullableDefaults<T extends Record<string, any>>(obj: T | Required<T>, defaults: T): T {
    return (Object.keys(obj) as (keyof T)[]).reduce((result, k) => {
        if ((typeof obj[k] === 'string') && (obj[k] as any as string === '')) {
            result[k] = defaults[k];
        } else {
            result[k] = obj[k];
        }
        return result;
    }, {} as T);
}

export function removeBlanks(obj: any): any {
    return Object.keys(obj).reduce((result, k) => {
        if (obj[k] !== '') {
            result[k] = obj[k];
        }
        return result;
    }, {} as any);
}

export function removeBlanksAndUndefined(obj?: Record<string, any> | null): (Record<string, any> | undefined) {
    if (!obj) {
        return undefined;
    }
    return Object.keys(obj).reduce((result, k) => {
        if (obj[k] !== '' && obj[k] !== undefined) {
            result[k] = obj[k];
        }
        return result;
    }, {} as any);
}

// Tests equality of two objects. The objects are equal if they have the same props and 
// for each prop the value is equal in the two objects. For props that are objects,
// prop equality means object reference identity. 
// see also https://masteringjs.io/tutorials/fundamentals/compare-objects
export function shallowEqual(obj1?: any, obj2?: any): boolean {
    if (!obj1 && !obj2) {
        return true;
    }
    if (!obj1 || !obj2) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let i = 0; i < keys1.length; i++) {
        if (obj2[keys1[i]] !== obj1[keys1[i]]) {
            return false;
        }
        if (obj1[keys2[i]] !== obj2[keys2[i]]) {
            return false;
        }
    }
    return true;
}

// Tests equality of two objects. The objects are equal if they have the same props and 
// for each prop the value is equal in the two objects. For props that are objects,
// prop equality means shallow equality (see shallowEqual) of the referenced objects. 
export function deep1Equal(obj1?: any, obj2?: any): boolean {
    if (!obj1 && !obj2) {
        return true;
    }
    if (!obj1 || !obj2) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let i = 0; i < keys1.length; i++) {
        if (obj2[keys1[i]] !== obj1[keys1[i]]) {
            if ((typeof obj2[keys1[i]] === 'object') && (typeof obj1[keys1[i]] === 'object')) {
                if (!shallowEqual(obj2[keys1[i]], obj1[keys1[i]])) {
                    return false;
                }
            } else return false;
        }
        if (obj1[keys2[i]] !== obj2[keys2[i]]) {
            if ((typeof obj1[keys2[i]] === 'object') && (typeof obj2[keys2[i]] === 'object')) {
                if (!shallowEqual(obj1[keys2[i]], obj2[keys2[i]])) {
                    return false;
                }
            } else return false;
        }
    }
    return true;
}

// Tests equality of two objects. The objects are equal if they have the same props and 
// for each prop the value is equal in the two objects. For props that are objects,
// prop equality means deep1Equality of the referenced objects. 
export function deep2Equal(obj1?: any, obj2?: any): boolean {
    if (!obj1 && !obj2) {
        return true;
    }
    if (!obj1 || !obj2) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let i = 0; i < keys1.length; i++) {
        if (obj2[keys1[i]] !== obj1[keys1[i]]) {
            if ((typeof obj2[keys1[i]] === 'object') && (typeof obj1[keys1[i]] === 'object')) {
                if (!deep1Equal(obj2[keys1[i]], obj1[keys1[i]])) {
                    return false;
                }
            } else return false;
        }
        if (obj1[keys2[i]] !== obj2[keys2[i]]) {
            if ((typeof obj1[keys2[i]] === 'object') && (typeof obj2[keys2[i]] === 'object')) {
                if (!deep1Equal(obj1[keys2[i]], obj2[keys2[i]])) {
                    return false;
                }
            } else return false;
        }
    }
    return true;
}

export function includesIgnoreCase(text?: string, substring?: string): boolean {
    if (!text) return false;
    if (!substring) return true;
    return text.toLowerCase().includes(substring.toLowerCase());
}

export function makePageSizeOptions(currentPageSize = 0, options: number[] = [10, 15, 20, 30]) {
    if (currentPageSize > 0) {
        if (!options.includes(currentPageSize)) {
            options.push(currentPageSize);
            options.sort();
        }
    }
    return options;
}


export {Status} from './reduxLogger';


export type EmptyObject = Record<string, never>;


export function hasContent(object: any): boolean {
    if (!object) return false;
    const keys = Object.getOwnPropertyNames(object) as unknown as (keyof typeof object)[];
    if (keys.length === 0) {
        return false;
    }
    const found = keys.find(x => {
        if (!object[x]) return false;
        if (Array.isArray(object[x]))
            return object[x].length > 0;
        return true;
    });
    return found != null;
}

export interface WithFormState<T> {
    values: T,
    isDirty?: boolean
}


export function nullableSum(...args: (number | null | undefined)[]): number | null {
    return args.reduce((result: number | null, next) => {
        if (result !== null && next !== undefined && next !== null) {
            return result + (next as number);
        } else {
            return null;
        }
    }, 0);
}

export const nullableMinus = (x: number | null | undefined) => x !== null && x !== undefined ? -x : null;


/**
 * @param obj
 * @returns a query string containing the non-undefined, non-blank properties of the provided object
 */
export function objectToURLSearchString<T>(obj?: any) {

    if (!obj || Object.getOwnPropertyNames(obj).length === 0) {
        return '';
    }

    const query = new URLSearchParams();

    Object.getOwnPropertyNames(obj).forEach(key => {

        const paramValue = obj[key];

        if (paramValue && paramValue !== '') {

            if (Array.isArray(paramValue)) {

                paramValue.forEach(val => {
                    query.append(key, val);
                });

            } else {

                query.append(key, paramValue);
            }
        }
    });

    return query.toString();
}

export function objectToURLPathAndSearchString<T>(obj?: any, route?: string): { search: string, path: string } {

    const searchParams: Record<string, any> = {};
    let path = route ?? '';
    Object.keys(obj).forEach(key => {
        const placeholder = `:${key}`;
        if (path?.includes(placeholder) && !!obj[key] && obj[key] != '') {
            path = path.replace(placeholder, obj[key]);
        } else {
            searchParams[key] = obj[key];
        }
    });
    const searchString = objectToURLSearchString(searchParams);
    return {search: searchString, path};
}


/**
 * Wraps setTimeout in a promise
 *
 * @returns
 */
export const wait = (ms: number): [Promise<void>, () => void] => {
    let timeout: NodeJS.Timeout;
    const promise = new Promise<void>((resolve, reject) => {
        timeout = setTimeout(resolve, ms)
    });
    return [promise, () => {
        clearTimeout(timeout)
    }];
}

export const stoppableWait = (ms: number): [result: Promise<unknown>, stop: () => void] => {
    let timeoutId: any;
    const promise = new Promise(resolve => {
        timeoutId = setTimeout(resolve, ms);
    });
    return [
        promise,
        () => clearTimeout(timeoutId) // stops the timer
    ];
}


export const roundTinyNegative = (value: number) => (value > -0.00001 && value < 0) ? 0 : value;

export const romanize = (num: number): string => {
    switch (num) {
        case 1:
            return 'I';
        case 2:
            return 'II';
        case 3:
            return 'III';
        case 4:
            return 'IV';
        case 5:
            return 'V';
        case 6:
            return 'VI';
        case 7:
            return 'VII';
        case 8:
            return 'VIII';
        case 9:
            return 'IX';
        case 10:
            return 'X';
        default:
            return num?.toString() ?? '';
    }
}

export function splitText(text: string): string[] {

    let middle = Math.floor(text.length / 2);
    const before = text.lastIndexOf(' ', middle);
    const after = text.indexOf(' ', middle + 1);

    if (middle - before < after - middle) {
        middle = before;
    } else {
        middle = after;
    }
    return [text.substring(0, middle), text.substring(middle + 1)];
}

export function splitTextIfLongerThan(text: string, limit: number): string[] {

    if (text.length <= limit) {
        return [text];
    } else {
        return splitText(text);
    }
}


/**
 * Changes position of an item in a list
 * @param list
 * @param indexA index of item to be moved
 * @param indexB index of new position of the item
 * @returns
 */
export const swapListItems = <T>(
    list: T[],
    indexA: number,
    indexB: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(indexA, 1);
    result.splice(indexB, 0, removed);
    return result;
};


export function arrayOrNull<T>(val: T[] | T | null | undefined): T[] | null {
    if (!val) {
        return null;
    } else if (Array.isArray(val)) {
        return val;
    } else {
        return [val];
    }
}


/**
 *
 * @param array Converts an array into a dictionary keyed by the array indices or,
 * by id strings derived from the array items through a id getter function
 * @param idGetter
 * @returns
 */
export function arrayToDict<T>(array: T[], idGetter?: (item: T) => string): { [key: string]: T } {
    if (array.length === 0) {
        return {};
    }
    if (idGetter)
        return array.reduce((dict, x) => ({...dict, [idGetter(x)]: x}), {});
    else
        return array.reduce((dict, x) => ({...dict, [Object.keys(dict).length]: x}), {});
}


export const deepCopy = (obj: any) => obj ? JSON.parse(JSON.stringify(obj)) : obj;

export const deepFreeze = (obj: any) => {
    if (!obj) return obj;
    Object.keys(obj).forEach(k => {
        const prop = (obj as any)[k];
        if (typeof prop === 'object') {
            deepFreeze(prop);
        }
    });
    Object.freeze(obj);
    return obj;
}