import { useRemoteResourceQueryService, useRemoteResourceSubmissionService } from "Components/RemoteEntities/resource.services";
import { ReleaseNote } from "./newsFeed.models";
import { useHashAuthorizedApiRoute } from "../HashCredentialsProvider";
import { EmptyObject } from "Components/utils";
import { useTranslation } from "react-i18next";

export type { FeedQueryParams } from './newsFeed.models';
import { FeedQueryParams } from './newsFeed.models';

export interface ReleaseNotesFeed {
    notes: ReleaseNote[],
    includesTechnical: boolean
}

export const useReleaseNotesFeed = () => {

    const authRoute = useHashAuthorizedApiRoute();

    return useRemoteResourceQueryService<ReleaseNotesFeed, ReleaseNotesFeed, FeedQueryParams>({
        endpoint: `${authRoute}/news`,
        defaultValue: {
            notes: [],
            includesTechnical: false
        }
    });
}

export const useReleaseNoteFull = (props: { releaseId: string, noteId: string, technical: boolean }) => {

    const authRoute = useHashAuthorizedApiRoute();

    const language = useTranslation()[1].language;

    return useRemoteResourceQueryService<ReleaseNote, null, EmptyObject>({
        endpoint: `${authRoute}/news/${props.releaseId}/${props.noteId}?language=${language}&technical=${props.technical}`,
        defaultValue: null
    });
}


export interface ReadHidePatch {
    readNotes?: {releaseId: string, noteId: string}[];
    unReadNotes?: {releaseId: string, noteId: string}[];
    hideAreas?: number[];
    unHideAreas?: number[];
}

export const useReadHidePatchService = () => {

    const authRoute = useHashAuthorizedApiRoute();

    return useRemoteResourceSubmissionService<ReadHidePatch, boolean>({
        endpoint:  `${authRoute}/status`,
        method: 'PATCH'
    })
}

