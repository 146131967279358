

import { CustomerPage, getBrandImage, useCustomerPageParams } from '../CustomerPage';
import { useCurrentUrlCredentials } from '../HashCredentialsProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, styled, Typography, useTheme } from '@mui/material';

import { makeBackgroundColor } from './style';
import { UserSettingsManager } from './UserSettingsManager';

export default function RelnoteUserSettingsPage() { 
    return <CustomerPage>{RelnoteUserSettingsPageContent}</CustomerPage> 
}


const StyledContainer = styled('div')(({ theme }) => ({
    //height: '100%',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: makeBackgroundColor(theme),
}));

const RelnoteUserSettingsPageContent = (props: { showHeader: boolean }) => {

    const theme = useTheme();
    const brand = useMemo(() => getBrandImage(theme), [theme]);
    const { customer: customerCode, user: userCode, hash } = useCurrentUrlCredentials();

    const [t] = useTranslation();

    const { asAdmin } = useCustomerPageParams();

    return (
        <StyledContainer id={'UserSettings-root'}>

            {props.showHeader && (
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'flex-start'}>
                <img src={brand} style={{ width: 200, height: 100 }} />
                <Box id={'ReleaseNotes-title'} sx={{ mt: 2, mb: 2, flex: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'baseline' }}>
                    <Typography variant='h3' color={theme.palette.grey[500]} sx={{ mr: 0 }}>{t('appTitle')}</Typography>
                    <Typography variant='h4' color={theme.palette.grey[500]}>{t('userSettings')}</Typography>
                </Box>
            </Stack>
            )}
            
            <UserSettingsManager customerCode={customerCode} userCode={userCode} asAdmin={!!asAdmin} hash={hash}/>

        </StyledContainer>
    )
}

