import { ClickableRowsTable, Column } from 'Components/Containers/ClickabkeRowsTable';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Software } from 'Entities/Software/software.services';

export function SoftwaresTable(props: { data: Software[], onSelected?: (item: Software) => void, }) {
    const { data } = props;

    const { t } = useTranslation();

    const columns: Column<Software>[] = useMemo(() => ([
        { id: 'code', label: t('code'), width: 30 },
        { id: 'description', label: t('description'), width: 300 },
    ]), [t]);

    return <ClickableRowsTable<Software>
        tableProps={{ size: 'medium', sx: { width: 1 } }}
        columns={columns}
        rows={data}
        rowIdGetter={(x: Software) => x.code}
        onRowClicked={props.onSelected}
    />
}