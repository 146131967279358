
import ToolbarWithDrawer from 'Components/Containers/ToolbarWithDrawer';
import { HookedSelect, HookedTextField } from 'Components/HookedForm/inputControls';
import { StyledTextField } from 'Components/Input/StyledTextField';
import { DescribedCodeAutocompleteMultiCodes } from 'Entities/DescribedCode/DescribedCodeAutocompleteMultiCodes';
//import { MacroareaAutocompleteMultiCodes } from 'Entities/Macroarea/MacroareaAutocompleteMultiCodes';
import { SimpleEntityAutocompleteIdsMulti } from 'Entities/SimpleEntity/SimpleEntityAutocompleteIdsMulti';

import { LabelCodeSelect } from 'Entities/Label/LabelSelect';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, Button, Stack } from '@mui/material';

import { defaultFilter, RelnotesFilter } from './filterHelpers';
import { VersionRangeSelect } from './VersionRangeSelect';
import { ModuleCodeMultiAutocompleteRemote } from 'Entities/SoftwareParts/ModuleAutocompletes';
import { FormKeyMultiAutocompleteRemote } from 'Entities/SoftwareParts/FormsAutocompletes';


const isEmptyFilter = (filter?: RelnotesFilter) => !filter || (
    !filter.versionRange.versionFrom
    && !filter.versionRange.versionTo
    && !filter.macroarea.length
    && !filter.department.length
    && !filter.form.length
    && !filter.content?.length
    && !filter.todo.length
    && !filter.label.length
);

export const FiltersToolbar = (props: {
    software: string,
    value?: RelnotesFilter,
    isTechUser: boolean,
    onChange: (value: RelnotesFilter) => void
}) => {

    const { value, software } = props;

    const [t] = useTranslation();

    const form = useForm<RelnotesFilter>({ defaultValues: value });
    const { watch, reset, control } = form;

    const resetFilter = (filter?: RelnotesFilter) => ({ ...filter, ...defaultFilter } as RelnotesFilter);

    return (
        <ToolbarWithDrawer id='filters'
            drawerMaxWidth='auto'
            drawerButtonText={t('filters')}
            onClose={() => props.onChange(watch())}
            onClear={() => reset(resetFilter(value))}
            keepDrawerMounted>
            {{
                main:
                    <Stack id="RelnotesFeedFilters-bar" direction={'row'} alignItems={'center'} justifyContent='space-around' sx={{ width: 1 }}>

                        <Controller control={control} name={'versionRange'}
                            render={({ field, fieldState, formState }) => (
                                <VersionRangeSelect
                                    value={field.value}
                                    software={props.software}
                                    onChange={field.onChange}
                                />)} />

                        {/* <IconButton size='large' onClick={() => props.onChange(watch())}><SearchIcon /></IconButton> */}
                        <Box sx={{ flexGrow: 1 }}><Button size='large' onClick={() => props.onChange(watch())}>{t('applyFilter')}</Button></Box>

                        <span style={{ flexGrow: 1, textAlign: 'right', marginRight: '4px' }}>
                            <Button disabled={isEmptyFilter(value)} onClick={() => {
                                reset(defaultFilter);
                                props.onChange(resetFilter(value));
                            }}>{t('removeFilters')}</Button>
                        </span>

                    </Stack>,

                drawer: <DrawerContent control={control} software={software} technical={props.isTechUser} />
            }}
        </ToolbarWithDrawer>
    );
}

const DrawerContent = (props: { control: Control<RelnotesFilter>, software: string, technical: boolean }) => {

    const { control, software } = props;
    const [t] = useTranslation();

    return <Stack id="RelnotesFeedFilters-drawer" >

        <Controller control={control} name='macroarea'
            render={({ field }) => (
                <SimpleEntityAutocompleteIdsMulti name='macroarea'
                    endpoint={`baseData/macroareas?softwareCode=${props.software}`}
                    label={t('macroarea')}
                    value={field.value}
                    onChange={field.onChange}
                />
            )} />


        <Controller control={control} name='department'
            render={({ field }) => (
                <DescribedCodeAutocompleteMultiCodes name='department'
                    endpoint={'baseData/departments'}
                    label={t('department')}
                    value={field.value}
                    onChange={field.onChange}
                />
            )} />

        <Controller control={control} name='module'
            render={({ field }) => (
                <ModuleCodeMultiAutocompleteRemote name={"module"}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur} 
                    softwareCode={props.software}
                />
            )}
        />

        <Controller control={control} name='form'
            render={({ field }) => (
                <FormKeyMultiAutocompleteRemote name={"form"}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur} 
                    softwareCode={props.software}
                />
            )}
        />

        <Controller control={control} name={'label'} render={({ field }) => <LabelCodeSelect value={field.value} onChange={field.onChange} onBlur={field.onBlur} />} />

        <Controller control={control} name={"todo"}
            render={({ field }) => (
                <Autocomplete<string, true, undefined, true>
                    freeSolo
                    multiple
                    options={field.value ?? []}
                    value={field.value ?? []}
                    onChange={(evt, value) => field.onChange(value)}
                    onBlur={field.onBlur}
                    sx={{ minWidth: 100, maxWidth: 300 }}
                    renderInput={(params) => <StyledTextField {...params} variant="outlined" label={t('todo')} />}
                />)}
        />

        {props.technical && (
            <HookedSelect control={control} name={'contentType'} label={t('noteContentType')}
                options={[{ value: 'NonTechnical', label: 'NonTechnical' }, { value: 'Technical', label: 'Technical' }]}
            />
        )}

        <HookedTextField control={control} name='content' label={t('content')} />

    </Stack>
}