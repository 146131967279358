import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import { StyledButton } from "./StyledInputs";
import ImageIcon from '@mui/icons-material/Image';

export const ImageControl = (props: { editor: Editor }) => {

    const { editor } = props;

    const addImage = useCallback((evt) => {
        evt.preventDefault();
        const url = window.prompt('URL')
        if (url) {
            editor.chain().focus().setImage({ src: url }).run()
        }
    }, [editor])

    return (
        <StyledButton type='button'
            onClick={addImage}
        >
            <ImageIcon/>
        </StyledButton>
    )
}

