
import { Status } from 'Components/reduxLogger';
import { purple } from '@mui/material/colors';
import { useErgoOneSetterService } from './faq.services';
import { Checkbox, CircularProgress } from '@mui/material';


export const ErgoOneFlag = (props: { faqId: number, value: boolean, softwareCode: string, onChange: (value: boolean) => void}) => {

    const { onChange } = props;
    
    const [state, submit] = useErgoOneSetterService({softwareCode: props.softwareCode});
 
    const { status, data } = state;

    return <>
       <span style={{ color: purple[600] }}>
          <Checkbox checked={!!props.value}
             sx={{ pr: 1, color: purple[800], '&.Mui-checked': { color: purple[600] } }}
             onChange={ () => submit({id: props.faqId, ergoOne: !props.value}).then((val) => { if (typeof val === 'boolean') onChange(val) }) }
          />
          One
       </span>
       {status === Status.Pending && <CircularProgress sx={{position: 'relative', left: -20}}/>}
    </>
 }