
import ToolbarWithSearch from 'Components/Containers/ToolbarWithSearch';
import { useGuardedExec } from 'Components/GuardService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserSettingsInfo } from './UserSettingsInfo';
import { UserSettingsEditableTable } from './UserSettingsEditableTable';
import { User, useUserSettings } from './userSettingsServices';
import { useCurrentUrlCredentials } from '../HashCredentialsProvider';
import { useSoftwareDetails } from 'Entities/Software/software.services';
import { Box } from '@mui/material';
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';


export function UserSettingsManager(props: { customerCode: string, userCode?: string, asAdmin: boolean, hash: string }) {

    const { customerCode, userCode } = props;

    const [search, setSearch] = useState<undefined | string>(undefined);

    const [collection] = useUserSettings({ search, asAdmin: props.asAdmin });
    const isLoaded = collection.isSuccess;
    const initialEntities = collection.entities;

    const [entities, setEntities] = useState<User[]>([]);

    useEffect(() => { if (isLoaded) { setEntities(initialEntities); } }, [isLoaded, initialEntities]);

    const guardedExec = useGuardedExec();

    const handleSearchChange = useCallback((value: string) => {
        guardedExec(() => setSearch(value));
    }, [guardedExec]);

    const currentUser = entities.find(x => x.userCode === userCode);

    console.log("CURRENT USER", currentUser);

    const isNotProfiled = !(currentUser?.isProfiled);

    const hasNoRelnoteSettings = useMemo(() => {
        if (currentUser != undefined) {
            const mySettings = currentUser;
            if (!mySettings.inactive && !mySettings.showAtStartup && !mySettings.showOnForm) {
                return true;
            }
        }
        return false;
    }, [currentUser]);

    const softwareCode = useCurrentUrlCredentials().software;
    const softwareDetails = useSoftwareDetails({ code: softwareCode });
    const softwareName = softwareDetails?.userDescription ?? softwareCode;


    return <>
        {currentUser && entities.length > 1 && (
            <Box sx={{ my: 1 }}>
                <UserSettingsInfo softwareName={softwareName} softwareCode={softwareCode} user={currentUser} asAdmin={props.asAdmin}/>
            </Box>
        )}
        <ToolbarWithSearch
            searchValue={search}
            onSearchChanged={handleSearchChange}
            sx={{}}
        >
            {/* {currentUser && !!props.asAdmin && (
                <Collapsible initialExpanded sx={{zIndex:3, borderRadius: 2}}>
                    <UserSettingsInfo softwareName={softwareName} user={currentUser} />
                </Collapsible>
                )} */}

        </ToolbarWithSearch>

        <Box sx={{ height: hasNoRelnoteSettings ? 'auto' : 'calc(100% - 78px)' }}>
            <UserSettingsEditableTable
                entities={entities}
                userCode={props.userCode}
                customerCode={customerCode}
                onEntitiesUpdated={setEntities}
                softwareName={softwareName}
                softwareCode={softwareCode}
            />
        </Box>

        
        {currentUser && entities.length <= 1 && (
            <Box sx={{ my: 1 }}>
                <UserSettingsInfo softwareName={softwareName} softwareCode={softwareCode} user={currentUser} asAdmin={props.asAdmin} />
            </Box>
        )}

        <ProgressBackdrop open={!isLoaded} />
    </>
}










