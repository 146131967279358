import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack, Typography } from '@mui/material';
import { NonEditableField } from 'Components/FormUtils';

import {ExistingItemState, MacroareasCollectionAction} from "./macroareasManagerState";


export function MacroareaView(props: ExistingItemState & {
    dispatch: React.Dispatch<MacroareasCollectionAction>,
}) {

    const { entity, dispatch } = props;
    console.log(entity);

    const handleEditClick = () => { dispatch(['OPEN', { id: entity.id, forDelete: false }]) };

    const handleDeleteClick = () => { dispatch(['OPEN', { id: entity.id, forDelete: true }]) };

    return (
        <Stack direction='row' sx={{ width: 1 }} >
            <MyField value={entity.descriptionDe}/>
            <MyField value={entity.descriptionIt}/>
            <MyField value={entity.descriptionEn}/>
            <Stack direction='row' sx={{flexGrow: 1, flexBasis: '10%'}}>
                <IconButton onClick={handleEditClick}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={handleDeleteClick}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
}



const MyField = (props: { value: string }) => {
    return <Typography px={2} flexGrow={1} flexBasis={'30%'}>{props.value}</Typography>
}
