
import ProgressBackdrop from 'Components/Feedback/ProgressBackdrop';
import { Status } from 'Components/reduxLogger';
import {
    CollectionExplorerWithSearch
} from 'Components/RemoteEntities/Collection/CollectionExplorerWithSearch';
import { useDescriptionGetter } from 'Entities/DescribedCode/describedCode.models';
import { Form, useModuleFormsService } from 'Entities/SoftwareParts/form.services';
import { Module, useSoftwareModulesService } from 'Entities/SoftwareParts/module.services';
import {
    Software, useSoftwaresPaginated
} from 'Entities/Software/software.services';
import { useCallback, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ComputerIcon from '@mui/icons-material/Computer';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Box,
    Button,
    Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';



export function SoftwareDeepExplorer(props: { 
  onSelected: (item: Software | false) => void, 
}) {
  return (
    <CollectionExplorerWithSearch<Software> titleKey={'software'}
      useFilteredCollection={useSoftwaresPaginated}
      context={{}}
      onSelected={props.onSelected}
      hasNewButton={false}
    >{{
      CollectionView: SoftwareDeepList
    }}
    </CollectionExplorerWithSearch>
  )
}


function SoftwareDeepList(props: { data: Software[], onSelected?: (item: Software) => void, }) {

  const { data } = props;

  return <List>
    {data.map(software => (
      <SoftwareViewer key={software.code} software={software} 
        onSettingsClicked={props.onSelected}/>
    ))}
  </List>

}

const SoftwareViewer = (props: { software: Software, onSettingsClicked?: (software: Software)=>void}) => {

  const [open, setOpen] = useState(false);

  const [modules, fetchModules] = useSoftwareModulesService();

  const handleToggleOpen = () => {
    if (!open && modules.status === Status.Idle) {
      fetchModules({ softwareCode: props.software.code });
    }
    setOpen(!open);
  }

  const {onSettingsClicked, software} = props;

  const handleSettingsClicked = useCallback((evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (onSettingsClicked) onSettingsClicked(software);
  }, [software, onSettingsClicked]);

  return <>
    <ListItemButton onClick={handleToggleOpen}>
      <ListItemIcon>
        <ComputerIcon />
      </ListItemIcon>
      <ListItemText primary={props.software.code} />
      <Box sx={{mr: 6}}><Button onClick={handleSettingsClicked}>{'Settings'}</Button></Box>
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    {
      modules.status !== Status.Pending
        ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {modules.data.map((module: Module, index) => <ModuleViewer key={index} module={module} />)}
            </List>
          </Collapse>
        )
        : <ProgressBackdrop open />
    }

  </>
}


const ModuleViewer = (props: { module: Module }) => {

  const [open, setOpen] = useState(false);

  const [forms, fetchForms] = useModuleFormsService();

  const handleToggleOpen = () => {
    if (!open && forms.status === Status.Idle) {
      fetchForms({ softwareCode: props.module.softwareCode, moduleCode: props.module.code });
    }
    setOpen(!open);
  }

  const getDescription = useDescriptionGetter();

  return <>
    <ListItemButton onClick={handleToggleOpen} sx={{ pl: 6 }}>
      <ListItemIcon>
        <ViewModuleIcon />
      </ListItemIcon>
      <ListItemText primary={getDescription(props.module)} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    {
      forms.status !== Status.Pending
        ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {forms.data.map((form: Form, index) => <FormView key={index} form={form} />)}
            </List>
          </Collapse>
        )
        : <ProgressBackdrop open />
    }
  </>
}


const FormView = (props: { form: Form }) => {

  const getDescription = useDescriptionGetter();
    
  return (
    <ListItem sx={{ pl: 12 }}>
      <ListItemIcon>
        <TextSnippetIcon />
      </ListItemIcon>
      <ListItemText primary={getDescription(props.form)} />
    </ListItem>
  );
}



