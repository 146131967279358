import { Status } from 'Components/utils';

export interface RemoteEntityState<TModel> {
	data?: TModel;
	readStatus: Status;
	readError?: any;
	upsertStatus: Status;
	upsertError?: any;
	deleteStatus: Status;
	deleteError?: any;
	writeStatus: Status;
	writeError?: any
}

export function makeInitialState<TModel>(defaultData?: TModel): RemoteEntityState<TModel> {
	return {
		readStatus: Status.Idle,
		readError: undefined,
		upsertStatus: Status.Idle,
		upsertError: undefined,
		deleteStatus: Status.Idle,
		deleteError: undefined,
		data: defaultData,
		writeStatus: Status.Idle,
		writeError: undefined
	};
}

export type Actions<TModel> =
	| ['CREATE', TModel | undefined]
	| ['READ_REQUEST']
	| ['READ_SUCCESS', TModel]
	| ['READ_FAILURE', any]
	| ['UPSERT_REQUEST']
	| ['UPSERT_SUCCESS', Partial<TModel>]
	| ['UPSERT_FAILURE', any]
	| ['DELETE_REQUEST']
	| ['DELETE_SUCCESS']
	| ['DELETE_FAILURE', any]

export type Reducer<TModel> = (state: RemoteEntityState<TModel>, action: Actions<TModel>) => RemoteEntityState<TModel>;

export function makeEntityReducer<TModel>(entityName: string, initialValue?: TModel): Reducer<TModel> {

	if (entityName === '') {
		throw 'Entity name needed to create reducer';
	}
	const reducerName = `EntityReducer[${entityName}]`;
	const tempObj = {
		[reducerName]: (state: RemoteEntityState<TModel> = makeInitialState(initialValue), action: Actions<TModel>) => {
			switch (action[0]) {
				case 'CREATE':
					return {
						...state,
						data: action[1],
						readStatus: Status.Success,
						readError: undefined,
						writeStatus: Status.Idle,
						upsertStatus: Status.Idle,
						deleteStatus: Status.Idle,
					};
				case 'READ_REQUEST':
					return {
						...state,
						data: state.data,
						readStatus: Status.Pending,
						readError: undefined,
					};
				case 'READ_SUCCESS':
					return {
						...state,
						data: action[1],
						readStatus: Status.Success,
						readError: undefined,
						writeStatus: Status.Idle,
						upsertStatus: Status.Idle,
						deleteStatus: Status.Idle,
					};
				case 'READ_FAILURE':
					return {
						...state,
						readStatus: Status.Failed,
						readError: action[1],
					};
				case 'UPSERT_REQUEST':
					return {
						...state,
						data: state.data,
						upsertStatus: Status.Pending,
						upsertError: undefined,
						writeStatus: Status.Pending,
						writeError: undefined
					};
				case 'UPSERT_SUCCESS':
					return {
						...state,
						data: { ...(state.data ?? initialValue), ...action[1] } as TModel,
						upsertStatus: Status.Success,
						upsertError: undefined,
						writeStatus: Status.Success,
						writeError: undefined
					};
				case 'UPSERT_FAILURE':
					return {
						...state,
						upsertStatus: Status.Failed,
						upsertError: action[1],
						writeStatus: Status.Failed,
						writeError: action[1]
					};
				case 'DELETE_REQUEST':
					return {
						...state,
						data: state.data,
						deleteStatus: Status.Pending,
						deleteError: undefined,
						writeStatus: Status.Pending,
						writeError: undefined
					};
				case 'DELETE_SUCCESS':
					return {
						...state,
						data: undefined,
						readStatus: Status.Idle,
						deleteStatus: Status.Success,
						deleteError: undefined,
						writeStatus: Status.Success,
						writeError: undefined
					};
				case 'DELETE_FAILURE':
					return {
						...state,
						deleteStatus: Status.Failed,
						deleteError: action[1],
						writeStatus: Status.Failed,
						writeError: action[1]
					};
				default:
					return state;
			}
		}
	}
	const reducer: Reducer<TModel> = tempObj[reducerName];
	return process.env.NODE_ENV === 'development' ? withLogger<TModel>(reducer) : reducer;
	//return reducer;
}

function withLogger<TModel>(reducer: Reducer<TModel>): Reducer<TModel> {
	return function (state: RemoteEntityState<TModel>, action: Actions<TModel>): RemoteEntityState<TModel> {
		const newState = reducer(state, action);
		console.log(`${reducer.name} ${action[0]} \npayload`, action.length > 0 ? action[1] : '', '\nold state', state, '\nnew state', newState);
		return newState;
	}
}


