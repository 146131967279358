import { useUrlQuery } from "Components/useUrlQuery";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";


export interface HashedCredentials {
    software: string,
    customer: string,
    user: string,
    hash: string,
    asAdmin?: boolean
}

const defaultHashCredentials: HashedCredentials = {
    software: 'ANY',
    customer: 'ANY',
    user: 'TEST',
    hash: 'TEST'
}

const getAsAdmin = (query: URLSearchParams) => {
    const params = query.entries();
    let param = params.next();
    while (!param.done) {
        const keyValue = param.value;
        if (keyValue[0].toLowerCase() === 'asadmin' && keyValue[1].toLowerCase()==='true') {
            return true;
        }
        param = params.next();
    }
    return false;
}

export const CustomerUserContext = React.createContext<HashedCredentials>(defaultHashCredentials)

export const HashCredentialsProvider = (props: { children: React.ReactNode}) => {

    const route = useRouteMatch();
    const query = useUrlQuery();
    
    const { software, customer, user, hash } = route.params as any;

    const asAdmin = useMemo(() => getAsAdmin(query), [query]);
    
    console.log("HASH CREDENTIALS", software, customer, user, hash, asAdmin);

    const context = useMemo(() => ({
        software: software, 
        customer: customer ?? defaultHashCredentials.customer, 
        user: user ?? defaultHashCredentials.user, 
        hash: hash ?? defaultHashCredentials.hash,
        asAdmin
    }), [software, customer, user, hash, asAdmin]);

    return (
        <CustomerUserContext.Provider value={context}>
            {props.children}
        </CustomerUserContext.Provider>
    );
}

export const useCurrentUrlCredentials = () => React.useContext(CustomerUserContext);

export const useHashAuthorizedApiRoute = () => {
    const { software, customer, user, hash } = React.useContext(CustomerUserContext);
    return `${software}/${customer}/${user}/${hash}`;
}

