import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Button, Dialog, DialogContent, IconButton, Stack} from "@mui/material";
import {FaqAbstract} from "./faq.models";
import {Dispatch, useContext, useRef, useState} from "react";
import {styled} from "@mui/system";
import {useTranslation} from "react-i18next";
import {FaqsCollectionAction} from "./faqsManagerState";
import {SwFaqsManagerContext} from "../Softwares/SwFaqsManagerContext";
import {SelectOtherSoftware} from "../Softwares/SelectOtherSoftware";

export const FaqCloneDialog = (props: {
    dispatch: Dispatch<FaqsCollectionAction>,
    abstract: FaqAbstract,
}) => {

    const [open, setOpen] = useState(false);
    const handleCancel = () => {
        setOpen(false);
    }

    const [t] = useTranslation();

    return <>
        <IconButton onClick={() => setOpen(true)}><ContentCopyIcon/></IconButton>

        <Dialog open={open} onClose={() => setOpen(false)} keepMounted={false}
                sx={{'& .MuiPaper-root-MuiDialog-paper': {maxWidth: 'auto'}}}>
            <FaqCloneDialogContent {...props} onClose={() => setOpen(false)}/>
        </Dialog>
    </>
}


const FaqCloneDialogContent = (props: {
    dispatch: Dispatch<FaqsCollectionAction>,
    abstract: FaqAbstract,
    onClose: () => void
}) => {

    const {abstract, dispatch, onClose} = props;

    const [openReleaseSelector, setOpenReleaseSelector] = useState(false);

    const [, shareDispatch] = useContext(SwFaqsManagerContext);

    const handleCloneIntoSameSoftware = () => {
        dispatch(['CLONE', abstract.id])
        props.onClose();
    };

    const handleCloneIntoOtherSoftware = () => {
        shareDispatch(['SET_FAQ_MODEL', abstract]);
        setOpenReleaseSelector(true);
    }
    
    const handleAbortCloningToOtherSoftware = () => {
        shareDispatch(['SET_FAQ_MODEL', undefined]);
        props.onClose();
    }

    const [t] = useTranslation();

    const myRef = useRef<HTMLElement>();

    return <DialogContent ref={myRef}>
        {!openReleaseSelector ? <>

            <p>{t('faqClone.question')}</p>

            <Stack direction={'column'}>
                <StyledButton variant='contained' onClick={handleCloneIntoSameSoftware}>
                    {t('faqClone.thisSoftware')}
                </StyledButton>
                <StyledButton variant='contained' onClick={handleCloneIntoOtherSoftware}>
                    {t('faqClone.otherSoftware')}
                </StyledButton>
                <StyledButton variant='outlined' onClick={onClose}>
                    {t('cancel')}
                </StyledButton>
            </Stack>

        </> : <>
            <SelectOtherSoftware container={myRef.current}/>
            <Stack>
                <StyledButton variant='outlined' onClick={handleAbortCloningToOtherSoftware}>
                    {t('cancel')}
                </StyledButton>
            </Stack>
        </>}
    </DialogContent>
}

const StyledButton = styled(Button)(() => ({
    margin: '12px 6px',
    textTransform: 'none'
})); 

