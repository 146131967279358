import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Box, Button, Drawer, Stack, Divider, ToolbarProps } from '@mui/material';
import Scrollbar from 'Components/scrollbar';
// component
import Iconify from 'Components/iconify';
import { useCallback, useState } from 'react';
import { StyledSearch } from 'Components/Input/StyledSearch';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
   height: 96,
   // display: 'flex',
   // alignItems: 'center',
   //justifyContent: 'space-between',
   padding: theme.spacing(0, 1, 0, 3),
   //padding: 0
}));


export default function ToolbarWithSearch(props: ToolbarProps & {
   searchValue?: string,
   onSearchChanged: (value: string) => void,
}) {

   const { onSearchChanged, searchValue, ...muiToolbarProps } = props;
   
   const handleSearchChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((evt) => { onSearchChanged(evt.currentTarget.value)}, [onSearchChanged]);

   const [t] = useTranslation();

   return (
      <StyledRoot {...muiToolbarProps}>
         <StyledSearch
            value={searchValue ?? ''}
            onChange={handleSearchChanged}
            placeholder={t('search') + '...'}
            startAdornment={
               <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
               </InputAdornment>
            }
         />
         {props.children}
      </StyledRoot>
   );
}

